import React, { useState } from "react";
import PropTypes from "prop-types";

import { determineStep } from "../../../utils/determineStep";
import Steps from "./steps";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import WidgetBurger from "./widgetBurger";

const Mobile = styled.div`
  ${mediaPhone} {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 60px;
    display: flex;
    flex-direction: column;
    border-radius: 30px 30px 0 0;
    justify-content: center;
    background-image: radial-gradient(
        circle at 0px 0px,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0) 81%
      ),
      linear-gradient(
        ${({ theme }) => theme.sell_instructions_bg_color},
        ${({ theme }) => theme.sell_instructions_bg_color}
      );
    background-blend-mode: overlay, normal;
    background-color: rgba(255, 255, 255, 0.85);
  }

  ${mediaDesktop} {
    display: none;
  }
`;

const MobileHeader = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11px;
`;

const MobileHeaderText = styled.span`
  margin-left: 2px;
  font-weight: bold;
`;

const Desktop = styled.div`
  border-radius: 30px 0 0 0;

  h1 {
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: rgb(51, 67, 61);
    margin: 3.5rem 0px 0px;
    span {
      color: ${({ theme }) => theme.primary_color};
    }

    ${mediaPhone} {
      font-size: 21px;
    }

    ${mediaDesktop} {
      font-size: 26px;
    }
  }

  h3 {
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    color: rgb(51, 67, 61);
    margin: 0;
    color: ${({ theme }) => theme.primary_color};

    ${mediaPhone} {
      font-size: 21px;
    }

    ${mediaDesktop} {
      font-size: 26px;
    }
  }

  h4 {
    font-size: 14px;
    line-height: 1.29;
    font-weight: 400;
    color: rgb(83, 92, 88);
    text-align: center;
    margin-bottom: 30px;
  }
`;

const Faq = styled.div`
  background-color: #f7f7f7;
  position: relative;
  height: 130px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  ${mediaPhone} {
    height: 120px;
  }

  ${mediaDesktop} {
    height: 130px;
  }

  h1 {
    color: #33433c;
    font-weight: 400;
    text-align: center;
    ${mediaPhone} {
      font-size: 18px;
    }

    ${mediaDesktop} {
      font-size: 25px;
      margin-top: 2rem;
    }
  }

  h3 {
    color: #868c88;
    font-size: 18px;
    font-weight: normal;
    text-align: center;

    ${mediaPhone} {
      font-size: 10px;
    }

    ${mediaDesktop} {
      font-size: 16px;
    }
  }
`;

const SellInstructions = styled.div`
  ${({ open, theme }) =>
    open
      ? `
  position: absolute;
  background-image: radial-gradient(
    circle at 0px 0px,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 81%
  ),
  linear-gradient(
    ${theme.sell_instructions_bg_color}, 
    ${theme.sell_instructions_bg_color}
  );
  background-blend-mode: overlay, normal;
  width: 100%;
  height: 600px;
  top: 4.7rem;
  z-index: 1;`
      : "display: none;"}
`;

const SellInstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-around;
  margin: 1.84615rem 3.84615rem 2.92308rem 3.07692rem;
`;

const SellInstructionsBack = styled.span`
  display: flex;
  justify-content: center;
  color: white;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 7rem;
`;

const Icon = styled.span`
  position: relative;
  top: 2px;
  transform: rotate(0deg);
  color: white;

  svg {
    width: 21px;
    fill: white;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
  }
`;

const Mast = {
  Mobile: ({ config, currentPath, node }) => {
    if (currentPath === "/faq") {
      return null;
    }

    const currentStep = determineStep(config.stepsV3Flow, currentPath, true);
    const stepText = config.stepsV3Text[currentStep];
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
      setIsOpen((open) => !open);
    };

    if (!stepText) return null;

    return (
      <React.Fragment>
        <Mobile>
          <MobileHeader onClick={() => toggle()}>
            Step {currentStep + 1}:{" "}
            <MobileHeaderText>{stepText[0]}</MobileHeaderText>
            <Icon>
              <svg
                width="12px"
                height="12px"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                role="presentation"
                fill="white"
              >
                <path
                  d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                  fill="white"
                ></path>
              </svg>
            </Icon>
          </MobileHeader>
          <WidgetBurger />
          <Steps.BarMobile config={config} currentPath={currentPath} />
          <SellInstructions
            open={isOpen === true}
            ref={node}
            id="mobileTooltip"
          >
            <SellInstructionsContainer>
              <Steps.Description config={config} currentPath={currentPath} />
            </SellInstructionsContainer>

            <SellInstructionsBack onClick={() => toggle()}>
              Back
            </SellInstructionsBack>
          </SellInstructions>
        </Mobile>
      </React.Fragment>
    );
  },
  Desktop: ({ config, currentPath }) => {
    if (currentPath !== "/faq") {
      return (
        <Desktop>
          {config.partnerBrand === "Anycard" && (
            <h1>
              {config.exchangeMastTextPartOneV1}{" "}
              <span>{config.exchangeMastTextPartTwoV1}</span>
            </h1>
          )}
          {config.partnerBrand !== "Anycard" && (
            <>
              <h1> {config.exchangeMastTextPartOneV1} </h1>
              <h3>{config.exchangeMastTextPartTwoV1}</h3>
            </>
          )}

          <h4>{config.exchangeMastTextPartThreeV1}</h4>
        </Desktop>
      );
    }

    if (currentPath === "/faq") {
      return (
        <Faq>
          <h1>Frequently asked Questions</h1>
          <h3>Got any questions? We've got answers!</h3>
        </Faq>
      );
    }
  },
};

Mast.propTypes = {
  config: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default Mast;
