import React from "react";
import { connect } from "react-redux";

import { defaultAction } from "../../../actions/defaultAction";

import ChooseVerification from "../containers/popups/chooseVerification";
import EnterPhoneVerifyCode from "../containers/popups/enterPhoneVerifyCode";
import VerificationInformationContainer from "../../../components/stepThree/verificationInformationContainer";
import OrderDetails from "../../../components/stepThree/orderDetails";
import SecurityImages from "../../../components/stepThree/securityImages";
import VerificationInfo from "../containers/popups/verificationInfo";
import PhoneAttemptsExceeded from "../containers/popups/phoneAttemptsExceeded";
import ContactInformation from "../../../components/stepThree/contactInfo";
import TLOVerificationInit from "../containers/popups/tloVerificationInit";
import TLOVerificationEnter from "../containers/popups/tloVerificationEnter";
import TLOVerificationExpired from "../containers/popups/tloVerificationExpired";
import TLOVerificationCompleted from "../containers/popups/tloVerificationCompleted";
import TLOVerificationError from "../containers/popups/tloVerificationError";

import {
  CHANGE_PAYPAL_STATUS,
  UPDATE_PASS_CODE,
  SET_CCV,
  SET_CHANGE_PHONE_TXT,
  ACCEPT_TERMS,
  DENY_TERMS,
  SELL_VERIFICATION_SET_EMAIL,
  SET_CREDIT_CARD,
  SELECT_MONTH,
  SELECT_YEAR,
  TOGGLE_VERIFICATION_BOX,
  SHOW_CHANGE_PHONE,
  HIDE_CHANGE_PHONE,
  HIDE_CHANGE_PHONE_ERROR,
  HIDE_ACCEPT_TERMS_ERROR,
  HIDE_SIGNIN_EMAIL_ERROR,
  HIDE_SIGNIN_PASS_ERROR,
  HIDE_SELL_VERIFICATION_EMAIL_ERROR,
  HIDE_SELL_VERIFICATION_PHONE_ERROR,
  HIDE_CREDIT_CARD_NUM_ERROR,
  HIDE_MONTH_ERROR,
  HIDE_YEAR_ERROR,
  HIDE_CCV_ERROR,
  HIDE_MAIN_PAYMENT_ERROR,
  MAKE_ANOTHER_PASSCODE_ATTEMPT,
  TOGGLE_DETAILS_ORDER,
  HIDE_DETAILS_ORDER,
  DISPLAY_CUSTOMER_INFO_ERRORS,
  DISPLAY_MAIN_PAYMENT_ERROR,
  HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR,
  HIDE_SELL_VERIFICATION_LAST_NAME_ERROR,
  HIDE_SELL_VERIFICATION_STREET_ERROR,
  HIDE_SELL_VERIFICATION_ZIP_ERROR,
  HIDE_SELL_VERIFICATION_CITY_ERROR,
  HIDE_SELL_VERIFICATION_STATE_ERROR,
  SELL_VERIFICATION_SET_FIRST_NAME,
  SELL_VERIFICATION_SET_LAST_NAME,
  SELL_VERIFICATION_SET_STREET,
  SELL_VERIFICATION_SET_STREET2,
  SELL_VERIFICATION_SET_CITY,
  SELL_VERIFICATION_SET_STATE,
  SELL_VERIFICATION_SET_ZIPCODE,
  DISPLAY_SELL_VERIFICATION_ERRORS,
  CLOSE_ATTEMPTS_EXCEEDED_BOX,
  SET_TWILIO_ATTEMPTS_EXCEEDED,
  SELL_VERIFICATION_SET_PHONE,
  SHOW_TLO_ERROR_PHONE_MODAL,
  HIDE_TLO_VERIFICATION_EXPIRED,
  HIDE_TLO_VERIFICATION_COMPLETED,
  SELECT_TLO_PHONE_NUMBER,
  SET_TLO_VERIFICATION_TYPE,
  HIDE_TLO_VERIFICATION_ENTER,
  SHOW_TLO_VERIFICATION_INIT,
  SET_TLO_ENTER_CODE,
  TLO_RESET,
  SELL_VERIFICATION_SET_PHONE_ERROR,
  SELL_VERIFICATION_SET_EMAIL_ERROR,
  DISPLAY_CREDIT_CARD_NUM_ERROR,
  DISPLAY_MONTH_ERROR,
  DISPLAY_YEAR_ERROR,
  DISPLAY_CCV_ERROR,
} from "../../../constants/actionTypes";

import {
  startVerify,
  completeVerify,
  createOrder,
  updatePhoneNumberForOrder,
  sendTLOVerification,
  doTLOVerify,
} from "../../../thunks/customerInfo";
import { createStore } from "redux";

class Payment extends React.Component {
  verificationComponents() {
    const {
      customerInfo,
      config,
      hideChangePhone,
      hideChangePhoneError,
      popups,
      setChangePhoneTxt,
      makeAnotherPasscodeAttempt,
      startVerify,
      completeVerify,
      updatePassCode,
      showChangePhone,
      sellVerification,
      toggleVerificationBox,
      tlo,
      isLoading,
    } = this.props;

    let Component = null;

    switch (config.verificationType) {
      case "twilio":
        Component = (
          <React.Fragment>
            <h1>Verification Type: {config.verificationType}</h1>
            <VerificationInfo
              popups={popups}
              toggleVerificationBox={toggleVerificationBox}
            />
            <EnterPhoneVerifyCode
              completeVerify={completeVerify}
              passCode={customerInfo.get("passCode")}
              orderID={customerInfo.get("orderID")}
              verifyID={customerInfo.get("verifyID")}
              updatePassCode={updatePassCode}
              showEnterCodeBox={popups.get("showEnterCodeBox")}
              attempts={customerInfo.get("attempts")}
              customTwilioMsg={customerInfo.get("customTwilioMsg")}
              makeAnotherPasscodeAttempt={makeAnotherPasscodeAttempt}
            />
            <ChooseVerification
              orderID={customerInfo.get("orderID")}
              updateThePhone={this.props.updateThePhone}
              setChangePhoneTxt={setChangePhoneTxt}
              changePhone={popups.get("changePhone")}
              showChoosePhoneOrTextBox={popups.get("showChoosePhoneOrTextBox")}
              phoneNumber={sellVerification.get("phone")}
              changePhoneTxt={customerInfo.get("changePhoneTxt")}
              startVerify={startVerify}
              showChangePhone={showChangePhone}
              hideChangePhone={hideChangePhone}
              hideChangePhoneError={hideChangePhoneError}
              _phoneErrorModal={customerInfo.get("_phoneErrorModal")}
            />
          </React.Fragment>
        );
        break;
      case "tlo":
        Component = (
          <React.Fragment>
            <TLOVerificationInit
              isShowingTLOVerificationInit={tlo.get(
                "isShowingTLOVerificationInit"
              )}
              phoneNumbers={tlo.get("phoneNumbers")}
              selectedPhoneNumberId={tlo.get("selectedPhoneNumberId")}
              showErrorModal={this.props.showTLOPhoneErrorModal}
              selectPhoneNumber={this.props.selectTLOPhoneNumber}
              onSend={(validationType) => {
                this.props.setTLOVerificationType(validationType);
                this.props.sendTLOVerification();
              }}
            />
            <TLOVerificationEnter
              isShowingTLOVerificationEnter={tlo.get(
                "isShowingTLOVerificationEnter"
              )}
              enteredCode={tlo.get("enteredCode")}
              enteredCodeError={tlo.get("enteredCodeError")}
              setTLOEnteredCode={this.props.setTLOEnteredCode}
              goToTLOVerificationInit={() => {
                this.props.hideTloVerificationEnter();
                this.props.showTloVerificationInit();
              }}
              doTLOResendCode={this.props.sendTLOVerification}
              doTLOVerify={this.props.doTLOVerify}
              isLoading={isLoading}
            />
            <TLOVerificationExpired
              isShowingTLOVerificationExpired={tlo.get(
                "iShowingTLOVerificationExpired"
              )}
              hideTLOVerificationExpired={this.props.hideTLOVerificationExpired}
            />
            <TLOVerificationCompleted
              isShowingTLOVerificationCompleted={tlo.get(
                "isShowingTLOVerificationCompleted"
              )}
              hideTLOVerificationCompleted={() => {
                this.props.hideTLOVerificationCompleted();
                this.props.resetTLO();
                this.props.history.push("/order-complete");
              }}
            />
            <TLOVerificationError />
          </React.Fragment>
        );
        break;
      default:
    }

    return Component;
  }

  componentDidMount() {
    this.props.hideOrderDetails();
    this.props.changePaypalStatus("");
    if (Object.keys(this.props.cards).length < 1) {
      this.props.history.push("/");
    } else {
      for (let card in this.props.cards) {
        if (this.props.config.partnerBrand === "PayPal") return;
        if (
          this.props.cards[card].match !== "PASSED" ||
          this.props.cards[card].serverError
        ) {
          this.props.history.push("/steptwo");
          return;
        }
      }
    }
    if (this.props.tlo.get("isShowingErrorModal")) {
      this.props.doTLOErrorEvent();
    }
  }

  closeAttemptsExceededBox = () => {
    this.props.closeAttemptsExceededBox();
    this.props.setTwilioAttemptsExceeded();
    this.props.history.push("/order-complete");
  };

  render() {
    const {
      popups,
      customerInfo,
      config,
      cartSell,
      setEmail,
      setPhone,
      sellVerificationSetFirstName,
      sellVerificationSetLastName,
      sellVerificationSetStreet,
      sellVerificationSetStreet2,
      sellVerificationSetCity,
      sellVerificationSetZip,
      sellVerificationSetState,
      sellShipping,
      hideSellVerificationFirstNameError,
      hideSellVerificationLastNameError,
      hideSellVerificationStreetError,
      hideSellVerificationCityError,
      hideSellVerificationStateError,
      hideSellVerificationZipError,
      hidePhoneError,
      hideEmailError,
      sellVerification,
      setPhoneError,
      setEmailError,
      store,
    } = this.props;

    return (
      <div className={"payment-container--v1 "}>
        {customerInfo.get("orderID") && this.verificationComponents()}

        {this.verificationComponents()}
        <PhoneAttemptsExceeded
          popups={popups}
          closeAttemptsExceededBox={this.closeAttemptsExceededBox}
        />

        <div className={"payment--v1 container common-step-two"}>
          <div className="col-md-12 payment-contain">
            <div
              className={
                customerInfo.get("mainPaymentError")
                  ? "alert alert-danger animated fadeInDown"
                  : "hide"
              }
              role="alert"
              style={{ marginLeft: "12px", marginRight: "13px" }}
            >
              <strong>Oops! </strong> {customerInfo.get("mainErrorMsg")}
            </div>
            <div className="col-md-6 fullscreen">
              <ContactInformation
                cartSell={cartSell}
                config={config}
                sellVerification={sellVerification}
                customerInfo={customerInfo}
                hideEmailError={hideEmailError}
                setEmail={setEmail}
                hidePhoneError={hidePhoneError}
                setPhone={setPhone}
                setPhoneError={setPhoneError}
                setEmailError={setEmailError}
                store={store}
              />
              <VerificationInformationContainer
                editNames={true}
                setFirstName={sellVerificationSetFirstName}
                setLastName={sellVerificationSetLastName}
                setStreet={sellVerificationSetStreet}
                setStreetTwo={sellVerificationSetStreet2}
                setCity={sellVerificationSetCity}
                setZip={sellVerificationSetZip}
                setNewState={sellVerificationSetState}
                sellShipping={sellShipping}
                hideFirstNameError={hideSellVerificationFirstNameError}
                hideLastNameError={hideSellVerificationLastNameError}
                hideStreetError={hideSellVerificationStreetError}
                hideCityError={hideSellVerificationCityError}
                hideStateError={hideSellVerificationStateError}
                hideZipError={hideSellVerificationZipError}
                sameAsShipping={sellVerification.get("sameAsShipping")}
                firstName={sellVerification.get("firstName")}
                lastName={sellVerification.get("lastName")}
                street={sellVerification.get("street")}
                street2={sellVerification.get("street2")}
                city={sellVerification.get("city")}
                zip={sellVerification.get("zip")}
                state={sellVerification.get("state")}
                firstNameError={sellVerification.getIn(["errors", "firstName"])}
                lastNameError={sellVerification.getIn(["errors", "lastName"])}
                streetError={sellVerification.getIn(["errors", "street"])}
                street2Error={sellVerification.getIn(["errors", "street2"])}
                cityError={sellVerification.getIn(["errors", "city"])}
                stateError={sellVerification.getIn(["errors", "state"])}
                zipError={sellVerification.getIn(["errors", "zip"])}
                store={store}
                {...this.props}
              />
            </div>
            <div className="col-md-6 fullscreen">
              <OrderDetails
                {...this.props}
                stepOneRoute={"/"}
                buttonText={this.props.config.sellBtnText}
                store={store}
              />
              {config.displayPaymentSecurityImages && <SecurityImages />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    popups: state.popups,
    cards: state.cards,
    cartSell: state.cartSell,
    customerInfo: state.customerInfo,
    config: state.config,
    payoutType: state.config.paymentType,
    merchantsSell: state.merchantsSell,
    sellVerification: state.sellVerification,
    tlo: state.tlo,
    store: state.store,
    isLoading: state.remote.loading,
  }),
  (dispatch) => ({
    changePaypalStatus: (status) =>
      dispatch(defaultAction(CHANGE_PAYPAL_STATUS, status)),
    startVerify: (orderID, type) => dispatch(startVerify(orderID, type)),
    completeVerify: (orderID, verifyID, code, context) =>
      dispatch(completeVerify(orderID, verifyID, code, context)),
    updatePassCode: (e) =>
      dispatch(
        defaultAction(
          UPDATE_PASS_CODE,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    placeOrder: (order, ctx) => dispatch(createOrder(order, ctx)),
    setCCV: (e) =>
      dispatch(
        defaultAction(SET_CCV, e.target.value.replace(/^[\s\uFEFF\xA0]+/g, ""))
      ),
    setChangePhoneTxt: (phoneNum) =>
      dispatch(defaultAction(SET_CHANGE_PHONE_TXT, phoneNum)),
    acceptTerms: (e) => dispatch(defaultAction(ACCEPT_TERMS)),
    denyTerms: (e) => dispatch(defaultAction(DENY_TERMS)),
    setEmail: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_EMAIL,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    setCreditCard: (e) =>
      dispatch(
        defaultAction(
          SET_CREDIT_CARD,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    selectMonth: (e) =>
      dispatch(
        defaultAction(
          SELECT_MONTH,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    selectYear: (e) =>
      dispatch(
        defaultAction(
          SELECT_YEAR,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    updateThePhone: (id, phone) =>
      dispatch(updatePhoneNumberForOrder(id, phone)),
    toggleVerificationBox: () =>
      dispatch(defaultAction(TOGGLE_VERIFICATION_BOX)),
    showChangePhone: () => dispatch(defaultAction(SHOW_CHANGE_PHONE)),
    hideChangePhone: () => dispatch(defaultAction(HIDE_CHANGE_PHONE)),
    displayMainError: (message) =>
      dispatch(defaultAction(DISPLAY_MAIN_PAYMENT_ERROR, message)),
    hideChangePhoneError: () =>
      dispatch(defaultAction(HIDE_CHANGE_PHONE_ERROR)),
    hideAcceptTermsError: () =>
      dispatch(defaultAction(HIDE_ACCEPT_TERMS_ERROR)),
    hideSignInEmailError: () =>
      dispatch(defaultAction(HIDE_SIGNIN_EMAIL_ERROR)),
    hideSignInPassError: () => dispatch(defaultAction(HIDE_SIGNIN_PASS_ERROR)),
    hideEmailError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_EMAIL_ERROR)),
    hidePhoneError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_PHONE_ERROR)),
    hideCreditCardNumError: () =>
      dispatch(defaultAction(HIDE_CREDIT_CARD_NUM_ERROR)),
    hideMonthError: () => dispatch(defaultAction(HIDE_MONTH_ERROR)),
    hideYearError: () => dispatch(defaultAction(HIDE_YEAR_ERROR)),
    hideCCVError: () => dispatch(defaultAction(HIDE_CCV_ERROR)),
    hideMainError: () => dispatch(defaultAction(HIDE_MAIN_PAYMENT_ERROR)),
    makeAnotherPasscodeAttempt: () =>
      dispatch(defaultAction(MAKE_ANOTHER_PASSCODE_ATTEMPT)),
    toggleViewOrderDetails: () => dispatch(defaultAction(TOGGLE_DETAILS_ORDER)),
    hideOrderDetails: () => dispatch(defaultAction(HIDE_DETAILS_ORDER)),
    displayCustomerInfoErrors: (errObj) =>
      dispatch(defaultAction(DISPLAY_CUSTOMER_INFO_ERRORS, errObj)),
    hideSellVerificationFirstNameError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR)),
    hideSellVerificationLastNameError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_LAST_NAME_ERROR)),
    hideSellVerificationStreetError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_STREET_ERROR)),
    hideSellVerificationZipError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_ZIP_ERROR)),
    hideSellVerificationCityError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_CITY_ERROR)),
    hideSellVerificationStateError: () =>
      dispatch(defaultAction(HIDE_SELL_VERIFICATION_STATE_ERROR)),
    sellVerificationSetFirstName: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_FIRST_NAME,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    sellVerificationSetLastName: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_LAST_NAME,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    sellVerificationSetStreet: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_STREET,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    sellVerificationSetStreet2: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_STREET2,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    sellVerificationSetCity: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_CITY,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    sellVerificationSetState: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_STATE,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    sellVerificationSetZip: (e) =>
      dispatch(
        defaultAction(
          SELL_VERIFICATION_SET_ZIPCODE,
          e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
        )
      ),
    displayVerificationErrors: (obj) =>
      dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_ERRORS, obj)),
    closeAttemptsExceededBox: () =>
      dispatch(defaultAction(CLOSE_ATTEMPTS_EXCEEDED_BOX)),
    setTwilioAttemptsExceeded: () =>
      dispatch(defaultAction(SET_TWILIO_ATTEMPTS_EXCEEDED)),
    setPhone: (phoneNum) =>
      dispatch(defaultAction(SELL_VERIFICATION_SET_PHONE, phoneNum)),
    showTLOPhoneErrorModal: () =>
      dispatch(defaultAction(SHOW_TLO_ERROR_PHONE_MODAL)),
    hideTLOVerificationExpired: () =>
      dispatch(defaultAction(HIDE_TLO_VERIFICATION_EXPIRED)),
    hideTLOVerificationCompleted: () =>
      dispatch(defaultAction(HIDE_TLO_VERIFICATION_COMPLETED)),
    selectTLOPhoneNumber: (id) =>
      dispatch(defaultAction(SELECT_TLO_PHONE_NUMBER, id)),
    sendTLOVerification: () => dispatch(sendTLOVerification()),
    setTLOVerificationType: (type) =>
      dispatch(defaultAction(SET_TLO_VERIFICATION_TYPE, type)),
    setTLOEnteredCode: (code) =>
      dispatch(defaultAction(SET_TLO_ENTER_CODE, code)),
    hideTloVerificationEnter: () =>
      dispatch(defaultAction(HIDE_TLO_VERIFICATION_ENTER)),
    showTloVerificationInit: () =>
      dispatch(defaultAction(SHOW_TLO_VERIFICATION_INIT)),
    doTLOVerify: () => dispatch(doTLOVerify()),
    resetTLO: () => dispatch(defaultAction(TLO_RESET)),
    doTLOErrorEvent: () => dispatch(doEvent("PV_CANT_FIND_NUMBER_CLICKED")),
    setPhoneError: () =>
      dispatch(defaultAction(SELL_VERIFICATION_SET_PHONE_ERROR)),
    setEmailError: () =>
      dispatch(defaultAction(SELL_VERIFICATION_SET_EMAIL_ERROR)),
    setCreditCardNumError: () =>
      dispatch(defaultAction(DISPLAY_CREDIT_CARD_NUM_ERROR)),
    setMonthError: () => dispatch(defaultAction(DISPLAY_MONTH_ERROR)),
    setYearError: () => dispatch(defaultAction(DISPLAY_YEAR_ERROR)),
    setCCVError: () => dispatch(defaultAction(DISPLAY_CCV_ERROR)),
  })
)(Payment);
