import React from "react";
import PropTypes from "prop-types";

import { EMAILREGEX, PHONEREGEX } from "../../constants/regex";

const ContactInfo = ({
  store,
  config,
  sellVerification,
  customerInfo,
  hideEmailError,
  setEmail,
  hidePhoneError,
  setPhone,
  setEmailError,
  setPhoneError,
}) => {
  const removeSpacesInEnteredPhone = (num) => num.match(/[0-9]+/g).join("");

  const checkPhone = (e) => {
    if (e.target.value.match(/[0-9]+/g) === null) {
      return false;
    }
    return PHONEREGEX.test(removeSpacesInEnteredPhone(e.target.value))
      ? true
      : false;
  };

  const formatPhone = (num) => {
    if (!num) {
      return num;
    }
    var numbers = num.replace(/\D/g, ""),
      char = { 0: "(", 3: ") ", 6: " - " };
    num = "";
    for (let i = 0; i < numbers.length; i++) {
      num += (char[i] || "") + numbers[i];
    }
    return num;
  };

  const checkEmail = (e) => {
    return EMAILREGEX.test(e.target.value) ? true : false;
  };

  const panelAdditionalStyles =
    config.layoutVersion === 1 ? { paddingBottom: "10px", width: "100%" } : {};

  const panelBodyAdditionalStyles =
    config.layoutVersion === 1 ? { padding: "0px 8px" } : {};

  const showInputs =
    store.payoutMethod === "PARTNER" ||
    store.payoutMethod === "DONATION" ||
    (config.partnerBrand && config.partnerBrand === "PayPal");
  const showEmailError = sellVerification.getIn(["errors", "email"]);
  const showPhoneNumError = sellVerification.getIn(["errors", "phone"]);

  return (
    <div
      className="panel panel-default col-md-6 payment_left full-width container-bg"
      style={panelAdditionalStyles}
    >
      <div
        className="panel-body container-bg animated fadeInUp"
        style={panelBodyAdditionalStyles}
      >
        <div className="row" id="name_inputs">
          {config.layoutVersion === 1 && (
            <div className="pay-panel-headers">Contact Information</div>
          )}
          <div className="row mar-b-5 no-mar-r no-mar-l">
            {showInputs ? (
              <React.Fragment>
                <div className="col-md-12 mar-b-10">
                  <input
                    type="text"
                    defaultValue={sellVerification.get("email")}
                    onChange={(e) => {
                      if (checkEmail(e)) {
                        hideEmailError();
                        setEmail(e);
                      }

                      if (!checkEmail(e) && !showEmailError) {
                        setEmailError();
                      }
                    }}
                    className="form-control"
                    placeholder={config.emailAddressTextPlaceHolder}
                  />
                  <div
                    className={
                      showEmailError ? " errorMsg fverror cc-def-color" : "hide"
                    }
                  >
                    Not a valid email address
                  </div>
                </div>
              </React.Fragment>
            ) : (
              `Email: ${customerInfo.get("email")}`
            )}
          </div>
          <div className="row mar-b-5 no-mar-r no-mar-l">
            {showInputs ? (
              <React.Fragment>
                <div className="col-md-12 mar-b-10">
                  <input
                    type="text"
                    defaultValue={sellVerification.get("phone")}
                    onChange={(e) => {
                      if (checkPhone(e)) {
                        hidePhoneError();
                        setPhone(removeSpacesInEnteredPhone(e.target.value));
                      }

                      if (!checkPhone(e) && !showPhoneNumError) {
                        setPhoneError();
                      }
                    }}
                    className="form-control"
                    placeholder={config.phoneNumTextPlaceHolder}
                    maxLength={16}
                  />
                  <div
                    className={
                      showPhoneNumError
                        ? " errorMsg fverror cc-def-color"
                        : "hide"
                    }
                  >
                    Not a valid phone number
                  </div>
                </div>
              </React.Fragment>
            ) : (
              `Phone: ${formatPhone(customerInfo.get("phone"))}`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ContactInfo.propTypes = {
  cartSell: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  sellVerification: PropTypes.object.isRequired,
  customerInfo: PropTypes.object.isRequired,
  hideEmailError: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  hidePhoneError: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setEmailError: PropTypes.func.isRequired,
  setPhoneError: PropTypes.func.isRequired,
};

export default ContactInfo;
