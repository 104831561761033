import fuzzysort from "fuzzysort";

const FLOOR = -Infinity;

class NullMerchant extends Error {}
class NullMerchants extends Error {}

function score(merchant, input, threshold = -2500) {
  if (merchant === null || typeof merchant === "undefined")
    throw new NullMerchant();

  let nameScore = FLOOR,
    aliasScore = FLOOR,
    scoreObject = null;

  aliasScore = merchant.aliases
    .map(alias => {
      const obj = fuzzysort.single(input, alias);
      return obj ? obj.score : FLOOR;
    })
    .reduce((acc, c) => Math.max(acc, c), FLOOR);

  scoreObject = fuzzysort.single(input, merchant.name);
  if (scoreObject) nameScore = scoreObject.score;

  return Math.max(nameScore, aliasScore) > threshold;
}

function filter({ merchants, pattern, threshold = -2500 }) {
  if (typeof merchants === "undefined" || merchants === null)
    throw new NullMerchants();
  merchants = JSON.parse(JSON.stringify(merchants));
  merchants.sort(
    (a, b) => score(b, pattern) / b.popular - score(a, pattern) / a.popular
  );
  return merchants.filter(i => score(i, pattern) > threshold);
}

export { score, filter };
