import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";

const DO_NOT_SHOW_TOTAL_ON_ENDPOINTS = [
  "/",
  "/why",
  "/faq",
  "/help",
  "/identity-tlo",
  "/identity-pass-code",
  "/order-complete",
];

const GiftCardImage = styled.img`
  ${mediaPhone} {
    max-height: 3rem;
    margin-right: 1rem;
    height: 2.3rem;
    color: #333;
  }
  ${mediaDesktop} {
    max-width: 5rem;
    max-height: 4rem;
  }
`;

const GiftCardTitle = styled.h1`
  flex: 1;
  font-weight: 400;
  color: #444;
  font-size: 1.23077rem;
  ${mediaPhone} {
    color: white;
  }
  ${mediaDesktop} {
    text-align: right;
  }
`;

const HR = styled.hr`
  border: 0.5px solid rgb(210, 229, 233);
`;

const H2 = styled.h2`
  text-align: right;
  margin-top: 0px;
  font-weight: 500;
  font-size: 1.5em;
`;

const H3 = styled.h3`
  text-align: right;
  margin: 0;
  padding: 0;
`;

const H4 = styled.h4`
  text-align: right;
  margin: 0;
`;

const PanelTitle = {
  V1: ({ config, cartTotal, currentPath }) => {
    if (config.exchangeMastVersion === 2) return null;

    const Container = styled.div`
      display: flex;
      flex-direction: row;
    `;

    const H1 = styled.h1`
      font-weight: lighter;
      text-align: right;
      margin: 0;
      padding: 0;
      margin-top: 1.5rem;
    `;

    const TotalText = styled.p`
      margin-top: -0.5rem;
      margin-bottom: 0;
      text-align: right;
      margin-bottom: 1rem;
      span {
        color: rgb(8, 167, 2);
        font-weight: bold;
      }
    `;

    return (
      <React.Fragment>
        <Container>
          <GiftCardImage
            alt={config.partnerBrand}
            src={config.exchangeMastCardImageUrl}
          />
          <GiftCardTitle>{config.exchangeMastTitle}</GiftCardTitle>
        </Container>

        {currentPath === "/" && (
          <React.Fragment>
            <H1>{config.exchangeMastTextPartOneV1}</H1>
            <H2>{config.exchangeMastTextPartTwoV1}</H2>
            <HR />
            <H4>{config.exchangeMastTextPartThreeV1}</H4>
          </React.Fragment>
        )}
        {!DO_NOT_SHOW_TOTAL_ON_ENDPOINTS.includes(currentPath) && (
          <React.Fragment>
            <TotalText>
              total <span>{cartTotal}</span>
            </TotalText>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  },
  V2: ({ config, cartTotal, currentPath }) => {
    if (config.exchangeMastVersion === 1) return null;
    const H3_V2 = styled.h3`
      text-align: center;
      margin-bottom: 3rem;
    `;
    const Wrapper = styled.div`
      display: flex;
      flex-direction: row;
      position: relative;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    `;
    const Container = styled.div`
      flex: 1;
    `;
    const GiftCardImage_v2 = styled.img`
      max-height: 3rem;
    `;
    const TotalText = styled.p`
      margin-top: 0.5rem;
      margin-bottom: 0px;
      text-align: right;
      span {
        color: rgb(8, 167, 2);
        font-weight: bold;
      }
    `;
    const ImageContainer = styled.div`
      display: flex;
      flex-direction: column;
    `;
    const H4_v2 = styled.h4`
      text-align: center;
      margin: 0px;
    `;
    return (
      <React.Fragment>
        {currentPath === "/" && (
          <React.Fragment>
            <H3_V2>{config.exchangeMastTextPartOneV2}</H3_V2>
          </React.Fragment>
        )}

        <Wrapper>
          <Container>
            <GiftCardImage_v2
              alt={config.partnerBrand}
              src={config.exchangeMastMultiLineBrandLogoUrlV2}
            />
          </Container>
          <ImageContainer>
            <GiftCardImage
              alt={config.partnerBrand}
              src={config.exchangeMastCardImageUrl}
            />
            {!DO_NOT_SHOW_TOTAL_ON_ENDPOINTS.includes(currentPath) && (
              <TotalText>
                total <span>{cartTotal}</span>
              </TotalText>
            )}
          </ImageContainer>
        </Wrapper>
        {currentPath === "/" && (
          <React.Fragment>
            <HR />
            <H4_v2>{config.exchangeMastTextPartTwoV2}</H4_v2>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  },
};

const Mast = {
  Mobile: ({ config, currentPath }) => {
    const MobileMastWrapper = styled.div`
      ${mediaDesktop} {
        display: none;
      }
      position: relative;
      margin-top: 6rem;
      margin-bottom: 4rem;
    `;
    const MobileMastContainer = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    `;

    return (
      <MobileMastWrapper>
        <MobileMastContainer>
          <GiftCardImage
            alt={config.partnerBrand}
            src={config.exchangeMastCardImageUrl}
          />
          <GiftCardTitle>
            {currentPath !== "/why"
              ? config.exchangeMastTitle
              : config.exchangeMastWhyTitle}
          </GiftCardTitle>
        </MobileMastContainer>
      </MobileMastWrapper>
    );
  },
  Desktop: ({ config, cartTotal, currentPath }) => {
    const DesktopMastWrapper = styled.div`
      padding-right: 1.92308rem;
      margin-bottom: 0.5rem;
      ${mediaPhone} {
        display: none;
      }
    `;

    return (
      <DesktopMastWrapper>
        <PanelTitle.V1
          config={config}
          cartTotal={cartTotal}
          currentPath={currentPath}
        />
        <PanelTitle.V2
          config={config}
          cartTotal={cartTotal}
          currentPath={currentPath}
        />
        <HR />
      </DesktopMastWrapper>
    );
  },
};

PanelTitle.propTypes = {
  config: PropTypes.object.isRequired,
  cartTotal: PropTypes.number.isRequired,
  currentPath: PropTypes.string.isRequired,
};

Mast.propTypes = {
  config: PropTypes.object.isRequired,
  cartTotal: PropTypes.number,
  currentPath: PropTypes.string.isRequired,
};

export default Mast;
