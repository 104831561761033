import React from "react";

export default ({ config, merchantsSell }) => {
  if (config.stepsVersion !== 1) return null;

  let highestDiscount = 0;
  merchantsSell.sortedByName.forEach((merchant) => {
    if (highestDiscount < merchant.sellPercentage) {
      highestDiscount = merchant.sellPercentage;
    }
  });

  return (
    <div className="sell-order-desc container" style={{ zIndex: "-10" }}>
      <div className="row text-center no-margin">
        <div className="col-md-12 buy-wrapper">
          <div className="col-md-4">
            <div className="buy-detail">
              <img src={config.iconBig1} />
            </div>
            <h3>
              {config.detailStepOneText ? config.detailStepOneText.header : ""}
            </h3>
            <p>
              {config.detailStepOneText ? config.detailStepOneText.text : ""}
            </p>
          </div>
          <div className="col-md-4">
            <div className="buy-detail">
              <img src={config.iconBig2} />
            </div>
            <h3>
              {config.detailStepTwoText ? config.detailStepTwoText.header : ""}
            </h3>
            <p>
              {config.detailStepTwoText ? config.detailStepTwoText.text : ""}
            </p>
          </div>
          <div className="col-md-4">
            <div className="buy-detail">
              <img className="brand-image" src={config.iconBig3} />
            </div>
            <h3>
              {config.detailStepThreeText
                ? config.detailStepThreeText.header
                : ""}
            </h3>
            <p>
              {config.detailStepThreeText
                ? config.detailStepThreeText.text
                : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
