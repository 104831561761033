import React from "react";
import { Helmet } from "react-helmet";

/**
 * Load Fraud/Analytics Tools
 *
 * @param {config} config - application config object.
 */
export default (googleAnalytics) => {
  return (
    <>
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', "${googleAnalytics}", { cookie_flags: 'secure;samesite=none' });
          `}
        </script>
        <script>
          {`
            (function () {
              var loadDeviceJs = function () {
                var element = document.createElement('script');
                element.src = ('https:' == document.location.protocol ? 'https:' : 'http:')
                  + '//device.maxmind.com/js/device.js';
                document.body.appendChild(element);
              };
              if (window.addEventListener) {
                window.addEventListener('load', loadDeviceJs, false);
              } else if (window.attachEvent) {
                window.attachEvent('onload', loadDeviceJs);
              }
            })();
        `}
        </script>
      </Helmet>
    </>
  );
};
