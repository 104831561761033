import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ActionContainer from "../containers/actionContainer";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import CCA from "../containers/a";

const Container = styled.div`
  background-color: white;
  border-radius: 0.2rem;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding: 0 1rem 0 1rem;
    text-align: justify;
  }

  ${mediaDesktop} {
    margin: 0 auto;
    width: 100%;
  }

  ${mediaPhone} {
    overflow-y: scroll;
  }
`;

const A = styled(CCA)`
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 3rem;
`;

export default () => {
  const history = useHistory();
  const whyContent = useSelector((state) => state.config.whyContent);

  return (
    <ActionContainer scrollable={true}>
      <React.Fragment>
        <Container>
          {whyContent.map(({ title, content }, index) => (
            <React.Fragment key={index}>
              <h4>{title}</h4>
              <p>{content}</p>
            </React.Fragment>
          ))}
        </Container>
      </React.Fragment>
      <React.Fragment>
        <A
          className="partner-accented-back--v3 accented-back--v3"
          onClick={() => history.goBack()}
        >
          Back
        </A>
      </React.Fragment>
    </ActionContainer>
  );
};
