import { configBuilder } from "ccui-framework";
import { commonConfig } from "../../../src/config/partner";

const Config = {
  fanatics: {
    ...commonConfig,
    //layout
    layoutVersion: 3,
    reduxLogger: false,
    layoutOverlayImagesLeftImg:
      "https://cdn.cardcash.com/website/partners/fanatics/FanaticsMobile.png",
    layoutOverlayImagesRightImg:
      "https://cdn.cardcash.com/website/partners/fanatics/FanaticsCards.png",

    exchangeMastTextPartOneV1: "Exchange Gift Cards for a",
    exchangeMastTextPartTwoV1: "Fanatics Gift Card",

    // meta
    paymentType: "Gift Card",
    aOrAn: "an",
    partnerBrand: "Fanatics",
    partnerURL: "http://www.fanatics.com/",
    partnerEmail: "support@cardcash.com",
    giftCardTerms:
      "https://www.fanatics.com/extras-gift-certificates/x-2965+z-931949264-4112655916",
    termsAndConditionsBuilder: (config) =>
      `Restrictions apply, see <a href='${config.giftCardTerms}'>Fanatics/Legal</a>`,
    // social
    facebookURL: "https://www.facebook.com/CardCash/",
    twitterURL: "https://twitter.com/CardCash",
    youTubeURL: null,
    instagramURL: "https://www.instagram.com/cardcash",
    googleURL: null,
    linkedInURL: null,
    pinterestURL: "https://www.pinterest.com/cardcash/",
    headerLogoOrder: ["headerLogoCC", "headerLogoBrand"],
    headerLogoBrandIcon:
      "https://cdn.cardcash.com/website/partners/fanatics/logo.png",
    // api
    googleAnalytics: "UA-71687019-1",
    x_cc_app: "oWuL2KXvm", // fanatics
    baseURL: "https://production-api.cardcash.com/",
    identityCardInfoTitle: "Enter Credit Card",
    // header
    headerMenuItemOrder: ["contactItem", "faqItem"],
    headerMenuFaqItem: {
      text: (config) => "FAQ",
    },

    headerBrandLogoIcon:
      "https://s3.amazonaws.com/new-cardcash-images/website/partners/fanatics/fanatics_logo.png",

    // steps
    stepsVersion: 3,
    stepsV3Text: [
      [
        "Get Offer",
        "Tell us about the brand and balance and get an instant offer.",
      ],
      ["Provide Details", "Enter the details for your gift card."],
      ["Verify Your Identity", "To help us protect everyone from fraudsters."],
      [
        "Receive your Fanatics Gift Card",
        "Your gift card will be emailed to you within 1 business day.",
      ],
    ],
    soundGoodAddAnotherCardLinkTxt: "+ Add Another Card",
    monthYearTextPlaceHolder: "MM/YY",
    cvvTextPlaceHolder: "CVV",
    tloMissingNumberLink: "Missing Number? Learn why",
    identityTloTitle: "Choose your phone number",
    identityTloPassCodeTitle: "Enter the code ",
    identityTloErrorTitle: "Phone Number Information",
    identityTloExpiredTitle: "Expired",

    tloVerifyBtnTxt: "Continue",

    tloErrorTxt: `<p>The phone numbers we list are pulled from trusted third parties who provide numbers associated with your identity.
    We can not accept any other phone numbers for verification.</p>  <p> Security is of paramount importance to CardCash and we apologize if you are unable to continue. </p>`,

    // Verification
    verificationType: "tlo",
    // offer
    offerButtonTxt: "Get Instant Offer",
    offerButtonTxtMany: "Get Instant Offer",
    offerBalanceInputPlaceHolder: "$ Remaining Balance",

    // card number and pin input boxs
    cardNumberPlaceHolderText: "Gift Card Number",
    cardPinPlaceHolderText: "PIN",

    // Have another card? Add it here?
    haveAnotherCardShowPopup: false,
    sellInstructionsDesktopTitle: "How It Works?",
    exchangeMastTextPartThreeV1: "Over 200 Brands Accepted!",

    cardCashUrl: "https://www.cardcash.com",
    tloResendCodeLinkTxt: "Resend Code",
    orderCompleteShowBonusTxt: false,
    theme: {
      background_color: "#edebe9",
      hyperlink_color: "#071D44",
      primary_color: "#F8211D",
      secondary_color: "#071D44",
      button_background_color: "#F8211D",
      button_font_color: "#FFFFFF",
      sell_instructions_bg_color: "#071D44",
      progress_bar: "#F8211D",
    },
  },

  "test-fanatics": {
    basename: null,
    devMode: "integration",
    googleAnalytics: "",
    x_cc_app: "cvRCR4C4z",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },

  localhost: {
    basename: null,
    devMode: "development",
    googleAnalytics: "",
    x_cc_app: "cvRCR4C4z",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("fanatics", Config);
