import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { doEvent } from "../../thunks/events";
import CCA from "../../layouts/v2/containers/a";
import CCButton from "../../layouts/v3/containers/button";
import { closeWidget } from "ccui-framework";

const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
`;
const Button = styled(CCButton)`
  margin-left: auto;
  margin-right: auto;
`;

const Action = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 5rem;
`;

const A = styled(CCA)`
  color: ${({ theme }) => theme.activeColor};
  &:visited {
    color: ${({ theme }) => theme.activeColor};
  }
`;

const TloVerificationError = ({ tloErrorTxt, showTloInit, config }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (config.partnerBrand === "Anycard") {
      setTimeout(() => {
        dispatch(
          closeWidget({ error: `Transaction flagged as possible fraudulent.` })
        );
      }, 5000);
    } else {
      dispatch(doEvent("PV_CANT_FIND_NUMBER_CLICKED"));
    }
  });

  return (
    <Container>
      <span
        style={{ textAlign: "center", paddingBottom: "1rem" }}
        dangerouslySetInnerHTML={{
          __html: tloErrorTxt, // needed to display format
        }}
      />

      {config.layoutVersion === 3 && (
        <Button onClick={showTloInit} active={true}>
          {config.partnerBrand === "Anycard" ? "Back to the Store" : "Back"}
        </Button>
      )}

      {config.layoutVersion === 2 && (
        <Action>
          <A onClick={showTloInit}>Back</A>
        </Action>
      )}
    </Container>
  );
};

TloVerificationError.propTypes = {
  config: PropTypes.object.isRequired,
  tloErrorTxt: PropTypes.string.isRequired,
  showTloInit: PropTypes.func.isRequired,
};

export default TloVerificationError;
