import { renderSite } from "ccui-framework";
import { combineReducers } from "../../../src/reducers/combineReducers";
import { configModule } from "./config";
import layoutSelector from "../../../src/layouts/selector";

// eslist ignored because SASS contains the style sheet to load. if not ignored, un-used var warning.
// eslint-disable-next-line
import "./styles.scss";

const config = configModule();

renderSite(config, combineReducers, layoutSelector(config));
