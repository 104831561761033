import React from "react";
import PropTypes from "prop-types";

import creditCardType from "credit-card-type";
import styled from "@emotion/styled";

import { mediaPhone } from "../../layouts/styles/Breakpoints";

const Card = styled.div`
  background-color: white;
  transition: all 1s ease, border-width 0s ease, background 0s ease,
    height 0.2s ease, top 0.5s ease;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  ${({ version }) =>
    version === 3
      ? `
      width: 100%;
      justify-content: space-between;
      box-shadow: rgba(31, 32, 33, 0.08) 0px 10px 10px 0px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      height: 4rem;  
      ${mediaPhone} {
        height: 3rem;
      } `
      : `
      height: 3rem;
      position: relative;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      border: 1px solid rgb(215, 215, 215);
      `}
`;

const TextV2 = styled.p`
  flex: 1;
  transition: all 1s ease, border-width 0s ease, background 0.2s ease,
    height 0.2s ease, top 0.5s ease;
  padding: 0 1rem;
  justify-content: space-between;
`;

const TextV3 = styled.p`
  padding: 0 2rem;
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  font-weight: bold;

  ${mediaPhone} {
    font-size: 12px;
  }
`;

const NumberV3 = styled.span`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 0.4rem;
  font-size: 14px;

  ${mediaPhone} {
    font-size: 12px;
  }
`;
const CreditCardInfo = ({ cardNumber, layoutVersion }) => {
  const getCreditCardUrl = (cardNumber) => {
    const ccType = creditCardType(cardNumber);
    if (ccType.length === 0) return null;

    const url = "https://cdn.cardcash.com/website";

    const cardName = ccType[0].niceType;
    const cardImageUrl =
      {
        Visa: `${url}/visa.jpg`,
        Mastercard: `${url}/mastercard.png`,
        "American Express": `${url}/amex.png`,
        Discover: `${url}/discover.png`,
      }[ccType[0].niceType] || null;

    return <img style={{ height: "1rem" }} src={cardImageUrl} alt={cardName} />;
  };

  return (
    <Card version={layoutVersion}>
      {layoutVersion === 2 && (
        <>
          <TextV2>{getCreditCardUrl(cardNumber)}</TextV2>
          <TextV2 style={{ textAlign: "right" }}>
            &hellip;
            {cardNumber.slice(cardNumber.length - 4, cardNumber.length)}
          </TextV2>
        </>
      )}

      {layoutVersion !== 2 && (
        <>
          <TextV3>
            <NumberV3>
              &hellip; &hellip; &hellip;
              {cardNumber.slice(cardNumber.length - 4, cardNumber.length)}
            </NumberV3>
          </TextV3>
          <TextV3>{getCreditCardUrl(cardNumber)}</TextV3>
        </>
      )}
    </Card>
  );
};

CreditCardInfo.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  layoutVersion: PropTypes.number.isRequired,
};

export default CreditCardInfo;
