import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import ActionContainer from "../containers/actionContainer";
import CCA from "../containers/a";

const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: auto;
  margin-right: auto;
  box-shadow: ${({ isOpen }) =>
    isOpen ? "0 10px 10px 0 rgba(119, 121, 132, 0.1)" : "none"};
  border-radius: 5px;
  width: 80%;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const QuestionContent = styled.div`
  background-color: ${({ isOpen }) => (isOpen ? "#FFFFFF" : "#F6F7F6")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 0 24px 0 24px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${mediaDesktop} {
    height: 75px;
    min-height: 65px;
  }
  ${mediaPhone} {
    height: 55px;
    min-height: 45px;
  }
`;

const Question = styled.p`
  font-weight: 500;
  ${mediaDesktop} {
    font-size: 16px;
  }

  ${mediaPhone} {
    font-size: 12px;
  }
`;

const AnswerContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  background-color: #ffffff;
  border-top: 2px solid #edeeee;
  transition-duration: 500ms;
  border-radius: 5px;
  height: auto;
  margin-top: 2px;
  padding: 25px;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${mediaDesktop} {
    min-height: 48px;
  }
  ${mediaPhone} {
    min-height: 30px;
  }
`;

const Answer = styled.p`
  color: #777984;
  line-height: 1.88;

  ${mediaDesktop} {
    font-size: 14px;
  }

  ${mediaPhone} {
    font-size: 12px;
  }
`;

const Icon = styled.span`
  background-color: transparent;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 50%;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};

  .expand-icon {
    width: 21px;
    color: #1f2021;
    fill: currentColor;
    height: 1em;
    display: inline-block;
    font-size: 50px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
`;

const A = styled(CCA)`
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 3rem;
`;

export default () => {
  const history = useHistory();
  const config = useSelector((state) => state.config);
  const [currentIdx, setCurrentIdx] = useState();
  const [previousIdx, setPreviousIdx] = useState();
  const [currentState, setCurrentState] = useState(false);
  const [previousState, setPreviousState] = useState(false);

  useEffect(() => {
    config.faqv3.content(config).map(({ question }, index) => {
      if (buildContentId(index, question) === history.location.hash.slice(1)) {
        const idx = history.location.hash.split("-")[0].slice(-1);
        stateToggle(Number(idx));
        refs[idx].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    });
  }, [config, history]);

  const stateToggle = (idx) => {
    if (previousIdx) {
      setPreviousState(false);
    }

    if (currentIdx === idx) {
      setCurrentState(false);
      setCurrentIdx();
    } else {
      setPreviousIdx(currentIdx);
      setCurrentIdx(idx);
      setCurrentState(true);
    }
  };

  const currentIndexState = (idx) => {
    if (previousIdx === idx) {
      return previousState;
    }

    if (currentIdx === idx) {
      return currentState;
    }

    return false;
  };

  const buildContentId = (index, question) =>
    `question${index}-${question
      .toLowerCase()
      .slice(0, 15)
      .replace(/\s+/g, "")}`;

  const refs = config.faqv3.content(config).reduce((acc, value, idx) => {
    acc[idx] = React.createRef();
    return acc;
  }, {});

  return (
    <ActionContainer faq={true} scrollable={true}>
      <React.Fragment>
        <Container>
          {config.faqv3.content(config).map(({ question, answer }, index) => (
            <Content
              key={index}
              isOpen={currentIndexState(index)}
              id={buildContentId(index, question)}
              ref={refs[index]}
            >
              <QuestionContent
                onClick={() => stateToggle(index)}
                isOpen={currentIndexState(index)}
              >
                <Question>{question}</Question>
                <Icon isOpen={currentIndexState(index)}>
                  <svg
                    className="expand-icon"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  </svg>
                </Icon>
              </QuestionContent>
              <AnswerContent isOpen={currentIndexState(index)}>
                <Answer
                  dangerouslySetInnerHTML={{
                    __html: answer,
                  }}
                />
              </AnswerContent>
            </Content>
          ))}
        </Container>
      </React.Fragment>
      <React.Fragment>
        <A onClick={() => history.goBack()}>Back</A>
      </React.Fragment>
    </ActionContainer>
  );
};
