import React from "react";
import FAQContent from "./faqContent";

export default props => {
  return (
    <div>
      <div className="faq_header">
        <p className="faq_title">
          Your unwanted gift cards are a new, innovative way to donate.
        </p>
        <div className="each-faq">
          Do you have a gift card that you don't plan to use? Have you been
          looking for another way you can help those who have been hurt by{" "}
          {props.config.donationCauseName}?
          <p>
            Turn your gift cards into donations. Unwanted gift cards are an
            untapped resource and it is time we put them to good use.
            <br />
            We accept gift cards from over 200 retailers and restaurants and
            turn them into help for {props.config.donationCauseName} victims.
          </p>
        </div>
      </div>
      <p>
        <span className="partner-specific-color bld">Step 1 — </span>{" "}
        <span className="text-light">Enter merchant name and card balance</span>
      </p>
      <p>
        <span className="partner-specific-color bld">Step 2 — </span>{" "}
        <span className="text-light">
          Enter your card number, pin, and basic customer information
        </span>
      </p>
      <p>
        <span className="partner-specific-color bld">Step 3 — </span>{" "}
        <span className="text-light">
          Your generous donation will be passed on to{" "}
          {props.config.partnerBrand}, you should receive a receipt from{" "}
          {props.config.partnerBrand} within seven days
        </span>
      </p>
      <hr />
      <h1>Gift Card Exchange FAQ's</h1>
      <FAQContent allFAQs={props.allFAQs} {...props} />
    </div>
  );
};
