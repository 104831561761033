import React from "react";

export default ({ config, toggleContactUs }) => {
  if (!config.showTermsConditions) return null;

  let textToRender;

  switch (config.partnerBrand) {
    case "Uber":
      textToRender = (
        <div
          className="text-center"
          style={{ fontFamily: "Roboto", fontWeight: "200", fontSize: "22px" }}
        >
          {config.bottomLinkText} <a href={config.partnerURL}>uber.com</a>
        </div>
      );
      break;
    case "Buy Buy Baby":
      textToRender = (
        <div className="text-center bld">
          {config.bottomLinkText}{" "}
          <a href={config.partnerURL}>{config.partnerBrand}</a> or{" "}
          <a href="https://www.bedbathandbeyond.com/">Bed Bath & Beyond</a> for
          any product, gift, or{" "}
          <a href="https://www.buybuybaby.com/store/page/BabyRegistry">
            registry
          </a>
          .
        </div>
      );
      break;
    default:
      textToRender = (
        <div className="text-center bld termsCondition--bottomLinkText">
          {config.bottomLinkText}{" "}
          <a href={config.partnerURL}>
            {config.bottomLinkContent || config.partnerBrand}
          </a>
        </div>
      );
      break;
  }

  return (
    <div>
      {config.donationStatement && (
        <div
          style={{
            marginRight: "10%",
            marginLeft: "10%",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          {config.donationStatement}
        </div>
      )}
      {textToRender}
      <div className="footer">
        <div className="container text-center">
          <div className="row no-margin">
            <span className="bld fnt16">Questions?</span>
            <span className="lnk" onClick={toggleContactUs}>
              {" "}
              Contact Us{" "}
            </span>
          </div>
          <div
            className="row text-center m_top_10"
            dangerouslySetInnerHTML={{
              __html: config.termsAndConditionsBuilder(config),
            }}
          />
        </div>
      </div>
    </div>
  );
};
