import React from "react";

export default ({
  twilioAttemptsExceeded,
  orderID,
  partnerBrand,
  partnerEmail
}) => {
  return (
    <div className="order-com-wrapper">
      {twilioAttemptsExceeded && (
        <div className="card-info-alert alert-text twilio-alert twilio-alert-receipt">
          We were unable to verify your phone number. Please contact customer
          service at support@cardcash.com to finalize your order.
        </div>
      )}
      <div className="container order-com">
        Thank you for your generosity! Your donation is complete.
        <div>
          {" "}
          Donation ID: <span className="partner-specific-color">
            {orderID}
          </span>{" "}
        </div>
        <div className="complete-details-contain" />
        <br />
        <br />
        <p className="detailed-text">
          <span>
            We will verify the correct balance on your card(s) and your donation
            should be sent in 1-2 business days. Within 7 days you should
            receive a tax deductible receipt from {partnerBrand}. If you have
            any questions please contact us at {partnerEmail}.{" "}
          </span>
        </p>
        <p className="detailed-text">Thank you and have a great day,</p>
        <p className="detailed-text" className="partner-specific-color">
          -Cardcash/{partnerBrand}
        </p>
      </div>
    </div>
  );
};
