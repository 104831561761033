function named(name, obj) {
  Object.defineProperty(obj, "name", {
    value: name
  });
  return obj;
}

/**
 * A Simple curry wrapper
 * @param {number} argCount - The number of arguments needed to execute the function.
 * @param {Function} fun - The function to be executed
 */
function curry(argCount, fun) {
  function implementation(fun) {
    return function impl(...args) {

      return args.length >= argCount
        ? fun(...args)
        : named(fun.name + `$${args.length}$${argCount - args.length}`, (...rest) => impl(...args.concat(rest)));
    };
  }

  return typeof fun === "function" ? implementation(fun) : implementation;
}

export default curry;
