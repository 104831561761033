import { Map } from "immutable";

import {
  SELL_VERIFICATION_SET_EMAIL,
  SELL_VERIFICATION_SET_PHONE,
  SELL_VERIFICATION_SET_FIRST_NAME,
  SELL_VERIFICATION_SET_LAST_NAME,
  SELL_VERIFICATION_SET_STREET,
  SELL_VERIFICATION_SET_STREET2,
  SELL_VERIFICATION_SET_CITY,
  SELL_VERIFICATION_SET_ZIPCODE,
  SELL_VERIFICATION_SET_STATE,
  CHANGE_PAYPAL_STATUS,
  UPDATE_PAYPAL_PROFILE_INFO,
  MATCH_CUSTOMER_INFO_TO_SELL_SHIPPING,
  MATCH_SELL_SHIPPING_TO_SELL_VERIFICATION,
  MATCH_CUSTOMER_INFO_TO_SELL_VERIFICATION,
  SHOW_SELL_VERIFICATION_SAME_AS_SHIPPING,
  HIDE_SELL_VERIFICATION_SAME_AS_SHIPPING,
  USE_UNIQUE_SELL_BILLING_ADDRESS,
  DISPLAY_SELL_VERIFICATION_ERRORS,
  HIDE_SELL_VERIFICATION_ZIP_ERROR,
  HIDE_SELL_VERIFICATION_CITY_ERROR,
  HIDE_SELL_VERIFICATION_LAST_NAME_ERROR,
  HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR,
  HIDE_SELL_VERIFICATION_STREET_ERROR,
  HIDE_SELL_VERIFICATION_STATE_ERROR,
  HIDE_SELL_VERIFICATION_PHONE_ERROR,
  HIDE_SELL_VERIFICATION_EMAIL_ERROR,
  HIDE_SELL_VERIFICATION_STREET2_ERROR,
  SELL_VERIFICATION_SET_PHONE_ERROR,
  SELL_VERIFICATION_SET_EMAIL_ERROR,
  DISPLAY_SELL_VERIFICATION_FIRST_NAME_ERROR,
  DISPLAY_SELL_VERIFICATION_LAST_NAME_ERROR,
  DISPLAY_SELL_VERIFICATION_STREET_ERROR,
  DISPLAY_SELL_VERIFICATION_STREET2_ERROR,
  DISPLAY_SELL_VERIFICATION_CITY_ERROR,
  DISPLAY_SELL_VERIFICATION_ZIP_ERROR,
  DISPLAY_SELL_VERIFICATION_STATE_ERROR,
  COMPLETE_ORDER_SELL,
} from "../constants/actionTypes";

const initialState = Map({
  firstName: "",
  lastName: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  creditCardNum: "",
  month: "",
  year: "",
  ccv: "",
  phone: "",
  email: "",
  paypalStatus: "",
  matchedOnInit: false,
  sameAsShipping: false,
  errors: Map({
    firstName: false,
    lastName: false,
    street: false,
    street2: false,
    city: false,
    state: false,
    zip: false,
    month: false,
    creditCardNum: false,
    year: false,
    ccv: false,
    phone: false,
    email: false,
  }),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SELL_VERIFICATION_SET_EMAIL:
      return state.set("email", action.payload);

    case SELL_VERIFICATION_SET_PHONE:
      return state.set("phone", action.payload);

    case SELL_VERIFICATION_SET_FIRST_NAME:
      return state.set("firstName", action.payload);

    case SELL_VERIFICATION_SET_LAST_NAME:
      return state.set("lastName", action.payload);

    case SELL_VERIFICATION_SET_STREET:
      return state.set("street", action.payload);

    case SELL_VERIFICATION_SET_STREET2:
      return state.set("street2", action.payload);

    case SELL_VERIFICATION_SET_CITY:
      return state.set("city", action.payload);

    case SELL_VERIFICATION_SET_ZIPCODE:
      return state.set("zip", action.payload);

    case SELL_VERIFICATION_SET_STATE:
      return state.set("state", action.payload);

    case CHANGE_PAYPAL_STATUS:
      return state.set("paypalStatus", action.payload);

    case UPDATE_PAYPAL_PROFILE_INFO:
      return state
        .set("firstName", action.payload.customer.firstname)
        .set("lastName", action.payload.customer.lastname)
        .set("email", action.payload.customer.email)
        .set("phone", action.payload.customer.phone[0].number)
        .set("street", action.payload.customer.address[0].street)
        .set("state", action.payload.customer.address[0].state)
        .set("zip", action.payload.customer.address[0].postcode)
        .set("city", action.payload.customer.address[0].city)
        .set("paypalStatus", "PAYPAL RECEIVED INFO");

    case MATCH_CUSTOMER_INFO_TO_SELL_SHIPPING:
      return state
        .set("firstName", action.payload.firstName)
        .set("lastName", action.payload.lastName)
        .set("street", action.payload.street)
        .set("street2", action.payload.street2)
        .set("city", action.payload.city)
        .set("zip", action.payload.zip)
        .set("state", action.payload.state)
        .set("sameAsShipping", action.payload.sameAsShipping);

    case MATCH_SELL_SHIPPING_TO_SELL_VERIFICATION:
      return state
        .set("firstName", action.payload.firstName)
        .set("lastName", action.payload.lastName)
        .set("street", action.payload.street)
        .set("street2", action.payload.street2)
        .set("city", action.payload.city)
        .set("zip", action.payload.zip)
        .set("state", action.payload.state)
        .set("sameAsShipping", true);

    case MATCH_CUSTOMER_INFO_TO_SELL_VERIFICATION:
      return state
        .set("firstName", action.payload.firstName)
        .set("lastName", action.payload.lastName)
        .set("street", action.payload.street)
        .set("street2", action.payload.street2)
        .set("city", action.payload.city)
        .set("zip", action.payload.zip)
        .set("state", action.payload.state)
        .set("email", action.payload.email)
        .set("phone", action.payload.phone);

    case SHOW_SELL_VERIFICATION_SAME_AS_SHIPPING:
      return state.set("sameAsShipping", true);

    case HIDE_SELL_VERIFICATION_SAME_AS_SHIPPING:
      return state.set("sameAsShipping", false);

    case USE_UNIQUE_SELL_BILLING_ADDRESS:
      return state
        .set("firstName", "")
        .set("lastName", "")
        .set("street", "")
        .set("street2", "")
        .set("city", "")
        .set("zip", "")
        .set("state", "")
        .set("sameAsShipping", false);

    case DISPLAY_SELL_VERIFICATION_ERRORS:
      return state
        .setIn(["errors", "firstName"], action.payload.firstName)
        .setIn(["errors", "lastName"], action.payload.firstName)
        .setIn(["errors", "street"], action.payload.street)
        .setIn(["errors", "street2"], action.payload.street2)
        .setIn(["errors", "city"], action.payload.city)
        .setIn(["errors", "state"], action.payload.state)
        .setIn(["errors", "zip"], action.payload.zip)
        .setIn(["errors", "phone"], action.payload.phone)
        .setIn(["errors", "email"], action.payload.zip);

    case HIDE_SELL_VERIFICATION_ZIP_ERROR:
      return state.setIn(["errors", "zip"], false);

    case HIDE_SELL_VERIFICATION_CITY_ERROR:
      return state.setIn(["errors", "city"], false);

    case HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR:
      return state.setIn(["errors", "firstName"], false);

    case HIDE_SELL_VERIFICATION_LAST_NAME_ERROR:
      return state.setIn(["errors", "lastName"], false);

    case HIDE_SELL_VERIFICATION_STREET_ERROR:
      return state.setIn(["errors", "street"], false);

    case HIDE_SELL_VERIFICATION_STATE_ERROR:
      return state.setIn(["errors", "state"], false);

    case HIDE_SELL_VERIFICATION_PHONE_ERROR:
      return state.setIn(["errors", "phone"], false);

    case HIDE_SELL_VERIFICATION_EMAIL_ERROR:
      return state.setIn(["errors", "email"], false);

    case HIDE_SELL_VERIFICATION_STREET2_ERROR:
      return state.setIn(["errors", "street2"], false);

    case SELL_VERIFICATION_SET_PHONE_ERROR:
      return state.setIn(["errors", "phone"], true);

    case SELL_VERIFICATION_SET_EMAIL_ERROR:
      return state.setIn(["errors", "email"], true);

    case DISPLAY_SELL_VERIFICATION_FIRST_NAME_ERROR:
      return state.setIn(["errors", "firstName"], true);

    case DISPLAY_SELL_VERIFICATION_LAST_NAME_ERROR:
      return state.setIn(["errors", "lastName"], true);

    case DISPLAY_SELL_VERIFICATION_STREET_ERROR:
      return state.setIn(["errors", "street"], true);

    case DISPLAY_SELL_VERIFICATION_STREET2_ERROR:
      return state.setIn(["errors", "street2"], true);

    case DISPLAY_SELL_VERIFICATION_CITY_ERROR:
      return state.setIn(["errors", "city"], true);

    case DISPLAY_SELL_VERIFICATION_ZIP_ERROR:
      return state.setIn(["errors", "zip"], true);

    case DISPLAY_SELL_VERIFICATION_STATE_ERROR:
      return state.setIn(["errors", "state"], true);

    case COMPLETE_ORDER_SELL:
      return initialState;
    default:
      return state;
  }
};
