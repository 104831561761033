import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";
import Button from "../../layouts/v3/containers/button";
import CCA from "../../layouts/v2/containers/a";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ version }) => (version === 2 ? "100%" : "90%")};
  margin-left: auto;
  margin-right: auto;
`;

const HelpText = styled.div``;
const Text = styled.p`
  font-size: ${({ version }) => (version === 2 ? "13px" : "12px")};
  ${mediaPhone} {
    font-size: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: ${({ version }) => (version === 2 ? "left" : "center")};
  ${mediaPhone} {
    flex-direction: ${({ version }) => (version === 2 ? "row" : "column")};
  }
  ${mediaDesktop} {
    margin-top: ${({ version }) => (version === 2 ? "0px" : "4rem")};
  }

  p {
    font-size: 12px;

    ${mediaPhone} {
      font-size: 10px;
    }
  }

  button {
    border-radius: ${({ version }) => (version === 2 ? "0.1rem" : "30px")};
    font-size: ${({ version }) => (version === 2 ? "10px" : "14px")};
    font-weight: ${({ version }) => (version === 2 ? "normal" : "bold")};
    font-family: ${({ version }) => (version === 2 ? "Lato" : "Montserrat")};
    height: ${({ version }) => (version === 2 ? "21px" : "40px")};
    ${mediaPhone} {
      font-size: 10px;
    }
  }
`;

const TextBtn = styled(Button)`
  border-radius: ${({ version }) => (version === 2 ? "0.1rem" : "30px")};
  font-size: ${({ version }) => (version === 2 ? "10px" : "14px")};
  font-weight: ${({ version }) => (version === 2 ? "normal" : "bold")};
  font-family: ${({ version }) => (version === 2 ? "Lato" : "Montserrat")};
  height: ${({ version }) => (version === 2 ? "21px" : "40px")};
  ${mediaDesktop} {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  ${mediaPhone} {
    margin-bottom: 0.5rem;
  }
`;

const TloVerificationInit = ({
  phoneNumbers,
  selectedPhoneNumberId,
  selectPhoneNumber,
  onSend,
  config,
  showTloError,
  showMissingNumber,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const verifiableNumber = selectedPhoneNumberId
    ? phoneNumbers.find((phone) => phone.id === selectedPhoneNumberId)
    : null;

  return (
    <Container version={config.layoutVersion}>
      <select
        className={"form-control"}
        value={selectedPhoneNumberId}
        onChange={(e) => selectPhoneNumber(e.target.value)}
      >
        <option value="">{config.tloPhoneNumTextPlaceHolder}</option>
        {phoneNumbers.map((phoneNumber, i) => (
          <option key={i} value={phoneNumber.id}>
            {phoneNumber.maskedPhone}
          </option>
        ))}
      </select>

      {config.layoutVersion === 3 &&
        !selectedPhoneNumberId &&
        !verifiableNumber && (
          <HelpText>
            <Text version={config.layoutVersion}>
              {!selectedPhoneNumberId && config.tloHelperText}
            </Text>
            {showMissingNumber && (
              <p>
                <a onClick={showTloError}>{config.tloMissingNumberLink}</a>
              </p>
            )}
          </HelpText>
        )}

      {config.layoutVersion === 2 && (
        <HelpText>
          <Text version={config.layoutVersion}>{config.tloHelperText}</Text>
          {showMissingNumber && config.layoutVersion === 2 && (
            <p>
              <CCA onClick={showTloError}>{config.tloMissingNumberLink}</CCA>
            </p>
          )}
        </HelpText>
      )}

      {selectedPhoneNumberId && verifiableNumber && (
        <Actions version={config.layoutVersion}>
          {config.layoutVersion !== 2 && <p> Verify via:</p>}
          <TextBtn
            version={config.layoutVersion}
            className={
              config.layoutVersion === 2 ? "btn btn-primary activebtn" : ""
            }
            active={true}
            onClick={() => {
              setIsSubmitting(true);
              onSend("sms");
            }}
            disabled={isSubmitting}
          >
            TEXT <small> {verifiableNumber.maskedPhone}</small>
          </TextBtn>

          <Button
            version={config.layoutVersion}
            className={
              config.layoutVersion === 2 ? "btn btn-primary activebtn" : ""
            }
            active={true}
            onClick={() => {
              setIsSubmitting(true);
              onSend("call");
            }}
            disabled={isSubmitting}
          >
            CALL <small> {verifiableNumber.maskedPhone}</small>
          </Button>
        </Actions>
      )}
    </Container>
  );
};

TloVerificationInit.defaultProps = {
  showMissingNumber: true,
};

TloVerificationInit.propTypes = {
  config: PropTypes.object.isRequired,
  phoneNumbers: PropTypes.array.isRequired,
  selectedPhoneNumberId: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  selectPhoneNumber: PropTypes.func.isRequired,
  showTloError: PropTypes.func.isRequired,
  showMissingNumber: PropTypes.bool.isRequired,
};

export default TloVerificationInit;
