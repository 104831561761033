import React from "react";
import Modal from "react-modal";

export default ({ popups, hideDonationInfo, config }) => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    closeTimeoutMS={150}
    isOpen={popups.get("showDonationInfo")}
    onRequestClose={hideDonationInfo}
    contentLabel="Contact Us"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" onClick={hideDonationInfo}>
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title partner-specific-color">
          Donation Information
        </h4>
      </div>
      {config.donationInfoText && (
        <div className="modal-body">
          <p>{config.donationInfoText}</p>
        </div>
      )}
    </div>
  </Modal>
);
