import React from "react";
import { ALL_MONTHS } from "../../constants/payfields";

export default props => {
  const months = ALL_MONTHS.map((month, index) => {
    return (
      <option key={index} value={month.value}>
        {month.month}
      </option>
    );
  });
  return (
    <div className="col-md-4 no-padding-left">
      <select
        name={props.name}
        className="form-control"
        value={props.defaultVal}
        onChange={props.selectMonth}
      >
        <option value="">Month</option>
        {months}
      </select>
      <div
        className={props.error ? "animated fadeIn errorMsg fverror" : "hide"}
      >
        Select a month
      </div>
    </div>
  );
};
