import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import CCA from "./a";

const FooterContainer = styled.footer`
  position: relative;
  z-index: 0;
  padding: 4vw;
  padding-top: 1rem;
  background-color: white;
  text-decoration: none;
  text-align: center;
`;

const PartnerTerms = styled.p``;
const Contact = styled.p`
  font-weight: 600;
  a {
    font-weight: 400;
  }
`;
const CopySocialWrapper = styled.div`
  ${mediaPhone} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${mediaDesktop} {
    display: flex;
    flex-direction: row;
  }
`;

const CopyRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    display: inline-block;
  }
  text-align: left;
  img {
    margin-right: 1rem;
  }
  ${mediaPhone} {
    margin-bottom: 1rem;
  }
`;
const CCLogo = styled.img``;
const SocialButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: inline-block;
  }
  text-align: right;
  flex-direction: row-reverse;
  img {
    max-height: 1.5rem;
  }
  a {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  ${mediaPhone} {
    margin-top: 1rem;
  }
`;

const Footer = ({ config }) => {
  if (!config.showFooter) return null; // if show footer is false break out!

  let year = new Date().getFullYear();

  const renderSocial = (network) => {
    let networkConfigName = `${network}URL`;
    if (!config[networkConfigName]) return null;

    return (
      <a href={config[networkConfigName]} target="_blank">
        <img
          src={`https://cdn.cardcash.com/website/partners/common/${network}_icon.png`}
        />
      </a>
    );
  };

  return (
    <FooterContainer>
      <Contact>
        Questions? <CCA href="/help#contact-us">Contact Us</CCA>
      </Contact>
      <PartnerTerms>
        Restrictions apply, see{" "}
        <CCA href={config.giftCardTerms}>
          {config.termsAndConditionsBuilder(config)}
        </CCA>
      </PartnerTerms>
      <CopySocialWrapper>
        <CopyRight>
          <CCLogo alt="Powered by CardCash" src={config.footerLogoImage} />
          <span> &copy; {year} CardCash</span>
        </CopyRight>
        <SocialButtons>
          {renderSocial("pinterest")}
          {renderSocial("linkedIn")}
          {renderSocial("youTube")}
          {renderSocial("twitter")}
          {renderSocial("instagram")}
          {renderSocial("facebook")}
        </SocialButtons>
      </CopySocialWrapper>
    </FooterContainer>
  );
};

Footer.propTypes = {
  config: PropTypes.object.isRequired,
};

export default Footer;
