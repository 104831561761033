import React from "react";
import styled from "@emotion/styled";

const CCA = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  color: rgb(43, 164, 211);

  &:visited {
    color: rgb(43, 164, 211);
  }
`;

export default (props) => <CCA {...props} />;
