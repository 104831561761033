import React from "react";
import PropTypes from "prop-types";

const TloVerificationExpired = ({ tloExpiredTxt }) => {
  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: tloExpiredTxt // needed to display format
        }}
      />
    </>
  );
};

TloVerificationExpired.propTypes = {
  tloExpiredTxt: PropTypes.string.isRequired
};

export default TloVerificationExpired;
