import React, { Component } from "react";
import { connect } from "react-redux";
import FAQContent from "./faqContent";
import FAQDonation from "./faqDonation";
import { capitalizeFirstLetter } from "../../../utils/display";
import { generateAllFAQs, generateDonationFAQs } from "../../../utils/faqs";

export const FAQHeader = ({ content, ...rest }) => (
  <p {...rest} dangerouslySetInnerHTML={{ __html: content }} />
);

export const FAQSteps = ({ header, steps }) => (
  <div className="faq-steps-container">
    <p
      className="faq-steps-header"
      dangerouslySetInnerHTML={{ __html: header }}
    />
    <div className="faq-steps">
      {steps.map((line, i) => (
        <p className="faq-step" key={i}>
          <span className="partner-specific-color bld">Step {i + 1} — </span>{" "}
          <span className="step-text"> {line}</span>
        </p>
      ))}
    </div>
  </div>
);

export class FAQ extends Component {
  render() {
    const { config } = this.props;
    let allFAQs = generateAllFAQs(config);

    if (config.paymentType === "donation") {
      allFAQs = generateDonationFAQs(config);

      if (config.partnerBrand === "Charity On Top") {
        allFAQs.unshift({
          question: "Where is my donation going?",
          answer:
            "Charity On Top is affiliated with top charities and will be donating the proceeds to American Red Cross, Greater Houston Community Foundation- Hurricane Harvey Relief Fund, Houston Food Bank, Houston Humane Society and Americares. Further inquiries should be directed to inquire@charityontop.org.",
        });
      } else if (
        config.partnerBrand === "Agudath Israel of America" ||
        config.partnerBrand === "Houston Food Bank"
      ) {
        allFAQs.unshift({
          question: "Where is my donation going?",
          answer: `Proceeds will be sent to ${config.partnerBrand}`,
        });
      }
      return <FAQDonation {...this.props} />;
    }

    return (
      <div>
        <div className="faq_header">
          <p style={{ lineHeight: "1.3" }}>
            Turn your unused gift cards into {config.aOrAn}{" "}
            {config.partnerBrand} {config.paymentType}!
          </p>
          <div className="each-faq">
            Did you receive a gift card that you don't plan to use?
            <p>
              Turn it into {config.aOrAn} {config.partnerBrand}{" "}
              {config.paymentType} right now!
              <br />
              We accept gift cards from over 200 retailers and restaurants in
              exchange for {config.aOrAn} {config.partnerBrand}{" "}
              {config.paymentType}.
            </p>
          </div>
        </div>
        <p>
          <span className="partner-specific-color bld">Step 1 — </span>{" "}
          <span style={{ fontWeight: "300" }}>
            {" "}
            Enter merchant name and card balance for a fast and easy offer.
          </span>
        </p>
        <p>
          <span className="partner-specific-color bld">Step 2 — </span>{" "}
          <span style={{ fontWeight: "300" }}>
            {" "}
            Enter your card number, pin, and basic customer information.
          </span>
        </p>
        <p>
          <span className="partner-specific-color bld">Step 3 — </span>{" "}
          <span style={{ fontWeight: "300" }}>
            {" "}
            {config.aOrAn ? capitalizeFirstLetter(config.aOrAn) : ""}{" "}
            {config.partnerBrand} {config.paymentType} will be emailed to you!
            Your card can be used online{" "}
            {!!(config.partnerBrand === "Buy Buy Baby") && (
              <span>
                {" "}
                at{" "}
                <a href="https://www.buybuybaby.com" target="_blank">
                  https://www.buybuybaby.com
                </a>{" "}
                or at any Buy Buy Baby retail location.
              </span>
            )}
            {!!(config.partnerBrand === "Bed Bath & Beyond") && (
              <span> or in store </span>
            )}
            {!!(config.partnerBrand !== "Buy Buy Baby") && (
              <span>
                at <a href={config.partnerURL}>{config.partnerBrand}</a>
              </span>
            )}
          </span>{" "}
        </p>
        <hr />
        <h1 style={{ fontSize: "30px" }}>Gift Card Exchange FAQ's</h1>
        <FAQContent allFAQs={allFAQs} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;
  return {
    config,
  };
};

export default connect(mapStateToProps)(FAQ);
