import React from "react";

export default props => {
  const currentDate = new Date();
  const currentYear = Number(currentDate.getFullYear());
  let allYears = [];
  for (let i = 0; i < 15; i++) {
    allYears.push(
      <option key={i} value={currentYear + i + ""}>
        {currentYear + i + ""}
      </option>
    );
  }
  return (
    <div className="col-md-4 responsive_input no-padding-left">
      <select
        name={props.name}
        className="form-control"
        value={props.defaultVal}
        onChange={props.selectYear}
      >
        <option value="">Year</option>
        {allYears}
      </select>
      <div
        className={props.error ? "animated fadeIn errorMsg fverror" : "hide"}
      >
        Select a year
      </div>
    </div>
  );
};
