import React from "react";

export default ({ cards, merchantsSell, showDetailsOrder }) => {
  let allCardsInOrderDetails = [];

  if (Object.keys(merchantsSell).length) {
    for (let card in cards) {
      allCardsInOrderDetails.push(
        <div className="row" style={{ padding: "5px" }} key={card}>
          <div className="col-xs-8">
            {merchantsSell[cards[card].id]
              ? merchantsSell[cards[card].id]["name"]
              : ""}{" "}
            {merchantsSell[cards[card].id] &&
            merchantsSell[cards[card].id].submissionMethod === "mail-in"
              ? "- Mail In Req."
              : ""}
          </div>
          <div className="col-xs-4">${cards[card].cashValue}</div>
        </div>
      );
    }
  }

  return (
    <div
      className={
        showDetailsOrder
          ? "container animated fadeIn view-order-details"
          : "hide"
      }
    >
      <div
        className="row"
        style={{ padding: "5px", borderBottom: "1px solid #d4dde2" }}
      >
        <div className="col-xs-8">
          <span style={{ fontWeight: "bold" }}>Merchant</span>
        </div>
        <div className="col-xs-4">
          <span style={{ fontWeight: "bold" }}>Offer</span>
        </div>
      </div>
      {allCardsInOrderDetails.length ? allCardsInOrderDetails : ""}
    </div>
  );
};
