import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import ActionContainer from "../containers/actionContainer";
import Guard from "../containers/guard";

import { defaultAction } from "../../../actions/defaultAction";
import {
  isOrderIdEmptySelector,
  cartTotalSelector,
  customerInfoSelector,
} from "../../../selectors";

import styled from "@emotion/styled";
import CCA from "../containers/a";

import {
  RESET_TWILIO_ATTEMPTS_EXCEEDED,
  COMPLETE_ORDER_SELL,
} from "../../../constants/actionTypes";

const Wrapper = styled.div`
  height: 4rem;
  background-color: rgba(250, 250, 250, 0.9);
  position: relative;
  padding: 1rem;
  border-radius: 0.01rem;
  box-shadow: 0rem 0rem 0.1rem white, 0 0 0.1rem black;
`;
const Img = styled.img`
  height: 100%;
`;
const Brand = styled.h3`
  padding: 0;
  margin: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
const Total = styled.p`
  padding: 0;
  margin: 0;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  font-weight: bold;
  color: rgb(8, 167, 2);
`;
const OrderCompleteTxt = styled.p``;
const ThankYou = styled.h1`
  text-align: center;
`;
const Close = styled(CCA)`
  margin-top: -5px;
  text-align: center;
`;

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, cartTotal, isOrderIdEmpty, orderID } = useSelector(
    (state) => ({
      config: state.config,
      cartTotal: cartTotalSelector(state),
      isOrderIdEmpty: isOrderIdEmptySelector(state),
      orderID: customerInfoSelector(state).get("orderID"),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (config.partnerBrand === "Amazon" || config.partnerBrand === "eBay") {
      let dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: `${config.partnerBrand.toLowerCase()}-trade`,
        orderNumber: orderID + "",
        transactionAffiliation: `${config.partnerBrand}`,
        transactionTotal: 1,
        transactionTax: 0,
        total: cartTotal,
      });
    }

    return () => {
      dispatch(defaultAction(RESET_TWILIO_ATTEMPTS_EXCEEDED));
      dispatch(defaultAction(COMPLETE_ORDER_SELL));
    };
  }, [cartTotal, config.partnerBrand, dispatch, orderID]);

  return (
    <Guard condition={isOrderIdEmpty} redirect={"/"}>
      <ActionContainer title={config.orderCompleteTitle}>
        <>
          <Wrapper>
            <Img
              src={config.orderCompleteCardImageUrl}
              alt={config.partnerBrand + " Gift Card Image"}
            />
            <Brand>{config.partnerBrand}</Brand>
            <Total>{cartTotal}</Total>
          </Wrapper>
          <OrderCompleteTxt>
            {config.orderCompleteCardInfoTxt.text(config)}
          </OrderCompleteTxt>
          <ThankYou>Thank You!</ThankYou>
          <Close
            onClick={() => {
              dispatch(defaultAction(RESET_TWILIO_ATTEMPTS_EXCEEDED));
              dispatch(defaultAction(COMPLETE_ORDER_SELL));
              history.push("/");
            }}
          >
            close
          </Close>
        </>
      </ActionContainer>
    </Guard>
  );
};
