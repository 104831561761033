import React from "react";
import Modal from "react-modal";

export default ({
  isShowingTLOVerificationExpired,
  hideTLOVerificationExpired
}) => (
  <Modal
    className="Modal__Bootstrap modal-dialog modal-lg"
    isOpen={isShowingTLOVerificationExpired}
    contentLabel="Verification Expired"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={hideTLOVerificationExpired}
        >
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title partner-specific-color">
          Verification Expired
        </h4>
      </div>
      <div className="modal-body" style={{ fontSize: "14px" }}>
        <div className="m_10_bottom">
          The verification process for this order has expired, and your order
          was cancelled. Please contact customer service.
        </div>
      </div>
    </div>
  </Modal>
);
