import React from "react";
import DelayedRedirect from "./delayedRedirect";
import PropTypes from "prop-types";

const Guard = ({ condition, redirect = "/", children }) => {
  if (typeof condition === "function") {
    condition = condition();
  }

  return condition ? <DelayedRedirect to={redirect} /> : children;
};

Guard.propTypes = {
  condition: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

export default Guard;
