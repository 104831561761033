import React from "react";
import AddressVersion1 from "./addressVersion1";
import AddressVersion2 from "./addressVersion2";

export default props => {
  if (props.config.layoutVersion === 1) {
    return <AddressVersion1 {...props} />;
  } else {
    return <AddressVersion2 {...props} />;
  }
};
