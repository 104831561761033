import React from "react";
import Modal from "react-modal";

export default ({
  isShowingTLOVerificationCompleted,
  hideTLOVerificationCompleted
}) => (
  <Modal
    className="Modal__Bootstrap modal-dialog modal-lg"
    isOpen={isShowingTLOVerificationCompleted}
    contentLabel="Verification Completed"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={hideTLOVerificationCompleted}
        >
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title partner-specific-color">
          Verification Completed
        </h4>
      </div>
      <div className="modal-body" style={{ fontSize: "14px" }}>
        <div className="m_10_bottom">
          Your order is already verified. There is nothing left for you to do.
        </div>
      </div>
    </div>
  </Modal>
);
