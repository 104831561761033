import React from "react";
import Modal from "react-modal";

export default ({ showSessionExpired, startNewSession }) => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    isOpen={showSessionExpired}
    contentLabel="Session Expired"
  >
    <div className="modal-content">
      <div className="modal-header">
        <h4
          className="modal-title partner-specific-color animated fadeIn"
          style={{ textAlign: "center" }}
        >
          Thank you for shopping with us!
        </h4>
      </div>
      <div className="modal-body">
        <div className="m_bottom_10 animated fadeIn">
          You have been inactive for too long. For your protection, we expired
          your session, which emptied your cart and logged you out. Please click
          the button below or refresh the page to continue.
        </div>
        <br />
        <p>
          {" "}
          If you are still having trouble, you may have cookies disabled in your
          browser settings. If that's the case, please enable acceptance of
          cookies and try again.
        </p>
        <button
          onClick={startNewSession}
          className="btn btn-primary animated fadeIn"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            textAlign: "center"
          }}
        >
          {" "}
          Renew session{" "}
        </button>
      </div>
    </div>
  </Modal>
);
