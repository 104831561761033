import React from "react";

const InfoIcon = () => {
  return (
    <div className={`partner-info-icon info-icon`}>
      <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Flow"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.300000012"
        >
          <g
            id="Starbucks3"
            transform="translate(-347.000000, -707.000000)"
            fill="#535C58"
            fillRule="nonzero"
          >
            <g id="Form" transform="translate(232.000000, 156.000000)">
              <g id="Group-6" transform="translate(0.000000, 282.000000)">
                <g id="Group" transform="translate(115.000000, 269.000000)">
                  <g id="Group-6">
                    <g id="info">
                      <path d="M7,0 C3.15,0 0,3.15 0,7 C0,10.85 3.15,14 7,14 C10.85,14 14,10.85 14,7 C14,3.15 10.85,0 7,0 Z M7.7,10.5 L6.3,10.5 L6.3,6.3 L7.7,6.3 L7.7,10.5 Z M7.7,4.9 L6.3,4.9 L6.3,3.5 L7.7,3.5 L7.7,4.9 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

InfoIcon.propTypes = {};

export default InfoIcon;
