import React from "react";
import TermsDetail from "./verificationDetail";
import Modal from "react-modal";

const allTerms = [
  {
    title: "Identity Verification: ",
    description:
      "We are all safer when we are who we say we are. Your credit card helps confirm your billing address and identity, protecting our community and helping us offer some of the best payouts in the gift card exchange industry."
  },
  {
    title: "Authorization Charge: ",
    description:
      "A $1 authorization charge will be placed on your card. This is a standard method of verifying the validity of a credit card. No actual funds will be debited from your card."
  },
  {
    title: "Fraud Protection: ",
    description:
      "Your card will only be charged if we determine that the gift cards were obtained fraudulently or if an attempt is made to use the cards after completing this transaction. This is the best way of ensuring that only legitimate customers like yourself make it through our exchange process."
  },
  {
    title: "You are protected: ",
    description:
      "Your credit card information is processed through PayPal. It is never saved on our servers."
  }
];

export default ({ popups, toggleVerificationBox }) => {
  const verifInfo = allTerms.map((term, index) => (
    <TermsDetail
      title={term.title}
      description={term.description}
      key={index}
    />
  ));
  return (
    <Modal
      className="Modal__Bootstrap modal-dialog"
      closeTimeoutMS={150}
      isOpen={popups.get("vertificationBox")}
      onRequestClose={toggleVerificationBox}
      contentLabel="Needed?"
    >
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={toggleVerificationBox}
          >
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Close</span>
          </button>
          <h4 className="modal-title partner-specific-color">
            Why do we need this?
          </h4>
        </div>
        <div className="modal-body">{verifInfo}</div>
      </div>
    </Modal>
  );
};
