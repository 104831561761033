import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";

import { defaultAction } from "../../../../actions/defaultAction";
import { doEvent } from "../../../../thunks/events";

import { HIDE_TLO_ERROR_PHONE_MODAL } from "../../../../constants/actionTypes";

class tloVerificationError extends React.Component {
  componentDidMount() {
    if (this.props.tlo.get("isShowingTLOVerificationError")) {
      this.props.doTLOErrorEvent();
    }
  }

  render() {
    return (
      <Modal
        className="Modal__Bootstrap modal-dialog modal-lg"
        isOpen={this.props.tlo.get("isShowingTLOVerificationError")}
        contentLabel="Verification Sorry"
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={this.props.hideTLOPhoneErrorModal}
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            <h4 className="modal-title partner-specific-color">Sorry</h4>
          </div>
          <div className="modal-body" style={{ fontSize: "14px" }}>
            <div className="m_10_bottom">
              <p>
                We are sorry but CardCash cannot process your order at this
                time.
              </p>
              <br />
              <p>
                CardCash's security tools are very sensitive and we apologize if
                your order has been incorrectly flagged. We hope you will try us
                again sometime.
              </p>
              <br />
              <p>
                You may see a pending charge on your credit card. This is just
                an authorization and will fall off within a few days.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    tlo: state.tlo
  }),
  dispatch => ({
    hideTLOPhoneErrorModal: () =>
      dispatch(defaultAction(HIDE_TLO_ERROR_PHONE_MODAL)),
    doTLOErrorEvent: () => dispatch(doEvent("PV_CANT_FIND_NUMBER_CLICKED"))
  })
)(tloVerificationError);
