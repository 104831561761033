import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { mediaDesktop } from "../../styles/Breakpoints";

import { determineStep } from "../../../utils/determineStep";

const Steps = {
  Mobile: ({ config, currentPath }) => {
    if (!config.stepsVersion === 3 && !config.stepsVersionMobile) return null;

    const currentStep = determineStep(config.stepsV3Flow, currentPath, true);
    const isActive = (step) => {
      return step <= currentStep;
    };

    const ProgressBar = styled.div`
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;

      ${mediaDesktop} {
        display: none;
      }
    `;

    const Segment = styled.div`
      box-shadow: rgb(170, 170, 170) -0.0375rem 0.0375rem 0px;
      transform: translate(0.0375rem, -0.0375rem);
      transition: width 0.8s ease 0s, box-shadow 0.8s ease 0s,
        transform 0.8s ease 0s;
      position: relative;
      flex: 1;
      height: 0.3rem;
      margin: 0 0.1rem;
      &:last-child {
        margin-right: 0;
      }
      &:first-of-type {
        margin-left: 0;
      }
    `;
    const SegmentFill = styled.div`
      position: absolute;
      height: 100%;
      width: 100%;
      transition: width 0.8s ease 0s, box-shadow 0.8s ease 0s,
        transform 0.8s ease 0s;
      background-color: ${({ active, theme }) =>
        active ? theme.activeColor : "rgb(178, 178, 178) "};
    `;

    return (
      <ProgressBar>
        {config.stepsV3Flow.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <Segment>
                <SegmentFill active={isActive(index)}></SegmentFill>
              </Segment>
            </React.Fragment>
          );
        })}
      </ProgressBar>
    );
  },
  Desktop: ({ config, currentPath }) => {
    if (config.stepsVersion != 3) return null;

    const currentStep = determineStep(config.stepsV3Flow, currentPath, true);
    const isActive = (step) => {
      return step <= currentStep;
    };

    const Step = styled.div`
      display: flex;
      flex-direction: row;
    `;

    const StepWrapper = styled.div`
      display: flex;
      flex-direction: column;
      width: 3.15385rem;
    `;

    const StepContainer = styled.div`
      display: flex;
      flex-direction: row;
      min-height: 4.61538rem;
      padding-bottom: 0.8rem;
      flex: 1;
    `;

    const Bar = styled.div`
      min-height: 4rem;
      width: 0.3rem;
      background-color: rgb(255, 255, 255);
      position: relative;
      transition: height 0.8s ease 0s, box-shadow 0.8s ease 0s,
        transform 0.8s ease 0s;
      border-radius: 0.05rem;
      overflow: hidden;
      margin: 0.1rem;
    `;

    const BarFill = styled.div`
      position: absolute;
      width: 100%;
      height: 100%;
      transition: height 0.8s ease 0s, box-shadow 0.8s ease 0s,
        transform 0.8s ease 0s;
      background-color: ${({ active, theme }) =>
        active ? theme.activeColor : "rgb(178, 178, 178) "};
    `;

    const BarNumber = styled.p`
      text-align: right;
      flex: 1 1 0%;
      padding: 0px 0.6rem 0px 0px;
      margin: 0px;
    `;

    const TextContainer = styled.div`
      flex: 1;
      display: flex;
      flex-direction: column;
    `;

    const TextTitle = styled.h4`
      color: rgb(43, 44, 46);
      margin: 0px 0px 0.5rem;
    `;

    const TextDesc = styled.p`
      margin-top: 0px;
      margin-bottom: 1em;
      max-height: 3em;
      color: rgb(91, 96, 101);
    `;

    return (
      <React.Fragment>
        {config.stepsV3Flow.map((_, index) => {
          return (
            <Step key={index}>
              <StepWrapper>
                <StepContainer>
                  <Bar>
                    <BarFill active={isActive(index)}></BarFill>
                  </Bar>
                  <BarNumber>{`${index + 1}.`}</BarNumber>
                </StepContainer>
              </StepWrapper>
              <TextContainer>
                <TextTitle>{config.stepsV3Text[index][0]}</TextTitle>
                <TextDesc>{config.stepsV3Text[index][1]}</TextDesc>
              </TextContainer>
            </Step>
          );
        })}
      </React.Fragment>
    );
  },
};

Steps.propTypes = {
  config: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default Steps;
