export const determineStep = (stepNames, pathName, deepMatch = false) => {
  const step = stepNames.length - 1;
  let found;
  for (let i = step; i >= 0; i--) {
    if (found) break;
    if (deepMatch && stepNames[i].length > 1) {
      for (let j = stepNames[i].length; j >= 0; j--) {
        if (pathName === stepNames[i][j]) {
          found = i;
          break;
        }
      }
    } else {
      if (pathName === stepNames[i][0]) {
        found = i;
        break;
      }
    }
  }
  return found;
};
