import React from "react";
import DesktopSocial from "../containers/footer/desktopSocial";
import Copyright from "../containers/footer/copyright";
import MobileSocial from "../containers/footer/mobileSocial";

export default ({ config }) => {
  if (!config.showFooter) return null; // if show footer is false break out!

  let year = new Date().getFullYear();
  return (
    <div className="footerOuter">
      <div>
        <div className="container">
          <div className="footer_logo">
            <a href="https://www.cardcash.com/">
              <img className="footer_logo" src={config.footerLogoImage} />
            </a>
          </div>
          <span className="hidden-md-down copyright">© {year} CardCash </span>
          <MobileSocial config={config} copyright={year} />
          <Copyright config={config} copyright={year} />
          <DesktopSocial config={config} />
        </div>
      </div>
    </div>
  );
};
