import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import CCA from "../containers/a";
import { mediaDesktop } from "../../styles/Breakpoints";
import ActionContainer from "../containers/actionContainer";

const Wrapper = styled.div`
  border-radius: 0.2rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: rgb(51, 51, 51) 0px 0px 0.1rem inset;
  border-radius: 0.2rem;

  ${mediaDesktop} {
    max-height: 18rem;
  }
`;
const Container = styled.div`
  width: 80%;
  margin: 0px auto;
  text-align: left;
`;

const Title = styled.h4``;
const Desc = styled.p``;
const Back = styled(CCA)`
  color: ${({ theme }) => theme.activeColor};
  margin: 0.3rem;
  width: 100%;
  top: 1.2rem;
  position: relative;
  display: flex;
  justify-content: center;

  &:visited {
    color: ${({ theme }) => theme.activeColor};
  }
`;

export default () => {
  const history = useHistory();
  const whyContent = useSelector((state) => state.config.whyContent);

  return (
    <ActionContainer>
      <React.Fragment>
        <Wrapper>
          <Container>
            {whyContent.map(({ title, content }, index) => (
              <React.Fragment key={index}>
                <Title>{title}</Title>
                <Desc>{content}</Desc>
              </React.Fragment>
            ))}
          </Container>
        </Wrapper>
      </React.Fragment>
      <React.Fragment>
        <Back onClick={() => history.goBack()}>Back</Back>
      </React.Fragment>
    </ActionContainer>
  );
};
