import React from "react";
import styled from "@emotion/styled";

const CCButton = styled.button`
  background-color: ${({ active, theme }) =>
    active ? `${theme.button_background_color}` : "rgba(83, 92, 88, 0.1)"};
  color: ${({ active, theme }) =>
    active ? `${theme.button_font_color}` : "#bbbcbc"};
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-weight: bold;
  max-width: 437px;
  min-width: 50px;
  transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s,
    border-width 0.1s ease 0s;
  padding: 0.5em;
  outline: none;
  border-radius: 30px;
  border: rgba(83, 92, 88, 0.1);
  cursor: ${({ active, theme }) => (active ? "pointer" : "not-allowed")};
  font-family: "Montserrat", sans-serif;
`;

export default (props) => <CCButton {...props} />;
