export const generateAllFAQs = config => [
    {
      question: "How much will I receive in return for my gift card?",
      answer: "Every brand is different. In order to find the exchange value for your card, please enter the brand name of the gift card and the balance to receive an offer. You are under no obligation to exchange your card, and we will not retain your gift card information if you do not confirm your exchange."
    },
    {
      question: "Do you accept partially used cards or uneven balances?",
      answer: "Yes, we accept gift cards with any balance remaining on the gift card."
    },
    {
      question: "Do I need to mail in my gift card?",
      answer: "No, you do not need to mail in your card."
    },
    {
      question: "Can I exchange more than one gift card?",
      answer: "Yes, you can exchange more than one gift card. To trade in more than one, click the \"Add Another Card\" link."
    },
    {
      question: `When will I receive my ${config.partnerBrand} ${config.paymentType}?`,
      answer: `As soon as you finish the process, your ${config.partnerBrand} ${config.receivingItem} will be issued and emailed to you within 1 business day.`
    },
    {
      question: "Can I get better rates for my cards or is it the same for all gift cards?",
      answer: "Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive an offer. You are under no obligation to sell your card, and we will not retain your gift card information if you do not confirm your exchange. Please note that the offer you see displayed for the same gift card of the same value may change from time to time."
    },
    {
      question: `I received ${config.aOrAn} ${config.partnerBrand} ${config.receivingItem} of lesser value than what you offered.`,
      answer: `If you believe this was in error, please contact by email at ${config.partnerEmail}`
    },
    {
      question: `Where can I find my ${config.partnerBrand} ${config.receivingItem}?`,
      answer: "Your card will be emailed to you upon order approval."
    },
    {
      question: "What do you do with the gift cards?",
      answer: "CardCash.com is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website."
    },
    {
      question: "I changed my mind. How do I get my card back?",
      answer: "We're sorry; once you've accepted the offer, the sale is final."
    },
    {
      question: "Do you accept merchandise credits?",
      answer: "Yes."
    },
    {
      question: "Do you accept cards with expiration dates?",
      answer: "No, unfortunately, we cannot accept gift cards that have an expiration date."
    },
    {
      question: "I’m still confused, how can I reach you?",
      answer: `Our customer service department can be reached during normal business hours or by email at ${config.partnerEmail}`
    },
  ]

export const generateDonationFAQs = config => [
    {
      question: "How much of my gift card balance will be donated?",
      answer: `Your gift card will be resold at market value to generate a cash donation to ${config.partnerBrand}. Every brand is different. In order to find the donation value for your card, please enter the brand name of the gift card and the balance. You are under no obligation to donate your card, and we will not retain your gift card information if you do not confirm your donation.`
    },
    {
      question: "Do you accept partially used cards or uneven balances?",
      answer: "Yes, we accept gift cards with any balance remaining on the gift card."
    },
    {
      question: "Do I need to mail in my gift card?",
      answer: "No, you do not need to mail in your card."
    },
    {
      question: "Can I donate more than one gift card?",
      answer: "Yes, you can donate more than one gift card."
    },
    {
      question: "Can I get better donation rates for my cards or is it the same for all gift cards?",
      answer: "Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive donation amount. You are under no obligation to donate  your card, and we will not retain your gift card information if you do not confirm your donation . Please note that the amount you see displayed for the same gift card of the same value may change from time to time."
    },
    {
      question: "What do you do with the gift cards?",
      answer: "CardCash.com is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website and donate the sale price to help with Hurricane Harvey relief."
    },
    {
      question: "I changed my mind. How do I get my card back?",
      answer: "We are sorry. Once you've confirmed your donation, the donation is final"
    },
    {
      question: "Do you accept merchandise credits?",
      answer: "Yes."
    },
    {
      question: "Do you accept cards with expiration dates?",
      answer: "No, unfortunately, we cannot accept gift cards that have an expiration date."
    },
    {
      question: "I’m still confused, how can I reach you?",
      answer: "Our customer service department can be reached during normal business hours or by email at harveyrelief@cardcash.com. Please keep in mind that due to the overwhelming amount of inquiries we are receiving, it may take some time for us to get back to you."
    }
  ]

export const stockpileFAQs = () => [
  {
    question: "How much will I receive in return for my gift card?",
    answer: "Every brand is different.To see how much you'll get for your gift card, enter the brand name of the gift card and the balance. You are under no obligation to exchange your card, and we will not retain your gift card information if you decide not to go through with your exchange. Please note that the offer you see displayed for the same gift card of the same value may change from time to time."
  },
  {
    question: "Do you accept partially used cards or uneven balances?",
    answer: "Yes, we accept gift cards with any balance remaining on the gift card."
  },
  {
    question: "Do I need to mail in my gift card?",
    answer: "No, the transaction is 100 % electronic.You won't be mailing in your gift card."
  },
  {
    question: "Can I exchange more than one gift card?",
    answer: "Yes, you can.To trade in more than one, click the \"Add Another Card\" button."
  },
  {
    question: `When and where will I receive my Stockpile ${config.receivingItem}?`,
    answer: `When you finish the process, your Stockpile ${config.receivingItem} will be issued and emailed to you within 1 business day.`
  },
  {
    question: "I changed my mind.How do I get my card back?",
    answer: "We're sorry — once you've accepted the offer, the sale is final."
  },
  {
    question: "Do you accept merchandise credits?",
    answer: "Yes."
  },
  {
    question: "Do you accept cards with expiration dates?",
    answer: "No, unfortunately, we cannot accept gift cards that have an expiration date."
  },
  {
    question: "I still have a question — how can I reach you?",
    answer: "Our customer service department can be reached during normal business hours or by email at cardexchange@stockpile.com"
  }
]
