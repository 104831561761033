import {
  OPEN_ERROR_BOX,
  SET_ORDER_ID,
  SHOW_TWILIO_POPUP,
  SET_VERIFY_ID,
  HIDE_TWILIO_POPUP,
  OPEN_ATTEMPTS_EXCEEDED_BOX,
  UPDATE_ACCOUNT_STATUS,
  UPDATE_ATTEMPTS,
  MAKE_ANOTHER_PASSCODE_ATTEMPT,
  SET_TWILIO_ATTEMPTS_EXCEEDED,
  SHOW_ACCOUNT_LOCKED,
  SELL_VERIFICATION_SET_PHONE,
  HIDE_CHANGE_PHONE,
  DISPLAY_CHANGE_PHONE_ERROR,
  CHANGE_PAYPAL_STATUS,
  UPDATE_PAYPAL_PROFILE_INFO,
  UPDATE_TLO_VALIDATION_STATUS,
  SET_TLO_VALIDATION_PHONE_NUMBERS,
  SHOW_TLO_VERIFICATION_EXPIRED,
  SHOW_TLO_VERIFICATION_INIT,
  SHOW_TLO_VERIFICATION_COMPLETED,
  SHOW_TLO_ERROR_PHONE_MODAL,
  SHOW_TLO_VERIFICATION_ENTER,
  SET_TLO_ENTERED_CODE_ERROR,
  SET_ERROR,
  SHOW_LOADING,
  HIDE_LOADING,
  RESET_TWILIO_ATTEMPTS_EXCEEDED,
  COMPLETE_ORDER_SELL,
} from "../constants/actionTypes";

import { doEvent } from "./events";
import { defaultAction } from "../actions/defaultAction";

const setError = (msg, dispatch, versionOne) => {
  let payload = versionOne ? { title: "Error", message: msg } : msg;
  dispatch({ type: versionOne ? OPEN_ERROR_BOX : SET_ERROR, payload });
};

export const createOrder =
  (orderBody) =>
  async (dispatch, getState, { history, remote }) => {
    try {
      if (getState().config.layoutVersion === 1) {
        dispatch({
          type: SHOW_LOADING,
          payload: null,
        });
      }
      const order = await remote(
        "post",
        `${
          orderBody.paymentMethod === "ANYCARD"
            ? "/v3/orders/anycard"
            : "/v3/orders"
        }`,
        orderBody,
        "creating an order",
        dispatch
      );

      if (order.message) {
        throw order;
      }

      if (
        !order ||
        !order.orders ||
        !order.orders.ecode ||
        !("orderId" in order.orders.ecode) ||
        !("id" in order.orders.ecode)
      ) {
        if (getState().config.layoutVersion === 1) {
          dispatch({
            type: OPEN_ERROR_BOX,
            payload: {
              title: "Sorry, we are experiencing technical difficulties",
              message:
                "There was a problem creating your order. If the problem continues please contact Customer Support. Thank you.",
            },
          });
        } else {
          dispatch({
            type: SET_ERROR,
            payload:
              "Sorry, we are experiencing technical difficulties. There was a problem creating your order. If the problem continues please contact Customer Support. Thank you.",
          });
        }

        return;
      }

      // should not include a physical order from automatic splitting server-side
      if (Object.keys(order.orders.physical).length > 0) {
        if (getState().config.layoutVersion === 1) {
          dispatch({
            type: OPEN_ERROR_BOX,
            title: "Sorry, we are experiencing technical difficulties",
            message:
              "There was a problem creating your ecode-only order. If the problem continues please contact Customer Support. Thank you.",
          });
        } else {
          dispatch({
            type: SET_ERROR,
            payload:
              "Sorry, we are experiencing technical difficulties. There was a problem creating your ecode-only order. If the problem continues please contact Customer Support. Thank you.",
          });
        }

        return;
      }

      dispatch({
        type: SET_ORDER_ID,
        payload: {
          orderId: order.orders.ecode.orderId,
          id: order.orders.ecode.id,
        },
      });
      if (getState().config.layoutVersion === 1) {
        dispatch({
          type: HIDE_LOADING,
        });
      }
      if (order.orders.twilioRequired) {
        dispatch({ type: SHOW_TWILIO_POPUP });
        dispatch(getPhoneVerificationNumbers());
      } else {
        history.push("/order-complete");
      }
    } catch (error) {
      const layoutVersion1 = getState().config.layoutVersion === 1;
      const message = error && error.message && error.message[0];

      if (
        message.includes("declined") ||
        (message.includes("invalid") && !message.includes("risk"))
      ) {
        setError("Sorry, your card was declined.", dispatch, layoutVersion1);
        if (!layoutVersion1) history.push("/identity-card-info");
      } else if (message.includes("email")) {
        setError(message, dispatch, layoutVersion1);
        if (!layoutVersion1) history.push("/identity");
      } else if (message.includes("prepaid")) {
        setError(
          "Sorry, we don't accept prepaid credit cards.",
          dispatch,
          layoutVersion1
        );
        if (!layoutVersion1) history.push("/identity-card-info");
      } else if (message.includes("CVV2 Mismatch")) {
        setError(
          "The CVV code entered when you checked out is invalid.",
          dispatch,
          layoutVersion1
        );
        if (!layoutVersion1) history.push("/identity-card-info");
      } else if (
        message.includes("billing address") ||
        message.includes("Failed AVS Check")
      ) {
        setError(
          "The billing address you entered does not match the one on file with your credit card company.",
          dispatch,
          layoutVersion1
        );
      } else if (
        message.includes("total order value") &&
        message.includes("of $")
      ) {
        setError(message, dispatch, layoutVersion1);
      } else if (message.includes("risk")) {
        setError(
          "Sorry, your order was canceled by our fraud prevention service.",
          dispatch,
          layoutVersion1
        );
        if (!layoutVersion1 && orderBody.paymentMethod !== "ANYCARD") {
          dispatch(defaultAction(RESET_TWILIO_ATTEMPTS_EXCEEDED));
          dispatch(defaultAction(COMPLETE_ORDER_SELL));
          history.push("/");
        }
        if (!layoutVersion1 && orderBody.paymentMethod === "ANYCARD") {
          dispatch(defaultAction(SHOW_TLO_ERROR_PHONE_MODAL));
          history.push("/identity-tlo");
        }
      } else {
        setError(
          "Sorry, our servers are having some problems.",
          dispatch,
          layoutVersion1
        );
      }
      dispatch({
        type: HIDE_LOADING,
      });
    }
  };

export const startVerify =
  (orderId, method) =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const verify = await remote(
        "post",
        `/v3/orders/${getState().customerInfo.get("orderID")}/phone-verify`,
        { method },
        "starting phone verify for an order",
        dispatch
      );

      if (verify.message) {
        throw verify;
      }

      dispatch({ type: SET_VERIFY_ID, payload: verify.verifyId });
    } catch (e) {
      if (
        e.message &&
        e.message.length &&
        e.message[0] ===
          "You have exceeded the number of allowed attempts to verify your phone number. Please contact customer service."
      ) {
        dispatch({ type: HIDE_TWILIO_POPUP });
        dispatch({ type: OPEN_ATTEMPTS_EXCEEDED_BOX });
      } else {
        if (getState().config.layoutVersion === 1) {
          dispatch({
            type: OPEN_ERROR_BOX,
            payload: {
              title: "Sorry, we are experiencing technical difficulties",
              message:
                "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
            },
          });
        } else {
          dispatch({
            type: SET_ERROR,
            payload:
              "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
          });
        }
      }
    }
  };

export const completeVerify =
  (orderId, verifyId, code) =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const verify = await remote(
        "put",
        `/v3/orders/${getState().customerInfo.get("orderID")}/phone-verify`,
        { code: Number(code), verifyId: verifyId },
        "complete phone verify for an order",
        dispatch
      );

      if (verify.message) {
        throw verify;
      }

      history.push("/order-complete/");
    } catch (e) {
      if (!!e) {
        dispatch({ type: UPDATE_ACCOUNT_STATUS, payload: e.message });
        dispatch({ type: UPDATE_ATTEMPTS, payload: e.message[0][15] });
        if (e.message[0][15] === "3" || e.message[0][15] === "6") {
          dispatch({ type: MAKE_ANOTHER_PASSCODE_ATTEMPT });
        }
        if (
          e.message[0] ===
            "Error, verification did not meet round/attempt specifications." ||
          e.message[0] ===
            "You have exhausted all of your verification attempts. Please reach out to our customer support team at support@cardcash.com or 1800-227-4214 for assistance with this order."
        ) {
          dispatch({ type: SET_TWILIO_ATTEMPTS_EXCEEDED });
          history.push("/order-complete/");
        }
      } else {
        dispatch({ type: UPDATE_ACCOUNT_STATUS, payload: "Account Locked" });
        dispatch({ type: SHOW_ACCOUNT_LOCKED });
      }
    }
  };

export const updatePhoneNumberForOrder =
  (id, phone) =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const updatePhoneNumberForOrder = await remote(
        "put",
        `/v3/orders/${getState().customerInfo.get("orderID")}`,
        { phone },
        "update Phone Number For an Order",
        dispatch
      );

      if (updatePhoneNumberForOrder.message) {
        throw updatePhoneNumberForOrder;
      }

      dispatch({ type: SELL_VERIFICATION_SET_PHONE, payload: phone });
      dispatch({ type: HIDE_CHANGE_PHONE });
    } catch (e) {
      dispatch({ type: DISPLAY_CHANGE_PHONE_ERROR });
    }
  };

export const getPaypalInfo =
  (authorizationCode) =>
  async (dispatch, getState, { history, remote }) => {
    try {
      dispatch({
        type: CHANGE_PAYPAL_STATUS,
        payload: "PAYPAL REQUESTING INFO",
      });

      const response = await remote(
        "post",
        "v3/customers/paypal",
        { authorizationCode },
        "update Phone Number For an Order",
        dispatch
      );

      if (response.message) {
        throw response;
      }

      dispatch({ type: UPDATE_PAYPAL_PROFILE_INFO, payload: response });
      history.push("/payment");
    } catch (e) {
      if (getState().config.layoutVersion === 1) {
        dispatch({
          type: OPEN_ERROR_BOX,
          payload: {
            title: "Sorry, we are experiencing technical difficulties",
            message:
              "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
          },
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload:
            "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
        });
      }
    }
  };

const handleTLOErrs = (message, dispatch, history, layoutVersion) => {
  switch (message) {
    case message.includes("complete"):
      dispatch({ type: SHOW_TLO_VERIFICATION_COMPLETED });
      if (layoutVersion !== 1) {
        history.push("/order-complete");
      }

      break;
    case message.includes("expired"):
      dispatch({ type: SHOW_TLO_VERIFICATION_EXPIRED });
      if (layoutVersion !== 1) {
        history.push("/identity-tlo");
      }

      break;
    default:
      dispatch({ type: SHOW_TLO_ERROR_PHONE_MODAL });
      if (layoutVersion !== 1) {
        history.push("/identity-tlo");
      }

      break;
  }
};

export const getPhoneVerificationNumbers =
  () =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const phoneNumbers = await remote(
        "get",
        `/v3/orders/sell/${getState().customerInfo.get("orderID")}/validation`,
        {},
        "get TLO Phone Verification Numbers",
        dispatch
      );

      if (phoneNumbers.error) {
        throw phoneNumbers;
      }

      if (phoneNumbers.verifiableNumbers == null) {
        phoneNumbers.verifiableNumbers = [];
      }

      if (phoneNumbers.validationStatus !== "pending") {
        handleTLOErrs(
          phoneNumbers.validationStatus,
          dispatch,
          history,
          getState().config.layoutVersion
        );
      }

      dispatch({
        type: UPDATE_TLO_VALIDATION_STATUS,
        payload: phoneNumbers.validationStatus,
      });
      dispatch({
        type: SET_TLO_VALIDATION_PHONE_NUMBERS,
        payload: phoneNumbers.verifiableNumbers,
      });
      dispatch({ type: SHOW_TLO_VERIFICATION_INIT });

      if (getState().config.layoutVersion !== 1) {
        history.push("/identity-tlo");
      }
    } catch (e) {
      dispatch({ type: SHOW_TLO_ERROR_PHONE_MODAL });
      if (getState().config.layoutVersion !== 1) {
        history.push("/identity-tlo");
      }
    }
  };

export const sendTLOVerification =
  () =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const sendTLOVerification = await remote(
        "post",
        `/v3/orders/sell/${getState().customerInfo.get("orderID")}/validation`,
        {
          validationType: getState().tlo.get("validationType"),
          phoneNumberId: getState().tlo.get("selectedPhoneNumberId"),
        },
        "Send TLO Verification",
        dispatch
      );

      if (
        sendTLOVerification.message &&
        sendTLOVerification.message !== "Success"
      ) {
        throw message;
      }

      dispatch({ type: SHOW_TLO_VERIFICATION_ENTER });
      dispatch(
        doEvent("PV_CODE_INITIATED", {
          COMMUNICATION_METHOD: getState().tlo.get("validationType"),
          SIDE: "sell",
        })
      );
    } catch (error) {
      handleTLOErrs(
        error.message,
        dispatch,
        history,
        getState().config.layoutVersion
      );
    }
  };

export const doTLOVerify =
  () =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const doTLOVerify = await remote(
        "put",
        `/v3/orders/sell/${getState().customerInfo.get("orderID")}/validation`,
        {
          code: getState().tlo.get("enteredCode"),
        },
        "Do TLO verify",
        dispatch
      );

      if (doTLOVerify.message && doTLOVerify.message !== "success") {
        throw doTLOVerify;
      }

      dispatch({ type: SHOW_TLO_VERIFICATION_COMPLETED });
      dispatch(
        doEvent("PV_COMPLETE", {
          COMMUNICATION_METHOD: getState().tlo.get("validationType"),
          STATUS: "SUCCESS",
          SIDE: "sell",
        })
      );
      if (getState().config.layoutVersion !== 1) {
        history.push("/order-complete");
      }
    } catch (error) {
      dispatch(
        doEvent("PV_COMPLETE", {
          COMMUNICATION_METHOD: getState().tlo.get("validationType"),
          STATUS: "FAILURE",
          SIDE: "sell",
        })
      );

      if (error.message[0].includes("Incorrect code")) {
        dispatch({
          type: SET_TLO_ENTERED_CODE_ERROR,
          payload: error.message[0],
        });
      } else {
        handleTLOErrs(
          error.message[0],
          dispatch,
          history,
          getState().config.layoutVersion
        );
      }
    }
  };
