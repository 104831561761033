import React from "react";
import { PublicRoute } from "ccui-framework";

import { Route, Switch } from "react-router-dom";

import Offer from "../pages/offer";
import CardDetails from "../pages/cardDetails";
import Identity from "../pages/identity";
import IdentityCardInfo from "../pages/identityCardInfo";
import IdentityBillingInfo from "../pages/identityBillingInfo";
import IdentityPassCode from "../pages/identityPassCode";
import IdentityTlo from "../pages/identityTlo";
import OrderComplete from "../pages/orderComplete";
import Why from "../pages/why";
import FAQ from "../pages/faq";
import SoundsGood from "../pages/soundsGood";
import SellInstructions from "../pages/sellInstructions";
import Agreement from "../pages/agreement";

export default {
  Mast: () => (
    <Switch>
      <PublicRoute path="/agreement" component={Agreement} />
      <PublicRoute path="/faq" component={FAQ} />
      <PublicRoute path="/why" component={Why} />
      <PublicRoute path="/order-complete" component={OrderComplete} />
      <PublicRoute path="/identity" component={Identity} />
      <PublicRoute path="/identity-card-info" component={IdentityCardInfo} />
      <PublicRoute
        path="/identity-billing-info"
        component={IdentityBillingInfo}
      />
      <PublicRoute path="/identity-pass-code" component={IdentityPassCode} />
      <PublicRoute path="/identity-tlo" component={IdentityTlo} />
      <PublicRoute path="/card-details" component={CardDetails} />
      <PublicRoute path="/sounds-good" component={SoundsGood} />
      <PublicRoute path="/" component={Offer} />
    </Switch>
  ),
  Info: () => (
    <Switch>
      <Route path="/(faq)" />
      <Route path="/">
        <SellInstructions />
      </Route>
    </Switch>
  ),
};
