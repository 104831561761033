// Same validation logic taken from Common Lib, with some modifications (i.e. error message handling, etc.).

function validateLength(number_length, pin_length, cardNumberLength, cardPinLength, success) {
  
  if (number_length !== "null" && pin_length !== "null") {
    
    if ( (cardNumberLength == number_length) && (cardPinLength == pin_length) ) {
      success++;
      return success;
    }
  
  } else if (number_length !== "null" && pin_length === "null") {
  
    if (cardNumberLength == number_length) {
      success++;
      return success;
    }
  
  } else if (pin_length !== "null" && number_length === "null") {
  
    if (cardPinLength == pin_length) {
      success++;
      return success;
    }
  
  }

  return success;
}

export default (number, pin, validation) => {
  if (validation === null) { return { error: false }; }
  else {

    const cardNumberExist = (number === undefined || number === null || number === '') ? false : true;
    const cardPinExist = (pin === undefined || pin === null || pin === '') ? false : true;
    const cardNumberLength = (number === undefined || number === null || number === '' || number.length === 0) ? false : number.length;
    const cardPinLength = (pin === undefined || pin === null || pin === '' || pin.length === 0) ? false : pin.length;
    const validationSplit = validation.split(',');
    
    let success = 0;
    let nums = [];
    let pins = [];

    for (let i = 0, vLen = validationSplit.length; i < vLen; i++) {

      const validate = validationSplit[i].split(':');
      const number_length = validate[0];
      const pin_length = validate[1];

      nums.push(number_length.trim());
      pins.push(pin_length.trim());

      if (number_length === "null" && pin_length === "null") {
        success++;
      }

      if (cardNumberExist === true && cardPinExist === true) {
        success = validateLength(number_length, pin_length, cardNumberLength, cardPinLength, success);
      } else if (cardNumberExist === true && cardPinExist === false) {
        success = validateLength(number_length, pin_length, cardNumberLength, cardPinLength, success);
      } else if (cardNumberExist === false && cardPinExist === true) {
        success = validateLength(number_length, pin_length, cardNumberLength, cardPinLength, success);
      } else if (cardNumberExist === false && cardPinExist === false) {
        success = validateLength(number_length, pin_length, cardNumberLength, cardPinLength, success);
      }
    }

    if (success > 0) {
      return { error: false };
    }

    const hasNullCard = nums.some(v => v === 'null')
    const hasNullPin = pins.some(v => v === 'null')
    
    if (!hasNullCard && nums.indexOf(number && number.length.toString()) === -1) {
      return { error: true, message: 'Invalid card number' };
    }
    
    if (!hasNullPin && pins.indexOf(pin && pin.length.toString()) === -1) {
      return { error: true, message: 'Invalid pin number' };
    }

    return { error: true, message: 'Card and pin do not match' };
  }
};