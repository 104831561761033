import {
  SET_ERROR,
  SET_ORDER_ID,
  RESET,
  RESET_EXPIRE,
} from "../constants/actionTypes";

const initialState = { errorMsg: "", orderID: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { errorMsg: action.payload };

    case SET_ORDER_ID:
      return {
        ...state,
        orderID: action.payload.orderId,
      };
    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};
