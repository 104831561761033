import { Map } from "immutable";

import {
  SHOW_ALL_MERCHANTS,
  FILTER_BY_LETTER,
  FILTER_BY_CATEGORY,
  SORT_DROP_DOWN,
  FILTER_BY_LETTER_NEW,
  SHOW_ALL_MERCHANTS_NEW,
  SHOW_SEARCH,
  SHOW_DROPDOWN,
  HIDE_SEARCH,
  HIDE_DROPDOWN,
  SET_SEARCH_TEXT,
  UPDATE_ORDER_STATUS_ID,
  UPDATE_ORDER_STATUS_LAST_NAME,
  FADE_IN_CARD_ADDED,
  FADE_OUT_CARD_ADDED,
  HIDE_CARD_ADDED,
  CHANGE_PAYMENT_METHOD,
  CHANGE_CURRENT_CARD,
  RESET,
  RESET_EXPIRE,
  SET_NOTIFICATION_MSG,
  UPDATE_SIGN_IN_TXT,
  FADE_ERROR_INIT_FORGOT_PASSWORD,
  HIDE_ERROR_INIT_FORGOT_PASSWORD,
  SHOW_ERROR_INIT_FORGOT_PASSWORD,
  SET_ACTIVE_PANEL,
  RESET_ACTIVE_PANEL,
  SET_ACCT_SETTINGS_PANEL,
  RESET_ACCT_SETTINGS_PANEL,
  HIDE_BULK_NOTIFICATION,
  SHOW_BULK_NOTIFICATION,
  OPEN_REMOVE_CREDIT_CARD_MODAL,
  CLOSE_REMOVE_CREDIT_CARD_MODAL,
  OPEN_CHANGE_PASSWORD_MODAL,
  CLOSE_CHANGE_PASSWORD_MODAL,
  OPEN_HELP_OPTIONS,
  CLOSE_HELP_OPTIONS,
  SHOW_CARD_HELP_BUTTONS,
  CLOSE_CARD_HELP_BUTTONS,
  RESET_SEARCH_TEXT,
  SET_SORT_ORDER,
  SET_FIELD_TO_SORT,
  HIDE_ADD_CARD_BOX,
  SHOW_ADD_CARD_BOX,
  KEEP_ADD_CARD_BOX_CLOSED,
  SET_CHART_TO_RENDER,
  CLEAR_CHART_TO_RENDER,
} from "../constants/actionTypes";

const initialState = Map({
  filterCards: "ALL",
  filterCardsNew: "ALL",
  filterDropDown: "Select",
  showSearch: false,
  showDesktopSearch: false,
  searchText: "",
  sortOrder: "desc",
  fieldToSort: "",
  orderStatusId: "",
  orderStatusLastName: "",
  showDropdown: false,
  dropDownCategories: [
    { name: "All Cards" },
    { name: "Highest Discount" },
    { name: "Lowest Discount" },
    { name: "Most Cards" },
    { name: "Least Cards" },
  ],
  showCardAdded: "",
  paymentType: "Credit Card",
  lastMerch: "",
  notificationMsg: "",
  signInTxt: "Sign in",
  showInitForgotPassError: "",
  activePanel: null,
  acctSettingsPanelSelected: null,
  showEditProfileModal: false,
  showSupportModal: false,
  showSupportCardLevelModal: false,
  bulkNotification: false,
  showRemoveCreditCardModal: false,
  showChangePasswordModal: false,
  helpOptionsOpen: false,
  showCardHelpButtons: false,
  displayAddCardBox: true,
  addCardBoxExited: false,
  chartToRender: "payments",
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BULK_NOTIFICATION:
      return state.set("bulkNotification", true);

    case HIDE_BULK_NOTIFICATION:
      return state.set("bulkNotification", false);

    case UPDATE_SIGN_IN_TXT:
      return state.set("signInTxt", action.payload);

    case SET_SEARCH_TEXT:
      return state.set("searchText", action.payload);

    case SET_SORT_ORDER:
      return state.set("sortOrder", action.payload);

    case SET_FIELD_TO_SORT:
      return state.set("fieldToSort", action.payload);

    case RESET_SEARCH_TEXT:
      return state.set("searchText", initialState.get("searchText"));

    case SHOW_ALL_MERCHANTS:
      return state.set("filterCards", "ALL");

    case SORT_DROP_DOWN:
      return state.set("filterDropDown", action.payload);

    case FILTER_BY_CATEGORY:
      return state
        .set("filterCards", action.payload)
        .set("filterDropDown", "Select");

    case FILTER_BY_LETTER:
      if (
        action.payload === "ALL" ||
        action.payload === "HIGHEST_DISCOUNT" ||
        action.payload === "LOWEST_DISCOUNT" ||
        action.payload === "MOST_CARDS" ||
        action.payload === "LEAST_CARDS"
      ) {
        return state.set("filterCards", action.payload);
      } else {
        return state
          .set("filterCards", action.payload)
          .set("filterDropDown", "Select");
      }

    case FILTER_BY_LETTER_NEW:
      return state.set("filterCardsNew", action.payload);

    case SHOW_ALL_MERCHANTS_NEW:
      return state.set("filterCardsNew", "ALL");

    case SHOW_SEARCH:
      return state.set("showSearch", true);

    case SHOW_DROPDOWN:
      return state.set("showDropdown", true);

    case HIDE_SEARCH:
      return state.set("showSearch", false);

    case HIDE_DROPDOWN:
      return state.set("showDropdown", false);

    case UPDATE_ORDER_STATUS_ID:
      return state.set("orderStatusId", action.payload);

    case UPDATE_ORDER_STATUS_LAST_NAME:
      return state.set("orderStatusLastName", action.payload);

    case FADE_IN_CARD_ADDED:
      return state.set("showCardAdded", "FADE IN");

    case FADE_OUT_CARD_ADDED:
      return state.set("showCardAdded", "FADE OUT");

    case HIDE_CARD_ADDED:
      return state.set("showCardAdded", "");

    case CHANGE_PAYMENT_METHOD:
      return state.set("paymentType", action.payload);

    case SET_NOTIFICATION_MSG:
      return state.set("notificationMsg", action.payload);

    case CHANGE_CURRENT_CARD:
      return state
        .set("lastMerch", action.payload.lastMerch)
        .set("lastMerchType", action.payload.lastMerchType);

    case SHOW_ERROR_INIT_FORGOT_PASSWORD:
      return state.set("showInitForgotPassError", "SHOW ERROR");

    case FADE_ERROR_INIT_FORGOT_PASSWORD:
      return state.set("showInitForgotPassError", "FADE");

    case HIDE_ERROR_INIT_FORGOT_PASSWORD:
      return state.set("showInitForgotPassError", "");

    case SET_ACTIVE_PANEL:
      return state.set("activePanel", action.payload);

    case RESET_ACTIVE_PANEL:
      return state.set("activePanel", null);

    case SET_ACCT_SETTINGS_PANEL:
      return state.set("acctSettingsPanelSelected", action.payload);

    case RESET_ACCT_SETTINGS_PANEL:
      return state.set("acctSettingsPanelSelected", null);

    case OPEN_REMOVE_CREDIT_CARD_MODAL:
      return state.set("showRemoveCreditCardModal", true);

    case CLOSE_REMOVE_CREDIT_CARD_MODAL:
      return state.set("showRemoveCreditCardModal", false);

    case OPEN_CHANGE_PASSWORD_MODAL:
      return state.set("showChangePasswordModal", true);

    case CLOSE_CHANGE_PASSWORD_MODAL:
      return state.set("showChangePasswordModal", false);

    case OPEN_HELP_OPTIONS:
      return state.set("helpOptionsOpen", true);

    case CLOSE_HELP_OPTIONS:
      return state.set("helpOptionsOpen", false);

    case SHOW_CARD_HELP_BUTTONS:
      return state.set("showCardHelpButtons", true);

    case CLOSE_CARD_HELP_BUTTONS:
      return state.set("showCardHelpButtons", false);

    case HIDE_ADD_CARD_BOX:
      return state.set("displayAddCardBox", false);

    case SHOW_ADD_CARD_BOX:
      return state.set("displayAddCardBox", true);

    case KEEP_ADD_CARD_BOX_CLOSED:
      return state.set("keepAddCardBoxClosed", true);

    case SET_CHART_TO_RENDER:
      return state.set("chartToRender", action.payload);

    case CLEAR_CHART_TO_RENDER:
      return state.set("chartToRender", initialState.get("chartToRender"));

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};
