import React from "react";
import { currencyFormatter } from "../../utils/display";
import validateSellCard from "../../utils/validators/validateSellCard";
import { CARD_NUMBER_REGEX, CARD_PIN_REGEX } from "../../constants/regex";
import debounce from "../../utils/debounce";

export default class StepTwoCart extends React.Component {
  constructor(props) {
    super(props);
    this.updateMerchNum = debounce(this.updateMerchNum, 400);
    this.updateMerchPin = debounce(this.updateMerchPin, 400);

    this.state = {
      cardNumActive: false,
      pinActive: false
    };
  }

  componentDidMount() {
    const {
      cards,
      cardID,
      clearUpdateCardServerError,
      updateCardNum,
      updatePin
    } = this.props;
    const { number: cardNumFromServer, pin: pinNumFromServer } = cards[cardID];
    if (cardNumFromServer || pinNumFromServer) {
      clearUpdateCardServerError(cardID);
      updateCardNum(cardID, cardNumFromServer);
      updatePin(cardID, pinNumFromServer);
    }
    setTimeout(this.validate, 0);
  }

  componentWillUnmount() {
    const { clearCardAndPin, cardID } = this.props;
    clearCardAndPin(cardID);
  }

  updateMerchNum(cardNumber) {
    const { cardID, updateCardNum } = this.props;
    updateCardNum(cardID, cardNumber);
    this.validate();
  }

  updateMerchPin(cardPin) {
    const { cardID, updatePin } = this.props;
    updatePin(cardID, cardPin);
    this.validate();
  }

  escapeSpecialChar(e) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
  }

  validate = () => {
    const {
      validation = "",
      cards,
      cardID,
      cartSellID,
      sendCardAndPinToServer,
      clearUpdateCardServerError,
      displayMatch,
      displayInvalidMatch
    } = this.props;
    const { cardNumEnter, pinNumEnter } = cards[cardID];
    let regexError = false;

    const { error: validationError } = validateSellCard(
      cardNumEnter,
      pinNumEnter,
      validation
    );

    if (cardNumEnter && !CARD_NUMBER_REGEX.test(cardNumEnter)) {
      regexError = true;
    }

    if (pinNumEnter && !CARD_PIN_REGEX.test(pinNumEnter)) {
      regexError = true;
    }

    if (validationError || regexError) {
      displayInvalidMatch(cardID);
    } else {
      displayMatch(cardID);
      if (!cardNumEnter && !pinNumEnter) {
        return;
      }
      const payload = {
        cartSellID,
        cardID,
        number: cardNumEnter,
        pin: pinNumEnter,
        cards
      };
      sendCardAndPinToServer(payload);
    }
    clearUpdateCardServerError(cardID);
  };

  render() {
    const {
      tradeBonus,
      merchantsSell,
      cartSellID,
      deleteCardInCart,
      cardID,
      cards,
      merchantId,
      enteredAmount,
      cashValue,
      number,
      pin,
      config
    } = this.props;

    const { cardNumEnter, pinNumEnter } = cards[cardID];
    const currentCardValidation = cards && cards[cardID];

    let serverError = false;
    let cardAndPinMatch = false;
    let validationMessage = "";

    if (currentCardValidation && Object.keys(currentCardValidation).length) {
      serverError = currentCardValidation.serverError;
      cardAndPinMatch = currentCardValidation.match;
      validationMessage = currentCardValidation.msg;
    }

    let totalCardVal = cashValue;
    if (tradeBonus) {
      let valueToAdd;
      totalCardVal = Number(totalCardVal);
      valueToAdd = (tradeBonus / 100) * Number(cashValue);
      totalCardVal += valueToAdd;
      totalCardVal = totalCardVal + "";
    }

    const toggleCardNumActive = () => {
      this.setState(prevState => ({
        cardNumActive: !prevState.cardNumActive
      }));
    };
    const togglePinActive = () => {
      this.setState(prevState => ({
        pinActive: !prevState.pinActive
      }));
    };

    return (
      <div>
        <div
          className={`${config.layoutVersion !== 3 ? "row" : "form-input--v3"}`}
          style={
            config.layoutVersion !== 3
              ? {
                  margin:
                    config.layoutVersion === 1 ? "0px 0px 10px 0px" : " 5px 0px"
                }
              : {}
          }
        >
          <div
            className={
              config.layoutVersion === 1
                ? "col-md-4 step-two-container"
                : "hide"
            }
          >
            <img
              alt="remove button"
              style={{ vertialAlign: "middle" }}
              src="https://s3.amazonaws.com/new-cardcash-images/website/bluex.png"
              width="18"
              height="18"
              onClick={() => deleteCardInCart(cartSellID, cardID, cards)}
            />
            <img
              alt="card logo"
              style={{ vertialAlign: "middle" }}
              className="card-resp"
              src={merchantsSell[merchantId].image}
            />
            {merchantsSell[merchantId].name} :{" "}
            {currencyFormatter(enteredAmount)}
          </div>
          <div
            className={`${
              config.layoutVersion === 3
                ? "form-row-card-num--v3"
                : "seperate-input no-padding-md col-md-4"
            }`}
          >
            <input
              type="text"
              defaultValue={number}
              maxLength={30}
              onChange={e => {
                this.updateMerchNum(e.target.value.trim());
              }}
              onKeyPress={e => {
                this.escapeSpecialChar(e);
              }}
              onFocus={() => toggleCardNumActive()}
              onBlur={() => toggleCardNumActive()}
              className="form-control"
              placeholder={config.cardNumberPlaceHolderText}
            />

            {config.layoutVersion === 1 && (
              <React.Fragment>
                <div
                  className={
                    cardAndPinMatch === "PASSED" && serverError
                      ? "errorMsg fverror cc-def-color"
                      : "errorMsg fverror hide"
                  }
                >
                  {validationMessage}
                </div>
                <div
                  className={
                    cardAndPinMatch !== "PASSED" &&
                    (cardNumEnter || pinNumEnter)
                      ? "errorMsg fverror cc-def-color animated fadeIn"
                      : "errorMsg fverror hide"
                  }
                >
                  Card and pin number not yet valid...
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="col-md-4 no-padding no-padding-md">
            <div
              className={`${
                config.layoutVersion === 3
                  ? "form-row-pin-num--v3"
                  : "cards-cart-container col-md-5"
              }`}
            >
              <input
                defaultValue={pin}
                type="text"
                maxLength={24}
                onChange={e => {
                  this.updateMerchPin(e.target.value.trim());
                }}
                onKeyPress={e => {
                  this.escapeSpecialChar(e);
                }}
                onFocus={() => togglePinActive()}
                onBlur={() => togglePinActive()}
                className="form-control"
                placeholder={config.cardPinPlaceHolderText}
              />
            </div>
            {config.layoutVersion === 2 && (
              <React.Fragment>
                <div
                  className={
                    cardAndPinMatch === "PASSED" && serverError
                      ? "errorMsg fverror cc-def-color"
                      : "hide"
                  }
                >
                  {validationMessage}
                </div>
                <div
                  className={
                    cardAndPinMatch !== "PASSED" &&
                    (cardNumEnter || pinNumEnter)
                      ? "errorMsg fverror cc-def-color animated fadeIn"
                      : "hide"
                  }
                >
                  Card and pin number not yet valid...
                </div>
              </React.Fragment>
            )}

            <div
              className={
                config.layoutVersion === 1
                  ? "seperate-button col-md-7 no-padding-md"
                  : "hide"
              }
            >
              <p className="text-center offer-style">
                {config.cardInCart_offerText +
                  " " +
                  currencyFormatter(totalCardVal)}
              </p>
            </div>
          </div>
        </div>
        {config.layoutVersion === 3 && (
          <div className="form-input-error--v3">
            <div
              className={
                cardAndPinMatch === "PASSED" && serverError
                  ? "errorMsg fverror cc-def-color"
                  : "hide"
              }
            >
              {validationMessage}
            </div>
            <div
              className={
                cardAndPinMatch !== "PASSED" && (cardNumEnter || pinNumEnter)
                  ? "errorMsg fverror cc-def-color animated fadeIn"
                  : "hide"
              }
            >
              Card and pin number not yet valid...
            </div>
          </div>
        )}
      </div>
    );
  }
}
