import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const CCLink = styled(Link)`
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => `${theme.hyperlink_color}`};
  font-weight: 400;
  font-size: 13px;

  &:visited {
    color: ${({ theme }) => `${theme.hyperlink_color}`};
  }
`;

export default (props) => <CCLink {...props} />;
