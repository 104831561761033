import {
  ADD_CARD,
  INVALID_CARD_ERROR,
  INVALID_PIN_ERROR,
  DELETE_CARD,
  UPDATE_CARD_PIN,
  UPDATE_CARD_NUMBER,
  UPDATE_CARD_BALANCE,
  UPDATE_THE_CARD_ERROR,
  UPDATE_THE_CARD_PIN_ERROR,
  DISPLAY_INVALID_MATCH,
  DISPLAY_MATCH,
  DISPLAY_VALID_BALANCE,
  DISPLAY_MAX_BALANCE_ERROR,
  DISPLAY_MIN_BALANCE_ERROR,
  DISPLAY_NO_BALANCE_ERROR,
  DISPLAY_VALID_CARD_NUM,
  RESET,
  RESET_EXPIRE,
  RESET_SELL,
  UPDATE_INVALID_CARD_MSG,
  DISPLAY_VALID_PIN,
  ALL_PASS,
  CHECKOUT_ORDER_SELL_SETCARDS,
  COMPLETE_ORDER_SELL,
  DELETE_ALL_CARDS,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  SET_UPDATE_CARD_SERVER_ERROR,
  CLEAR_CARD_AND_PIN_ENTRY,
} from "../constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_CARD:
      return action.payload;

    case UPDATE_THE_CARD_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          cardError: "PASSED",
        },
      };

    case UPDATE_THE_CARD_PIN_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          pinError: "PASSED",
        },
      };

    case DELETE_CARD:
      delete state[action.payload];
      return state;

    case DELETE_ALL_CARDS:
      return {};

    case DISPLAY_VALID_CARD_NUM:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          cardError: "",
        },
      };

    case UPDATE_CARD_PIN:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          pinNumEnter: action.payload.pin,
        },
      };

    case UPDATE_CARD_NUMBER:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          cardNumEnter: action.payload.number,
        },
      };

    case UPDATE_CARD_BALANCE:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          enterValue: [action.payload.balance],
        },
      };

    case INVALID_CARD_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          cardError: "INVALID_CARD_ERROR",
          match: "INVALID",
        },
      };

    case UPDATE_INVALID_CARD_MSG:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          msg: action.payload.msg,
          cardError: "INVALID_CARD_ERROR",
        },
      };

    case COMPLETE_ORDER_SELL:
      return {};

    case INVALID_PIN_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          pinError: "INVALID_PIN_ERROR",
          match: "INVALID",
        },
      };

    case DISPLAY_INVALID_MATCH:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          match: "INVALID",
        },
      };

    case ALL_PASS:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          match: "PASSED",
          pinError: "PASSED",
          cardError: "PASSED",
        },
      };

    case DISPLAY_MATCH:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          match: "PASSED",
          pinError: "PASSED",
        },
      };

    case DISPLAY_VALID_PIN:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          pinError: "PASSED",
        },
      };

    case DISPLAY_VALID_BALANCE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "PASSED",
        },
      };

    case DISPLAY_MAX_BALANCE_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "MAX_BALANCE_ERROR",
        },
      };

    case DISPLAY_MIN_BALANCE_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "MIN_BALANCE_ERROR",
        },
      };

    case DISPLAY_NO_BALANCE_ERROR:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          balanceError: "NO_BALANCE_ERROR",
        },
      };

    case CLEAR_UPDATE_CARD_SERVER_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          msg: "",
          serverError: false,
        },
      };

    case SET_UPDATE_CARD_SERVER_ERROR:
      return {
        ...state,
        [action.payload.cardID]: {
          ...state[action.payload.cardID],
          msg: action.payload.msg,
          serverError: true,
        },
      };

    case CLEAR_CARD_AND_PIN_ENTRY:
      if (state.hasOwnProperty(action.payload.cardID)) {
        return {
          ...state,
          [action.payload.cardID]: {
            ...state[action.payload.cardID],
            cardNumEnter: "",
            pinNumEnter: "",
          },
        };
      }
      return state;

    case CHECKOUT_ORDER_SELL_SETCARDS:
      let newState = { ...state };

      if (Array.isArray(action.payload)) {
        action.payload.map((sellCard) => {
          if (sellCard.id) {
            // bogus return from WebAPI sometimes gives cards will null ID. Skip.

            // ensure this card exists in Redux
            if (!newState[sellCard.id]) {
              newState[sellCard.id] = { id: sellCard.internalId };
            }

            // blend in
            newState[sellCard.id] = Object.assign(newState[sellCard.id], {
              enterValue: sellCard.enteredValue,
              newCard: sellCard.newCard,
              type: sellCard.type,
              cardNumLastFour: sellCard.number,
              merchantId: sellCard.merchantId,
            });
          }
        });
      }

      return newState;

    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
      return {};

    default:
      return state;
  }
};
