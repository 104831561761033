import React from "react";
import { determineStep } from "../../../utils/determineStep";
import { ifBuilder } from "../../../utils/propTools";

export default ({ config, path }) => {
  if (config.stepsVersion !== 2) return null;

  const currentStep = determineStep(config.stepsV2, path);
  const step_LTE_current = ifBuilder((step) => step <= currentStep);
  const active = step_LTE_current("active")(null);

  return (
    <div className={"steps--v2 container"}>
      {config.steps[currentStep].map((step, i) => (
        <div key={i} className={"step--v2"}>
          <div className={"progress--v2"}>
            <div className={`number--v2 ${active(i)}`}>
              <span>{i + 1}</span>
            </div>
            {i === config.steps[currentStep].length - 1 ? (
              <img
                className={"image--v2 hidden-md-down"}
                src={config.detailStepThreeText.cardImage}
              />
            ) : (
              <div className={`line--v2 ${active(i + 1)}`} />
            )}
          </div>
          <div className={"description--v2 hidden-md-down"}>
            <h4>{step.header(config)}</h4>
            <p
              className="hidden-md-down"
              dangerouslySetInnerHTML={{ __html: step.text(config) }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
