import React from "react";
import CCModal from "../modal";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import CCButton from "../button";

import { mediaPhone } from "../../../styles/Breakpoints";
import { currencyFormatter } from "../../../../utils/display";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 30px;
  z-index: 5;
`;

const Head = styled.div`
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  color: rgb(51, 67, 61);
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 30px 30px 0 0;
`;

const Title = styled.h4``;

const Body = styled.div`
  border-radius: 30px;
  box-shadow: rgba(61, 58, 53, 0.05) 0px 5px 20px 0px;
  background-color: rgb(255, 255, 255);
  padding: 4rem;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;

  p {
    font-size: 21px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    color: #1f2021;

    ${mediaPhone} {
      font-size: 18px;
    }

    &:last-of-type {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      font-size: 14px;
      color: #777984;
    }
  }
`;

const BoldText = styled.span`
  font-weight: 500;
`;

const OrderTotalMaxExceeded = ({ isOpen, backTo, maxAmount }) => (
  <CCModal isOpen={isOpen}>
    <Container>
      <Head>
        <Title>Oops...</Title>
      </Head>
      <Body>
        <BodyContainer>
          <p>
            The total order value is above the allowed maximum of{" "}
            <BoldText>{currencyFormatter(maxAmount)}</BoldText>
          </p>

          <p>
            We can only accept cards where the offer value is less then the
            order total.
          </p>

          <CCButton onClick={backTo} active={true}>
            Back to checkout
          </CCButton>
        </BodyContainer>
      </Body>
    </Container>
  </CCModal>
);

OrderTotalMaxExceeded.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  backTo: PropTypes.func.isRequired,
  maxAmount: PropTypes.number.isRequired,
};

export default OrderTotalMaxExceeded;
