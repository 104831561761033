import React from "react";

const BackArrow = () => {
  return (
    <div className={`back-arrow`}>
      <svg
        width="9px"
        height="14px"
        viewBox="0 0 9 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.300000012"
        >
          <g
            id="Starbucks1"
            transform="translate(-116.000000, -31.000000)"
            fill="#535C58"
            fillRule="nonzero"
          >
            <g id="Top">
              <g id="Group-7" transform="translate(115.000000, 23.000000)">
                <g
                  id="chevron-down-sb"
                  transform="translate(5.000000, 15.000000) rotate(-270.000000) translate(-5.000000, -15.000000) translate(-2.000000, 10.500000)"
                >
                  <path
                    d="M6.43657192,8.14678474 L0.224695205,2.0096058 C-0.0748984018,1.71361632 -0.0748984018,1.23374264 0.224695205,0.937784743 L0.949211187,0.221984743 C1.24829338,-0.0734994672 1.73301941,-0.0740678882 2.03280479,0.220721585 L6.97902397,5.08454264 L11.9252112,0.220721585 C12.2249966,-0.0740678882 12.7097226,-0.0734994672 13.0088048,0.221984743 L13.7333208,0.937784743 C14.0329144,1.23377422 14.0329144,1.7136479 13.7333208,2.0096058 L7.52147603,8.14678474 C7.22188242,8.44274264 6.73616553,8.44274264 6.43657192,8.14678474 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

BackArrow.propTypes = {};

export default BackArrow;
