import React from "react";
import { Route, Switch } from "react-router-dom";

import Main from "../pages/main";
import Payment from "../pages/payment";
import StepTwo from "../pages/stepTwo";
import OrderComplete from "../pages/orderComplete";
import HowItWorks from "../containers/howItWorks";
import NotFound from "../containers/404";

const Router = () => (
  <Switch>
    <Route path="/faq" component={HowItWorks} />
    <Route path="/steptwo" component={StepTwo} />
    <Route path="/payment" component={Payment} />
    <Route path="/order-complete" component={OrderComplete} />
    <Route path="/" component={Main} />
    <Route component={NotFound} />
  </Switch>
);

export default Router;
