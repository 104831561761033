import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const Wrapper = styled.div`
  cursor: wait;
  display: inline-block;
  border-radius: 0.2rem;
  position: absolute;
  width: 4rem;
  height: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const myLoaderBar = keyframes`
  from {
    height: 20%;
    top: 40%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.05);
    height: 80%;
    top: 10%;
  }
`;

const Bar = styled.div`
  transition: opacity 1s ease, transform 1s ease, top 1s ease,
    transform-origin 1s ease, width 0.1s ease, left 1s ease, height 0.1s ease;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  width: 10%;
  left: 10%;
  top: 40%;
  height: 20%;
  position: absolute;
  transform-origin: 50% 0%;
  animation-name: ${myLoaderBar};
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  &:nth-of-type(1) {
    left: 20%;
    animation-delay: 0.1s;
  }

  &:nth-of-type(2) {
    left: 35%;
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    left: 50%;
    animation-delay: 0.3s;
  }

  &:nth-of-type(4) {
    left: 65%;
    animation-delay: 0.4s;
  }
  &:nth-of-type(5) {
    left: 80%;
    animation-delay: 0.5s;
  }
`;

const DelayedRedirect = ({ to }) => {
  let currentTimeOut;
  const [localTimeout, setLocalTimeout] = useState(false);
  const loadTrigger = () => {
    if (localTimeout) clearTimeout(currentTimeOut);
    currentTimeOut = setTimeout(() => {
      setLocalTimeout(true);
    }, 1000);
  };

  useEffect(() => {
    loadTrigger();

    return () => {
      if (localTimeout) clearTimeout(currentTimeOut);
    };
  }, []);

  return localTimeout ? (
    <Redirect to={to} />
  ) : (
    <Wrapper>
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
    </Wrapper>
  );
};

DelayedRedirect.propTypes = {
  to: PropTypes.string.isRequired,
};

export default DelayedRedirect;
