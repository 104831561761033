import React from "react";

export default () => (
  <div className="panel panel-default col-md-6 payment_right security animated fadeInUp">
    <div className="panel-body">
      <div>
        <img
          className="trust-seal"
          src="https://new-cardcash-images.s3.amazonaws.com/website/partners-securedbypaypal.jpg"
        />
      </div>
      <div>
        <img
          className="globalsign"
          src="https://new-cardcash-images.s3.amazonaws.com/website/partners-globalsign-seal.png"
        />
      </div>
    </div>
  </div>
);
