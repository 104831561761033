import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import CreditCardForm from "../../../components/stepThree/creditCardForm";

import { defaultAction } from "../../../actions/defaultAction";

import {
  customerInfoSelector,
  creditCardValidateSelector,
  contactInfoValidateSelector,
} from "../../../selectors";

import {
  SET_CCV,
  SET_CREDIT_CARD,
  SELECT_MONTH,
  SELECT_YEAR,
  HIDE_CREDIT_CARD_NUM_ERROR,
  HIDE_MONTH_ERROR,
  HIDE_YEAR_ERROR,
  HIDE_CCV_ERROR,
  DISPLAY_CREDIT_CARD_NUM_ERROR,
  DISPLAY_MONTH_ERROR,
  DISPLAY_YEAR_ERROR,
  DISPLAY_CCV_ERROR,
} from "../../../constants/actionTypes";

const IdentityCardInfo = styled.p`
  color: rgb(111, 112, 112);
`;

const A = styled(Link)`
  font-size: 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  color: rgb(43, 164, 211);

  &:visited {
    color: rgb(43, 164, 211);
  }
`;

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    customerInfo,
    contactInfoValidate,
    creditCardValidate,
  } = useSelector(
    (state) => ({
      config: state.config,
      customerInfo: customerInfoSelector(state),
      contactInfoValidate: contactInfoValidateSelector(state),
      creditCardValidate: creditCardValidateSelector(state),
    }),
    shallowEqual
  );

  const submitBtnClass = creditCardValidate
    ? "btn btn-primary"
    : "btn btn-primary activebtn";

  return (
    <Guard condition={contactInfoValidate} redirect={"/identity"}>
      <ActionContainer title={config.identityCardInfoTitle}>
        <React.Fragment>
          <CreditCardForm
            config={config}
            customerInfo={customerInfo}
            hideCreditCardNumError={() =>
              dispatch(defaultAction(HIDE_CREDIT_CARD_NUM_ERROR))
            }
            setCreditCard={(e) =>
              dispatch(
                defaultAction(
                  SET_CREDIT_CARD,
                  e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
                )
              )
            }
            hideMonthError={() => dispatch(defaultAction(HIDE_MONTH_ERROR))}
            selectMonth={(val) => dispatch(defaultAction(SELECT_MONTH, val))}
            hideYearError={() => dispatch(defaultAction(HIDE_YEAR_ERROR))}
            selectYear={(val) => dispatch(defaultAction(SELECT_YEAR, val))}
            hideCCVError={() => dispatch(defaultAction(HIDE_CCV_ERROR))}
            setCCV={(e) =>
              dispatch(
                defaultAction(
                  SET_CCV,
                  e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
                )
              )
            }
            setCreditCardNumError={() =>
              dispatch(defaultAction(DISPLAY_CREDIT_CARD_NUM_ERROR))
            }
            setMonthError={() => dispatch(defaultAction(DISPLAY_MONTH_ERROR))}
            setYearError={() => dispatch(defaultAction(DISPLAY_YEAR_ERROR))}
            setCCVError={() => dispatch(defaultAction(DISPLAY_CCV_ERROR))}
          />
          <IdentityCardInfo>
            {!config.IdentityCardInfoWhyLinkShow && config.IdentityCardInfoTxt}
            {config.IdentityCardInfoWhyLinkShow && (
              <>
                {config.IdentityCardInfoTxt}
                &nbsp;
                <A
                  to={(location) => ({
                    pathname: "/why",
                    state: { referrer: `${location.pathname}` },
                  })}
                >
                  Why?
                </A>
              </>
            )}
          </IdentityCardInfo>
        </React.Fragment>
        <React.Fragment>
          <input
            id="continue"
            type="button"
            name="submit"
            className={submitBtnClass}
            value={config.identityCardContinueButtonTxt}
            onClick={() => {
              setIsSubmitting(true);
              history.push("/identity-billing-info");
            }}
            disabled={isSubmitting || creditCardValidate}
          />
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
