import React from "react";
import { Link as MLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { defaultAction } from "../../../actions/defaultAction";
import BackArrow from "./icons/backArrow";
import {
  TOGGLE_DROP_DOWN,
  TOGGLE_CONTACT_US,
} from "../../../constants/actionTypes";

const Nav = styled.nav`
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  height: 72px;
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 72px;

  ${mediaDesktop} {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const BackTo = styled.span`
  ${mediaPhone} {
    display: none;
  }

  ${mediaDesktop} {
    flex: 1;

    a {
      span {
        margin-left: 1rem;
        margin-top: -9px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        color: rgb(119, 121, 132);
        &:visited {
          color: rgb(119, 121, 132);
        }
      }

      .back-arrow {
        margin-right: 8px;
        height: 10px;

        svg {
          width: 6px;
          height: 9px;
        }
      }
    }
  }
`;

const Link = styled(MLink)`
  text-decoration: none;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 16px;
  line-height: 2.25;
  color: rgb(119, 121, 132);
  &:visited {
    color: rgb(119, 121, 132);
  }

  ${mediaDesktop} {
    &:nth-last-of-type {
      margin-right: 5rem;
    }
  }
`;

const A = styled.a`
  text-decoration: none;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 16px;
  line-height: 2.25;
  color: rgb(119, 121, 132);
  cursor: pointer;
  &:visited {
    color: rgb(119, 121, 132);
  }

  ${mediaDesktop} {
    &:nth-last-of-type {
      margin-right: 5rem;
    }
  }
`;

const HeaderDivider = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
  border: 1px solid #eceff1;
  height: 2rem;
`;

const LogoContainer = styled.span`
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

const CCLogo = styled.img`
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PartnerLogo = styled.img`
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;

  ${mediaPhone} {
    max-width: 120px;
  }

  ${mediaDesktop} {
    max-width: 165px;
  }
`;

const DesktopLinks = styled.span`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transition: height 0.5s ease, background-color 0.5s ease;

  ${mediaPhone} {
    display: flex;
    background-color: transparent;
    position: fixed;
    top: 3.4rem;
    height: 0;
    overflow-y: hidden;
    width: 100vw;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  ${mediaDesktop} {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const MobileLinks = styled.span`
  z-index: 1;
  border-radius: 0px 0px 30px 30px;
  border-top: 1px solid rgba(83, 92, 88, 0.15);
  display: ${({ open }) => (open ? "none" : "flex")};
  background-color: white;
  position: fixed;
  top: 5rem;
  width: 100vw;
  left: 0px;
  overflow-y: hidden;
  flex-direction: column-reverse;
  align-items: center;
  -webkit-box-align: center;
  transition: height 0.5s ease 0s, background-color 0.5s ease 0s;
`;

const BurgerWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.025rem;
  right: 1.5rem;
  top: 2rem;

  ${mediaDesktop} {
    display: none;
  }
`;

const BurgerLines = styled.div`
  transition: opacity 0.5s ease, transform 0.5s ease, top 0.5s ease,
    transform-origin 0.5s ease, width 0.5s ease, left 0.5s ease,
    border-radius 0.5s ease;
  transform: translateY(-50%);
  border-radius: 0.025rem;
  width: 80%;
  left: 10%;
  height: 10%;
  position: absolute;
  transform-origin: 50% 0%;
  background-color: black;

  ${({ open }) =>
    open
      ? ` &:nth-of-type(1) {
        top: 20%;
        transform-origin: 9% 50%;
        transform: translateY(-50%) rotate(0deg);
      }

      &:nth-of-type(2) {
        opacity: 1;
        top: 50%;
      }

      &:nth-of-type(3) {
        top: 80%;
        transform-origin: 9% 50%;
        transform: translateY(-50%) rotate(0deg);
      }
    `
      : ` &:nth-of-type(1) {
        transform-origin: 50% 50%;
        top: 50%;
        transform: translate(0%, -50%) rotate(45deg);
      }

      &:nth-of-type(2) {
        top: 50%;
        opacity: 0;
      }

      &:nth-of-type(3) {
        top: 50%;

        transform-origin: 50% 50%;
        transform: translate(0%, -50%) rotate(-45deg);
      }`}
`;

const LogoLink = styled(MLink)``;
const Header = ({ config, popups, node }) => {
  if (!config.showHeader) return null;

  const dispatch = useDispatch();

  const toggleDropDown = () => dispatch(defaultAction(TOGGLE_DROP_DOWN));

  const toggleContactMobile = () => {
    dispatch(defaultAction(TOGGLE_DROP_DOWN));
    dispatch(defaultAction(TOGGLE_CONTACT_US));
  };

  const toggleContact = () => {
    dispatch(defaultAction(TOGGLE_CONTACT_US));
  };

  const capitalizeName = (name) =>
    name[0].toUpperCase() + name.slice(1, name.length) || "";

  const menuItems = {
    faqItem: (itemConfig) => <Link to={"/faq"}>{itemConfig.text(config)}</Link>,
    contactItem: (itemConfig) => (
      <A onClick={toggleContact}>{itemConfig.text(config)}</A>
    ),
  };

  const menuItemsMobile = {
    backToItem: (itemConfig) => (
      <A href={config.partnerURL} target="_blank" onClick={toggleDropDown}>
        {itemConfig.text(config)}
      </A>
    ),
    homeItem: (itemConfig) => (
      <Link to={"/"} onClick={toggleDropDown}>
        {itemConfig.text(config)}
      </Link>
    ),
    helpItem: (itemConfig) => (
      <Link to={"/help"} onClick={toggleDropDown}>
        {itemConfig.text(config)}
      </Link>
    ),
    faqItem: (itemConfig) => (
      <Link to={"/faq"} onClick={toggleDropDown}>
        {itemConfig.text(config)}
      </Link>
    ),
    contactItem: (itemConfig) => (
      <A onClick={toggleContactMobile}>{itemConfig.text(config)}</A>
    ),
  };

  return (
    <Nav>
      <Container>
        <BackTo>
          {config.headerBackTo && (
            <a href={config.partnerURL} target="_blank">
              <BackArrow />
              <span>{`Back to ${config.partnerBrand}`}</span>
            </a>
          )}
        </BackTo>
        <LogoContainer>
          {config.headerLogoOrder.map((name, i) => (
            <React.Fragment key={i}>
              <LogoLink to="/">
                <span key={i}>
                  {name === "headerLogoCC" ? (
                    <CCLogo src={config[name + "Icon"]} />
                  ) : (
                    <PartnerLogo src={config[name + "Icon"]} />
                  )}
                </span>
              </LogoLink>
              {i != config.headerLogoOrder.length - 1 && (
                <HeaderDivider></HeaderDivider>
              )}
            </React.Fragment>
          ))}
          <BurgerWrapper id="BurgerWrapper" onClick={toggleDropDown}>
            <BurgerLines id="BurgerLines" open={!popups.get("showDropDown")} />
            <BurgerLines id="BurgerLines" open={!popups.get("showDropDown")} />
            <BurgerLines id="BurgerLines" open={!popups.get("showDropDown")} />
          </BurgerWrapper>
        </LogoContainer>
        <DesktopLinks>
          {config.headerMenuItemOrder.map((name, i) => (
            <React.Fragment key={i}>
              {menuItems[name](config[`headerMenu${capitalizeName(name)}`])}
            </React.Fragment>
          ))}
        </DesktopLinks>
        <MobileLinks
          open={!popups.get("showDropDown")}
          ref={node}
          id="mobileMenu"
        >
          {config.headerMobileMenuItemOrder.map((name, i) => (
            <React.Fragment key={i}>
              {menuItemsMobile[name](
                config[`headerMenu${capitalizeName(name)}`]
              )}
            </React.Fragment>
          ))}
        </MobileLinks>
      </Container>
    </Nav>
  );
};

Header.propTypes = {
  config: PropTypes.object.isRequired,
  popups: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
};

export default Header;
