import React from "react";
import PropTypes from "prop-types";

import ExpireYear from "./expireYear";
import SelectMonth from "./selectMonth";
import styled from "@emotion/styled";

import {
  cardNumValid,
  expiryValid,
  cvvValid,
} from "../../utils/validators/creditCardValidation";

const CCExpiryWrapper_V2 = styled.div`
  display: flex;
`;
const CCExpiryMonth_V2 = styled.div`
  flex: 1;
`;
const CCExpiryYear_V2 = styled.div`
  flex: 1;
  margin-left: 0.5rem;
`;

const CreditCardForm = ({
  config,
  customerInfo,
  hideCreditCardNumError,
  setCreditCard,
  hideMonthError,
  selectMonth,
  hideYearError,
  selectYear,
  hideCCVError,
  setCCV,
  setCreditCardNumError,
  setMonthError,
  setYearError,
  setCCVError,
}) => {
  const cvvInput = (
    <React.Fragment>
      <input
        type="text"
        defaultValue={customerInfo.get("ccv")}
        onChange={(e) => {
          if (e.target.value && cvvValid(e.target.value)) {
            hideCCVError();
            setCCV(e);
          } else {
            setCCVError();
          }
        }}
        className="form-control"
        maxLength={4}
        placeholder={config.cvvTextPlaceHolder}
      />
      <div
        className={
          customerInfo.get("_ccvError")
            ? " errorMsg fverror cc-def-color"
            : "hide"
        }
      >
        Not a valid cvv
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="row mar-b-10 no-mar-r no-mar-l">
        <div className="col-md-12 responsive_input no-padding-left">
          <input
            type="text"
            defaultValue={customerInfo.get("creditCardNum")}
            onChange={(e) => {
              if (cardNumValid(e.target.value)) {
                hideCreditCardNumError();
                setCreditCard(e);
              } else {
                setCreditCardNumError();
              }
            }}
            className="form-control"
            maxLength={19}
            placeholder={config.creditCardTextPlaceHolder}
          />
          <div
            className={
              customerInfo.get("_creditCardNumError")
                ? " errorMsg fverror cc-def-color"
                : "hide"
            }
          >
            Not a valid credit number
          </div>
        </div>
      </div>
      <div
        className={
          config.layoutVersion === 1
            ? "row mar-b-10 no-mar-r no-mar-l"
            : "partner-creditCardExpiry--v2 creditCardExpiry--v2"
        }
      >
        {config.layoutVersion === 1 && (
          <React.Fragment>
            <SelectMonth
              name="month"
              selectMonth={(e) => {
                if (e.target.value) {
                  hideMonthError();
                  selectMonth(e);
                } else {
                  setMonthError();
                }
              }}
              error={customerInfo.get("_monthError")}
              defaultVal={customerInfo.get("month")}
            />
            <ExpireYear
              name="year"
              selectYear={(e) => {
                if (e.target.value) {
                  hideYearError();
                  selectYear(e);
                } else {
                  setYearError();
                }
              }}
              error={customerInfo.get("_yearError")}
              defaultVal={customerInfo.get("year")}
            />
            <div className="col-md-4 responsive_input no-padding-left">
              {cvvInput}
            </div>
          </React.Fragment>
        )}
        {config.layoutVersion === 2 && (
          <React.Fragment>
            <CCExpiryWrapper_V2>
              <CCExpiryMonth_V2>
                <input
                  type="text"
                  defaultValue={`${
                    customerInfo.get("month") && customerInfo.get("year")
                      ? customerInfo.get("month") / customerInfo.get("year")
                      : ""
                  }`}
                  onChange={(e) => {
                    let validExpiry = expiryValid(e.target.value);

                    if (e.target.value && validExpiry.status) {
                      hideMonthError();
                      hideYearError();
                      selectMonth(validExpiry.month);
                      selectYear(validExpiry.year);
                    } else {
                      setMonthError();
                      setYearError();
                    }
                  }}
                  className="form-control"
                  maxLength={5}
                  placeholder={config.monthYearTextPlaceHolder}
                />
                <div
                  className={
                    customerInfo.get("_monthError") &&
                    customerInfo.get("_yearError")
                      ? " errorMsg fverror cc-def-color"
                      : "hide"
                  }
                >
                  Not a expiration date
                </div>
              </CCExpiryMonth_V2>
              <CCExpiryYear_V2>{cvvInput}</CCExpiryYear_V2>
            </CCExpiryWrapper_V2>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

CreditCardForm.propTypes = {
  config: PropTypes.object.isRequired,
  customerInfo: PropTypes.object.isRequired,
  hideCreditCardNumError: PropTypes.func.isRequired,
  setCreditCard: PropTypes.func.isRequired,
  hideMonthError: PropTypes.func.isRequired,
  selectMonth: PropTypes.func.isRequired,
  hideYearError: PropTypes.func.isRequired,
  selectYear: PropTypes.func.isRequired,
  hideCCVError: PropTypes.func.isRequired,
  setCCV: PropTypes.func.isRequired,
  setCreditCardNumError: PropTypes.func.isRequired,
  setMonthError: PropTypes.func.isRequired,
  setYearError: PropTypes.func.isRequired,
  setCCVError: PropTypes.func.isRequired,
};

export default CreditCardForm;
