import { Map } from "immutable";

import {
  HIDE_TLO_ERROR_PHONE_MODAL,
  SHOW_TLO_ERROR_PHONE_MODAL,
  HIDE_TLO_VERIFICATION_INIT,
  SHOW_TLO_VERIFICATION_INIT,
  UPDATE_TLO_VALIDATION_STATUS,
  SET_TLO_VALIDATION_PHONE_NUMBERS,
  HIDE_TLO_VERIFICATION_EXPIRED,
  SHOW_TLO_VERIFICATION_EXPIRED,
  HIDE_TLO_VERIFICATION_COMPLETED,
  SHOW_TLO_VERIFICATION_COMPLETED,
  SELECT_TLO_PHONE_NUMBER,
  SET_TLO_VERIFICATION_TYPE,
  HIDE_TLO_VERIFICATION_ENTER,
  SHOW_TLO_VERIFICATION_ENTER,
  SET_TLO_ENTER_CODE,
  CLEAR_TLO_ENTER_CODE,
  SET_TLO_ENTERED_CODE_ERROR,
  TLO_RESET
} from "../constants/actionTypes";

const initialState = Map({
  isShowingTLOVerificationInit: false,
  isShowingTLOVerificationEnter: false,
  isShowingTLOVerificationError: false,
  iShowingTLOVerificationExpired: false,
  isShowingTLOVerificationCompleted: false,
  lastVerificationAttemptMethod: null,
  validationType: "",
  phoneNumbers: [],
  enteredCode: "",
  selectedPhoneNumberId: "",
  status: null,
  enteredCodeError: ""
});

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_TLO_ERROR_PHONE_MODAL:
      return state
        .set("isShowingTLOVerificationError", false)
        .set("isShowingTLOVerificationInit", false);

    case SHOW_TLO_ERROR_PHONE_MODAL:
      return state
        .set("isShowingTLOVerificationError", true)
        .set("isShowingTLOVerificationInit", false);

    case HIDE_TLO_VERIFICATION_INIT:
      return state.set("isShowingTLOVerificationInit", false);

    case SHOW_TLO_VERIFICATION_INIT:
      return state.set("isShowingTLOVerificationInit", true);

    case HIDE_TLO_VERIFICATION_ENTER:
      return state.set("isShowingTLOVerificationEnter", false);

    case SHOW_TLO_VERIFICATION_ENTER:
      return state
        .set("isShowingTLOVerificationEnter", true)
        .set("isShowingTLOVerificationInit", false);

    case UPDATE_TLO_VALIDATION_STATUS:
      return state.set("status", action.payload);

    case SET_TLO_VALIDATION_PHONE_NUMBERS:
      return state.set("phoneNumbers", action.payload);

    case HIDE_TLO_VERIFICATION_EXPIRED:
      return state.set("iShowingTLOVerificationExpired", false);

    case SHOW_TLO_VERIFICATION_EXPIRED:
      return state.set("iShowingTLOVerificationExpired", true);

    case HIDE_TLO_VERIFICATION_COMPLETED:
      return state.set("isShowingTLOVerificationCompleted", false);

    case SHOW_TLO_VERIFICATION_COMPLETED:
      return state
        .set("isShowingTLOVerificationCompleted", true)
        .set("isShowingTLOVerificationEnter", false);

    case SELECT_TLO_PHONE_NUMBER:
      return state.set("selectedPhoneNumberId", action.payload);

    case SET_TLO_VERIFICATION_TYPE:
      return state.set("validationType", action.payload);

    case SET_TLO_ENTER_CODE:
      return state.set("enteredCode", action.payload);

    case CLEAR_TLO_ENTER_CODE:
      return state.set("enteredCode", null);

    case SET_TLO_ENTERED_CODE_ERROR:
      return state.set("enteredCodeError", action.payload);

    case TLO_RESET:
      return initialState;
    default:
      return state;
  }
};
