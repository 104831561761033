export const stepsMapper = (steps) =>
  steps.map((name) => ({
    cardImage: (config) => config[name].cardImage,
    description: (config) => config[name].description,
    header: (config) => config[name].header,
    iconText: (config) => config[name].iconText,
    text: (config) => config[name].text,
  }));

export const commonConfig = {
  // layout version
  layoutVersion: 1, // 1 ,  2, 3
  reduxLogger: true, // do you want redux logging?
  headerContained: false,
  variant: "website",
  //layout bottom images for layout v3
  layoutOverlayImagesLeftImg: "",
  layoutOverlayImagesRightImg: "",
  layoutOverlayImagesTopRightImg: "",
  // v3 only
  customDisclosure: "",
  // Exchange Mast: used with layout 2 ONLY!
  exchangeMastVersion: 1, //1 or 2
  exchangeMastCardImageUrl: "",
  exchangeMastTitle: "Gift Card Exchange",
  exchangeMastWhyTitle: "Why We Verify Your Identity",
  exchangeMastTextPartOneV1: "Exchange Gift Cards",
  exchangeMastTextPartTwoV1: "",
  exchangeMastTextPartThreeV1: "Over 200 Merchants Accepted!",
  exchangeMastTextPartOneV2: "Exchange Gift Cards for an",
  exchangeMastTextPartTwoV2: "Over 200 Merchants Accepted!",
  exchangeMastMultiLineBrandLogoUrlV2: "",

  headerTextPartOne: "Exchange Gift Cards",
  headerTextPartTwo: "",
  headerTextPartThree: "Over 200 Merchants Accepted!",
  headerBackTo: false,
  sellInstructionsMobileTitle: "Here Is How It Works",
  sellInstructionsShowLearnMoreLink: true,
  sellInstructionsDesktopTitle: "How It Works",
  sellInstructionsDoubleArrowIcon:
    "https://cdn.cardcash.com/website/partners/common/doubleForwardArrows.svg",

  receivingItem: "eGift Card",

  // donation
  donationSite: false,
  donationCauseName: null,
  donationInfoText: null,
  donationStatement: null,

  // steps module
  stepsVersion: 1, // 1 and 2 work on layout 1 ; 3 works on layout 2
  stepsVersionMobile: true, // stepsVersion must be 3 and used in layout 2
  stepsV1: [["/"], ["/steptwo"], ["/payment", "/stepthree"]],
  stepsV2: [["/"], ["/steptwo"], ["/payment", "/stepthree"]],
  steps: [
    ["detailStepOneText", "detailStepTwoText", "detailStepThreeText"],
    ["detailStepOneText", "detailStepTwoText", "detailStepThreeText"],
    ["detailStepOneText", "detailStepTwoText", "detailStepThreeText"],
  ].map(stepsMapper),
  stepsHeadline: false,
  stepsHideTopOn: [], // Needs a better name
  stepsHideBottomOn: ["steptwo", "payment"], // Needs a better name
  stepsV3Flow: [
    ["/", "/sounds-good"],
    ["/card-details"],
    [
      "/identity",
      "/identity-card-info",
      "/identity-billing-info",
      "/identity-pass-code",
      "/identity-tlo",
    ],
    ["/order-complete"],
  ],
  stepsV3Text: [
    [
      "Get Offer",
      "Tell us about the brand and balance and get an instant offer.",
    ],
    ["Provide Details", "Enter the details for your gift card."],
    ["Verify Your Identity", "To help us protect everyone from fraudsters."],
    [
      "Receive your Starbucks Card",
      "Your gift card will be emailed to you within 1 business day.",
    ],
  ],

  // offer tile place holder text layout 2 offer
  offerTitle: "Tell us about your gift card",
  offerBalanceInputPlaceHolder: "Balance",
  offerMerchantDropDownPlaceHolder: "Enter a Merchant",
  offerButtonTxt: "Get Offer",
  offerButtonTxtMany: "ADD CARD",

  // sound good tile place holder text layout 2 offer
  soundGoodTitle: "Your Offer",
  soundGoodAddAnotherCardLinkTxt: "Add Another Card",
  soundsGoodContinueButtonTxt: "Continue",
  soundsGoodCancelButtonTxt: "Cancel",
  soundsGoodSaveButtonTxt: "Save",
  showSoundsGoodMsg: true,
  soundsGoodMsg: "Remember, not all trades are accepted.",
  offerTxt: "Offer",
  // card details place holder text layout 2
  cardDetailsTitle: "Provide Card Details",
  cardDetailsContinueButtonTxt: "Continue",

  // card number and pin input boxes
  cardNumberPlaceHolderText: "Card Number",
  cardPinPlaceHolderText: "Pin",

  //confirm page for Anycard
  confirmTitle: "Confirm Order & Savings",
  confirmContinueButtonTxt: "Confirm",

  // identity place holders text layout 2
  identityTitle: "Provide your contact info",
  identityContinueButtonTxt: "Continue",
  identityCardInfoTitle: "Verify Your Identity",
  identityCardContinueButtonTxt: "Continue",
  IdentityCardInfoTxt: `To verify your identify provide a valid credit card and associated
  address.`,
  identityTxt: "",
  IdentityCardInfoWhyLinkShow: true,
  emailAddressTextPlaceHolder: "Email Address",
  phoneNumTextPlaceHolder: "Phone Number",
  creditCardTextPlaceHolder: "Credit Card Number",
  monthYearTextPlaceHolder: "mm/yy",
  cvvTextPlaceHolder: "cvv",
  identityBillingInfoTitle: "Verify Your Identity",
  addressFirstNamePlaceHolder: "First Name",
  addressLastNamePlaceHolder: "Last Name",
  addressStreetPlaceHolder: "Address Line 1",
  addressStreet2PlaceHolder: "Address Line 2",
  addressCityPlaceHolder: "City",
  addressStatePlaceHolder: "State (e.g. NY)",
  addressZipPlaceHolder: "Zip Code",
  identityBillingContinueButtonTxt: "Continue",
  identityBillingLoadingButtonTxt: "Processing",

  identityTloTitle: "Verify Your Identity",
  identityTloPassCodeTitle: "Enter Verification Code",
  identityTloErrorTitle: "Sorry",
  identityTloExpiredTitle: "Expired",

  // tlo place holders text layout 2
  tloMissingNumberLink: "Missing Number?",
  tloHelperText:
    "Please select from one of the following phone numbers where you can receive a text message or phone call.",
  tloEnteredCodePlaceHolderTxt: "XXXXXX",
  tloResendCodeLinkTxt: "Resend",
  tloChooseADifferentPhoneTxt: "Choose a different phone",
  tloVerifyBtnTxt: "Verify",
  tloErrorTxt: `<p>We are sorry but CardCash cannot process your order at this time.</p>  <p> CardCash's security tools are very sensitive and we apologize if your order has been incorrectly flagged. We hope you will try us again sometime. </p> <p> You may see a pending charge on your credit card. This is just an authorization and will fall off within a few days. </p>`,
  tloExpiredTxt: `<p>The verification process for this order has expired, and your order was cancalled. Please contact customer service</p>  <p><a href='/'>Close</a></p>`,
  tloPhoneNumTextPlaceHolder: "Choose phone number",

  // order complete place holders text layout 2
  orderCompleteTitle: "Your Order is Complete",
  orderCompleteCustomCheckMarkerIcon: "",
  orderCompleteCardImageUrl: "",
  orderCompleteCardInfoTxt: {
    text: (
      config
    ) => `Your ${config.partnerBrand} card will be emailed to you once your
    order is processed. It may to up to 1 business day.`,
  },
  orderCompleteButtonTxt: "Check your trade order status",
  orderCompleteShowBonusTxt: false,
  orderCompleteBonusTxt: {
    text: (config) =>
      ` Register your eGift to join ${config.partnerBrand} Rewards.`,
  },

  // Have another card? Add it here?
  haveAnotherCardShowPopup: true,
  haveAnotherCardTitle: "Have another card? Add it here",
  haveAnotherCardBody: "No need to start another order, simply add it below.",

  // contact us
  showContactUs_phoneNumber: false,
  contactUs_phoneNumber: "1-800-227-4214",

  // nav and footer layout 1, 2,3
  showHeader: true,
  headerMenuItemOrder: ["backToItem", "helpItem", "faqItem"], //contactItem for v3 only
  headerMobileMenuItemOrder: [
    "contactItem",
    "faqItem",
    "homeItem",
    "backToItem",
  ],
  headerMenuHomeItem: {
    text: (config) => "Home",
  },
  headerMenuFaqItem: {
    text: (config) => "How It Works",
  },
  headerMenuBackToItem: {
    text: (config) => `Back to ${config.partnerBrand}`,
  },
  headerMenuContactItem: {
    text: (config) => "Contact Us",
  },
  headerMenuHelpItem: {
    text: (config) => `${config.headerContactLinkText}`,
  },
  headerLogoOrder: ["headerLogoBrand", "headerLogoCC"],
  headerLogoCCIcon:
    "https://cdn.cardcash.com/website/partners/common/cc_logo_dark.png",
  headerLogoCC:
    "https://cdn.cardcash.com/website/partners/common/cc_logo_dark.png",
  headerLogoBrand: "",
  headerLogoBrandIcon: "/src/images/logo.png",
  headerLogoDivider:
    "https://s3.amazonaws.com/new-cardcash-images/website/partner-divider.png",
  headerMenuItemImage1: null,
  headerMenuItemImage2:
    "https://cdn.cardcash.com/website/partners/common/icon2.png",
  headerMenuItemImage3:
    "https://cdn.cardcash.com/website/partners/common/icon3.png",
  iconBig1: "src/images/icon_big1.png",
  iconBig2: "src/images/icon_big2.png",
  iconBig3: "src/images/icon_big3.png",
  showFooter: true,
  footerLogoImage:
    "https://cdn.cardcash.com/website/partners/common/powered_by.png",
  footerSocialClass: "circle",
  footerCopyright: {
    text: (config) => `${config.partnerBrand}`,
  },

  // offer and continue
  offerAndContinue_displayLessThanCard: false,
  offerAndContinue_continueButtonText: "CONTINUE",

  // cardInCart
  cardInCart_offerText: "Gift Card Offer:",

  // stepTwo
  stepTwo_addACard: "ADD A CARD",

  // payment (partners stepThree)
  displayPaymentSecurityImages: true,
  payment_showHaveACardCashAccount: null,
  payment_partnerTermsAndConditions: null,
  termsAndConditionsBuilder: (config) =>
    `See ${config.partnerBrand} ${config.paymentType} <a href='${config.giftCardTerms}' className="partner-anchor1"> Terms and Conditions </a>`,

  // verificationInformation
  verificationInformation_header: "Verification Info",
  verificationInformation_subHeader: (config) =>
    `To protect ${config.partnerBrand} customers against fraudulent activity, a credit card number with the address associated with it is required. `,

  // orderDetail
  orderDetails_title: "Order Details",
  orderDetails_viewOrderDetailSection: true,
  orderDetails_detailSubHeader: null,
  orderDetails_offerAmount_prefix: (config) => "Total Offer ",
  orderDetails_offerAmount_postfix: (config) =>
    ` ${config.partnerBrand} ${config.paymentType}`,
  whyDoWeNeedThis: true,
  // social button
  youTubeURL: null,
  instagramURL: null,
  googleURL: null,
  linkedInURL: null,
  pinterestURL: null,

  // faq
  faqVersion: 1,
  faq: {},

  // path sensitive
  hideIfPathContains: {
    goodbyeText: ["faq"],
  },

  // verification
  verificationType: "twilio", // or tlo

  whyContent: [
    {
      title: "Identity Verification",
      content:
        "We are all safer when we are who we say we are. Your credit card helps confirm your billing address and identity, protecting our community and helping us offer some of the best payouts in the gift card exchange industry.",
    },
    {
      title: "Authorization Charge",
      content:
        "A $1 authorization charge will be placed on your card. This is a standard method of verifying the validity of a credit card. No actual funds will be debited from your card.",
    },
    {
      title: "Fraud Protection",
      content:
        "Your card will only be charged if we determine that the gift cards were obtained fraudulently or if an attempt is made to use the cards after completing this transaction. This is the best way of ensuring that only legitimate customers like yourself make it through our exchange process.",
    },
    {
      title: "You Are Protected",
      content:
        "Your credit card information is processed through PayPal. It is never saved on our servers.",
    },
  ],

  helpContent: {
    title: "Contact Us",
    content:
      '<p>Have any questions or concerns?<br />We would love to hear from you.<br />Email Address: <a href="mailto:support@cardcash.com">support@cardcash.com</a>',
  },
  faqv3: {
    content: (config) => {
      return [
        {
          question: "How much will I receive in return for my gift card?",
          answer:
            "Every brand is different. In order to find the exchange value for your card, please enter the brand name of the gift card and the balance to receive an offer. You are under no obligation to exchange your card, and we will not retain your gift card information if you do not confirm your exchange.",
        },
        {
          question: "Do you accept partially used cards or uneven balances?",
          answer: "Yes, we accept partially used gift cards.",
        },
        {
          question: "Do I need to mail in my gift card?",
          answer: "No, you do not need to mail in your card.",
        },
        {
          question: "Can I exchange more than one gift card?",
          answer:
            "Yes, you can exchange more than one gift card. To trade in more than one, click the “Add Another Card” link.",
        },
        {
          question: `Why are some trade orders not accepted?`,
          answer: `CardCash is a secondary gift card market and our priority is protecting our marketplace for both gift card sellers and buyers. We use a combination of automated and manual reviewers to ensure all orders are cleared of fraud risk, have the proper card balances, and are of interest to our buyer community.`,
        },
        {
          question: `When will I receive my ${config.partnerBrand} ${config.paymentType}?`,
          answer: `Your ${config.partnerBrand} ${config.paymentType} will be emailed to you within 1 business day.`,
        },
        {
          question: `Can I get better rates for my cards or is it the same for all gift cards?`,
          answer: `Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive an offer. You are under no obligation to sell your card, and we will not retain your gift card information if you do not confirm your exchange. Please note that the offer you see displayed for the same gift card of the same value may change from time to time.`,
        },
        {
          question:
            "I am experiencing an issue with my order, how do I get help?",
          answer: `Please contact  <a href="mailto:${config.partnerEmail}" target="_blank">${config.partnerEmail}</a> for help with any issues.`,
        },
        {
          question: `Where can I find my ${config.partnerBrand} ${config.paymentType}?`,
          answer: "Your card will be emailed to you upon order approval.",
        },
        {
          question: "Who is CardCash.com and what do you do?",
          answer: `<a href="https://www.cardcash.com" target="_blank">CardCash.com</a> is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website.`,
        },
        {
          question: "I changed my mind. How do I get my card back?",
          answer: `Until your order is finalized you can cancel your order by contacting customer service.`,
        },
        {
          question: "Do you accept merchandise credits?",
          answer: "Yes.",
        },
        {
          question: "Do you accept cards with expiration dates?",
          answer:
            "No, unfortunately, we cannot accept gift cards that have an expiration date.",
        },
        {
          question: "I’m still confused, how can I reach you?",
          answer: `Our customer service department can be reached during normal business hours or by email at <a href="mailto:${config.partnerEmail}" target="_blank">${config.partnerEmail}</a>.`,
        },
      ];
    },
  },
  faq: {
    content: (config) => {
      return [
        {
          question: "How much will I receive in return for my gift card?",
          answer:
            "Every brand is different. In order to find the exchange value for your card, please enter the brand name of the gift card and the balance to receive an offer. You are under no obligation to exchange your card, and we will not retain your gift card information if you do not confirm your exchange.",
        },
        {
          question: "Do you accept partially used cards or uneven balances?",
          answer:
            "Yes, we accept gift cards with any balance remaining on the gift card.",
        },
        {
          question: "Do I need to mail in my gift card?",
          answer: "No, you do not need to mail in your card.",
        },
        {
          question: "Can I exchange more than one gift card?",
          answer:
            'Yes, you can exchange more than one gift card. To trade in more than one, click the "Add Another Card" link.',
        },
        {
          question: `When will I receive my ${config.partnerBrand} ${config.paymentType}?`,
          answer: `As soon as you finish the process, your ${config.partnerBrand} ${config.paymentType} will be issued and emailed to you within 1 business day.`,
        },
        {
          question:
            "Can I get better rates for my cards or is it the same for all gift cards?",
          answer:
            "Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive an offer. You are under no obligation to sell your card, and we will not retain your gift card information if you do not confirm your exchange. Please note that the offer you see displayed for the same gift card of the same value may change from time to time.",
        },
        {
          question: `I received ${config.aOrAn} ${config.partnerBrand} ${config.paymentType} of lesser value than what you offered.`,
          answer: `If you believe this was in error, please contact by email at ${config.partnerEmail}`,
        },
        {
          question: `Where can I find my ${config.partnerBrand} ${config.paymentType}?`,
          answer: "Your card will be emailed to you upon order approval.",
        },
        {
          question: "What do you do with the gift cards?",
          answer:
            "CardCash.com is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website.",
        },
        {
          question: "I changed my mind. How do I get my card back?",
          answer:
            "We're sorry; once you've accepted the offer, the sale is final.",
        },
        {
          question: "Do you accept merchandise credits?",
          answer: "Yes.",
        },
        {
          question: "Do you accept cards with expiration dates?",
          answer:
            "No, unfortunately, we cannot accept gift cards that have an expiration date.",
        },
        {
          question: "I’m still confused, how can I reach you?",
          answer: `Our customer service department can be reached during normal business hours or by email at ${config.partnerEmail}`,
        },
      ];
    },
  },
  agreementPageOn: false,
  agreementTitle: "",
  agreementContent: [],
  agreementContinueButtonTxt: "Accept Offer                           ",
  agreementAcceptOfferTxt: "",
};

export const smallText = (text) => ({
  className: "mast-small",
  text,
});

export const largeText = (text) => ({
  className: "mast-large",
  text,
});

export const qa = (question, answer) => ({ question, answer });
