import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import Steps from "../containers/steps";
import CCA from "../containers/a";

const SellInstructions = styled.div`
  flex: 1;
  background-color: rgba(255, 255, 255, 0.85);

  ${mediaPhone} {
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
    border: 1px solid #e1e1e1;
    border-bottom: none;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  ${mediaDesktop} {
    border-top: 1px solid rgb(225, 225, 225);
    border-right: 1px solid rgb(225, 225, 225);
    border-bottom: 1px solid rgb(225, 225, 225);
    border-image: initial;
    border-left: none;
    border-top-right-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
  }
`;

const SellInstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaPhone} {
    margin: 1.5rem 1.53846rem 2.92308rem;
    margin-top: 1.5rem;
    flex: 1;
  }

  ${mediaDesktop} {
    flex: 0.8;
    margin: 1.84615rem 3.84615rem 2.92308rem 3.07692rem;
  }

  h3 {
    ${mediaPhone} {
      margin-top: 0;
      margin-bottom: 1.5rem;
      color: #333333;
    }

    ${mediaDesktop} {
      display: none;
    }
  }

  h1 {
    margin-left: calc(3.15385rem);
    margin-top: 0.7rem;
    margin-bottom: 2.7rem;
    font-weight: lighter;

    ${mediaPhone} {
      display: none;
    }
  }

  a {
    margin-left: calc(3.15385rem);
  }
`;

export default () => {
  const location = useLocation();
  const config = useSelector((state) => state.config);

  return (
    <SellInstructions>
      <SellInstructionsContainer>
        <h3>{config.sellInstructionsMobileTitle}</h3>
        <h1>{config.sellInstructionsDesktopTitle}</h1>
        <Steps.Desktop config={config} currentPath={location.pathname} />
        {config.sellInstructionsShowLearnMoreLink && (
          <CCA href="/faq">Learn more &gt;</CCA>
        )}
      </SellInstructionsContainer>
    </SellInstructions>
  );
};
