export const doEvent = (event, payload = {}) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    await remote(
      "post",
      `/v3/events/${event}`,
      payload,
      `Sending Event: ${event}`,
      dispatch
    );
  } catch (e) {}
};
