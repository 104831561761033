import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import TloVerificationInit from "../../../components/stepThree/tloVerificationInit";
import TloVerificationError from "../../../components/stepThree/tloVerificationError";
import TloVerificationExpired from "../../../components/stepThree/tloVerificationExpired";
import InfoIcon from "../containers/icons/info";

import {
  SELECT_TLO_PHONE_NUMBER,
  SET_TLO_VERIFICATION_TYPE,
  SHOW_TLO_ERROR_PHONE_MODAL,
  SHOW_TLO_VERIFICATION_INIT,
  HIDE_TLO_ERROR_PHONE_MODAL,
} from "../../../constants/actionTypes";

import { defaultAction } from "../../../actions/defaultAction";
import { sendTLOVerification } from "../../../thunks/customerInfo";

import { isOrderIdEmptySelector, tloSelector } from "../../../selectors";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { closeWidget } from "ccui-framework";

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin-left: 1rem;
    ${mediaDesktop} {
      font-size: 12px;
    }
    ${mediaPhone} {
      font-size: 10px;
    }
  }
`;

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, tlo, isOrderIdEmpty, error } = useSelector(
    (state) => ({
      config: state.config,
      tlo: tloSelector(state),
      isOrderIdEmpty: isOrderIdEmptySelector(state),
      error: state.userNotifications.errorMsg,
    }),
    shallowEqual
  );

  if (tlo.get("iShowingTLOVerificationExpired")) {
    return (
      <Guard condition={isOrderIdEmpty} redirect={"/"}>
        <ActionContainer title={config.identityTloErrorTitle}>
          <TloVerificationExpired tloExpiredTxt={config.tloExpiredTxt} />
        </ActionContainer>
      </Guard>
    );
  }

  if (tlo.get("isShowingTLOVerificationError")) {
    return (
      <Guard condition={isOrderIdEmpty} redirect={"/"}>
        <ActionContainer title={config.identityTloErrorTitle}>
          <TloVerificationError
            tloErrorTxt={error || config.tloErrorTxt}
            showTloInit={() => {
              if (config.partnerBrand === "Anycard") {
                dispatch(
                  closeWidget({
                    error: `Transaction flagged as possible fraudulent.`,
                  })
                );
              } else {
                dispatch(defaultAction(HIDE_TLO_ERROR_PHONE_MODAL));
                dispatch(defaultAction(SHOW_TLO_VERIFICATION_INIT));
              }
            }}
            config={config}
          />
        </ActionContainer>
      </Guard>
    );
  }

  if (tlo.get("isShowingTLOVerificationInit")) {
    return (
      <Guard condition={isOrderIdEmpty} redirect={"/"}>
        <ActionContainer title={config.identityTloTitle}>
          <React.Fragment>
            <TloVerificationInit
              config={config}
              phoneNumbers={tlo.get("phoneNumbers")}
              selectedPhoneNumberId={tlo.get("selectedPhoneNumberId")}
              selectPhoneNumber={(id) =>
                dispatch(defaultAction(SELECT_TLO_PHONE_NUMBER, id))
              }
              showTloError={() =>
                dispatch(defaultAction(SHOW_TLO_ERROR_PHONE_MODAL))
              }
              onSend={(validationType) => {
                dispatch(
                  defaultAction(SET_TLO_VERIFICATION_TYPE, validationType)
                );
                dispatch(sendTLOVerification());
                history.push("/identity-pass-code");
              }}
              showMissingNumber={false}
            />
          </React.Fragment>
          <React.Fragment>
            <Message>
              <InfoIcon />
              <a
                onClick={() =>
                  dispatch(defaultAction(SHOW_TLO_ERROR_PHONE_MODAL))
                }
              >
                {config.tloMissingNumberLink}
              </a>
            </Message>
          </React.Fragment>
        </ActionContainer>
      </Guard>
    );
  }

  return (
    <Guard condition={isOrderIdEmpty} redirect={"/"}>
      <ActionContainer title={config.identityTloTitle}></ActionContainer>
    </Guard>
  );
};
