import LayoutV1 from "./v1/containers/layout";
import LayoutV2 from "./v2/containers/layout";
import LayoutV3 from "./v3/containers/layout";

export default config => {
  switch (config.layoutVersion) {
    case 1:
      return LayoutV1;
    case 2:
      return LayoutV2;
    case 3:
      return LayoutV3;
    default:
      throw new Error("Layout not setup");
  }
};
