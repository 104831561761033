import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

import { SET_ERROR } from "../../../constants/actionTypes";
import { defaultAction } from "../../../actions/defaultAction";

import { cartTotalSelector } from "../../../selectors";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  text-align: left;
  margin-top: 0px;
`;
const Total = styled.span`
  display: none;

  ${mediaPhone} {
    display: block;
    float: right;
    font-weight: 400;

    span {
      margin-left: 0.3rem;
      color: rgb(8, 167, 2);
    }
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ scrollable }) => (scrollable ? "overflow-y: auto" : "")};
  ${mediaDesktop} {
    max-height: 18rem;
    min-height: 9.5rem;
    padding-right: 1.92308rem;
  }
  ${mediaPhone} {
    min-height: 6rem;
  }
`;
const ErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
`;
const ErrorMsg = styled.div`
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  box-sizing: border-box;
  position: absolute;
  left: -8%;
  top: -4rem;
  margin: 0;
  width: 110%;
  padding: 0 5%;
  background-color: #fcf6dd;
  color: #cb0d0d;
  border: 1px solid #cb0d0d;
  box-shadow: 0 0 0.2rem #000;
  z-index: 343434;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
`;
const ErrorText = styled.p`
  flex: 1;
  margin: 0.6rem 5%;
  overflow-y: hidden;
  overflow-x: hidden;
`;
const ErrorCrossWrapper = styled.div`
  margin: 0.6rem 5%;
`;
const ErrorCrossContainer = styled.div`
  cursor: pointer;
  border-radius: 0.05rem;
  position: absolute;
  margin: 0.2rem;
  width: 0.6rem;
  height: 0.6rem;
`;
const Cross = styled.div`
  background-color: darkgrey;
  transition: transform;
  border-radius: 0.05rem;
  top: 50%;
  width: 100%;
  height: 0.15rem;
  position: absolute;
  transform-origin: 50% 0%;

  &:first-of-type {
    transform: rotate(-45deg) translateY(-50%);
  }
  &:last-child {
    transform: rotate(45deg) translateY(-50%);
  }

  animation: ${({ order }) =>
    order === 1
      ? "-45, 135 2s normal infinite"
      : "45, -315 2s normal infinite"};
`;
const Actions = styled.div`
  ${mediaDesktop} {
    display: flex;
    position: relative;
    padding-right: 1.92308rem;
    margin-top: 0.5rem;
  }

  ${mediaPhone} {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
  }
`;

const ActionContainer = ({ title, children, scrollable }) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.userNotifications.errorMsg);
  const cartTotal = useSelector((state) => cartTotalSelector(state));
  const resetError = () => {
    dispatch(defaultAction(SET_ERROR, ""));
  };

  return (
    <Container>
      <Title>
        {title}
        <Route
          path={
            "/(sounds-good|card-details|identity|identity-card-info|identity-billing-info|identity-verify)"
          }
        >
          <Total>
            <small>total</small>
            <span>{cartTotal}</span>
          </Total>
        </Route>
      </Title>
      <Body scrollable={scrollable}>{children[0] || children}</Body>
      <ErrorContainer>
        {error && error !== "" && (
          <ErrorMsg>
            <ErrorText>{error}</ErrorText>
            <ErrorCrossWrapper onClick={resetError}>
              <ErrorCrossContainer>
                <Cross order={1} />
                <Cross order={2} />
              </ErrorCrossContainer>
            </ErrorCrossWrapper>
          </ErrorMsg>
        )}
      </ErrorContainer>
      {children[1] && <Actions>{children[1]}</Actions>}
    </Container>
  );
};

ActionContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ActionContainer;
