import React from "react";

export default ({ config }) => (
  <div className="hidden-lg-up mobile-social">
    <a href={config.googleURL} target="_blank">
      <img
        className={config.googleURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/googleplus_icon.png"
      />
    </a>
    <a href={config.instagramURL} target="_blank">
      <img
        className={config.instagramURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/instagram_icon.png"
      />
    </a>
    <a href={config.youTubeURL} target="_blank">
      <img
        className={config.youTubeURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/youtube_icon.png"
      />
    </a>
    <a href={config.facebookURL} target="_blank">
      <img
        className={config.facebookURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/facebook_icon.png"
      />
    </a>
    <a href={config.twitterURL} target="_blank">
      <img
        className={config.twitterURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/twitter_icon.png"
      />
    </a>
    <a href={config.pinterestURL} target="_blank">
      <img
        className={config.pinterestURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/pintrest_icon.png"
      />
    </a>
    <a href={config.linkedInURL} target="_blank">
      <img
        className={config.linkedInURL ? "" : "hide"}
        src="https://new-cardcash-images.s3.amazonaws.com/website/linkedin_icon.png"
      />
    </a>
  </div>
);
