import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import ContactInformation from "../../../components/stepThree/contactInfo";

import { defaultAction } from "../../../actions/defaultAction";

import {
  enteredCardsValidateSelector,
  contactInfoValidateSelector,
  cartIsEmptySelector,
  sellVerificationSelector,
  customerInfoSelector,
  cartSelector,
} from "../../../selectors";

import {
  SELL_VERIFICATION_SET_EMAIL,
  HIDE_SELL_VERIFICATION_EMAIL_ERROR,
  HIDE_SELL_VERIFICATION_PHONE_ERROR,
  SELL_VERIFICATION_SET_PHONE,
  SELL_VERIFICATION_SET_PHONE_ERROR,
  SELL_VERIFICATION_SET_EMAIL_ERROR,
} from "../../../constants/actionTypes";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    cartSell,
    customerInfo,
    sellVerification,
    enteredCardsValidate,
    contactInfoValidate,
    cartIsEmpty,
    store,
  } = useSelector(
    (state) => ({
      config: state.config,
      cartSell: cartSelector(state),
      customerInfo: customerInfoSelector(state),
      sellVerification: sellVerificationSelector(state),
      enteredCardsValidate: enteredCardsValidateSelector(state),
      contactInfoValidate: contactInfoValidateSelector(state),
      cartIsEmpty: cartIsEmptySelector(state),
      store: state.store,
    }),
    shallowEqual
  );

  const submitBtnClass = contactInfoValidate
    ? "btn btn-primary"
    : "btn btn-primary activebtn";

  return (
    <Guard
      condition={!cartIsEmpty && enteredCardsValidate}
      redirect={"/card-details"}
    >
      <ActionContainer title={config.identityTitle}>
        <React.Fragment>
          <ContactInformation
            cartSell={cartSell}
            config={config}
            sellVerification={sellVerification}
            customerInfo={customerInfo}
            hideEmailError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_EMAIL_ERROR))
            }
            setEmail={(e) =>
              dispatch(
                defaultAction(
                  SELL_VERIFICATION_SET_EMAIL,
                  e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
                )
              )
            }
            hidePhoneError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_PHONE_ERROR))
            }
            setPhone={(phoneNum) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_PHONE, phoneNum))
            }
            setPhoneError={() =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_PHONE_ERROR))
            }
            setEmailError={() =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_EMAIL_ERROR))
            }
            store={store}
          />
        </React.Fragment>
        <React.Fragment>
          <input
            id="continue"
            type="button"
            name="submit"
            className={submitBtnClass}
            value={config.identityContinueButtonTxt}
            onClick={() => {
              setIsSubmitting(true);
              history.push("/identity-card-info");
            }}
            disabled={isSubmitting || contactInfoValidate}
          />
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
