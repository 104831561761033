import curry from "./curry";

const DEFAULT_OPTIONS = {
  // When set to true, fields who's value is null or undefined will not be included in the outputObject.
  drop: false,
  // When set to true all the aliases & the name will be used in the outputObject.
  pollute: false
};

const getOrNull = (obj, key) => (key in obj ? obj[key] : null);

function splitSlug(keyName) {
  return keyName.split(":");
}

/**
 * helper function that builds the output object based on the key, aliases and options defined.
 */
function assign(source, key, aliases, options = DEFAULT_OPTIONS) {
  const value = getOrNull(source, key);

  if (options.pollute)
    return [key, ...aliases].reduce((outputObject, key) => ({ ...outputObject, [key]: value }), {});

  if (aliases.length > 0) {
    key = aliases[0];
  }

  return { [key]: value };
}

/**
 * Returns a new object containing the fields sourced from `sourceObject` and defined by `keys`.
 * @param {Object} sourceObject - Source containing the values of the output object
 * @param {Array<String>|Object} keys - The list of keys that will make up the key value pairs of the output object
 * @return {Object}
 */
function select(sourceObject, keys = [], options = DEFAULT_OPTIONS) {
  if (!sourceObject) throw Error("sourceObject must be defined.");

  if (!Array.isArray(keys)) keys = Object.keys(keys || {});
  options = { ...DEFAULT_OPTIONS, ...options };

  return keys.reduce((outputObject, slug) => {
    let [key, ...aliases] = splitSlug(slug);

    return sourceObject[key] == null && options.drop
      ? outputObject
      : { ...outputObject, ...assign(sourceObject, key, aliases, options) };
  }, {});
}

function selectF(keys, sourceObject, options) {
  return select(sourceObject, keys, options);
}
selectF = curry(2, selectF);

export default curry(1, select);
export { selectF };
