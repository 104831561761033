import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import CardInCart from "../../../components/stepTwo/cardInCartMini";
import StepTwoCart from "../../../components/stepTwo/stepTwoCart";

import {
  deleteCardInCart,
  sendCardAndPinToServer,
} from "../../../thunks/cards";

import { defaultAction } from "../../../actions/defaultAction";

import {
  cartIsEmptySelector,
  enteredCardsValidateSelector,
  cartSelector,
  cardsSelector,
  merchantSelector,
} from "../../../selectors";

import {
  CLEAR_CARD_AND_PIN_ENTRY,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  DISPLAY_INVALID_MATCH,
  DISPLAY_MATCH,
  UPDATE_CARD_NUMBER,
  UPDATE_CARD_PIN,
} from "../../../constants/actionTypes";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    cartSell,
    cards,
    cartIsEmpty,
    enteredCardsValidate,
    merchantsSell,
  } = useSelector(
    (state) => ({
      config: state.config,
      cartSell: cartSelector(state),
      cards: cardsSelector(state),
      cartIsEmpty: cartIsEmptySelector(state),
      merchantsSell: merchantSelector(state),
      enteredCardsValidate: enteredCardsValidateSelector(state),
    }),
    shallowEqual
  );

  const submitBtnClass = enteredCardsValidate
    ? "btn btn-primary"
    : "btn btn-primary activebtn";

  return (
    <Guard condition={cartIsEmpty} redirect={"/"}>
      <ActionContainer title={config.cardDetailsTitle} scrollable={true}>
        <React.Fragment>
          {cartSell.cardIds.map(
            (card, index) =>
              card && (
                <React.Fragment key={index}>
                  <CardInCart
                    key={`cardDetailsDisplay_${index}`}
                    brand={
                      merchantsSell.hasOwnProperty(cards[card].id)
                        ? merchantsSell[cards[card].id].name
                        : ""
                    }
                    balance={+cards[card].enterValue}
                    offer={+cards[card].cashValue}
                    onRemove={() =>
                      dispatch(deleteCardInCart(cartSell.cartId, card, cards))
                    }
                  />
                  <StepTwoCart
                    key={`cardDetailsInputs_${index}`}
                    merchantsSell={merchantsSell}
                    cashValue={cards[card].cashValue}
                    merchantId={cards[card].id}
                    enteredAmount={cards[card].enterValue}
                    cardIndex={index}
                    cartSellID={cartSell.cartId}
                    cardID={card}
                    updatePin={(cardID, pin) =>
                      dispatch(
                        defaultAction(UPDATE_CARD_PIN, { cardID, pin: pin })
                      )
                    }
                    updateCardNum={(cardID, num) =>
                      dispatch(
                        defaultAction(UPDATE_CARD_NUMBER, {
                          cardID,
                          number: num,
                        })
                      )
                    }
                    sendCardAndPinToServer={(
                      cartSellID,
                      cardID,
                      number,
                      pin,
                      cards
                    ) =>
                      dispatch(
                        sendCardAndPinToServer(
                          cartSellID,
                          cardID,
                          number,
                          pin,
                          cards
                        )
                      )
                    }
                    pin={cards[card].pin}
                    number={cards[card].number}
                    validation={
                      (merchantsSell[cards[card].id] &&
                        merchantsSell[cards[card].id].validation) ||
                      ""
                    }
                    displayInvalidMatch={(cardID) =>
                      dispatch(
                        defaultAction(DISPLAY_INVALID_MATCH, {
                          cardID: cardID,
                        })
                      )
                    }
                    displayMatch={(cardID) =>
                      dispatch(defaultAction(DISPLAY_MATCH, { cardID: cardID }))
                    }
                    cardNumEnter={cards[card].cardNumEnter}
                    pinNumEnter={cards[card].pinNumEnter}
                    cards={cards}
                    match={cards[card].match}
                    msg={cards[card].msg}
                    clearUpdateCardServerError={(cardID) =>
                      dispatch(
                        defaultAction(CLEAR_UPDATE_CARD_SERVER_ERROR, {
                          cardID,
                        })
                      )
                    }
                    clearCardAndPin={(cardID) =>
                      dispatch(
                        defaultAction(CLEAR_CARD_AND_PIN_ENTRY, { cardID })
                      )
                    }
                    config={config}
                  />
                </React.Fragment>
              )
          )}
        </React.Fragment>
        <React.Fragment>
          <input
            id="continue"
            type="button"
            name="submit"
            className={submitBtnClass}
            value={config.cardDetailsContinueButtonTxt}
            onClick={() => {
              setIsSubmitting(true);
              history.push("/identity");
            }}
            disabled={isSubmitting || enteredCardsValidate}
          />
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
