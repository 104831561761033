export const ALL_STATES = ["AL", "AK", "AS", "AZ", "AR", "AA", "AE", "AP", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

export const ALL_MONTHS = [
	{
		value: "01",
		month: "Jan"
	},
	{
		value: "02",
		month: "Feb"
	},
	{
		value: "03",
		month: "Mar"
	},
	{
		value: "04",
		month: "Apr"
	},
	{
		value: "05",
		month: "May"
	},
	{
		value: "06",
		month: "Jun"
	},
	{
		value: "07",
		month: "Jul"
	},
	{
		value: "08",
		month: "Aug"
	},
	{
		value: "09",
		month: "Sep"
	},
	{
		value: "10",
		month: "Oct"
	},
	{
		value: "11",
		month: "Nov"
	},
	{
		value: "12",
		month: "Dec"
	}
]

