import {
  RECEIVE_SELL_MERCHANTS,
  RESET,
  RESET_EXPIRE,
  LOAD_HARD_CODED_TEST_DATA
} from "../constants/actionTypes";

const initialState = { sortedByName: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SELL_MERCHANTS:
      // State has two levels
      //   Level One: object properties that are the merchantId, allowing quick access by ID.
      //   Level Two: array of merchants, sorted by merchant name
      let newState = { sortedByName: action.payload.sellMerchants };
      return newState.sortedByName.reduce((obj, merchant) => {
        obj[merchant.id] = merchant;
        return obj;
      }, newState);

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};
