import React from "react";
import Modal from "react-modal";

export default ({ errorBox, closeError, title, notification }) => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    isOpen={errorBox}
    onRequestClose={closeError}
    contentLabel="Error"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" onClick={closeError}>
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title partner-specific-color">{title}</h4>
      </div>
      <div className="modal-body">{notification}</div>
    </div>
  </Modal>
);
