import React from "react";
import { determineStep } from "../../../utils/determineStep";

export default ({
  detailStepOneText,
  detailStepTwoText,
  detailStepThreeText,
  path,
  stepsVersion,
  steps
}) => {
  if (stepsVersion !== 2) return null;

  const step = determineStep(steps, path, false);

  let description = null;
  switch (step) {
    case 0:
      description = detailStepOneText.description;
      break;
    case 1:
      description = detailStepTwoText.description;
      break;
    case 2:
      description = detailStepThreeText.description;
      break;
  }

  if (!description) return null;

  return (
    <div className="container steps-styles">
      {description.map(({ className, text }, i) => (
        <div
          key={i}
          className={className}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ))}
    </div>
  );
};
