import React from "react";

const DoubleArrowIcon = () => {
  return (
    <div className={`partner-double-arrow-icon double-arrow-icon`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6px"
        height="6px"
        viewBox="0 0 6 6"
        version="1.1"
      >
        <link type="text/css" id="dark-mode" rel="stylesheet" />
        <style xmlns="" type="text/css" id="dark-mode-custom-style" />

        <g
          id="Mobile"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Fill"
            transform="translate(-185.000000, -721.000000)"
            fillRule="nonzero"
          >
            <g id="Form" transform="translate(18.000000, 90.000000)">
              <g id="Group-16" transform="translate(0.000000, 272.000000)">
                <g id="Group-15" transform="translate(30.000000, 42.000000)">
                  <path
                    d="M138.18,322.964 L140.196,320.29 L138.18,317.602 L136.948,317.602 L138.922,320.29 L136.948,322.964 L138.18,322.964 Z M140.868,322.964 L142.884,320.29 L140.868,317.602 L139.636,317.602 L141.61,320.29 L139.636,322.964 L140.868,322.964 Z"
                    id="»"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

DoubleArrowIcon.propTypes = {};

export default DoubleArrowIcon;
