import React from "react";
import Modal from "react-modal";
import CountdownTimer from "../countdownTimer";

export default ({
  showSessionExpireWarning,
  showSessionExpiredBox,
  startNewSession
}) => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    isOpen={showSessionExpireWarning}
    contentLabel="Session Expire Warning"
  >
    <div className="modal-content">
      <div className="modal-header">
        <h4
          className="modal-title partner-specific-color"
          style={{ textAlign: "center" }}
        >
          Your Session is about to expire
        </h4>
      </div>
      <div className="modal-body">
        <div className="m_bottom_10">
          You have been inactive for too long. For your protection, you will
          have to renew your session to trade in cards. If you don't address
          this warning, your cart will be emptied.
        </div>
        <CountdownTimer
          showSessionExpiredBox={showSessionExpiredBox}
          secondsRemaining={300}
        />
        <button
          className="btn btn-primary"
          onClick={startNewSession}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            textAlign: "center"
          }}
        >
          {" "}
          Renew session{" "}
        </button>
      </div>
    </div>
  </Modal>
);
