import React from "react";
import { ALL_STATES } from "../../constants/payfields";

export default (props) => {
  const states = ALL_STATES.map((state) => {
    return (
      <option key={state} value={state}>
        {state}
      </option>
    );
  });
  return (
    <div className="col-md-4 responsive_input no-padding-left">
      <select
        tabIndex={props.tabIndex}
        name={props.name}
        className="form-control"
        onChange={props.selectState}
        value={props.signedInVal}
        disabled={props.signedIn}
      >
        <option value="">Select State</option>
        {states}
      </select>
      <div
        className={
          props.errorType ? "animated fadeIn errorMsg fverror" : "hide"
        }
      >
        State required
      </div>
    </div>
  );
};
