import {
  SET_SESSION_ID,
  OPEN_ERROR_BOX,
  SET_ERROR,
  SESSION_RENDER_ASSETS,
} from "../constants/actionTypes.js";

import { getSellMerch } from "./sellMerchants";
import { getCartContents, getPaymentOptions } from "./cart";
import { doEvent } from "./events";

export const startSession =
  (utmData = {}) =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const {
        config: { partnerBrand },
      } = getState();
      const session = await remote(
        "post",
        "/v3/session",
        utmData.type !== "" ? utmData : {},
        "creating a session",
        dispatch
      );

      if (session.message) {
        throw session;
      }

      await dispatch({ type: SET_SESSION_ID, payload: session.sessionId });
      await dispatch({
        type: SESSION_RENDER_ASSETS,
        payload: session.renderAssets,
      });
      await dispatch(getSellMerch());
      await dispatch(getCartContents());
      if (partnerBrand !== "Anycard") await dispatch(getPaymentOptions());
      await dispatch(doEvent("SESSION_CONFIRMED"));
    } catch (e) {
      if (getState().config.layoutVersion === 1) {
        dispatch({
          type: OPEN_ERROR_BOX,
          payload: {
            title: "Sorry, we are experiencing technical difficulties",
            message:
              "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
          },
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload:
            "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
        });
      }
    }
  };
