import React, { useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import ActionContainer from "../containers/actionContainer";
import Guard from "../containers/guard";
import InputCard from "../../../components/stepOne/inputCardV3";
import CardInCart from "../../../components/stepOne/cardInCartV2";
import Button from "../containers/button";
import CCA from "../containers/a";
import CCLink from "../containers/link";
import { defaultAction } from "../../../actions/defaultAction";

import {
  validBalance,
  addCard,
} from "../../../utils/validators/stepOneSell_v2";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { deleteCardInCart } from "../../../thunks/cards";

import {
  cartIsEmptySelector,
  cartSelector,
  cardsSelector,
  cardPartialSelector,
  userActionsSelector,
  merchantSelector,
  anyCardOrderTotalSelector,
} from "../../../selectors";

import {
  ANYCARD_ORDER_TOTAL_EXCEEDED,
  CARD_PARTIAL_CLEAR,
  ORDER_TOTAL_MAX_EXCEEDED,
  ORDER_TOTAL_MIN_REQUIRED,
  SOUNDS_GOOD_CONTINUE_DISABLED,
} from "../../../constants/actionTypes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    text-align: center;
    margin: 0;
    padding-bottom: 12px;

    ${mediaPhone} {
      font-size: 10px;

      a {
        font-size: 10px;
      }
    }
  }

  a {
    margin-top: 20px;
  }
`;

const InputContainer = styled.div`
  width: 90%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

const Cancel = styled(Button)`
  ${mediaPhone} {
    width: 100px;
    height: 40px;
  }
  ${mediaDesktop} {
    width: 200px;
    height: 56px;
  }

  height: 56px;
  font-size: 14px;
  font-weight: bold;
  color: rgb(51, 67, 61);
  margin-right: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(51, 67, 61, 0.05) 0px 9px 18px 0px;
  cursor: pointer;
`;

const Save = styled(Button)`
  ${mediaDesktop} {
    width: 250px;
    height: 56px;
  }
  ${mediaPhone} {
    width: 135px;
    height: 40px;
  }
`;

const A = styled(CCA)`
  margin-top: 1rem;
  font-weight: bold;
  cursor: pointer;
`;

const Link = styled(CCLink)`
  margin-top: 1rem;
  font-weight: lighter;
  cursor: pointer;
`;

const Actions = styled.div`
  height: 150px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const soundsGoodVal = (
  partnerBrand,
  cartSell,
  cards,
  anyCardTotal,
  history,
  store,
  dispatch,
  agreementPageOn
) => {
  let tradeTotal = Object.values(cartSell.cardIds)
    .map((id) => cards[id].cashValue)
    .reduce((total, cashValue) => +cashValue + total, 0);

  if (partnerBrand === "Anycard" && anyCardTotal - tradeTotal < 0) {
    dispatch({
      type: ANYCARD_ORDER_TOTAL_EXCEEDED,
    });

    if (!store.soundsGoodContinueDisabled) {
      dispatch({ type: SOUNDS_GOOD_CONTINUE_DISABLED });
    }
  } else if (partnerBrand !== "Anycard" && tradeTotal < store.minPayout) {
    dispatch({
      type: ORDER_TOTAL_MIN_REQUIRED,
    });

    if (!cartSell.soundsGoodContinueDisabled) {
      dispatch({ type: SOUNDS_GOOD_CONTINUE_DISABLED });
    }
  } else if (partnerBrand !== "Anycard" && tradeTotal > store.maxPayout) {
    dispatch({
      type: ORDER_TOTAL_MAX_EXCEEDED,
    });

    if (!cartSell.soundsGoodContinueDisabled) {
      dispatch({ type: SOUNDS_GOOD_CONTINUE_DISABLED });
    }
  } else {
    if (cartSell.soundsGoodContinueDisabled) {
      dispatch({ type: SOUNDS_GOOD_CONTINUE_DISABLED });
    }

    if (agreementPageOn) {
      history.push("/agreement");
    } else {
      history.push("/card-details");
    }
  }
};
export default () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showEnterForm, setShowEnterForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    cartSell,
    cards,
    cardPartial,
    cartIsEmpty,
    merchantsSell,
    anyCardTotal,
    store,
  } = useSelector(
    (state) => ({
      config: state.config,
      cartSell: cartSelector(state),
      cards: cardsSelector(state),
      cardPartial: cardPartialSelector(state),
      userActions: userActionsSelector(state),
      cartIsEmpty: cartIsEmptySelector(state),
      merchantsSell: merchantSelector(state),
      anyCardTotal: anyCardOrderTotalSelector(state),
      store: state.store,
    }),
    shallowEqual
  );
  const reset = () => {
    dispatch(defaultAction(CARD_PARTIAL_CLEAR));
    setShowEnterForm(false);
    setIsSubmitting(false);
  };

  const save = () => {
    setIsSubmitting(true);
    addCard(cartSell, cardPartial, cards, dispatch, () =>
      validBalance(cardPartial, dispatch)
    );

    if (
      !cardPartial.displayMinError &&
      !cardPartial.displayMaxError &&
      !cardPartial.displayBalanceError &&
      !cardPartial.displayMerchantError
    ) {
      reset();
    }
  };

  const saveBtnDisabled =
    isSubmitting ||
    (cardPartial.balance === "" && cardPartial.selectedMerchant === null) ||
    (cardPartial.balance === "" && cardPartial.selectedMerchant !== null);

  return (
    <Guard condition={cartIsEmpty} redirect={"/"}>
      <ActionContainer title={config.soundGoodTitle} scrollable={true}>
        <React.Fragment>
          <Container>
            {config.showSoundsGoodMsg && (
              <p>
                {config.soundsGoodMsg}{" "}
                <Link
                  to={`${
                    config.basename
                      ? `${config.basename}/faq#question4-whyaresometr`
                      : "/faq#question4-whyaresometr"
                  }`}
                >
                  Learn more
                </Link>
              </p>
            )}

            {cartSell.cardIds.map(
              (card, index) =>
                card && (
                  <CardInCart
                    key={index}
                    brand={
                      merchantsSell.hasOwnProperty(cards[card].id)
                        ? merchantsSell[cards[card].id].name
                        : ""
                    }
                    balance={+cards[card].enterValue}
                    offer={+cards[card].cashValue}
                    onRemove={() =>
                      dispatch(deleteCardInCart(cartSell.cartId, card, cards))
                    }
                    layoutVersion={config.layoutVersion}
                    image={
                      merchantsSell.hasOwnProperty(cards[card].id)
                        ? merchantsSell[cards[card].id].image
                        : ""
                    }
                    offerTxt={config.offerTxt}
                  />
                )
            )}

            {showEnterForm && (
              <InputContainer ref={inputRef}>
                <InputCard
                  offerMerchantDropDownPlaceHolder={
                    config.offerMerchantDropDownPlaceHolder
                  }
                  offerBalanceInputPlaceHolder={
                    config.offerBalanceInputPlaceHolder
                  }
                  cardPartial={cardPartial}
                  primaryColor={config.theme.primary_color}
                  variant={config.variant}
                />
              </InputContainer>
            )}
          </Container>
        </React.Fragment>
        <React.Fragment>
          {showEnterForm ? (
            <div style={{ marginTop: "2rem" }}>
              <Cancel onClick={reset}>
                {config.soundsGoodCancelButtonTxt}
              </Cancel>
              <Save
                onClick={save}
                active={!saveBtnDisabled}
                disabled={saveBtnDisabled}
              >
                {config.soundsGoodSaveButtonTxt}
              </Save>
            </div>
          ) : (
            <Actions>
              <Button
                active={true}
                disabled={store.soundsGoodContinueDisabled}
                onClick={() =>
                  soundsGoodVal(
                    config.partnerBrand,
                    cartSell,
                    cards,
                    anyCardTotal,
                    history,
                    store,
                    dispatch,
                    config.agreementPageOn
                  )
                }
              >
                {config.soundsGoodContinueButtonTxt}
              </Button>

              <A
                onClick={() => {
                  setShowEnterForm(true);
                  setTimeout(
                    () =>
                      inputRef.current.scrollIntoView({ behavior: "smooth" }),
                    100
                  );
                }}
              >
                {config.soundGoodAddAnotherCardLinkTxt}
              </A>
            </Actions>
          )}
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
