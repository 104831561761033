import React, { useState } from "react";
import VerificationInput from "react-verification-input";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import {
  mediaPhone,
  mediaIPadPro,
  mediaIPad,
} from "../../layouts/styles/Breakpoints";
import Button from "../../layouts/v3/containers/button";
import CCA from "../../layouts/v2/containers/a";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ version }) => (version === 2 ? "width: 100%;" : "")}
  margin-left: auto;
  margin-right: auto;
`;

const Actions = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: ${({ version }) => (version === 2 ? "100%;" : "90%")};
  ${mediaPhone} {
    width: 100%;
  }
`;

const ActionsLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TloVerificationEnter = ({
  setTLOEnteredCode,
  enteredCode,
  enteredCodeError,
  goToTLOVerificationInit,
  doTLOResendCode,
  doTLOVerify,
  config,
  showLinks,
}) => {
  let numOfInputs = 5;
  let [code, setCode] = useState("");

  const setValue = (val) => {
    setCode(val);
    if (val.length === numOfInputs) {
      setTLOEnteredCode(val);
    }
  };

  const disabledV3 = code.length !== numOfInputs;
  const disabledV2 = enteredCode.length !== numOfInputs;

  return (
    <Container version={config.layoutVersion}>
      <style>{`
          .vi__wrapper {
            margin-bottom: 30px;
            align-self: center;
          }
          .vi__character {
            outline-color: rgba(83,92,88,0.3);
            border: none;
            border-bottom: 2px solid #b5b1b1;
            border-radius: 4px;
          }
          .vi__character--selected {
            color: initial;
          }
          .vi__character--inactive {
            background: rgba(83,92,88,0.1);
          }
      `}</style>
      {enteredCodeError && (
        <div style={{ marginBottom: "23px", textAlign: "center" }}>
          <span className={"errorMsg"}>{enteredCodeError}</span>
        </div>
      )}
      {config.layoutVersion === 2 ? (
        <div>
          <input
            type="text"
            value={enteredCode}
            className={"form-control"}
            onChange={(e) => setTLOEnteredCode(e.target.value.trim())}
            placeholder={config.tloEnteredCodePlaceHolderTxt}
          />
        </div>
      ) : (
        <VerificationInput
          length={numOfInputs}
          validChars={"0-9"}
          placeholder={""}
          autoFocus={true}
          onChange={setValue}
        />
      )}

      {config.layoutVersion === 2 && showLinks && (
        <React.Fragment>
          <ActionsLinks>
            <CCA onClick={doTLOResendCode}>{config.tloResendCodeLinkTxt}</CCA>
            <CCA onClick={goToTLOVerificationInit}>
              {config.tloChooseADifferentPhoneTxt}
            </CCA>
          </ActionsLinks>
        </React.Fragment>
      )}

      {config.layoutVersion === 3 && showLinks && (
        <React.Fragment>
          <div>
            <a onClick={doTLOResendCode}>{config.tloResendCodeLinkTxt}</a>
            <br />
            <a onClick={goToTLOVerificationInit}>
              {config.tloChooseADifferentPhoneTxt}
            </a>
          </div>
        </React.Fragment>
      )}

      <Actions version={config.layoutVersion}>
        {config.layoutVersion === 2 && (
          <input
            type="button"
            onClick={() => {
              doTLOVerify();
            }}
            className={"btn btn-primary activebtn"}
            value={config.tloVerifyBtnTxt}
            disabled={disabledV2}
          />
        )}
        {config.layoutVersion === 3 && (
          <Button
            active={!disabledV3}
            onClick={() => {
              doTLOVerify();
            }}
            disabled={disabledV3}
          >
            {config.tloVerifyBtnTxt}
          </Button>
        )}
      </Actions>
    </Container>
  );
};

TloVerificationEnter.defaultProps = {
  showLinks: true,
};

TloVerificationEnter.propTypes = {
  goToTLOVerificationInit: PropTypes.func,
  doTLOResendCode: PropTypes.func,

  enteredCode: PropTypes.string.isRequired,
  enteredCodeError: PropTypes.string.isRequired,
  setTLOEnteredCode: PropTypes.func.isRequired,
  doTLOVerify: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  showLinks: PropTypes.bool.isRequired,
};

export default TloVerificationEnter;
