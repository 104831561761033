import React, { useEffect, useState } from "react";
import Router from "../routes";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

import {
  mediaPhone,
  mediaDesktop,
  mediaIPad,
  mediaIPadPro,
} from "../../styles/Breakpoints";
import { cartTotalSelector } from "../../../selectors";
import { startSession } from "../../../thunks/session";
import { defaultAction } from "../../../actions/defaultAction";
import loadAssets from "../../../utils/loadAssets";
import utmFinder from "../../../utils/utmFinder";

import Header from "./header";
import Footer from "./footer";
import ExchangeMast from "./exchangeMast";
import Steps from "./steps";
import ErrorBoundary from "./errorBoundary";
import WebFont from "webfontloader";

import { SET_PAYOUT } from "../../../constants/actionTypes";
import { doEvent } from "../../../thunks/events";

const ACCENTED_ENDPOINTS = ["/why", "/faq", "/help"];

WebFont.load({
  google: {
    families: ["Lato:300,400,500,600,700"],
  },
});

const Globals = (theme) => css`
  html,
  body {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    margin: 0;
    padding: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
  }

  #root {
    display: flex;
    height: 100vh;
    width: 100vw;
    position: absolute;
    flex-direction: column;
    top: 0px;
    left: 0px;
  }

  .hide {
    display: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
    font-size: 1rem;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc;
    font-size: 1rem;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc;
    font-size: 1rem;
  }

  .form-control {
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    height: 2.76923rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s,
      border-width 0.1s ease 0s;
    padding: 0px 1rem;
    outline: none;
    border-width: 0.1rem;
    border-style: solid;
    border-color: rgb(215, 215, 215);
    color: #3d3d3d;
  }

  .btn {
    border-radius: 0.1rem;
    transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s,
      border-width 0.1s ease 0s;
    background-color: ${theme.activeColor};
    color: white;
    padding: 0.5em;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.30769rem;
    height: 2.76923rem;
    margin: 0.3rem;
    width: 100%;

    &:disabled {
      background-color: ${theme.inactiveColor};
    }

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  .errorMsg {
    color: red;
    display: block !important;
    font-size: 11px;
    line-height: 1.8em;
  }
`;

const FadeContainer = styled.div`
  position: relative;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaPhone} {
    padding-bottom: 6rem;
  }
`;

const FadeImg = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  ${mediaPhone} {
    position: fixed;
    background-image: url(${({ theme }) => `${theme.background.phone.url}`};);
    background-position: ${({ theme }) => `${theme.background.phone.position}`};
    background-size: ${({ theme }) => `${theme.background.phone.size}`};
    background-color: ${({ theme }) => `${theme.background.phone.color}`};
    overflow: unset;
  }
  ${mediaDesktop} {
    position: absolute;
    min-height: 38.3rem;
    background-image: url(${({ theme }) => `${theme.background.desktop.url}`};);
    background-position: ${({ theme }) =>
      `${theme.background.desktop.position}`};
    background-size: ${({ theme }) => `${theme.background.desktop.size}`};
    background-color: ${({ theme }) => `${theme.background.desktop.color}`};
  }
  ${mediaIPad} {
    position: absolute;
    background-image: url(${({ theme }) => `${theme.background.iPad.url}`};);
    background-position: ${({ theme }) => `${theme.background.iPad.position}`};
    background-size: ${({ theme }) => `${theme.background.iPad.size}`};
    background-color: ${({ theme }) => `${theme.background.iPad.color}`};
  }
  ${mediaIPadPro} {
    position: absolute;
    background-image: url(${({ theme }) => `${theme.background.iPadPro.url}`};);
    background-position: ${({ theme }) =>
      `${theme.background.iPadPro.position}`};
    background-size: ${({ theme }) => `${theme.background.iPadPro.size}`};
    background-color: ${({ theme }) => `${theme.background.iPadPro.color}`};
  }
`;

const ModalHalfContainer = styled.div`
  color: #3d3d3d;
  display: flex;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  transition: margin-top 0.2s ease-in;
  ${mediaPhone} {
    flex-direction: column;
    padding: 0 0;
    width: 90%;
  }
  ${mediaDesktop} {
    box-shadow: 0rem 0rem 24rem -6rem black, inset 0 0 12rem black;
    flex-direction: row;
    width: 50rem;
  }
`;

const ExchangeContainer = styled.div`
  ${mediaDesktop} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: row;
    width: 50rem;
    color: rgb(61, 61, 61);
  }
`;

const ExchangeMastContainer = styled.div`
  ${mediaPhone} {
    margin-top: 2.5rem;
    flex-direction: column;
    padding: 0px;
    width: 90%;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.9);

  ${mediaPhone} {
    min-height: 40vh;
    border-radius: 0.1rem;
    border-top: 1px solid rgb(225, 225, 225);
    border-right: 1px solid rgb(225, 225, 225);
    border-left: 1px solid rgb(225, 225, 225);
    border-image: initial;
    border-bottom: none;
  }
  ${mediaDesktop} {
    border-top: 1px solid rgb(225, 225, 225);
    border-bottom: 1px solid rgb(225, 225, 225);
    border-left: 1px solid rgb(225, 225, 225);
    border-top-left-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem;
    border-image: initial;
    border-right: none;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${mediaPhone} {
    margin: 1.84615rem 1.53846rem 2.92308rem;
  }
  ${mediaDesktop} {
    margin: 1.84615rem 1.92308rem 2.92308rem 3.07692rem;
  }
`;

const FormContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export default () => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { config, popups, renderAssets, cartTotal } = useSelector(
    (state) => ({
      config: state.config,
      popups: state.popups,
      renderAssets: state.customerInfo.get("renderAssets"),
      cartTotal: cartTotalSelector(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    Promise.all([
      dispatch(startSession()),
      dispatch(
        defaultAction(SET_PAYOUT, {
          payoutType: config.paymentType,
          payoutMethod: "PARTNER",
        })
      ),
      utmFinder(location.hash || location.search || ""),
    ])
      .then(() => {
        const data = utmFinder(location.hash || location.search || "");

        if (data.length)
          dispatch(doEvent("CAPTURE_UTM_DATA", { values: data }));
      })
      .then(() => setFetched(true));

    // only load Analytics & fraud tools if session response `renderAssets` is `true`
    if (renderAssets) {
      loadAssets(location.pathname, config);
    }

    return () => {
      setFetched(false);
    };
  }, [
    config,
    dispatch,
    location.hash,
    location.pathname,
    location.search,
    renderAssets,
  ]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      pageUrl: `${window?.location?.href}`,
    });
  }, [location.pathname]);

  const currentPath =
    (location.state && location.state.referrer) || location.pathname;

  if (!fetched) {
    return null;
  }

  return (
    <>
      <ThemeProvider theme={config.theme}>
        <Global styles={Globals(config.theme)} />
        {/* load Analytics & fraud tools */}
        {renderAssets && loadAssets(config.googleAnalytics)}
        {/* Headers */}
        <Header config={config} popups={popups} />
        {/* Main Content */}
        <FadeContainer>
          <FadeImg />
          <ExchangeContainer>
            <ModalHalfContainer>
              <ExchangeMastContainer>
                <ExchangeMast.Mobile
                  config={config}
                  currentPath={currentPath}
                />
              </ExchangeMastContainer>
              {/* Left Content */}
              <FormWrapper>
                <FormContainer>
                  <ExchangeMast.Desktop
                    config={config}
                    cartTotal={cartTotal}
                    currentPath={location.pathname}
                  />
                  <Steps.Mobile config={config} currentPath={currentPath} />
                  <ErrorBoundary>
                    <FormContent>
                      <Router.Mast />
                    </FormContent>
                  </ErrorBoundary>
                </FormContainer>
              </FormWrapper>

              {/* Right Content */}
              <Router.Info />
            </ModalHalfContainer>
          </ExchangeContainer>
        </FadeContainer>
        {/* Footers */}
        <Footer config={config} />
      </ThemeProvider>
    </>
  );
};
