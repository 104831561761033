import { Map } from "immutable";

import {
  TOGGLE_CONTACT_US,
  OPEN_ERROR_BOX,
  CLOSE_ERROR_BOX,
  TOGGLE_VERIFICATION_BOX,
  TOGGLE_CHOOSE_VERIFICATION,
  SHOW_CHANGE_PHONE,
  HIDE_CHANGE_PHONE,
  SHOW_LOADING,
  HIDE_LOADING,
  FADE_PASS_RESET,
  SHRINK_BOX,
  TOGGLE_DROP_DOWN,
  SHOW_TWILIO_POPUP,
  SET_VERIFY_ID,
  SHOW_ACCOUNT_LOCKED,
  HIDE_ACCOUNT_LOCKED,
  MAKE_ANOTHER_PASSCODE_ATTEMPT,
  SHOW_SESSION_EXPIRE_WARNING,
  HIDE_SESSION_EXPIRE_WARNING,
  SHOW_SESSION_EXPIRED,
  HIDE_SESSION_EXPIRED,
  RESET,
  RESET_EXPIRE,
  DISPLAY_FORGOT_PASSWORD,
  HIDE_FORGOT_PASSWORD,
  SHOW_SELL_PAYMENT_POPUP,
  HIDE_SELL_PAYMENT_POPUP,
  SHOW_CHOOSE_CHECKOUT_POPUP,
  HIDE_CHOOSE_CHECKOUT_POPUP,
  SET_CARDS_UNDER_CONSIDERATION,
  HIDE_CARDS_UNDER_CONSIDERATION_POPUP,
  HIDE_TWILIO_POPUP,
  SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID,
  HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID,
  OPEN_EDIT_PROFILE_MODAL,
  CLOSE_EDIT_PROFILE_MODAL,
  OPEN_SUPPORT_MODAL,
  CLOSE_SUPPORT_MODAL,
  OPEN_SUPPORT_CARD_LEVEL_MODAL,
  CLOSE_SUPPORT_CARD_LEVEL_MODAL,
  OPEN_CANCEL_SELL_ORDER_MODAL,
  CLOSE_CANCEL_SELL_ORDER_MODAL,
  SHOW_LOCAL_SPINNER,
  HIDE_LOCAL_SPINNER,
  ADD_MESSAGES,
  POPUPS_OPEN_COLLEGE_VIDEO,
  POPUPS_CLOSE_COLLEGE_VIDEO,
  POPUPS_SHOW_DONATION_INFO,
  POPUPS_HIDE_DONATION_INFO,
  TOGGLE_BULK_SELL_LOGIN_ERROR,
  OPEN_ATTEMPTS_EXCEEDED_BOX,
  CLOSE_ATTEMPTS_EXCEEDED_BOX,
  OPEN_PAYMENT_DETAILS_MODAL,
  CLOSE_PAYMENT_DETAILS_MODAL,
  CLOSE_SHARE_A_SALE_MODAL,
  OPEN_DEDUCTION_CARD_IMG_MODAL,
  CLOSE_DEDUCTION_CARD_IMG_MODAL,
  ANYCARD_ORDER_TOTAL_EXCEEDED,
  ORDER_TOTAL_MIN_REQUIRED,
  ORDER_TOTAL_MAX_EXCEEDED,
} from "../constants/actionTypes";

const initialState = Map({
  contactus: false,
  vertificationBox: false,
  errorBox: false,
  errorMsg: "",
  title: "",
  choosePhoneOrText: false,
  enterCode: false,
  changePhone: false,
  showLoading: false,
  passReset: "alert alert-warning",
  showDropDown: false,
  showChoosePhoneOrTextBox: false,
  showEnterCodeBox: false,
  showAccountLockedBox: false,
  showSessionExpireWarning: false,
  showSessionExpired: false,
  displayForgotPassword: false,
  showSellPaymentPopup: false,
  showChooseCheckoutPopup: false,
  showCardsUnderConsideration: false,
  showHowWouldYouLikeToGetPaid: false,
  showCancelSellOrderModal: false,
  showPaymentDetailsModal: false,
  showDeductionCardImgModal: false,
  showLocalSpinner: false,
  showCollegeYoutube: false,
  showDonationInfo: false,
  topMsg: "",
  sellMsg: "",
  buyMsg: "",
  bulkSellLoginError: false,
  attemptsExceededBox: false,
  showShareASaleModal: true,
  anyCardOrderTotalExceeded: false,
  orderTotalMaxExceeded: false,
  orderTotalMinRequired: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;

    case DISPLAY_FORGOT_PASSWORD:
      return state.set("displayForgotPassword", true);

    case HIDE_FORGOT_PASSWORD:
      return state.set("displayForgotPassword", false);

    case RESET_EXPIRE:
      return state.set("showSessionExpired", true);

    case SHOW_SESSION_EXPIRED:
      return state.set("showSessionExpired", true);

    case HIDE_SESSION_EXPIRED:
      return state.set("showSessionExpired", false);

    case SHOW_SESSION_EXPIRE_WARNING:
      return state.set("showSessionExpireWarning", true);

    case HIDE_SESSION_EXPIRE_WARNING:
      return state.set("showSessionExpireWarning", false);

    case TOGGLE_CONTACT_US:
      return state
        .set("contactus", !state.get("contactus"))
        .set("showDropDown", false);

    case TOGGLE_BULK_SELL_LOGIN_ERROR:
      return state.set("bulkSellLoginError", !state.get("bulkSellLoginError"));

    case SHOW_ACCOUNT_LOCKED:
      return state.set("showAccountLockedBox", true);

    case HIDE_ACCOUNT_LOCKED:
      return state.set("showAccountLockedBox", false);

    case SET_VERIFY_ID:
      return state
        .set("showChoosePhoneOrTextBox", false)
        .set("showEnterCodeBox", true);

    case MAKE_ANOTHER_PASSCODE_ATTEMPT:
      return state
        .set("showEnterCodeBox", false)
        .set("showChoosePhoneOrTextBox", true);

    case TOGGLE_VERIFICATION_BOX:
      return state.set("vertificationBox", !state.get("vertificationBox"));

    case OPEN_ERROR_BOX:
      return state
        .set("errorMsg", action.payload.message)
        .set("errorBox", true)
        .set("title", action.payload.title);

    case CLOSE_ERROR_BOX:
      return state.set("errorBox", false);

    case OPEN_ATTEMPTS_EXCEEDED_BOX:
      return state.set("attemptsExceededBox", true);

    case CLOSE_ATTEMPTS_EXCEEDED_BOX:
      return state.set("attemptsExceededBox", false);

    case TOGGLE_CHOOSE_VERIFICATION:
      return state.set("choosePhoneOrText", !state.get("choosePhoneOrText"));

    case ANYCARD_ORDER_TOTAL_EXCEEDED:
      return state.set(
        "anyCardOrderTotalExceeded",
        !state.get("anyCardOrderTotalExceeded")
      );

    case ORDER_TOTAL_MIN_REQUIRED:
      return state.set(
        "orderTotalMinRequired",
        !state.get("orderTotalMinRequired")
      );

    case ORDER_TOTAL_MAX_EXCEEDED:
      return state.set(
        "orderTotalMaxExceeded",
        !state.get("orderTotalMaxExceeded")
      );

    case SHOW_CHANGE_PHONE:
      return state.set("changePhone", true);

    case HIDE_CHANGE_PHONE:
      return state.set("changePhone", false);

    case SHOW_LOADING:
      return state.set("showLoading", true);

    case HIDE_LOADING:
      return state.set("showLoading", false);

    case FADE_PASS_RESET:
      return state.set("passReset", "alert alert-warning animated fadeOut");

    case SHRINK_BOX:
      return state.set("passReset", "alert alert-warning");

    case TOGGLE_DROP_DOWN:
      return state.set("showDropDown", !state.get("showDropDown"));

    case SHOW_TWILIO_POPUP:
      return state.set("showChoosePhoneOrTextBox", true);

    case HIDE_TWILIO_POPUP:
      return state
        .set("showEnterCodeBox", false)
        .set("showChoosePhoneOrTextBox", false);

    case SHOW_SELL_PAYMENT_POPUP:
      return state.set("showSellPaymentPopup", true);

    case HIDE_SELL_PAYMENT_POPUP:
      return state.set("hideSellPaymentPopup", false);

    case SHOW_CHOOSE_CHECKOUT_POPUP:
      return state.set("showChooseCheckoutPopup", true);

    case HIDE_CHOOSE_CHECKOUT_POPUP:
      return state.set("showChooseCheckoutPopup", false);

    case SET_CARDS_UNDER_CONSIDERATION:
      return state.set("showCardsUnderConsideration", true);

    case HIDE_CARDS_UNDER_CONSIDERATION_POPUP:
      return state.set("showCardsUnderConsideration", false);

    case SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID:
      return state.set("showHowWouldYouLikeToGetPaid", true);

    case HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID:
      return state.set("showHowWouldYouLikeToGetPaid", false);

    case OPEN_EDIT_PROFILE_MODAL:
      return state.set("showEditProfileModal", true);

    case CLOSE_EDIT_PROFILE_MODAL:
      return state.set("showEditProfileModal", false);

    case OPEN_SUPPORT_MODAL:
      return state.set("showSupportModal", true);

    case CLOSE_SUPPORT_MODAL:
      return state.set("showSupportModal", false);

    case OPEN_SUPPORT_CARD_LEVEL_MODAL:
      return state.set("showSupportCardLevelModal", true);

    case CLOSE_SUPPORT_CARD_LEVEL_MODAL:
      return state.set("showSupportCardLevelModal", false);

    case OPEN_CANCEL_SELL_ORDER_MODAL:
      return state.set("showCancelSellOrderModal", true);

    case CLOSE_CANCEL_SELL_ORDER_MODAL:
      return state.set("showCancelSellOrderModal", false);

    case OPEN_PAYMENT_DETAILS_MODAL:
      return state.set("showPaymentDetailsModal", true);

    case CLOSE_PAYMENT_DETAILS_MODAL:
      return state.set("showPaymentDetailsModal", false);

    case OPEN_DEDUCTION_CARD_IMG_MODAL:
      return state.set("showDeductionCardImgModal", true);

    case CLOSE_DEDUCTION_CARD_IMG_MODAL:
      return state.set("showDeductionCardImgModal", false);

    case SHOW_LOCAL_SPINNER:
      return state.set("showLocalSpinner", true);

    case HIDE_LOCAL_SPINNER:
      return state.set("showLocalSpinner", false);

    case ADD_MESSAGES:
      return state
        .set("topMsg", action.payload.top)
        .set("buyMsg", action.payload.buy)
        .set("sellMsg", action.payload.sell)
        .set("mobileMsg", action.payload.mobile);

    case POPUPS_OPEN_COLLEGE_VIDEO:
      return state.set("showCollegeYoutube", true);

    case POPUPS_CLOSE_COLLEGE_VIDEO:
      return state.set("showCollegeYoutube", false);

    case POPUPS_SHOW_DONATION_INFO:
      return state.set("showDonationInfo", true);

    case POPUPS_HIDE_DONATION_INFO:
      return state.set("showDonationInfo", false);

    case CLOSE_SHARE_A_SALE_MODAL:
      return state.set("showShareASaleModal", false);

    default:
      return state;
  }
};
