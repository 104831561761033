import React from "react";
import Modal from "react-modal";

export default class EnterPhoneVerifyModal extends React.Component {
  render() {
    const {
      showEnterCodeBox,
      updatePassCode,
      attempts,
      completeVerify,
      orderID,
      verifyID,
      passCode,
      makeAnotherPasscodeAttempt,
      customTwilioMsg = ""
    } = this.props;

    let errorMsg = !!customTwilioMsg.length ? (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="errorMsg">{customTwilioMsg}</div>
      </div>
    ) : (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className={
            attempts === "1" || attempts === "4" || attempts === "7"
              ? "errorMsg animated fadeIn"
              : "hide"
          }
        >
          You typed in the wrong passcode. You have two more chances to verify
          this passcode.{" "}
        </div>
        <div
          className={
            attempts === "2" || attempts === "5" || attempts === "8"
              ? "errorMsg animated fadeIn"
              : "hide"
          }
        >
          You typed in the wrong passcode again.
        </div>
        <div className={attempts === "9" ? "errorMsg animated fadeIn" : "hide"}>
          This is the last time for you to type in your right passcode before
          your account is locked.
        </div>
      </div>
    );

    return (
      <Modal
        className="Modal__Bootstrap modal-dialog"
        isOpen={showEnterCodeBox}
        contentLabel="Enter Phone Verify Code"
      >
        <div className="modal-content" style={{ padding: "30px" }}>
          <div className="modal-header">
            <h4
              className="modal-title partner-specific-color"
              style={{ textAlign: "center", lineHeight: "1.5em" }}
            >
              Please enter your passcode
            </h4>
          </div>
          <div
            className="modal-body"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <input
              onChange={updatePassCode}
              type="text"
              className="form-control m_10_bottom enter-phone-input"
              placeholder="Pass Code"
              tabIndex="3"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <button
              className="btn btn-primary mar-b-10"
              onClick={() => completeVerify(orderID, verifyID, passCode, this)}
            >
              {" "}
              Complete Verification{" "}
            </button>{" "}
            <button
              className="btn btn-primary m_10_bottom"
              onClick={() => {
                makeAnotherPasscodeAttempt();
              }}
            >
              {" "}
              REQUEST A NEW IDENTIFICATION CODE{" "}
            </button>
          </div>
          {errorMsg}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "black",
              marginTop: "20px",
              fontSize: "12px"
            }}
          >
            Haven't received your Identification Code? We text identification
            codes immediately, but too many factors influence how fast you
            receive them. You have the option of requesting a new identification
            code by clicking "Request a new identification code" if you are
            having trouble receiving the original.{" "}
          </div>
        </div>
      </Modal>
    );
  }
}
