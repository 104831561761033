import React from "react";
import { currencyFormatter } from "../../utils/display";

export default ({
  config,
  totalPrice,
  showDonationInfo,
  moveToStepTwo,
  cards,
  preventGoToNextStep
}) => (
  <div className="row text-right-center offer-wrapper">
    <div className="col-sm-1" />
    <div className="card-offer col-sm-8">
      {`${config.totalText} ${currencyFormatter(totalPrice)}`}
      <br />

      {config.offerAndContinue_displayLessThanCard && (
        <span
          style={{ fontWeight: "normal" }}
          className="lnk-harvey"
          onClick={showDonationInfo}
        >
          Why is this less than my <span className="hidden-sm-down">gift</span>{" "}
          card balance?
        </span>
      )}
    </div>
    <div className="col-sm-3">
      <input
        id="continue"
        className="btn btn-primary next-step activebtn"
        onClick={moveToStepTwo}
        value={config.offerAndContinue_continueButtonText}
        type="button"
        tabIndex="4"
        disabled={!Object.keys(cards).length || preventGoToNextStep}
      />
    </div>
  </div>
);
