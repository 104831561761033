const VALIDATE_FIELDS = [
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "utm_term",
  "utm_content",
  "gclid",
];
export default (query) => {
  let values = [];

  if (query) {
    values = query
      .slice(1)
      .split("&")
      .filter((data) => VALIDATE_FIELDS.includes(data.split("=")[0]))
      .map((data) => {
        const val = data.split("=");
        return { id: val[0], value: val[1] };
      });
  }

  return values;
};
