import React from "react";
import Address from "./address";
import CreditCardForm from "./creditCardForm";

export default class VerificationInformationContainer extends React.Component {
  isChecked = () => {
    if (this.props.sameAsShipping) {
      this.props.useUniqueBillingAddress();
    } else {
      this.props.matchSameAsShipping({
        firstName: this.props.sellShipping.get("firstName"),
        lastName: this.props.sellShipping.get("lastName"),
        street: this.props.sellShipping.get("street"),
        street2: this.props.sellShipping.get("street2"),
        city: this.props.sellShipping.get("city"),
        zip: this.props.sellShipping.get("zip"),
        state: this.props.sellShipping.get("state"),
      });
    }
  };

  render() {
    const {
      customerInfo,
      hideCreditCardNumError,
      setCreditCard,
      hideMonthError,
      selectMonth,
      hideYearError,
      selectYear,
      hideCCVError,
      setCCV,
      cartSell,
      sameAsShipping,
      config,
      toggleVerificationBox,
      setCreditCardNumError,
      setMonthError,
      setYearError,
      setCCVError,
      store,
    } = this.props;

    return (
      <div
        className="panel panel-default col-md-6 payment_left container-bg full-width animated fadeInUp"
        style={{ paddingBottom: "5px" }}
      >
        <div className="panel-body container-bg" style={{ padding: "0px 8px" }}>
          <div>
            <div className="pay-panel-headers">
              {config.verificationInformation_header}
            </div>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: config.verificationInformation_subHeader(config),
                }}
              />
              {config.whyDoWeNeedThis && (
                <span
                  className="partner-anchor1 lnk"
                  onClick={toggleVerificationBox}
                >
                  Why do we need this?
                </span>
              )}
            </p>
          </div>
          {config.paymentType !== "donation" ? (
            <CreditCardForm
              customerInfo={customerInfo}
              hideCreditCardNumError={hideCreditCardNumError}
              setCreditCard={setCreditCard}
              hideMonthError={hideMonthError}
              selectMonth={selectMonth}
              hideYearError={hideYearError}
              selectYear={selectYear}
              hideCCVError={hideCCVError}
              setCCV={setCCV}
              config={config}
              setCreditCardNumError={setCreditCardNumError}
              setMonthError={setMonthError}
              setYearError={setYearError}
              setCCVError={setCCVError}
            />
          ) : (
            ""
          )}

          {store.payoutMethod === "MAILCHECK" ? (
            <div className="col-md-12 mar-b-10" style={{ fontSize: "12px" }}>
              <input
                tabIndex="16"
                onChange={(e) => {
                  this.isChecked(e);
                }}
                checked={this.props.sameAsShipping}
                type="checkbox"
              />{" "}
              Use same address as shipping?
            </div>
          ) : (
            ""
          )}
          {!sameAsShipping ? (
            <Address section="verification" {...this.props} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
