import {
  CARD_PARTIAL_MERCHANT,
  CARD_PARTIAL_CLEAR,
  CARD_PARTIAL_BALANCE,
  CARD_PARTIAL_PIN,
  CARD_PARTIAL_CARD_NUMBER,
  CARD_PARTIAL_REF,
  CARD_PARTIAL_CHANGE_SUBMIT_TYPE,
  DISPLAY_CARD_ERROR_PARTIAL,
  DISPLAY_MAX_ERROR_PARTIAL,
  DISPLAY_MIN_ERROR_PARTIAL,
  DISPLAY_MERCHANT_ERROR_PARTIAL,
  DISPLAY_NO_ERROR_PARTIAL,
  DISPLAY_CARD_BALANCE_FORMAT_ERROR,
  COMPLETE_ORDER_SELL,
  RESET,
  RESET_EXPIRE,
  RESET_SELL,
  CARD_PARTIAL_MAGSTRIPE,
  CARD_PARTIAL_CHANGE_SUBMISSION_METHOD,
  CARD_PARTIAL_RECEIVE_MAGSTRIPE,
  CARD_PARTIAL_UPDATE_MSG_STATE,
  CARD_PARTIAL_CARD_MSG,
  CARD_PARTIAL_PIN_MSG,
  CARD_PARTIAL_MERCH_MSG,
  CARD_PARTIAL_BALANCE_MSG,
  DELETE_BULK_SPREADSHEET_ERRORS,
  USER_INPUT_MERCHANT,
  CLEAR_USER_INPUT_MERCHANT,
  UPDATE_AUTOCOMPLETE_TEXT,
  CLEAR_AUTOCOMPLETE_TEXT,
} from "../constants/actionTypes";

const initialState = {
  balance: "",
  selectedMerchant: null,
  cardErrors: [],
  displayBalanceError: false,
  displayMaxError: false,
  displayMinError: false,
  displayMerchantError: false,
  autocompleteText: "",
  refId: "",
  pin: "",
  number: "",
  submitType: "manual",
  magstripe: "",
  subMethod: "",
  magstripeMsgState: "HIDE",
  bulkErrors: {
    pinErr: "",
    numErr: "",
    merchErr: "",
    balanceErr: "",
  },
  userInputMerchant: null,
  bulkSpreadSheetErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CARD_PARTIAL_CHANGE_SUBMISSION_METHOD:
      return {
        ...state,
        subMethod: action.payload,
      };

    case CARD_PARTIAL_RECEIVE_MAGSTRIPE:
      return {
        ...state,
        ...action.payload,
      };

    case CARD_PARTIAL_UPDATE_MSG_STATE:
      return {
        ...state,
        magstripeMsgState: action.payload,
      };

    case CARD_PARTIAL_MAGSTRIPE:
      return {
        ...state,
        magstripe: action.payload,
      };

    case CARD_PARTIAL_CHANGE_SUBMIT_TYPE:
      return {
        ...state,
        submitType: action.payload,
      };

    case CARD_PARTIAL_PIN:
      return {
        ...state,
        pin: action.payload,
        pinError: "",
      };

    case CARD_PARTIAL_CARD_NUMBER:
      return {
        ...state,
        number: action.payload,
        numError: "",
      };

    case CARD_PARTIAL_REF:
      return {
        ...state,
        refId: action.payload,
      };

    case CARD_PARTIAL_MERCHANT:
      return {
        ...state,
        selectedMerchant: action.payload,
        displayMerchantError: false,
        bulkErrors: {
          ...state.bulkErrors,
          merchErr: "",
        },
      };

    case USER_INPUT_MERCHANT:
      return {
        ...state,
        userInputMerchant: action.payload,
      };

    case CLEAR_USER_INPUT_MERCHANT:
      return {
        ...state,
        userInputMerchant: null,
      };

    case CARD_PARTIAL_BALANCE:
      return {
        ...state,
        balance: action.payload,
        displayMinError: false,
        displayMaxError: false,
        displayBalanceError: false,
      };

    case DISPLAY_CARD_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayMinError: false,
        displayMaxError: false,
        displayBalanceError: true,
      };

    case DISPLAY_MAX_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayBalanceError: false,
        displayMinError: false,
        displayMaxError: true,
      };

    case DISPLAY_MIN_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayBalanceError: false,
        displayMaxError: false,
        displayMinError: true,
      };

    case DISPLAY_NO_ERROR_PARTIAL:
      return {
        ...state,
        displayCardBalanceFormatError: false,
        displayBalanceError: false,
        displayMaxError: false,
        displayMinError: false,
        displayMerchantError: false,
      };

    case DISPLAY_MERCHANT_ERROR_PARTIAL:
      return {
        ...state,
        displayMerchantError: true,
      };

    case DISPLAY_CARD_BALANCE_FORMAT_ERROR:
      return {
        ...state,
        displayCardBalanceFormatError: true,
        displayBalanceError: false,
        displayMinError: false,
        displayMaxError: false,
      };

    case CARD_PARTIAL_CARD_MSG:
      return {
        ...state,
        bulkErrors: {
          ...state.bulkErrors,
          numErr: action.payload,
        },
      };

    case CARD_PARTIAL_PIN_MSG:
      return {
        ...state,
        bulkErrors: {
          ...state.bulkErrors,
          pinErr: action.payload,
        },
      };

    case CARD_PARTIAL_MERCH_MSG:
      return {
        ...state,
        bulkErrors: {
          ...state.bulkErrors,
          pinErr: "",
          numErr: "",
          merchErr: action.payload,
          balanceErr: "",
        },
      };

    case CARD_PARTIAL_BALANCE_MSG:
      return {
        ...state,
        bulkErrors: {
          ...state.bulkErrors,
          pinErr: "",
          numErr: "",
          merchErr: "",
          balanceErr: action.payload,
        },
      };

    case DELETE_BULK_SPREADSHEET_ERRORS:
      return {
        ...state,
        bulkSpreadSheetErrors: [],
      };

    case UPDATE_AUTOCOMPLETE_TEXT:
      return {
        ...state,
        autocompleteText: action.payload,
      };

    case CLEAR_AUTOCOMPLETE_TEXT:
      return {
        ...state,
        autocompleteText: "",
      };

    case COMPLETE_ORDER_SELL:
    case CARD_PARTIAL_CLEAR:
    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
      return initialState;

    default:
      return state;
  }
};
