export const checkBalance = ({
  showCardBalanceError,
  cardPartial,
  showMaxCardError,
  showMinCardError,
  showNoCardPartialError,
  showAddCardBox,
  userActions,
  config
}) => {
  const selectedMerchant = cardPartial.selectedMerchant;
  const cardBalance = Number(cardPartial.balance);

  if (cardBalance === null) {
    showCardBalanceError();
    return;
  } else if (
    selectedMerchant &&
    selectedMerchant.limit &&
    selectedMerchant.limit.max < cardBalance
  ) {
    showMinCardError();
    return;
  } else if (
    selectedMerchant &&
    selectedMerchant.limit &&
    selectedMerchant.limit.min > cardBalance
  ) {
    showMaxCardError();
    return;
  } else {
    showNoCardPartialError();

    if (
      config.haveAnotherCardShowPopup &&
      !userActions.get("keepAddCardBoxClosed")
    ) {
      showAddCardBox();
    }

    return true;
  }
};

export const changeButtonVal = (
  {
    cartSell,
    cardPartial,
    showCardBalanceFormatError,
    showMerchantError,
    sendCard,
    cardPartialClear,
    cards,
    createSellCart
  },
  checkBalance
) => {
  let currentCardPartialBalance = cardPartial.balance;

  if (cardPartial.selectedMerchant === null) {
    return showMerchantError();
  } else {
    if (!cardPartial.balance) {
      return showCardBalanceFormatError();
    } else if (cardPartial.balance.includes(",")) {
      currentCardPartialBalance = Number(
        cardPartial.balance.split(",").join("")
      );
    } else {
      currentCardPartialBalance = Number(cardPartial.balance);
    }
  }

  if (checkBalance()) {
    if (
      cardPartial.displayMinError ||
      cardPartial.displayMaxError ||
      cardPartial.showMerchantError ||
      cardPartial.showCardBalanceError ||
      cardPartial.showCardBalanceFormatError
    ) {
      cardPartialClear();
      return;
    }

    if (!cartSell.cartId) {
      createSellCart(
        currentCardPartialBalance,
        cardPartial.selectedMerchant,
        cards
      );
    } else {
      sendCard(
        currentCardPartialBalance,
        cardPartial.selectedMerchant,
        cartSell.cartId,
        cards
      );
    }
    cardPartialClear();
  }
};
