import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Loading from "./loading";

const DelayedRedirect = ({ to }) => {
  let currentTimeOut;
  const [localTimeout, setLocalTimeout] = useState(false);
  const loadTrigger = () => {
    if (localTimeout) clearTimeout(currentTimeOut);
    currentTimeOut = setTimeout(() => {
      setLocalTimeout(true);
    }, 1000);
  };

  useEffect(() => {
    loadTrigger();

    return () => {
      if (localTimeout) clearTimeout(currentTimeOut);
    };
  }, []);

  return localTimeout ? <Redirect to={to} /> : <Loading />;
};

DelayedRedirect.propTypes = {
  to: PropTypes.string.isRequired
};

export default DelayedRedirect;
