import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import {
  ZIPREGEX,
  CITYREGEX,
  STREETREGEX,
  NAMEREGEX,
} from "../../constants/regex";
import { ALL_STATES } from "../../constants/payfields";

const Container = styled.div`
  margin-top: ${({ version }) => (version === 3 ? `1rem` : `.2rem`)};
  margin-bottom: ${({ version }) => (version === 3 ? `3rem` : `.2rem`)};
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Firstname = styled.div`
  flex: 1;
  margin-right: ${({ version }) => (version === 3 ? `1rem` : `.2rem`)};
`;

const Lastname = styled.div`
  flex: 1;
  margin-left: ${({ version }) => (version === 3 ? `1rem` : `.2rem`)};
`;

const Street1 = styled.div`
  flex: ${({ version }) => (version === 3 ? `1.5` : `1`)};
  margin-right: ${({ version }) => (version === 3 ? `1rem` : `0px`)};
`;

const Street2 = styled.div`
  flex: 1;
  margin-left: ${({ version }) => (version === 3 ? `1rem` : `0px`)};
`;

const City = styled.div`
  flex: 0.5;
  margin-right: ${({ version }) => (version === 3 ? `1rem` : `.2rem`)};
`;

const State = styled.div`
  flex: 0.5;
  margin-left: ${({ version }) => (version === 3 ? `1rem` : `.2rem`)};
`;

const Zip = styled.div`
  flex: 0.5;
  margin-left: ${({ version }) => (version === 3 ? `1rem` : `.2rem`)};
`;

const AddressVersion2 = ({
  config,
  hideFirstNameError,
  setFirstName,
  hideLastNameError,
  setLastName,
  hideStreetError,
  setStreet,
  setStreetTwo,
  hideStreet2Error,
  hideCityError,
  setCity,
  hideStateError,
  setNewState,
  hideZipError,
  setZip,
  firstNameError,
  firstName,
  editNames,
  lastName,
  lastNameError,
  streetError,
  street,
  street2,
  street2Error,
  city,
  cityError,
  state,
  stateError,
  zipError,
  zip,
  displayFirstNameError,
  displayLastNameError,
  displayStreetError,
  displayStreet2Error,
  displayZipError,
  displayCityError,
  displayStateError,
}) => {
  const checkZip = (val) => {
    return ZIPREGEX.test(val);
  };

  const checkState = (val) => {
    return ALL_STATES.includes(val.toUpperCase());
  };

  const checkCity = (val) => {
    return CITYREGEX.test(val);
  };

  const checkStreet = (val) => {
    return STREETREGEX.test(val);
  };

  const checkName = (val) => {
    return NAMEREGEX.test(val);
  };

  return (
    <Container version={config.layoutVersion}>
      <ContainerRow>
        <Firstname version={config.layoutVersion}>
          <input
            type="text"
            defaultValue={firstName}
            onChange={(e) => {
              if (e.target.value && checkName(e.target.value)) {
                hideFirstNameError();
                setFirstName(e.target.value);
              } else {
                displayFirstNameError();
              }
            }}
            className="form-control"
            placeholder={config.addressFirstNamePlaceHolder}
            disabled={editNames ? false : true}
          />
          <p className={firstNameError ? " errorMsg" : "hide"}>
            Not a valid first name
          </p>
        </Firstname>
        <Lastname version={config.layoutVersion}>
          <input
            type="text"
            defaultValue={lastName}
            onChange={(e) => {
              if (e.target.value && checkName(e.target.value)) {
                hideLastNameError();
                setLastName(e.target.value);
              } else {
                displayLastNameError();
              }
            }}
            className="form-control"
            placeholder={config.addressLastNamePlaceHolder}
            disabled={editNames ? false : true}
          />
          <p className={lastNameError ? " errorMsg" : "hide"}>
            Not a valid last name
          </p>
        </Lastname>
      </ContainerRow>

      {config.layoutVersion !== 2 && (
        <>
          <ContainerRow>
            <Street1>
              <input
                type="text"
                defaultValue={street}
                onChange={(e) => {
                  if (e.target.value && checkStreet(e.target.value)) {
                    hideStreetError();
                    setStreet(e.target.value);
                  } else {
                    displayStreetError();
                  }
                }}
                className="form-control"
                placeholder={config.addressStreetPlaceHolder}
              />
              <p className={streetError ? " errorMsg" : "hide"}>
                Not a valid street address
              </p>
            </Street1>
            <Street2>
              <input
                type="text"
                defaultValue={street2}
                onChange={(e) => {
                  if (e.target.value) {
                    if (checkStreet(e.target.value)) {
                      hideStreet2Error();
                      setStreetTwo(e.target.value);
                    } else {
                      displayStreet2Error();
                    }
                  } else {
                    setStreetTwo(e.target.value);
                  }
                }}
                className="form-control"
                placeholder={config.addressStreet2PlaceHolder}
              />
              <p className={street2Error ? " errorMsg" : "hide"}>
                Not a valid street address continued
              </p>
            </Street2>
          </ContainerRow>
        </>
      )}
      {config.layoutVersion === 2 && (
        <>
          <ContainerColumn>
            <Street1>
              <input
                type="text"
                defaultValue={street}
                onChange={(e) => {
                  if (e.target.value && checkStreet(e.target.value)) {
                    hideStreetError();
                    setStreet(e.target.value);
                  } else {
                    displayStreetError();
                  }
                }}
                className="form-control"
                placeholder={config.addressStreetPlaceHolder}
              />
              <div className={streetError ? " errorMsg" : "hide"}>
                Not a valid street address
              </div>
            </Street1>
            <Street2>
              <input
                type="text"
                defaultValue={street2}
                onChange={(e) => {
                  if (e.target.value && checkStreet(e.target.value)) {
                    hideStreet2Error();
                    setStreetTwo(e.target.value);
                  } else {
                    displayStreet2Error();
                  }
                }}
                className="form-control"
                placeholder={config.addressStreet2PlaceHolder}
              />
              <div className={street2Error ? " errorMsg" : "hide"}>
                Not a valid street address continued
              </div>
            </Street2>
          </ContainerColumn>
        </>
      )}

      <ContainerRow>
        <City version={config.layoutVersion}>
          <input
            type="text"
            defaultValue={city}
            onChange={(e) => {
              if (e.target.value && checkCity(e.target.value)) {
                hideCityError();
                setCity(e.target.value);
              } else {
                displayCityError();
              }
            }}
            className="form-control"
            placeholder={config.addressCityPlaceHolder}
          />
          <p className={cityError ? " errorMsg" : "hide"}>Not a valid city</p>
        </City>

        <State version={config.layoutVersion}>
          <input
            type="text"
            defaultValue={state}
            onChange={(e) => {
              if (e.target.value && checkState(e.target.value)) {
                hideStateError();
                setNewState(e.target.value);
              } else {
                displayStateError();
              }
            }}
            className="form-control"
            placeholder={config.addressStatePlaceHolder}
            maxLength={2}
          />
          <p className={stateError ? " errorMsg" : "hide"}>Not a valid state</p>
        </State>
        <Zip version={config.layoutVersion}>
          <input
            type="text"
            defaultValue={zip}
            onChange={(e) => {
              if (e.target.value && checkZip(e.target.value)) {
                hideZipError();
                setZip(e.target.value);
              } else {
                displayZipError();
              }
            }}
            className="form-control"
            placeholder={config.addressZipPlaceHolder}
          />
          <p className={zipError ? " errorMsg" : "hide"}>
            Not a valid zip code
          </p>
        </Zip>
      </ContainerRow>
    </Container>
  );
};

AddressVersion2.propTypes = {
  config: PropTypes.object.isRequired,
  hideFirstNameError: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  hideLastNameError: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  hideStreetError: PropTypes.func.isRequired,
  setStreet: PropTypes.func.isRequired,
  setStreetTwo: PropTypes.func.isRequired,
  hideStreet2Error: PropTypes.func.isRequired,
  hideCityError: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  hideStateError: PropTypes.func.isRequired,
  setNewState: PropTypes.func.isRequired,
  hideZipError: PropTypes.func.isRequired,
  setZip: PropTypes.func.isRequired,
  firstNameError: PropTypes.bool.isRequired,
  lastNameError: PropTypes.bool.isRequired,
  streetError: PropTypes.bool.isRequired,
  street2Error: PropTypes.bool.isRequired,
  cityError: PropTypes.bool.isRequired,
  stateError: PropTypes.bool.isRequired,
  zipError: PropTypes.bool.isRequired,
  editNames: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  street2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  displayFirstNameError: PropTypes.func.isRequired,
  displayLastNameError: PropTypes.func.isRequired,
  displayStreetError: PropTypes.func.isRequired,
  displayStreet2Error: PropTypes.func.isRequired,
  displayZipError: PropTypes.func.isRequired,
  displayCityError: PropTypes.func.isRequired,
  displayStateError: PropTypes.func.isRequired,
};

export default AddressVersion2;
