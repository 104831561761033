import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import TloVerificationInit from "../../../components/stepThree/tloVerificationInit";
import TloVerificationError from "../../../components/stepThree/tloVerificationError";
import TloVerificationExpired from "../../../components/stepThree/tloVerificationExpired";

import {
  SELECT_TLO_PHONE_NUMBER,
  SET_TLO_VERIFICATION_TYPE,
  SHOW_TLO_ERROR_PHONE_MODAL,
  SHOW_TLO_VERIFICATION_INIT,
  HIDE_TLO_ERROR_PHONE_MODAL,
} from "../../../constants/actionTypes";

import { defaultAction } from "../../../actions/defaultAction";
import { sendTLOVerification } from "../../../thunks/customerInfo";

import { isOrderIdEmptySelector, tloSelector } from "../../../selectors";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, tlo, isOrderIdEmpty } = useSelector(
    (state) => ({
      config: state.config,
      tlo: tloSelector(state),
      isOrderIdEmpty: isOrderIdEmptySelector(state),
    }),
    shallowEqual
  );

  if (tlo.get("iShowingTLOVerificationExpired")) {
    return (
      <Guard condition={isOrderIdEmpty} redirect={"/"}>
        <ActionContainer title={config.identityTloErrorTitle}>
          <TloVerificationExpired tloExpiredTxt={config.tloExpiredTxt} />
        </ActionContainer>
      </Guard>
    );
  }

  if (tlo.get("isShowingTLOVerificationError")) {
    return (
      <Guard condition={isOrderIdEmpty} redirect={"/"}>
        <ActionContainer title={config.identityTloErrorTitle}>
          <TloVerificationError
            version={config.layoutVersion}
            tloErrorTxt={config.tloErrorTxt}
            showTloInit={() => {
              dispatch(defaultAction(HIDE_TLO_ERROR_PHONE_MODAL));
              dispatch(defaultAction(SHOW_TLO_VERIFICATION_INIT));
            }}
          />
        </ActionContainer>
      </Guard>
    );
  }

  if (tlo.get("isShowingTLOVerificationInit")) {
    return (
      <Guard condition={isOrderIdEmpty} redirect={"/"}>
        <ActionContainer title={config.identityTloTitle}>
          <TloVerificationInit
            config={config}
            phoneNumbers={tlo.get("phoneNumbers")}
            selectedPhoneNumberId={tlo.get("selectedPhoneNumberId")}
            selectPhoneNumber={(id) =>
              dispatch(defaultAction(SELECT_TLO_PHONE_NUMBER, id))
            }
            showTloError={() =>
              dispatch(defaultAction(SHOW_TLO_ERROR_PHONE_MODAL))
            }
            onSend={(validationType) => {
              dispatch(
                defaultAction(SET_TLO_VERIFICATION_TYPE, validationType)
              );
              dispatch(sendTLOVerification());
              history.push("/identity-pass-code");
            }}
          />
        </ActionContainer>
      </Guard>
    );
  }

  return (
    <Guard condition={isOrderIdEmpty} redirect={"/"}>
      <ActionContainer title={config.identityTloTitle}></ActionContainer>
    </Guard>
  );
};
