import {
  UPDATE_THE_CARD_ERROR,
  UPDATE_THE_CARD_PIN_ERROR,
  DELETE_CARD,
  OPEN_ERROR_BOX,
  ADD_CARD,
  ADD_CARD_TO_CART,
  UPDATE_INVALID_CARD_MSG,
  DISPLAY_MATCH,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  SET_UPDATE_CARD_SERVER_ERROR,
  SET_ERROR,
} from "../constants/actionTypes";

import { getSellCards, reorderCards } from "../utils/validators/card";
import { doEvent } from "./events";

export const deleteCardInCart = (cartSellID, cardID) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    const deleteCardInCart = await remote(
      "delete",
      `/v3/carts/${getState().cartSell.cartId}/cards/${cardID}`,
      {},
      "deleting a card from the cart",
      dispatch
    );

    if (deleteCardInCart && deleteCardInCart.hasOwnProperty("message")) {
      throw deleteCardInCart;
    }

    dispatch({ type: DELETE_CARD, payload: cardID });
  } catch (e) {
    if (getState().config.layoutVersion === 1) {
      dispatch({
        type: OPEN_ERROR_BOX,
        payload: {
          title: "Sorry, we are experiencing technical difficulties",
          message:
            "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
        },
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
      });
    }
  }
};

export const updateStepOneFields = (
  cartSellID,
  cardID,
  merchantId,
  amount,
  cards
) => async (dispatch, getState, { history, remote }) => {
  try {
    const cartSell = await remote(
      "put",
      `/v3/carts/${cartSellID}/cards/${cardID}`,
      {
        card: {
          merchantId: Number(merchantId),
          enterValue: Number(amount),
        },
      },
      "deleting a card from the cart",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }

    const [currentCards, cardIds, cartID] = getSellCards(cartSell, cards);
    const correctlyOrderedCardsInCart =
      currentCards.length > 1
        ? reorderCards(cards, currentCards, cardIds)
        : cardIds;

    dispatch({ type: ADD_CARD, payload: currentCards });
    dispatch({ type: ADD_CARD_TO_CART, payload: correctlyOrderedCardsInCart });
  } catch (error) {
    if (getState().config.layoutVersion === 1) {
      dispatch({
        type: OPEN_ERROR_BOX,
        payload: {
          title: "Problem updating card",
          message: (error.message && error.message[0]) || "",
        },
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload: `Problem updating card, ${(error.message &&
          error.message[0]) ||
          ""}`,
      });
    }
  }
};

export const sendCardNumToServer = (
  cartSellID,
  cardID,
  number,
  cards
) => async (dispatch, getState, { history, remote }) => {
  try {
    const cartSell = await remote(
      "put",
      `/v3/carts/${getState().cartSell.cartId}/cards/${cardID}`,
      { card: { number } },
      "updating the card number",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }
    const [currentCards, cardIds, cartID] = getSellCards(
      cartSell,
      getState().state.cards
    );
    const correctlyOrderedCardsInCart =
      currentCards.length > 1
        ? reorderCards(getState().state.cards, currentCards, cardIds)
        : cardIds;

    dispatch({ type: UPDATE_THE_CARD_ERROR, payload: { cardID } });
    dispatch({ type: ADD_CARD_TO_CART, payload: correctlyOrderedCardsInCart });
  } catch (error) {
    dispatch({
      type: UPDATE_INVALID_CARD_MSG,
      payload: {
        cardID,
        msg: (error.message && error.message[0]) || "",
      },
    });
  }
};

export const sendCardAndPinToServer = ({
  cartSellID,
  cardID,
  number,
  pin,
  cards,
}) => async (dispatch, getState, { history, remote }) => {
  try {
    const cartSell = await remote(
      "put",
      `/v3/carts/${getState().cartSell.cartId}/cards/${cardID}`,
      { card: { number, pin } },
      "updating the card number and pin",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }
    const [currentCards, cardIds, cartID] = getSellCards(
      cartSell,
      getState().cards
    );
    const correctlyOrderedCardsInCart =
      currentCards.length > 1
        ? reorderCards(getState().cards, currentCards, cardIds)
        : cardIds;

    dispatch({ type: UPDATE_THE_CARD_ERROR, payload: { cardID } });
    dispatch({ type: UPDATE_THE_CARD_PIN_ERROR, payload: { cardID } });
    dispatch({ type: ADD_CARD_TO_CART, payload: correctlyOrderedCardsInCart });
    dispatch({ type: DISPLAY_MATCH, payload: { cardID } });
    dispatch({ type: CLEAR_UPDATE_CARD_SERVER_ERROR, payload: { cardID } });
    doEvent("CART_FORM_SUBMIT");
  } catch (error) {
    dispatch({
      type: SET_UPDATE_CARD_SERVER_ERROR,
      payload: {
        cardID,
        msg: (error.message && error.message[0]) || "",
      },
    });
  }
};

export const sendPinToServer = (cartSellID, cardID, pin, cards) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    const cartSell = await remote(
      "put",
      `/v3/carts/${getState().cartSell.cartId}/cards/${cardID}`,
      { card: { pin } },
      "updating the pin",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }
    const [currentCards, cardIds, cartID] = getSellCards(
      cartSell,
      getState().state.cards
    );
    const correctlyOrderedCardsInCart =
      currentCards.length > 1
        ? reorderCards(getState().state.cards, currentCards, cardIds)
        : cardIds;

    dispatch({ type: UPDATE_THE_CARD_PIN_ERROR, payload: { cardID } });
    dispatch({ type: ADD_CARD_TO_CART, payload: correctlyOrderedCardsInCart });
  } catch (error) {
    if (error && error.message && error.message[0]) {
      dispatch(updateCardMatch(cardID, error.message[0]));
    } else {
      if (getState().config.layoutVersion === 1) {
        dispatch({
          type: OPEN_ERROR_BOX,
          payload: {
            title: "Sorry, we are experiencing technical difficulties",
            message:
              "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
          },
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload:
            "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
        });
      }
    }
  }
};
