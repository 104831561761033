import React from "react";
import Modal from "react-modal";

export default ({
  changePhone,
  hideChangePhone,
  showChangePhone,
  phoneNumber,
  _phoneErrorModal,
  orderID,
  showChoosePhoneOrTextBox,
  startVerify,
  changePhoneTxt,
  updateThePhone,
  hideChangePhoneError,
  setChangePhoneTxt
}) => {
  const setPhoneNumber = newPhoneNumber => {
    if (newPhoneNumber.length < 1) {
      setChangePhoneTxt("");
      return;
    }
    const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    let newNumber = newPhoneNumber.match(/[0-9]+/g).join("");
    let checkIfValidPhone = phoneRegex.test(Number(newNumber));
    if (checkIfValidPhone) {
      hideChangePhoneError();
    }
    setChangePhoneTxt(newPhoneNumber);
  };

  const updatePhone = () => {
    updateThePhone(orderID, changePhoneTxt);
  };

  return (
    <Modal
      className="Modal__Bootstrap modal-dialog modal-lg"
      isOpen={showChoosePhoneOrTextBox}
      contentLabel="Choose Verification"
    >
      <div className="modal-content" style={{ padding: "20px" }}>
        <div className="modal-header">
          <h4
            className={
              changePhone
                ? "hide"
                : "modal-title text-center partner-specific-color"
            }
          >
            Wait... just one <br className="hidden-sm-up" /> more thing.{" "}
          </h4>
          <h4
            className={
              changePhone
                ? "modal-title text-center partner-specific-color animated fadeIn"
                : "hide"
            }
          >
            Enter a New <br className="hidden-sm-up" /> Phone Number{" "}
          </h4>
        </div>
        <div className="modal-body text-center fnt14">
          <div className={changePhone ? "hide" : "m_10_bottom"}>
            Before starting to process your order, we just need to verify the
            phone number you entered.
          </div>
          <div className="center-flex">
            <div
              className={changePhone ? "hide" : "partner-specific-color bld"}
            >
              {phoneNumber}
            </div>
            <input
              className={changePhone ? "form-control animated fadeIn" : "hide"}
              type="text"
              placeholder="Phone"
              onKeyPress={evt => {
                if (evt.which < 48 || evt.which > 57) {
                  evt.preventDefault();
                }
              }}
              onChange={e => setPhoneNumber(e.target.value)}
              style={{ width: "50%" }}
            />
          </div>
          <div
            className={
              changePhone && _phoneErrorModal
                ? "errorMsg m_10_bottom animated fadeIn"
                : "hide"
            }
          >
            Invalid Phone Number
          </div>
          <div
            className={changePhone ? "hide" : "bld lnk"}
            onClick={showChangePhone}
          >
            Change?
          </div>
          <div>
            {" "}
            <input
              style={{ marginTop: "10px" }}
              className={changePhone ? "btn btn-primary" : "hide"}
              type="button"
              value="Confirm"
              onClick={updatePhone}
            />{" "}
          </div>
          <div
            className={changePhone ? "hide" : "text-center"}
            style={{ lineHeight: "30px" }}
          >
            <p style={{ lineHeight: "1.5em" }}>
              How would you like to receive the passcode?
            </p>
            <div className="mar-t-10">
              <input
                className="btn btn-primary"
                type="button"
                value="Text Message"
                onClick={() => startVerify(orderID, "sms")}
              />{" "}
              <br className="hidden-sm-up" /> OR <br className="hidden-sm-up" />{" "}
              <input
                className="btn btn-primary"
                type="button"
                value="Voice Call"
                onClick={() => startVerify(orderID, "call")}
              />
            </div>
          </div>
          <p
            className="lnk"
            onClick={hideChangePhone}
            style={changePhone ? { lineHeight: "3em" } : { display: "none" }}
          >
            Cancel
          </p>
        </div>
      </div>
    </Modal>
  );
};
