import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import ActionContainer from "../containers/actionContainer";
import Guard from "../containers/guard";
import InputCard from "../../../components/stepOne/inputCard";
import CardInCart from "../../../components/stepOne/cardInCartV2";
import CCA from "../containers/a";
import { defaultAction } from "../../../actions/defaultAction";

import {
  validBalance,
  addCard,
} from "../../../utils/validators/stepOneSell_v2";

import { deleteCardInCart } from "../../../thunks/cards";

import {
  cartIsEmptySelector,
  cartSelector,
  cardsSelector,
  cardPartialSelector,
  userActionsSelector,
  merchantSelector,
} from "../../../selectors";

import { CARD_PARTIAL_CLEAR } from "../../../constants/actionTypes";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEnterForm, setShowEnterForm] = useState(false);
  const {
    config,
    cartSell,
    cards,
    cardPartial,
    cartIsEmpty,
    merchantsSell,
  } = useSelector(
    (state) => ({
      config: state.config,
      cartSell: cartSelector(state),
      cards: cardsSelector(state),
      cardPartial: cardPartialSelector(state),
      userActions: userActionsSelector(state),
      cartIsEmpty: cartIsEmptySelector(state),
      merchantsSell: merchantSelector(state),
    }),
    shallowEqual
  );

  const reset = () => {
    dispatch(defaultAction(CARD_PARTIAL_CLEAR));
    setShowEnterForm(false);
  };

  const save = () => {
    setIsSubmitting(true);
    addCard(cartSell, cardPartial, cards, dispatch, () =>
      validBalance(cardPartial, dispatch)
    );

    if (
      !cardPartial.displayMinError &&
      !cardPartial.displayMaxError &&
      !cardPartial.displayBalanceError &&
      !cardPartial.displayMerchantError
    ) {
      reset();
    }
  };

  const saveBtnDisabled =
    isSubmitting ||
    (cardPartial.balance === "" && cardPartial.selectedMerchant === null);

  const saveBtnClasses = !saveBtnDisabled
    ? "btn btn-primary activebtn soundsGoodSaveBtn"
    : "btn btn-primary soundsGoodSaveBtn";

  return (
    <Guard condition={cartIsEmpty} redirect={"/"}>
      <ActionContainer title={config.soundGoodTitle} scrollable={true}>
        <React.Fragment>
          {cartSell.cardIds.map(
            (card, index) =>
              card && (
                <CardInCart
                  key={index}
                  brand={
                    merchantsSell.hasOwnProperty(cards[card].id)
                      ? merchantsSell[cards[card].id].name
                      : ""
                  }
                  balance={+cards[card].enterValue}
                  offer={+cards[card].cashValue}
                  onRemove={() =>
                    dispatch(deleteCardInCart(cartSell.cartId, card, cards))
                  }
                  layoutVersion={config.layoutVersion}
                />
              )
          )}

          {showEnterForm ? (
            <InputCard
              offerMerchantDropDownPlaceHolder={
                config.offerMerchantDropDownPlaceHolder
              }
              offerBalanceInputPlaceHolder={config.offerBalanceInputPlaceHolder}
              cardPartial={cardPartial}
            />
          ) : (
            <p>
              <CCA
                onClick={() => {
                  setShowEnterForm(true);
                }}
              >
                {config.soundGoodAddAnotherCardLinkTxt}
              </CCA>
            </p>
          )}
        </React.Fragment>
        <React.Fragment>
          {showEnterForm ? (
            <React.Fragment>
              <input
                id="save"
                type="button"
                onClick={save}
                name="submit"
                className={saveBtnClasses}
                value={config.soundsGoodSaveButtonTxt}
                disabled={saveBtnDisabled}
              />
              <input
                id="cancel"
                type="button"
                onClick={reset}
                name="submit"
                className={"btn btn-primary soundsGoodCancelBtn"}
                value={config.soundsGoodCancelButtonTxt}
              />
            </React.Fragment>
          ) : (
            <input
              id="continue"
              type="button"
              name="submit"
              className={"btn btn-primary"}
              value={config.soundsGoodContinueButtonTxt}
              onClick={() => {
                history.push("/card-details");
              }}
            />
          )}
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
