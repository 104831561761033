import React from "react";

export default ({ copyright, config }) => (
  <div className="pull-lg-right copyright-container stay_connected">
    <span>
      &copy; {copyright} {config.footerCopyright.text(config)}
    </span>
    <span className="hidden-lg-up">&copy; {copyright} CardCash</span>
  </div>
);
