import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import Address from "../../../components/stepThree/address";
import CreditCardInfo from "../../../components/stepThree/creditCardInfo";

import { defaultAction } from "../../../actions/defaultAction";
import { createOrder } from "../../../thunks/customerInfo";

import {
  creditCardValidateSelector,
  contactInfoValidateSelector,
  adddressValidateSelector,
  partnerOrderSelector,
  sellVerificationSelector,
  customerInfoSelector,
} from "../../../selectors";

import { isLoadingSelector } from "ccui-framework";

import {
  DISPLAY_SELL_VERIFICATION_FIRST_NAME_ERROR,
  DISPLAY_SELL_VERIFICATION_LAST_NAME_ERROR,
  DISPLAY_SELL_VERIFICATION_STREET_ERROR,
  DISPLAY_SELL_VERIFICATION_STREET2_ERROR,
  DISPLAY_SELL_VERIFICATION_CITY_ERROR,
  DISPLAY_SELL_VERIFICATION_ZIP_ERROR,
  DISPLAY_SELL_VERIFICATION_STATE_ERROR,
  HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR,
  HIDE_SELL_VERIFICATION_LAST_NAME_ERROR,
  HIDE_SELL_VERIFICATION_STREET_ERROR,
  HIDE_SELL_VERIFICATION_STREET2_ERROR,
  HIDE_SELL_VERIFICATION_ZIP_ERROR,
  HIDE_SELL_VERIFICATION_CITY_ERROR,
  HIDE_SELL_VERIFICATION_STATE_ERROR,
  SELL_VERIFICATION_SET_FIRST_NAME,
  SELL_VERIFICATION_SET_LAST_NAME,
  SELL_VERIFICATION_SET_STREET,
  SELL_VERIFICATION_SET_STREET2,
  SELL_VERIFICATION_SET_CITY,
  SELL_VERIFICATION_SET_STATE,
  SELL_VERIFICATION_SET_ZIPCODE,
} from "../../../constants/actionTypes";

export default () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    sellVerification,
    customerInfo,
    isLoading,
    creditCardValidate,
    contactInfoValidate,
    addressValidate,
    partnerOrder,
  } = useSelector(
    (state) => ({
      config: state.config,
      sellVerification: sellVerificationSelector(state),
      customerInfo: customerInfoSelector(state),
      isLoading: isLoadingSelector(state),
      creditCardValidate: creditCardValidateSelector(state),
      contactInfoValidate: contactInfoValidateSelector(state),
      addressValidate: adddressValidateSelector(state),
      partnerOrder: partnerOrderSelector(state),
    }),
    shallowEqual
  );

  const submitBtnClass = addressValidate
    ? "btn btn-primary"
    : "btn btn-primary activebtn";

  return (
    <Guard
      condition={contactInfoValidate && creditCardValidate}
      redirect={"/identity"}
    >
      <ActionContainer title={config.identityBillingInfoTitle}>
        <React.Fragment>
          <CreditCardInfo
            cardNumber={customerInfo.get("creditCardNum")}
            layoutVersion={config.layoutVersion}
          />
          <Address
            config={config}
            editNames={true}
            setFirstName={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_FIRST_NAME, val))
            }
            setLastName={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_LAST_NAME, val))
            }
            setStreet={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_STREET, val))
            }
            setStreetTwo={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_STREET2, val))
            }
            setCity={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_CITY, val))
            }
            setZip={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_ZIPCODE, val))
            }
            setNewState={(val) =>
              dispatch(defaultAction(SELL_VERIFICATION_SET_STATE, val))
            }
            hideFirstNameError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR))
            }
            hideLastNameError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_LAST_NAME_ERROR))
            }
            hideStreetError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_STREET_ERROR))
            }
            hideCityError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_CITY_ERROR))
            }
            hideStateError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_STATE_ERROR))
            }
            hideZipError={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_ZIP_ERROR))
            }
            hideStreet2Error={() =>
              dispatch(defaultAction(HIDE_SELL_VERIFICATION_STREET2_ERROR))
            }
            displayFirstNameError={() =>
              dispatch(
                defaultAction(DISPLAY_SELL_VERIFICATION_FIRST_NAME_ERROR)
              )
            }
            displayLastNameError={() =>
              dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_LAST_NAME_ERROR))
            }
            displayStreetError={() =>
              dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_STREET_ERROR))
            }
            displayStreet2Error={() =>
              dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_STREET2_ERROR))
            }
            displayZipError={() =>
              dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_ZIP_ERROR))
            }
            displayCityError={() =>
              dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_CITY_ERROR))
            }
            displayStateError={() =>
              dispatch(defaultAction(DISPLAY_SELL_VERIFICATION_STATE_ERROR))
            }
            firstName={sellVerification.get("firstName")}
            lastName={sellVerification.get("lastName")}
            street={sellVerification.get("street")}
            street2={sellVerification.get("street2")}
            city={sellVerification.get("city")}
            zip={sellVerification.get("zip")}
            state={sellVerification.get("state")}
            firstNameError={sellVerification.getIn(["errors", "firstName"])}
            lastNameError={sellVerification.getIn(["errors", "lastName"])}
            streetError={sellVerification.getIn(["errors", "street"])}
            street2Error={sellVerification.getIn(["errors", "street2"])}
            cityError={sellVerification.getIn(["errors", "city"])}
            stateError={sellVerification.getIn(["errors", "state"])}
            zipError={sellVerification.getIn(["errors", "zip"])}
          />
        </React.Fragment>
        <React.Fragment>
          <input
            id="continue"
            type="button"
            name="submit"
            className={submitBtnClass}
            value={
              isLoading
                ? config.identityBillingLoadingButtonTxt
                : config.identityBillingContinueButtonTxt
            }
            onClick={() => {
              setIsSubmitting(true);
              // false means we have all data points.. backwards because input disabled.
              if (!addressValidate) {
                dispatch(createOrder(partnerOrder));
              }
            }}
            disabled={isSubmitting || addressValidate}
          />
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
