import React, { useEffect, useState, useRef } from "react";
import Router from "../routes";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { startSession } from "../../../thunks/session";
import { defaultAction } from "../../../actions/defaultAction";

import loadAssets from "../../../utils/loadAssets";
import utmFinder from "../../../utils/utmFinder";
import WebFont from "webfontloader";

import Header from "./header";
import Footer from "./footer";
import ExchangeMast from "./exchangeMast";
import ErrorBoundary from "./errorBoundary";
import ContactUs from "./popups/contactUs";
import WidgetCopyright from "./widgetCopyright";
import AnyCardOrderTotalExceeded from "./popups/anyCardOrderTotalExceeded";
import OrderTotalMaxExceeded from "./popups/orderTotalMaxExceeded";
import OrderTotalMinRequired from "./popups/orderTotalMinRequired";

import * as packageInfo from "../../../../package.json";
import {
  SET_PAYOUT,
  TOGGLE_CONTACT_US,
  ANYCARD_ORDER_TOTAL_EXCEEDED,
  ORDER_TOTAL_MAX_EXCEEDED,
  ORDER_TOTAL_MIN_REQUIRED,
  SOUNDS_GOOD_CONTINUE_DISABLED,
} from "../../../constants/actionTypes";

import {
  anyCardOrderTotalSelector,
  cardsSelector,
  cartSelector,
  cartTotalSelector,
} from "../../../selectors";
import { closeWidget } from "ccui-framework";
import { deleteCardInCart } from "../../../thunks/cards";
import { doEvent } from "../../../thunks/events";

WebFont.load({
  google: {
    families: ["Montserrat:300,400,500,600,700"],
  },
});

const Globals = (theme) => css`
  html,
  body {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    width: 100%;
    height: 100%;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #33433d;
    opacity: 1; /* Firefox */
    font-size: 1rem;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #33433d;
    font-size: 1rem;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #33433d;
    font-size: 1.5rem;
  }

  .form-control {
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    height: 2.76923rem;
    margin-top: 0.2rem;
    transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s,
      border-width 0.1s ease 0s;
    outline: none;
    color: #3d3d3d;
    cursor: pointer;
    align-items: center;
    border-style: none none solid;
    border-bottom: 1px solid rgba(51, 67, 61, 0.1);
    caret-color: ${theme.primary_color};
    background-color: white;

    ${mediaDesktop} {
      margin-bottom: 0.2rem;
    }

    ${mediaPhone} {
      margin-bottom: 1rem;
    }

    &:focus {
      border-bottom: 1px solid ${theme.primary_color};
    }
  }

  input {
    font-family: "Montserrat", sans-serif;
  }

  .errorMsg {
    color: red;
    display: block !important;
    font-size: 11px;

    ${mediaDesktop} {
      height: 5px;
    }
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .hide {
    display: none;
  }

  .ReactModal__Content--after-open {
    background-color: transparent !important;
    border: 0px !important;
    ${mediaDesktop} {
      width: 50rem;
      height: 30rem;
    }
    ${mediaPhone} {
      top: 7rem !important;
      left: 0 !important;
      right: 0 !important;
      width: 95%;
      height: 32rem;
    }
  }
`;

const MainContainer = styled.div`
  ${({ variant }) => (variant === "website" ? "min-height: 858px" : "")};
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  flex: 1 auto;
  ${mediaDesktop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const InnerContainer = styled.div`
  ${mediaDesktop} {
    position: absolute;
    top: ${({ variant, isFaq }) =>
      variant === "website" && isFaq
        ? "7%"
        : variant === "website" && !isFaq
        ? "16%"
        : "20%"};
  }

  ${mediaPhone} {
    position: relative;
    padding-top: 30px;
  }

  ${mediaDesktop} {
    max-width: 935px;
    width: 85%;
  }
`;

const ModalHalfContainer = styled.div`
  color: #3d3d3d;
  display: flex;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  transition: margin-top 0.2s ease-in;
  box-shadow: 0 50px 80px 0 rgba(51, 67, 61, 0.1),
    0 2px 4px 0 rgba(51, 67, 61, 0.05);

  flex-direction: row;

  ${mediaPhone} {
    width: 80%;
    display: block;
  }

  @media (max-width: 410px) {
    width: 90%;
  }
`;

const FormWrapper = styled.div`
  flex: 1.8;
  background-color: #f7f7f7;
  border-radius: 30px;

  ${mediaDesktop} {
    border-radius: 30px 0 0 30px;
  }

  ${mediaPhone} {
    border-radius: 30px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: #f7f7f7;

  ${mediaPhone} {
    display: block;
    border-radius: 30px;
  }
`;

const FormContent = styled.div`
  width: 100%;
  background-color: ${({ faq }) => (faq ? "#f5f4f3" : "#f7f7f7")};

  ${mediaPhone} {
    border-radius: 30px 30px 0 0;
  }
`;

const LeftImage = styled.img`
  pointer-events: none;
  position: absolute;
  z-index: 5;
`;

const RightImage = styled.img`
  pointer-events: none;
  position: absolute;
  z-index: 5;
`;

const TopRightImage = styled.img`
  pointer-events: none;
  position: absolute;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(237, 235, 233);
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Top = styled.div`
  height: 60%;
  width: 100%;
  opacity: 0.7;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0) 93%
  );
`;

const Bottom = styled.div`
  height: 50%;
  width: 100%;
  opacity: 0.16;
  background-image: radial-gradient(
    circle at 50% 0px,
    rgb(61, 57, 53),
    rgba(61, 57, 53, 0.1) 33%
  );
`;
const Terms = styled.div`
  ${mediaDesktop} {
    display: none;
  }
  ${mediaPhone} {
    display: flex;
    flex: 1;
    justify-content: center;
    height: 65px;
    align-items: center;
  }
`;

console.info("App Version:", packageInfo.version);

export default () => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const mobileMenuNode = useRef();
  const mobileToolTipNode = useRef();
  const {
    config,
    popups,
    renderAssets,
    anyCardOrderTotal,
    cartTotal,
    cartSell,
    cards,
    store,
  } = useSelector(
    (state) => ({
      config: state.config,
      popups: state.popups,
      renderAssets: state.customerInfo.get("renderAssets"),
      anyCardOrderTotal: anyCardOrderTotalSelector(state),
      cartTotal: cartTotalSelector(state),
      cartSell: cartSelector(state),
      cards: cardsSelector(state),
      store: state.store,
    }),
    shallowEqual
  );

  useEffect(() => {
    Promise.all([
      dispatch(startSession()),
      dispatch(
        defaultAction(SET_PAYOUT, {
          payoutType: config.paymentType,
          payoutMethod: "PARTNER",
        })
      ),
    ])
      .then(() => {
        const data = utmFinder(location.hash || location.search || "");

        if (data.length)
          dispatch(doEvent("CAPTURE_UTM_DATA", { values: data }));
      })
      .then(() => setFetched(true));

    return () => {
      setFetched(false);
    };
  }, [config.paymentType, dispatch, location.hash, location.search]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      pageUrl: `${window?.location?.href}`,
    });
  }, [location.pathname]);

  const currentPath =
    (location.state && location.state.referrer) || location.pathname;
  const isFaq = () => location.pathname === "/faq";

  if (!fetched) {
    return null;
  }

  return (
    <>
      <ThemeProvider theme={config.theme}>
        <Global styles={Globals(config.theme)} />

        {/* load Analytics & fraud tools */}
        {renderAssets && loadAssets(config.googleAnalytics)}

        {/* Headers */}
        <Header config={config} popups={popups} node={mobileMenuNode} />

        {/* Main Content */}
        <MainContainer variant={config.variant}>
          {config.variant !== "widget" && (
            <React.Fragment>
              <Background>
                <Top></Top>
                <Bottom></Bottom>
              </Background>
            </React.Fragment>
          )}
          <InnerContainer variant={config.variant} isFaq={isFaq()}>
            <ModalHalfContainer>
              {/* Left Content */}
              <FormWrapper>
                <FormContainer>
                  <ExchangeMast.Mobile
                    config={config}
                    currentPath={currentPath}
                    node={mobileToolTipNode}
                  />
                  <ExchangeMast.Desktop
                    config={config}
                    currentPath={currentPath}
                  />
                  <ErrorBoundary>
                    <FormContent faq={isFaq()}>
                      <Router.Mast />
                      <ContactUs
                        isOpen={popups.get("contactus")}
                        toggle={() =>
                          dispatch(defaultAction(TOGGLE_CONTACT_US))
                        }
                        partnerEmail={config.partnerEmail}
                        showPhoneNum={config.showContactUs_phoneNumber}
                        phoneNum={config.contactUs_phoneNumber}
                      />
                      <AnyCardOrderTotalExceeded
                        isOpen={popups.get("anyCardOrderTotalExceeded")}
                        backTo={() => {
                          dispatch(defaultAction(ANYCARD_ORDER_TOTAL_EXCEEDED));
                          dispatch(
                            closeWidget({
                              error: `Transaction gift card total was greater than order total.`,
                            })
                          );
                        }}
                        newGiftCard={() => {
                          dispatch(
                            deleteCardInCart(
                              cartSell.cartId,
                              cartSell.cardIds[0],
                              cards
                            )
                          );
                          dispatch(defaultAction(ANYCARD_ORDER_TOTAL_EXCEEDED));
                        }}
                        orderTotal={anyCardOrderTotal}
                        cartTotal={cartTotal}
                      />
                      <OrderTotalMaxExceeded
                        isOpen={popups.get("orderTotalMaxExceeded")}
                        backTo={() => {
                          dispatch(defaultAction(ORDER_TOTAL_MAX_EXCEEDED));
                          dispatch({ type: SOUNDS_GOOD_CONTINUE_DISABLED });
                        }}
                        maxAmount={store.maxPayout}
                      />
                      <OrderTotalMinRequired
                        isOpen={popups.get("orderTotalMinRequired")}
                        backTo={() => {
                          dispatch(defaultAction(ORDER_TOTAL_MIN_REQUIRED));
                          dispatch({ type: SOUNDS_GOOD_CONTINUE_DISABLED });
                        }}
                        minAmount={store.minPayout}
                      />
                    </FormContent>
                  </ErrorBoundary>
                </FormContainer>

                {config.variant === "widget" &&
                  config.partnerBrand !== "Anycard" && (
                    <Terms>
                      <WidgetCopyright
                        textColor={"rgba(49, 46, 48, 0.6)"}
                        linkColor={"#302e30"}
                      />
                    </Terms>
                  )}
              </FormWrapper>
              {/* Right Content */}

              <Router.Info />
            </ModalHalfContainer>
            {config.layoutOverlayImagesTopRightImg && (
              <TopRightImage
                className="top-right-image--v3"
                src={config.layoutOverlayImagesTopRightImg}
                alt="overlay image top right"
              />
            )}
            {config.layoutOverlayImagesLeftImg && (
              <LeftImage
                className="left-image--v3"
                src={config.layoutOverlayImagesLeftImg}
                alt="overlay image left"
              />
            )}
            {config.layoutOverlayImagesRightImg && (
              <RightImage
                className="right-image--v3"
                src={config.layoutOverlayImagesRightImg}
                alt="overlay image right"
              />
            )}
          </InnerContainer>
        </MainContainer>

        {/* Footers */}
        <Footer config={config} />
      </ThemeProvider>
    </>
  );
};
