import select from "../select";

export const getCardType = number => {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "Visa";

  // Mastercard
  re = new RegExp("^5[1-5]");
  if (number.match(re) != null) return "Mastercard";

  // AMEX
  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "AMEX";

  // Discover
  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "Discover";

  // Diners
  re = new RegExp("^36");
  if (number.match(re) != null) return "Diners";

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]");
  if (number.match(re) != null) return "Diners - Carte Blanche";

  // JCB
  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "JCB";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  if (number.match(re) != null) return "Visa Electron";

  return "";
};


/**
 * Checks if:
 * - Card was previously stored in the store.
 * - The balance is supplied by the payload.
 * - The pin is supplied by the payload.
 *
 * Then:
 * - Populates an error object and spreads it across the incoming card payload.
 * @param {Card} card - The card returned from the api
 * @param {Map<CardID, Card>} currentCards - The previous state of the cards stored in the redux store.
 * @return {Card}
 */
const sanitizeCard = (card, currentCards) => {
  const sanitizedCard = select(card, [
    "enterValue",
    "cashValue",
    "merchant:id", // MerchantID's are stored as id in card Objects... A little wierd.
    "pin",
    "number",
    "refId",
    "percentage"
  ]);

  let errorObject = { balanceError: "", cardError: "", pinError: "", msg: "" };

  if (currentCards[card.id] == null) {
    errorObject = {
      ...errorObject,
      balanceError: "PASSED",
      msg: "Invalid Card Number"
    };
  } else if (currentCards[card.id] != null && card.pin && card.number) {
    errorObject = {
      ...errorObject,
      balanceError: "PASSED",
      pinError: "PASSED",
      cardError: "PASSED"
    };
  } else {
    errorObject = { ...errorObject, balanceError: "PASSED" };
  }

  currentCards[card.id] = {
    ...currentCards[card.id], // Apply properties of old card state.
    ...sanitizedCard, // Apply new card state.
    ...errorObject, // Apply any errors found.

    // These are littered around the code. I would love to use flow
    // as a safeguard against object pollution.
    cardNumEnter: card.number, // MARK: Duplicate key values.
    pinNumEnter: card.pin // MARK: Duplicate key values.
  };

  return currentCards[card.id];
};

/**
 * TODO: Refactor this function.
 * getSellCards
 * @param {AxiosResponseBody} responseData - Response body ideally containing a cart, cards variable.
 * @param {Array<Card>} currentCards - cards currently in store.
 * @returns {Tuple<Map<CardID, Card>, Array<CardID>, CartID>}
 */
export const getSellCards = (responseData, currentCards = {}) => {
  let cartID = null;
  let cardIds = [];
  let cards = null;
  if (responseData.cart && responseData.cart.sellCart) {
    cartID = responseData.cart.sellCart.cartId;
    cards = responseData.cart.sellCart.cards;
  } else {
    cards = responseData.cards;
  }

  if (responseData.cart && responseData.cart.sellCart) {
    cartID = responseData.cart.sellCart.cartId;
    if (responseData.cart.sellCart.cards) {
      cardIds = cards.map(card => card.id);
      responseData.cart.sellCart.cards.forEach(value => {
        let cardID = value.id;
        let {
          enterValue,
          cashValue,
          merchant,
          pin,
          number,
          percentage,
          refId
        } = value;
        if (!currentCards[cardID] && pin !== null && number !== null) {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            cardNumEnter: number,
            pinNumEnter: pin,
            pinError: "PASSED",
            // match: "PASSED",
            cardError: "PASSED",
            id: merchant,
            balanceError: "PASSED"
          };
        } else if (!currentCards[cardID] && number !== null) {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            id: merchant,
            cardError: "PASSED",
            pinError: "",
            // match: "PASSED",
            cardNumEnter: number,
            pinNumEnter: "",
            balanceError: "PASSED",
            msg: "Invalid Card Number"
          };
        } else if (!currentCards[cardID]) {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            id: merchant,
            cardError: "",
            pinError: "",
            // match: "PASSED",
            cardNumEnter: "",
            pinNumEnter: "",
            balanceError: "PASSED",
            msg: "Invalid Card Number"
          };
        } else {
          currentCards[cardID] = {
            ...currentCards[cardID],
            enterValue,
            cashValue,
            pin,
            number,
            refId,
            percentage,
            // match: "PASSED",
            cardNumEnter: number,
            pinNumEnter: pin,
            id: merchant,
            balanceError: "PASSED"
          };
        }
      }); // forEach
    } // if cards in cart
  } else {
    cardIds = cards.map(card => card.id);
    cards.forEach(card => sanitizeCard(card, currentCards));
  }

  return [currentCards, cardIds, cartID];
};

export const reorderCards = (correctOrder, currentCards, cardsInCart) => {
  const indexedCards = cardsInCart.map(cardId => {
    let card = currentCards[cardId];
    let cardIndex = correctOrder.findIndex(c => {
      return (
        c.number === card.number &&
        c.pin === card.pin &&
        c.balance === card.enterValue.toString() &&
        c.ref === card.refId
      );
    });
    return { cardId, cardIndex };
  });
  indexedCards.sort((a, b) => a.cardIndex - b.cardIndex);
  const orderedCardsInCart = indexedCards.map(card => card.cardId);

  return orderedCardsInCart;
};
