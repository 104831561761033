import React, { Component } from "react";

const covertSecondsToTimeClock = time => {
  let minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;
  let hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  let str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  let finalTime =
    str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
  return finalTime;
};

export default class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondsRemaining: 0
    };
  }

  componentDidMount() {
    this.setState({ secondsRemaining: this.props.secondsRemaining });
    this.interval = setInterval(this.tick, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => {
    this.setState({ secondsRemaining: this.state.secondsRemaining - 1 });
    if (this.state.secondsRemaining <= 0) {
      clearInterval(this.interval);
      this.props.showSessionExpiredBox();
    }
  };

  render() {
    return (
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        Session expiring in{" "}
        <span className="partner-specific-color" style={{ fontWeight: "bold" }}>
          {covertSecondsToTimeClock(this.state.secondsRemaining)}
        </span>
      </div>
    );
  }
}
