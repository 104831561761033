import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import CCA from "../containers/a";
import { mediaDesktop } from "../../styles/Breakpoints";
import ActionContainer from "../containers/actionContainer";

const Wrapper = styled.div`
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: rgb(51, 51, 51) 0px 0px 0.1rem inset;
  border-radius: 0.2rem;
  overflow: hidden auto;
  ${mediaDesktop} {
    max-height: 18rem;
  }
`;

const Back = styled(CCA)`
  margin: 0.3rem;
  width: 100%;
  top: 1.2rem;
  position: relative;
  display: flex;
  justify-content: center;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Q = styled.h4`
  color: #2b2c2e;
  margin: 0 0 0.2rem 0;
`;
const A = styled.p`
  margin-top: 0;
  margin-bottom: 1em;
  color: rgb(91, 96, 101);
`;
const Question = styled.h4`
  color: #2b2c2e;
  margin: 0 0 0.2rem 0;
`;
const Answer = styled.p`
  margin-top: 0;
  margin-bottom: 1em;
  color: rgb(91, 96, 101);
`;

export default () => {
  const history = useHistory();
  const config = useSelector((state) => state.config);

  return (
    <ActionContainer>
      <React.Fragment>
        <Wrapper>
          {config.faq.content(config).map(({ question, answer }, index) => (
            <React.Fragment key={index}>
              <QuestionWrapper>
                <ContentWrapper style={{ marginRight: "1rem" }}>
                  <Q>Q:</Q>
                  <A>A:</A>
                </ContentWrapper>
                <ContentWrapper style={{ marginBottom: "1rem" }}>
                  <Question>{question}</Question>
                  <Answer>{answer}</Answer>
                </ContentWrapper>
              </QuestionWrapper>
            </React.Fragment>
          ))}
        </Wrapper>
      </React.Fragment>
      <React.Fragment>
        <Back onClick={() => history.goBack()}>Back</Back>
      </React.Fragment>
    </ActionContainer>
  );
};
