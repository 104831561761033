import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import StepTwoCart from "../../../components/stepTwo/stepTwoCart";
import PayPalButton from "../containers/paypalButton";

import { currencyFormatter } from "../../../utils/display";
import { defaultAction } from "../../../actions/defaultAction";
import totalPrice from "../../../utils/totalPrice";

import {
  deleteCardInCart,
  sendCardAndPinToServer,
} from "../../../thunks/cards";

import {
  UPDATE_CARD_PIN,
  UPDATE_CARD_NUMBER,
  DISPLAY_INVALID_MATCH,
  DISPLAY_MATCH,
  POPUPS_SHOW_DONATION_INFO,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  CLEAR_CARD_AND_PIN_ENTRY,
} from "../../../constants/actionTypes";

function randomNumber(len) {
  return Math.floor(Math.random() * Math.pow(10, len));
}

class StepTwo extends React.Component {
  componentDidMount() {
    if (Object.keys(this.props.cards).length < 1) {
      this.props.history.push("/");
    }

    if (!!this.props.config.paypalReturnUrl) {
      paypal.use(["login"], function(login) {
        login.render({
          appid: config.paypalCode,
          scopes: "openid profile email address phone",
          containerid: "paypalContainer",
          responseType: "code",
          locale: "en-us",
          fullPage: "false",
          buttonType: "CWP",
          authend: config.paypalEnv,
          buttonSize: "md",
          returnurl: config.paypalReturnUrl,
          nonce: randomNumber(10),
        });
      });
    }
  }

  checkButtonActive = () => {
    return Object.keys(this.props.cards).some(
      (id) =>
        this.props.cards[id].match !== "PASSED" ||
        this.props.cards[id].serverError
    );
  };

  moveToStepThree = () => {
    this.props.history.push("/payment");
  };

  render() {
    const {
      merchantsSell,
      updatePin,
      showDonationInfo,
      updateCardNum,
      cards,
      cartSell,
      deleteCardInCart,
      displayInvalidMatch,
      displayMatch,
      sendCardAndPinToServer,
      config,
      clearUpdateCardServerError,
      clearCardAndPin,
    } = this.props;

    let allCardsInCart;
    if (cartSell !== {} && cartSell.cardIds.length) {
      allCardsInCart = cartSell.cardIds.map((card, index) => {
        return (
          <StepTwoCart
            key={card}
            merchantsSell={merchantsSell}
            cashValue={cards[card].cashValue}
            merchantId={cards[card].id}
            enteredAmount={cards[card].enterValue}
            deleteCardInCart={deleteCardInCart}
            cardIndex={index}
            cartSellID={cartSell.cartId}
            cardID={card}
            updatePin={updatePin}
            updateCardNum={updateCardNum}
            sendCardAndPinToServer={sendCardAndPinToServer}
            pin={cards[card].pin}
            number={cards[card].number}
            validation={merchantsSell[cards[card].id].validation}
            displayInvalidMatch={displayInvalidMatch}
            displayMatch={displayMatch}
            cardNumEnter={cards[card].cardNumEnter}
            pinNumEnter={cards[card].pinNumEnter}
            cards={cards}
            match={cards[card].match}
            msg={cards[card].msg}
            clearUpdateCardServerError={clearUpdateCardServerError}
            clearCardAndPin={clearCardAndPin}
            config={config}
          />
        );
      });
    } else {
      allCardsInCart = (
        <h1 className="tgc partner-specific-color">
          No cards found. Please click back and add a card to the cart!
        </h1>
      );
    }
    const balanceOfferClasses =
      config.paymentType !== "donation"
        ? "balance-offer col-md-8 col-sm-12 total-offer"
        : "balance-offer col-md-8 col-sm-12";

    let buttonInactive = this.checkButtonActive() || !allCardsInCart.length;

    return (
      <div className="common-step-two container panel panel-default custBox animated fadeInUp small-margins">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              {allCardsInCart}
              <div
                className="row m_bottom_10"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <div className={balanceOfferClasses}>
                  {`${config.totalText}: ${currencyFormatter(
                    totalPrice(cartSell, cards)
                  )}`}
                  <br />
                  {config.offerAndContinue_displayLessThanCard && (
                    <span
                      style={{ fontWeight: "normal" }}
                      className="lnk-harvey"
                      onClick={showDonationInfo}
                    >
                      Why is this less than my{" "}
                      <span className="hidden-sm-down">gift</span> card balance?
                    </span>
                  )}
                </div>
                <div className="col-md-4 col-sm-12 no-padding">
                  <div className="col-md-5 col-sm-6 no-padding-sm">
                    <Link to="/">
                      <input
                        className={
                          "btn btn-primary nextStepBtn  next-step m_bottom_10"
                        }
                        value={config.stepTwo_addACard}
                        type="button"
                        tabIndex="4"
                      />
                    </Link>
                  </div>
                  <div className="col-md-7 col-sm-6 no-padding">
                    {config.partnerBrand === "PayPal" ? (
                      <PayPalButton
                        disabled={buttonInactive}
                        id="paypalContainer"
                      />
                    ) : (
                      <input
                        className="btn btn-primary next-step activebtn"
                        disabled={buttonInactive}
                        value="CONTINUE"
                        onClick={this.moveToStepThree}
                        type="button"
                        tabIndex="4"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cardPartial: state.cardPartial,
    cards: state.cards,
    cartSell: state.cartSell,
    merchantsSell: state.merchantsSell,
    config: state.config,
  }),
  (dispatch) => ({
    showDonationInfo: () => dispatch(defaultAction(POPUPS_SHOW_DONATION_INFO)),
    deleteCardInCart: (cartSellID, cardID) =>
      dispatch(deleteCardInCart(cartSellID, cardID)),
    updatePin: (cardID, pin) =>
      dispatch(defaultAction(UPDATE_CARD_PIN, { cardID, pin: pin })),
    updateCardNum: (cardID, num) =>
      dispatch(defaultAction(UPDATE_CARD_NUMBER, { cardID, number: num })),
    displayInvalidMatch: (cardID) =>
      dispatch(defaultAction(DISPLAY_INVALID_MATCH, { cardID: cardID })),
    displayMatch: (cardID) =>
      dispatch(defaultAction(DISPLAY_MATCH, { cardID: cardID })),
    sendCardAndPinToServer: (cartSellID, cardID, number, pin, cards) =>
      dispatch(sendCardAndPinToServer(cartSellID, cardID, number, pin, cards)),
    clearUpdateCardServerError: (cardID) =>
      dispatch(defaultAction(CLEAR_UPDATE_CARD_SERVER_ERROR, { cardID })),
    clearCardAndPin: (cardID) =>
      dispatch(defaultAction(CLEAR_CARD_AND_PIN_ENTRY, { cardID })),
  })
)(StepTwo);
