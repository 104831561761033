import {
  RECEIVE_SELL_MERCHANTS,
  OPEN_ERROR_BOX,
  SET_ERROR
} from "../constants/actionTypes";

export const getSellMerch = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    const merchantsSell = await remote(
      "get",
      "/v3/merchants/sell",
      {},
      "loading all the cool merchants we have to offer for selling gift cards",
      dispatch
    );

    if (merchantsSell.message) {
      throw merchantsSell;
    }

    dispatch({ type: RECEIVE_SELL_MERCHANTS, payload: merchantsSell });
  } catch (e) {
    if (getState().config.layoutVersion === 1) {
      dispatch({
        type: OPEN_ERROR_BOX,
        payload: {
          title: "Sorry, we are experiencing technical difficulties",
          message:
            "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you."
        }
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you."
      });
    }
  }
};
