import React from "react";
import Modal from "react-modal";

export default ({ showLoading }) => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    closeTimeoutMS={150}
    isOpen={showLoading}
    contentLabel="Loading"
  >
    <div id="loader"></div>
  </Modal>
);
