import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import TloVerificationEnter from "../../../components/stepThree/tloVerificationEnter";

import {
  SET_TLO_ENTER_CODE,
  HIDE_TLO_VERIFICATION_ENTER,
  SHOW_TLO_VERIFICATION_INIT,
} from "../../../constants/actionTypes";

import { defaultAction } from "../../../actions/defaultAction";
import { sendTLOVerification, doTLOVerify } from "../../../thunks/customerInfo";
import { isOrderIdEmptySelector, tloSelector } from "../../../selectors";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, tlo, isOrderIdEmpty } = useSelector(
    (state) => ({
      config: state.config,
      tlo: tloSelector(state),
      isOrderIdEmpty: isOrderIdEmptySelector(state),
    }),
    shallowEqual
  );

  return (
    <Guard condition={isOrderIdEmpty} redirect={"/"}>
      <ActionContainer title={config.identityTloPassCodeTitle}>
        <TloVerificationEnter
          config={config}
          enteredCode={tlo.get("enteredCode")}
          enteredCodeError={tlo.get("enteredCodeError")}
          setTLOEnteredCode={(code) =>
            dispatch(defaultAction(SET_TLO_ENTER_CODE, code))
          }
          goToTLOVerificationInit={() => {
            dispatch(defaultAction(HIDE_TLO_VERIFICATION_ENTER));
            dispatch(defaultAction(SHOW_TLO_VERIFICATION_INIT));
            history.push("/identity-tlo");
          }}
          doTLOResendCode={() => dispatch(sendTLOVerification())}
          doTLOVerify={() => dispatch(doTLOVerify())}
        />
      </ActionContainer>
    </Guard>
  );
};
