import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";

const Icon = styled.div`
  background: white;
  height: 300px;
  border-radius: 30px 30px 0 30px;

  #Loading {
    background: rgb(255, 255, 255);
    display: block;
    shape-rendering: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
  }

  #circle {
    stroke: ${({ theme }) => theme.secondary_color};
  }
`;

const Loading = () => {
  return (
    <Icon>
      <svg
        id="Loading"
        xmlns="http://www.w3.org/2000/svg"
        width="194px"
        height="194px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          id="circle"
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="5"
          r="45"
          strokeDasharray="212.05750411731105 72.68583470577035"
          transform="rotate(289.383 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
    </Icon>
  );
};

Loading.propTypes = {};

export default Loading;
