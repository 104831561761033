import React from "react";
import Modal from "react-modal";

export default ({ showAccountLockedBox, hideAccountLockedBox }) => (
  <Modal
    className="Modal__Bootstrap modal-dialog modal-lg"
    isOpen={showAccountLockedBox}
    contentLabel="Account Locked"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" onClick={hideAccountLockedBox}>
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title text-center partner-specific-color">
          Account Locked{" "}
        </h4>
      </div>
      <div className="modal-body" style={{ fontSize: "14px" }}>
        <div className="m_10_bottom">
          We've temporaily locked your account after too many failed attempts to
          verify your identity. Please call Customer Service at 1-800-227-4214
          to have your account unlocked or feel free to place a new order using
          a different email address.{" "}
        </div>
      </div>
    </div>
  </Modal>
);
