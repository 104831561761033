
export const HideIfPathContains = ({options , path, children}) => {
  for (const option of options) {
    if (path.includes(option)) {
      return null;
    }
  }

  return children;
};
