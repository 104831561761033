import { defaultAction } from "../../actions/defaultAction";
import { sendCardToCart, createSellCart } from "../../thunks/cart";

import {
  CARD_PARTIAL_CLEAR,
  DISPLAY_CARD_ERROR_PARTIAL,
  DISPLAY_MAX_ERROR_PARTIAL,
  DISPLAY_MIN_ERROR_PARTIAL,
  DISPLAY_NO_ERROR_PARTIAL,
  DISPLAY_MERCHANT_ERROR_PARTIAL,
  DISPLAY_CARD_BALANCE_FORMAT_ERROR,
} from "../../constants/actionTypes";

/**
 * Valids entered remaining balance field
 *
 * @param {Object} cardPartial - state.cardPartial
 * @param {Callback} dispatch - redux dispatch callback
 * @returns {boolean} true if valid
 */
export const validBalance = (cardPartial, dispatch) => {
  const selectedMerchant = cardPartial.selectedMerchant;
  const cardBalance = Number(cardPartial.balance);

  if (!cardBalance) {
    dispatch(defaultAction(DISPLAY_CARD_ERROR_PARTIAL));
    return;
  } else if (
    selectedMerchant &&
    selectedMerchant.limit &&
    selectedMerchant.limit.max < cardBalance
  ) {
    dispatch(defaultAction(DISPLAY_MAX_ERROR_PARTIAL));
    return;
  } else if (
    selectedMerchant &&
    selectedMerchant.limit &&
    selectedMerchant.limit.min > cardBalance
  ) {
    dispatch(defaultAction(DISPLAY_MIN_ERROR_PARTIAL));
    return;
  } else {
    dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL));

    return true;
  }
};

/**
 * Adds a submitted card to a given/new cart after validation.
 *
 * @param {Object} cartSell - state.cartSell
 * @param {Object} cardPartial - state.cardPartial
 * @param {Object} cards - state.cards
 * @param {Callback} dispatch - redux dispatch callback
 * @param {Function} checkBalance - function to execute validBalance
 */
export const addCard = (
  cartSell,
  cardPartial,
  cards,
  dispatch,
  checkBalance
) => {
  let currentCardPartialBalance = cardPartial.balance;

  if (cardPartial.selectedMerchant === null) {
    dispatch(defaultAction(DISPLAY_MERCHANT_ERROR_PARTIAL));
    return;
  } else {
    if (!cardPartial.balance) {
      dispatch(defaultAction(DISPLAY_CARD_BALANCE_FORMAT_ERROR));
      return;
    } else if (cardPartial.balance.includes(",")) {
      currentCardPartialBalance = Number(
        cardPartial.balance.split(",").join("")
      );
    } else {
      currentCardPartialBalance = Number(cardPartial.balance);
    }
  }

  if (checkBalance()) {
    if (
      cardPartial.displayMinError ||
      cardPartial.displayMaxError ||
      cardPartial.showMerchantError ||
      cardPartial.showCardBalanceError ||
      cardPartial.showCardBalanceFormatError
    ) {
      dispatch(defaultAction(CARD_PARTIAL_CLEAR));
      return;
    }

    if (!cartSell.cartId) {
      dispatch(
        createSellCart(
          currentCardPartialBalance,
          cardPartial.selectedMerchant,
          cards
        )
      );
    } else {
      dispatch(
        sendCardToCart(
          currentCardPartialBalance,
          cardPartial.selectedMerchant,
          cartSell.cartId,
          cards
        )
      );
    }
  }
};
