import React from "react";
import { ZIPREGEX } from "../../constants/regex";
import SelectState from "./selectState";

export default (props) => {
  const checkZip = (e) => {
    return ZIPREGEX.test(e.target.value);
  };

  const {
    hideFirstNameError,
    setFirstName,
    hideLastNameError,
    setLastName,
    hideStreetError,
    setStreet,
    setStreetTwo,
    hideStreet2Error,
    hideCityError,
    setCity,
    hideStateError,
    setNewState,
    hideZipError,
    setZip,
    firstNameError,
    firstName,
    editNames,
    lastname,
    lastNameError,
    hideStreetTwoField,
    streetError,
    street,
    street2,
    street2Error,
    city,
    cityError,
    zipError,
    zip,
  } = props;

  return (
    <React.Fragment>
      <div>
        <div className="row mar-b-10  no-mar-r no-mar-l">
          <div className="col-md-6 responsive_input no-padding-left">
            <input
              type="text"
              defaultValue={firstName}
              onChange={(e) => {
                if (!firstName && e.target.value) {
                  hideFirstNameError();
                }
                setFirstName(e);
              }}
              className="form-control"
              placeholder={"First Name"}
              disabled={editNames ? false : true}
            />
            <div
              className={
                firstNameError ? " errorMsg fverror cc-def-color" : "hide"
              }
            >
              Not a valid first name
            </div>
          </div>
          <div className="col-md-6 responsive_input no-padding-left">
            <input
              type="text"
              defaultValue={lastname}
              onChange={(e) => {
                if (!lastname && e.target.value) {
                  hideLastNameError();
                }
                setLastName(e);
              }}
              className="form-control"
              placeholder={"Last Name"}
              disabled={editNames ? false : true}
            />
            <div
              className={
                lastNameError ? " errorMsg fverror cc-def-color" : "hide"
              }
            >
              Not a valid last name
            </div>
          </div>
        </div>
      </div>
      <div className="row mar-b-10  no-mar-r no-mar-l">
        <div className="col-md-12 responsive_input no-padding-left ">
          <input
            type="text"
            defaultValue={street}
            onChange={(e) => {
              if (!props.street && e.target.value) {
                hideStreetError();
              }
              setStreet(e);
            }}
            className="form-control"
            placeholder={"Street Address"}
          />
          <div
            className={streetError ? " errorMsg fverror cc-def-color" : "hide"}
          >
            Not a valid street address
          </div>
        </div>
      </div>

      {hideStreetTwoField ? (
        ""
      ) : (
        <div className="row mar-b-10  no-mar-r no-mar-l">
          <div className="col-md-12 responsive_input no-padding-left ">
            <input
              type="text"
              defaultValue={street2}
              onChange={(e) => {
                if (props.street2Error) {
                  hideStreet2Error();
                }
                setStreetTwo(e);
              }}
              className="form-control"
              placeholder={"Street Address Continued (Optional)"}
            />
            <div
              className={
                street2Error ? " errorMsg fverror cc-def-color" : "hide"
              }
            >
              Not a valid street address continued
            </div>
          </div>
        </div>
      )}
      <div className="row mar-b-10  no-mar-r no-mar-l">
        <div className="col-md-4 no-padding-left">
          <input
            type="text"
            defaultValue={city}
            onChange={(e) => {
              if (!props.city && e.target.value) {
                hideCityError();
              }
              setCity(e);
            }}
            className="form-control"
            placeholder={"City"}
          />
          <div
            className={cityError ? " errorMsg fverror cc-def-color" : "hide"}
          >
            Not a valid city
          </div>
        </div>
        <SelectState
          name="state"
          className="form-control"
          selectState={(e) => {
            if (!props.state && e.target.value) {
              hideStateError();
            }
            setNewState(e);
          }}
          signedIn={false}
          signedInVal={props.state}
          errorType={props.stateError}
        />
        <div className="col-md-4 responsive_input no-padding-left">
          <input
            type="text"
            defaultValue={zip}
            onChange={(e) => {
              if (checkZip(e)) {
                hideZipError();
              }
              setZip(e);
            }}
            className="form-control"
            placeholder={"Zip Code"}
          />
          <div className={zipError ? " errorMsg fverror cc-def-color" : "hide"}>
            Not a valid zip code
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
