import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

const CCModal = ({ isOpen, onRequestClose, children }) => (
  <Modal
    closeTimeoutMS={150}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(31, 32, 33, .6)",
        zIndex: "10",
      },

      content: {
        position: "absolute",
        top: "40px",
        left: "40px",
        right: "40px",
        bottom: "40px",
        padding: "0px",
        overflow: "hidden",
        boxShadow: "0 5px 20px 0 rgba(61, 58, 53, 0.05)",
        background: "#fff",
        minHeight: "200px",
        WebkitOverflowScrolling: "touch",
        borderRadius: "30px",
        zIndex: "10",
      },
    }}
  >
    {children}
  </Modal>
);

CCModal.propTypes = {
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default CCModal;
