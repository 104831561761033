import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../containers/button";
import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import CreditCardForm from "../../../components/stepThree/creditCardFormV3";
import InfoIcon from "../containers/icons/info";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { defaultAction } from "../../../actions/defaultAction";
import CCLink from "../containers/link";

import {
  customerInfoSelector,
  creditCardValidateSelector,
  contactInfoValidateSelector,
} from "../../../selectors";

import {
  SET_CCV,
  SET_CREDIT_CARD,
  SELECT_MONTH,
  SELECT_YEAR,
  HIDE_CREDIT_CARD_NUM_ERROR,
  HIDE_MONTH_ERROR,
  HIDE_YEAR_ERROR,
  HIDE_CCV_ERROR,
  DISPLAY_CREDIT_CARD_NUM_ERROR,
  DISPLAY_MONTH_ERROR,
  DISPLAY_YEAR_ERROR,
  DISPLAY_CCV_ERROR,
} from "../../../constants/actionTypes";

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

  p {
    margin-left: 1rem;
    font-size: 10px;
  }
`;

const AnyCardText = styled.div`
  margin-bottom: 1rem;

  ${mediaPhone} {
    margin-top: -34px;
  }
`;

const P = styled.p`
  ${mediaPhone} {
    font-size: 10px;
  }
`;

const Link = styled(CCLink)`
  font-size: 10px;
`;

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    customerInfo,
    contactInfoValidate,
    creditCardValidate,
  } = useSelector(
    (state) => ({
      config: state.config,
      customerInfo: customerInfoSelector(state),
      contactInfoValidate: contactInfoValidateSelector(state),
      creditCardValidate: creditCardValidateSelector(state),
    }),
    shallowEqual
  );

  return (
    <Guard condition={contactInfoValidate} redirect={"/identity"}>
      <ActionContainer title={config.identityCardInfoTitle} goBack={true}>
        <React.Fragment>
          <Message>
            {!config.IdentityCardInfoWhyLinkShow && config.IdentityCardInfoTxt}
            {config.IdentityCardInfoWhyLinkShow && (
              <>
                {config.layoutVersion !== 2 && <InfoIcon />}
                <p>{config.IdentityCardInfoTxt}</p>
                &nbsp; &nbsp;
                <Link
                  to={(location) => ({
                    pathname: "/why",
                    state: { referrer: `${location.pathname}` },
                  })}
                >
                  Why?
                </Link>
              </>
            )}
          </Message>
          <CreditCardForm
            config={config}
            customerInfo={customerInfo}
            showInputs={true}
            hideCreditCardNumError={() =>
              dispatch(defaultAction(HIDE_CREDIT_CARD_NUM_ERROR))
            }
            setCreditCard={(e) =>
              dispatch(
                defaultAction(
                  SET_CREDIT_CARD,
                  e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
                )
              )
            }
            hideMonthError={() => dispatch(defaultAction(HIDE_MONTH_ERROR))}
            selectMonth={(val) => dispatch(defaultAction(SELECT_MONTH, val))}
            hideYearError={() => dispatch(defaultAction(HIDE_YEAR_ERROR))}
            selectYear={(val) => dispatch(defaultAction(SELECT_YEAR, val))}
            hideCCVError={() => dispatch(defaultAction(HIDE_CCV_ERROR))}
            setCCV={(e) =>
              dispatch(
                defaultAction(
                  SET_CCV,
                  e.target.value.replace(/^[\s\uFEFF\xA0]+/g, "")
                )
              )
            }
            setCreditCardNumError={() =>
              dispatch(defaultAction(DISPLAY_CREDIT_CARD_NUM_ERROR))
            }
            setMonthError={() => dispatch(defaultAction(DISPLAY_MONTH_ERROR))}
            setYearError={() => dispatch(defaultAction(DISPLAY_YEAR_ERROR))}
            setCCVError={() => dispatch(defaultAction(DISPLAY_CCV_ERROR))}
          />
          {config.partnerBrand === "Anycard" && (
            <AnyCardText>
              <P>
                A temporary authorization will appear on your credit card for
                the full purchase amount. Once your gift card(s) are
                successfully verified and applied to your order, only the
                remaining outstanding amount will be captured from your credit
                card.
              </P>
            </AnyCardText>
          )}
        </React.Fragment>
        <React.Fragment>
          <Button
            active={!creditCardValidate}
            onClick={() => {
              setIsSubmitting(true);
              history.push("/identity-billing-info");
            }}
            disabled={isSubmitting || creditCardValidate}
          >
            {config.identityCardContinueButtonTxt}
          </Button>
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
