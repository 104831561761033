import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import WidgetBurger from "../containers/widgetBurger";
import WidgetCopyright from "../containers/widgetCopyright";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import Steps from "../containers/steps";
import DoubleArrowIcon from "../containers/icons/doubleArrowIcon";
import CCLink from "../containers/link";

const SellInstructionsWrapper = styled.div`
  ${mediaPhone} {
    display: none;
  }

  ${mediaDesktop} {
    border-radius: 0 30px 30px 0;
    flex: 1.2;
    background-image: radial-gradient(
        circle at 0px 0px,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0) 81%
      ),
      linear-gradient(
        ${({ theme }) => theme.sell_instructions_bg_color},
        ${({ theme }) => theme.sell_instructions_bg_color}
      );
    background-blend-mode: overlay, normal;
  }
`;

const SellInstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-around;
  margin: 1.84615rem 3.84615rem 2.92308rem 3.07692rem;

  h1 {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    color: rgb(255, 255, 255);
  }
`;

const Link = styled(CCLink)`
  display: flex;
  margin-left: 2rem;
  color: white;
  font-size: 14px;

  &:visited {
    color: white;
  }

  .double-arrow-icon {
    #Fill {
      fill: white;
    }
    margin-left: 0.5rem;
  }
`;

export default () => {
  const location = useLocation();
  const config = useSelector((state) => state.config);

  return (
    <SellInstructionsWrapper>
      <WidgetBurger />
      <Steps.Bar config={config} currentPath={location.pathname} />
      <SellInstructionsContainer>
        <h1>{config.sellInstructionsDesktopTitle}</h1>
        <Steps.Description config={config} currentPath={location.pathname} />
        {config.sellInstructionsShowLearnMoreLink && (
          <Link to="/faq">
            Learn more <DoubleArrowIcon />
          </Link>
        )}
        {config.partnerBrand !== "Anycard" && (
          <WidgetCopyright textColor={"white"} linkColor={"white"} />
        )}
      </SellInstructionsContainer>
    </SellInstructionsWrapper>
  );
};
