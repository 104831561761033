import React from "react";
import Modal from "react-modal";

export default ({
  isShowingTLOVerificationEnter,
  setTLOEnteredCode,
  enteredCode,
  enteredCodeError,
  goToTLOVerificationInit,
  doTLOResendCode,
  doTLOVerify,
  isLoading,
}) => {
  return (
    <Modal
      className="Modal__Bootstrap modal-dialog modal-lg"
      isOpen={isShowingTLOVerificationEnter}
      contentLabel="Enter Verification Code"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title text-center partner-specific-color">
            Enter Verification Code
          </h4>
        </div>
        <div className="modal-body" style={{ fontSize: "14px" }}>
          {enteredCodeError && (
            <div>
              <span style={{ color: "red" }}> {enteredCodeError} </span>
            </div>
          )}
          <div>
            <input
              type="text"
              value={enteredCode}
              className={"form-control"}
              onChange={(e) => setTLOEnteredCode(e.target.value.trim())}
              placeholder="XXXXXX"
            />
          </div>
          <div>
            <button
              disabled={isLoading}
              className={"btn btn-primary"}
              onClick={() => {
                doTLOVerify();
              }}
            >
              VERIFY
            </button>
          </div>
          <div>
            <button
              disabled={isLoading}
              className={"btn btn-primary"}
              onClick={() => {
                doTLOResendCode();
              }}
            >
              RESEND
            </button>
            <button
              className={"btn btn-primary"}
              onClick={goToTLOVerificationInit}
            >
              CHOOSE A DIFFERENT PHONE
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
