import {
  SET_LIMITS,
  SET_PAYOUT,
  SOUNDS_GOOD_CONTINUE_DISABLED,
} from "../constants/actionTypes";

const initCartSellState = {
  minPayout: null,
  maxPayout: null,
  payoutType: null,
  payoutMethod: null,
  tradeBonus: 0,
  soundsGoodContinueDisabled: false,
};

export default (state = initCartSellState, action) => {
  switch (action.type) {
    case SOUNDS_GOOD_CONTINUE_DISABLED:
      return {
        ...state,
        soundsGoodContinueDisabled: !state.soundsGoodContinueDisabled,
      };

    case SET_LIMITS:
      return {
        ...state,
        minPayout: action.payload.trade[0].limit.minPrice,
        maxPayout: action.payload.trade[0].limit.maxPrice,
      };

    case SET_PAYOUT:
      return {
        ...state,
        payoutType: action.payload.payoutType,
        payoutMethod: action.payload.payoutMethod,
        payoutMethodTradeID: action.payload.payoutMethodTradeID,
        tradeBonus: action.payload.tradeBonus,
      };

    default:
      return state;
  }
};
