export const currencyFormatter = num => {
  num = Number(num);
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const capitalizeFirstLetter = string => {
  if (string === undefined || string === null || string.length === 0) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
