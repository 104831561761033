import React from "react";

import { connect } from "react-redux";
import { defaultAction } from "../../../actions/defaultAction";
import totalPrice from "../../../utils/totalPrice";

import SaleComplete from "../containers/saleComplete";
import DonationComplete from "../containers/donationComplete";

import {
  SHOW_DETAILS_ORDER,
  RESET_TWILIO_ATTEMPTS_EXCEEDED,
  COMPLETE_ORDER_SELL,
} from "../../../constants/actionTypes";

class OrderComplete extends React.Component {
  componentDidMount() {
    const { config, userNotifications, cartSell, cards, history } = this.props;

    if (userNotifications.orderID === null) {
      history.push("/");
    }

    if (config.partnerBrand === "Amazon" || config.partnerBrand === "eBay") {
      let dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: `${config.partnerBrand.toLowerCase()}-trade`,
        orderNumber: userNotifications.orderID + "",
        transactionAffiliation: `${config.partnerBrand}`,
        transactionTotal: 1,
        transactionTax: 0,
        total: totalPrice(cartSell, cards),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.cartSell.cardIds.length < 1) {
      return false;
    }
    return true;
  }

  componentWillUnmount = () => {
    this.props.resetTwilioAttemptsExceeded();

    this.props.completeSellOrder();
    this.props.showDetailsOrder();
  };

  render() {
    const {
      config,
      userInfo,
      customerInfo,
      cards,
      userNotifications,
      merchantsSell,
    } = this.props;

    return config.donationSite ? (
      <DonationComplete
        twilioAttemptsExceeded={userInfo.get("twilioAttemptsExceeded")}
        orderID={userNotifications.orderID}
        partnerBrand={config.partnerBrand}
        partnerEmail={config.partnerEmail}
      />
    ) : (
      <SaleComplete
        twilioAttemptsExceeded={userInfo.get("twilioAttemptsExceeded")}
        orderID={userNotifications.orderID}
        partnerBrand={config.partnerBrand}
        paymentType={config.paymentType}
        partnerEmail={config.partnerEmail}
        cards={cards}
        merchantsSell={merchantsSell}
        showDetailsOrder={customerInfo.get("showDetailsOrder")}
      />
    );
  }
}

export default connect(
  (state) => ({
    cards: state.cards,
    cartSell: state.cartSell,
    config: state.config,
    customerInfo: state.customerInfo,
    merchantsSell: state.merchantsSell,
    userInfo: state.userInfo,
    userNotifications: state.userNotifications,
  }),
  (dispatch) => ({
    showDetailsOrder: () => dispatch(defaultAction(SHOW_DETAILS_ORDER)),
    resetTwilioAttemptsExceeded: () =>
      dispatch(defaultAction(RESET_TWILIO_ATTEMPTS_EXCEEDED)),
    completeSellOrder: () => dispatch(defaultAction(COMPLETE_ORDER_SELL)),
  })
)(OrderComplete);
