import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "../../utils/display";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: ${({ version }) => (version === 3 ? " 10px" : "0.2rem")};
  margin-top: ${({ version }) => (version === 3 ? "0px" : "0.2rem")};
  transition: all 0.3s ease-out;
  ${({ version }) =>
    version === 3
      ? `
        box-shadow: 0 20px 70px 0 rgba(51, 67, 61, 0.08);
        height: 70px;
        border-radius: 5px;
        width: 90%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
      `
      : ` `}
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
`;

const Top = styled(Div)`
  padding: 1rem 1rem 0rem 1rem;
  p:last-child {
    transition: all 0.3s ease-out, color 3s ease;
  }

  ${({ version }) =>
    version === 3
      ? ` border-radius: 5px; p:last-child { text-align: right; } `
      : `
        margin-bottom: 0px;
        border: 0.1rem solid #c3c3c3;
        border-bottom-width: 0;
        border-bottom-color: transparent;

        p:last-child {  font-size: smaller; margin-top: .2rem; }
      `}
`;

const Bottom = styled(Div)`
  p:last-child {
    transition: all 0.3s ease-out, color 3s ease;
  }

  ${({ version }) =>
    version === 3
      ? ` 
        border-radius: 5px;
        padding: 0 1rem;
        p:first-of-type { color: #3d3d3d; padding-bottom: 12px; }

        p:last-child { 
          padding-bottom: 12px; 
          padding-left: 11.5rem;
          text-align: right;
        }
      `
      : `
        margin-top: 0px;
        padding: 0 1rem .9rem 1rem;
        border: 0.1rem solid #c3c3c3;
        border-top-width: 0;
        border-top-color: transparent;
        
        p:last-child { font-weight: bold; color: #42a54d; }
   `}
`;

const P = styled.p`
  transition: all 0.3s ease-out;
  margin: 0px;
  padding: 0px;
  ${({ version }) =>
    version === 3
      ? ` 
        ${mediaDesktop} { font-size: 14px; }
        ${mediaPhone} { font-size: 10px; }
      `
      : ``}
`;

const Brand = styled(P)`
  font-weight: bold;

  ${({ version }) =>
    version === 3 ? ` color: rgb(61, 61, 61); ` : ` flex: 2; `}
`;

const Balance = styled(P)`
  font-weight: lighter;

  ${({ version }) =>
    version === 2 ? `color: gray; font-size: smaller; flex: 2;` : ``}
`;

const Offer = styled(P)`
  font-weight: lighter;
  color: gray;
  text-align: right;

  ${({ version }) =>
    version === 3 ? `flex: 3; padding-left: 0px;` : `flex: 1;`}
`;

const CardImg = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;

  ${mediaPhone} {
    left: 50%;
  }

  ${mediaDesktop} {
    left: 55%;
  }

  img {
    width: 56px;
    height: 39px;

    ${mediaPhone} {
      height: 36px;
    }
  }
`;

const Line = styled.div`
  border-right: 1px solid rgba(119, 121, 132, 0.1);
  height: 3rem;
  margin-left: 10px;

  ${mediaPhone} {
    margin-left: -7px;
  }

  ${mediaDesktop} {
    margin-left: 10px;
  }
`;

const RemoveContainer = styled.div`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transform: translateY(-50%);
  position: ${({ version }) => (version === 3 ? "relative" : "absolute")};
  right: ${({ version }) => (version === 3 ? "0.5rem" : "-1rem")};

  ${({ version }) =>
    version === 2
      ? `justify-content: flex-end; top: 50%;`
      : `background-color: white; border-radius: 10px; padding: 1px;`};
`;

const Remove = styled.div`
  cursor: pointer;
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.05rem;
  margin: 0.2rem;
`;

const RemoveBar = styled.div`
  background-color: darkgrey;
  top: 50%;
  width: 100%;
  height: 0.15rem;
  position: absolute;
  transform-origin: 50% 0%;
  transition: all 0.3s ease-out;
  border-radius: 0.05rem;

  &:first-of-type {
    transform: rotate(-45deg) translateY(-50%);
  }

  &:last-of-type {
    transform: rotate(45deg) translateY(-50%);
  }
`;

const CardInCart = ({
  brand,
  balance,
  offer,
  onRemove,
  layoutVersion,
  image,
  offerTxt,
}) => (
  <Container version={layoutVersion}>
    <Top version={layoutVersion}>
      <Brand version={layoutVersion}>{brand}</Brand>
      <Offer version={layoutVersion}>{offerTxt}</Offer>
    </Top>
    {layoutVersion === 3 && (
      <CardImg>
        <img src={image} />
        <RemoveContainer version={layoutVersion} onClick={onRemove}>
          <Remove>
            <RemoveBar></RemoveBar>
            <RemoveBar></RemoveBar>
          </Remove>
        </RemoveContainer>
        <Line />
      </CardImg>
    )}
    <Bottom version={layoutVersion}>
      <Balance version={layoutVersion}>{currencyFormatter(balance)}</Balance>
      <Offer version={layoutVersion}>{currencyFormatter(offer)}</Offer>
    </Bottom>
    {layoutVersion === 2 && (
      <RemoveContainer version={layoutVersion} onClick={onRemove}>
        <Remove>
          <RemoveBar />
          <RemoveBar />
        </Remove>
      </RemoveContainer>
    )}
  </Container>
);

CardInCart.propTypes = {
  image: PropTypes.string,

  brand: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  offer: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  layoutVersion: PropTypes.number.isRequired,
  offerTxt: PropTypes.string.isRequired,
};

export default CardInCart;
