import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  toggleHamburger = () => {
    if (this.props.popups.get("showDropDown")) {
      document.body.style.overflow = "visible";
      this.props.toggleDropDown();
    } else {
      document.body.style.overflow = "hidden";
      this.props.toggleDropDown();
    }
  };

  capitalizeName = (name) =>
    name[0].toUpperCase() + name.slice(1, name.length) || "";

  render() {
    const { config, popups } = this.props;

    if (!config.showHeader) return null; // if show header is false break out!

    const mobileMenuItemClass = popups.get("showDropDown")
      ? "list-item animated fadeInDown"
      : "item-hide";
    const mobileIsHidden = popups.get("showDropDown")
      ? "menu hidden-lg-up"
      : "menu-hide";
    const BURGER_LEVELS = [
      ["tl", "top"],
      ["mid", "middle"],
      ["bl", "bottom"],
    ];
    const burgerClass = (showDropDown, level) =>
      showDropDown
        ? `${BURGER_LEVELS[level][0]}-active`
        : `${BURGER_LEVELS[level][1]}-line`;
    const BurgerLines = ({ showDropDown, onClick }) => (
      <li
        className="list-holder hidden-lg-up partner-header-burger-menu"
        onClick={onClick}
      >
        {[0, 1, 2].map((i) => (
          <span key={i} className={burgerClass(showDropDown, i)} />
        ))}
      </li>
    );
    const menuItems = {
      backToItem: (itemConfig) => (
        <a
          target="_blank"
          href={config.partnerURL}
          className={"item-link--v1 pointer-black partner-header-link"}
        >
          <span className={"item-span--v1"}>{itemConfig.text(config)}</span>
        </a>
      ),
      helpItem: (itemConfig) => (
        <a
          onClick={this.props.toggleContactUs}
          className={"item-link--v1 pointer-black partner-header-link"}
        >
          <i className="glyphicon1">
            <img
              src={config.headerMenuItemImage2}
              className={"item-image--v1"}
            />
          </i>
          <span className={"item-span--v1"}>{itemConfig.text(config)}</span>
        </a>
      ),
      faqItem: (itemConfig) => (
        <Link to="/faq" className={"menu-item--v1 nav-item"}>
          <i className="glyphicon1 pointer-black">
            <img
              src={config.headerMenuItemImage3}
              className={"item-image--v1"}
            />
          </i>
          <span className={"item-span--v1 pointer-black"}>
            {itemConfig.text()}
          </span>
        </Link>
      ),
    };
    const menuListDesktop = config.headerMenuItemOrder.map((name, i) => (
      <li key={i} className={"menu-item--v1 nav-item"}>
        {menuItems[name](config[`headerMenu${this.capitalizeName(name)}`])}
      </li>
    ));
    const menuListMobile = config.headerMenuItemOrder.map((name, i) => (
      <li
        onClick={this.toggleHamburger}
        key={i}
        className={`mobile-menu--v1 ${mobileMenuItemClass}`}
      >
        {menuItems[name](config[`headerMenu${this.capitalizeName(name)}`])}
      </li>
    ));

    const logos = config.headerLogoOrder.map((name, i) => {
      let [kind, value] = name.split(":");

      return (
        <span key={i}>
          <Link to="/">
            {value === undefined && (
              <React.Fragment>
                <img
                  className={`cfg-${name}--${config.configurationName} hidden-sm-down card-cash-brand-logo`}
                  src={config[name]}
                />
                <img
                  className={`cfg-${name}Icon--${config.configurationName} hidden-md-up cc-icon`}
                  src={config[name + "Icon"]}
                />
              </React.Fragment>
            )}
            {value && (
              <span
                className={`cfg-${kind}--${config.configurationName} cfg-id-${index}`}
              >
                {value}
              </span>
            )}
          </Link>
          {i != config.headerLogoOrder.length - 1 && (
            <img className={"divider"} src={config.headerLogoDivider} />
          )}
        </span>
      );
    });

    return (
      <nav
        className={
          "partner-header--v1 navbar navbar-inverse navbar-fixed-top nav-style"
        }
      >
        <div
          className={`partner-header-container--v1 container-fluid full-width ${
            config.headerContained ? "container" : null
          }`}
        >
          <ul className="nav-contain">
            <BurgerLines
              showDropDown={popups.get("showDropDown")}
              onClick={this.toggleHamburger}
            />
          </ul>

          {/* Logo */}
          <span className={"header-logo-container--v1"}>{logos}</span>

          {/* Desktop Menu */}
          <div className="pull-lg-right hidden-md-down" id="nav-content">
            <ul className={"desktop-menu--v1 nav navbar-nav"}>
              {menuListDesktop}
            </ul>
          </div>

          {/* mobile menu */}
          <ul className={`mobile-menu--v1 ${mobileIsHidden}`}>
            <li
              onClick={this.toggleHamburger}
              className={`${mobileMenuItemClass}`}
            >
              <Link to="/" className={"item-link--v1 nav-link"}>
                <span className={"item-span--v1"}>Home</span>
              </Link>
            </li>
            <li
              onClick={this.toggleHamburger}
              className={`${mobileMenuItemClass}`}
            >
              <a
                href="http://www.cardcash.com"
                target="_blank"
                className={"item-link--v1 nav-link"}
              >
                <span className={"item-span--v1"}>CardCash</span>
              </a>
            </li>
            {menuListMobile}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
