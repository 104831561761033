import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ActionContainer from "../containers/actionContainer";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import Button from "../containers/button";

const Container = styled.div`
  background-color: white;
  border-radius: 0.2rem;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding: 0 1rem 0 1rem;
    text-align: justify;
  }

  ${mediaDesktop} {
    margin: 0 auto;
    width: 100%;
  }

  ${mediaPhone} {
    overflow-y: scroll;
  }
`;

const Actions = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95px;
`;

export default () => {
  const history = useHistory();
  const title = useSelector((state) => state.config.agreementTitle);
  const acceptOfferTxt = useSelector(
    (state) => state.config.agreementAcceptOfferTxt
  );
  const buttonTxt = useSelector(
    (state) => state.config.agreementContinueButtonTxt
  );
  const content = useSelector((state) => state.config.agreementContent);

  return (
    <ActionContainer title={title} goBack={true} scrollable={true}>
      <React.Fragment>
        <Container>
          <ul>
            {content.map((line, index) => (
              <li
                key={index}
                dangerouslySetInnerHTML={{
                  __html: line,
                }}
              />
            ))}
          </ul>
          <p>{acceptOfferTxt}</p>
        </Container>
      </React.Fragment>
      <React.Fragment>
        <Actions>
          <Button
            onClick={() => {
              history.push("/card-details");
            }}
            active={true}
          >
            {buttonTxt}
          </Button>
        </Actions>
      </React.Fragment>
    </ActionContainer>
  );
};
