import React from "react";
import Modal from "react-modal";

export default ({ popups, closeAttemptsExceededBox }) => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    closeTimeoutMS={150}
    isOpen={popups.get("attemptsExceededBox")}
    onRequestClose={closeAttemptsExceededBox}
    contentLabel="Phone Verify Attempts Exceeded"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={closeAttemptsExceededBox}
        >
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title partner-specific-color">
          Attempts Exceeded
        </h4>
      </div>
      <div className="modal-body">
        <p>
          You have exceeded the number of allowed attempts to verify your phone
          number. Please contact customer service at support@cardcash.com to
          finalize your order.
        </p>
      </div>
    </div>
  </Modal>
);
