import {
  ADD_CARD,
  ADD_CARD_TO_CART,
  OPEN_ERROR_BOX,
  SET_SELL_CART_ID,
  SET_LIMITS,
  SET_ERROR,
  CARD_PARTIAL_CLEAR,
} from "../constants/actionTypes";

import { getSellCards, reorderCards } from "../utils/validators/card";

export const sendCardToCart = (
  cardBalance,
  selectedMerchant,
  cartSellId,
  cards
) => async (dispatch, getState, { history, remote }) => {
  try {
    const cartSell = await remote(
      "post",
      `/v3/carts/${getState().cartSell.cartId}/cards`,
      {
        card: {
          merchantId: selectedMerchant.id,
          enterValue: Number(cardBalance),
        },
      },
      "sending a card to a cart",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }

    const [currentCards, cardIds, cartID] = getSellCards(cartSell, cards);
    const correctlyOrderedCardsInCart =
      currentCards.length > 1
        ? reorderCards(cards, currentCards, getState().cards)
        : cardIds;

    dispatch({ type: ADD_CARD, payload: currentCards });
    dispatch({ type: ADD_CARD_TO_CART, payload: correctlyOrderedCardsInCart });
    dispatch({ type: CARD_PARTIAL_CLEAR });
  } catch (error) {
    console.log("JJJF", error);
    if (getState().config.layoutVersion === 1) {
      dispatch({
        type: OPEN_ERROR_BOX,
        payload: {
          title: "Unable to add card",
          message: (error && error.message[0]) || "Unable to add card",
        },
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload: `Unable to add card: ${error && error.message[0]}`,
      });
    }
  }
};

export const createSellCart = (amount, selectedMerchant, cards) => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    const cartSell = await remote(
      "post",
      "/v3/carts/",
      { action: "sell" },
      "creating a cart",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }

    dispatch({ type: SET_SELL_CART_ID, payload: cartSell.cartId });
    dispatch(sendCardToCart(amount, selectedMerchant, cartSell.cartId, cards));
  } catch (e) {
    if (getState().config.layoutVersion === 1) {
      dispatch({
        type: OPEN_ERROR_BOX,
        payload: {
          title: "Sorry, we are experiencing technical difficulties",
          message:
            "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
        },
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
      });
    }
  }
};

export const getCartContents = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    const cartSell = await remote(
      "get",
      "/v3/carts",
      {},
      "getting the contents of a cart",
      dispatch
    );

    if (cartSell.message) {
      throw cartSell;
    }

    const [currentCards, cardIds, cartID] = getSellCards(
      cartSell,
      getState().cards
    );
    const correctlyOrderedCardsInCart =
      currentCards.length > 1
        ? reorderCards(getState().cards, currentCards, cardIds)
        : cardIds;

    dispatch({ type: ADD_CARD, payload: currentCards });
    dispatch({ type: SET_SELL_CART_ID, payload: cartID });
    dispatch({ type: ADD_CARD_TO_CART, payload: correctlyOrderedCardsInCart });
  } catch (e) {}
};

export const getPaymentOptions = () => async (
  dispatch,
  getState,
  { history, remote }
) => {
  try {
    const paymentOptions = await remote(
      "get",
      "/v3/payments/options",
      {},
      "getting payment options",
      dispatch
    );

    if (paymentOptions.message) {
      throw paymentOptions;
    }

    dispatch({ type: SET_LIMITS, payload: paymentOptions });
  } catch (e) {
    if (getState().config.layoutVersion === 1) {
      dispatch({
        type: OPEN_ERROR_BOX,
        payload: {
          title: "Sorry, we are experiencing technical difficulties",
          message:
            "We are sorry. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
        },
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Sorry, we are experiencing technical difficulties. There was a problem connecting to the server. Please try again. If you are still having issues, please reach out to support@cardcash.com. Thank you.",
      });
    }
  }
};
