export const cardNumValid = input => {
  let sum = 0;
  let numdigits = input.length;
  let parity = numdigits % 2;
  for (let i = 0; i < numdigits; i++) {
    let digit = parseInt(input.charAt(i));
    if (i % 2 == parity) digit *= 2;
    if (digit > 9) digit -= 9;
    sum += digit;
  }
  return sum % 10 == 0;
};

export const expiryValid = input => {
  let valid = { status: false, month: "", year: "" };

  if (input.length !== 5) {
    return valid;
  }

  let formattedExpiry = input
    .replace(/[\-\\\.A-z]/g, "") // remove all unsupported characters
    .replace(/\/+/, "/") // only one forward slash
    .replace(/^(\d{2})(\d{1,2})$/, "$1/$2") // insert forward slash.
    .replace(/^(\d{2})\d*/, "$1") // only two month digits
    .replace(/(\d{2})\d*$/, "$1") // only two year digits;
    .match(/^(0[1-9]|1[0-2]{2})\/(\d{2})|(1[0-2]|0[1-9]{2})\/(\d{2})$/); // make sure first block is 0-12;

  if (formattedExpiry) {
    valid.status = true;
    valid.month = formattedExpiry[1] || formattedExpiry[3];
    valid.year = formattedExpiry[2] || formattedExpiry[4];
  }

  return valid;
};

export const cvvValid = input => {
  let formattedCvv = input
    .replace(/[\-\\\.A-z]/g, "") // remove all unsupported characters
    .match(/^(\d{3,4})$/); // make sure its only digits & length or 3 or 4;

  if (formattedCvv) {
    return true;
  }

  return false;
};
