import React from "react";
import OrderDetailsBox from "../../../components/stepThree/orderDetailsBox";

export default ({
  twilioAttemptsExceeded,
  orderID,
  partnerBrand,
  paymentType,
  partnerEmail,
  cards,
  merchantsSell,
  showDetailsOrder,
}) => {
  return (
    <div className="order-com-wrapper">
      {twilioAttemptsExceeded && (
        <div className="card-info-alert alert-text twilio-alert twilio-alert-receipt">
          We were unable to verify your phone number. Please contact customer
          service at support@cardcash.com to finalize your order.
        </div>
      )}
      <div className="container order-com">
        Thank you for choosing to exchange your gift card(s) with​ CardCash.
        Your Order ID is​:{" "}
        <span className="partner-specific-color">{orderID}</span>.
        <div className="complete-details-contain">
          <OrderDetailsBox
            cards={cards}
            merchantsSell={merchantsSell}
            showDetailsOrder={showDetailsOrder}
          />
        </div>
        <br />
        <br />
        <p className="detailed-text">
          Once we verify the balance of your​ ​gift card(s) and finish
          processing your order, we will deliver your {partnerBrand}{" "}
          {paymentType}. Please allow up to two business days​ ​to complete the
          process. If you have any questions ​or comments ​please contact us at{" "}
          <span className="partner-specific-color"> {partnerEmail} </span> or by
          calling{" "}
          <span className="partner-specific-color"> 1-800-227-4214</span>.
        </p>
        <p className="detailed-text">Thank you and have a great day.</p>
        <p className="detailed-text" className="partner-specific-color">
          {" "}
          - The CardCash Support Team{" "}
        </p>
      </div>
    </div>
  );
};
