import React from "react";
import { determineStep } from "../../../utils/determineStep";
import { ifBuilder } from "../../../utils/propTools";

export default ({
  detailStepOneText,
  detailStepTwoText,
  detailStepThreeText,
  headerTextPartOne,
  headerTextPartTwo,
  secHeaderText,
  stepsVersion,
  paymentType,
  path,
  stepsV1
}) => {
  if (stepsVersion != 1) return null;

  const step = determineStep(stepsV1, path);
  const thresholdLTEStep = ifBuilder(threshold => threshold <= step + 1);
  const arrowClass = thresholdLTEStep("steps_arrow active")("steps_arrow");
  const hidden = thresholdLTEStep("hide")("tgc partner-specific-color");
  const active = thresholdLTEStep(n => `step${n} active`)(n => `step${n}`);

  return (
    <div>
      <div className="steps">
        <div>
          <div className="step1">
            <div className="step1_icon" />
            <div className={`step_desc ${paymentType}-text-desc`}>
              {detailStepOneText.header}
            </div>
          </div>
        </div>
        <div className={arrowClass(2)} />
        <div>
          <div className={active(2)}>
            <div className="step2_icon" />
            <div className={`step_desc2 ${paymentType}-text-desc`}>
              {detailStepTwoText.header}
            </div>
          </div>
        </div>
        <div className={arrowClass(3)} />
        <div>
          <div className={active(3)}>
            <div className="step3_icon" />
            <div className={`step_desc3 ${paymentType}-text-desc`}>
              {detailStepThreeText.iconText}
            </div>
          </div>
        </div>
      </div>
      <h1 className={hidden(2)}>
        {headerTextPartOne}
        <br className="hidden-sm-up" /> {headerTextPartTwo}
        <p>{secHeaderText}</p>
      </h1>
    </div>
  );
};
