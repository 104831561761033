import React from "react";

export default ({ config }) => (
  <div className="hidden-md-down desktop-social">
    {!!config.facebookURL && (
      <a target="_blank" href={config.facebookURL}>
        <i className={`fa fa-facebook ${config.footerSocialClass}`} />
      </a>
    )}
    {!!config.twitterURL && (
      <a target="_blank" href={config.twitterURL}>
        <i className={`fa fa-twitter ${config.footerSocialClass}`} />
      </a>
    )}
    {!!config.linkedInURL && (
      <a target="_blank" href={config.linkedInURL}>
        <i className={`fa fa-linkedin ${config.footerSocialClass}`} />
      </a>
    )}
    {!!config.googleURL && (
      <a target="_blank" href={config.googleURL}>
        <i className={`fa fa-google-plus ${config.footerSocialClass}`} />
      </a>
    )}
    {!!config.instagramURL && (
      <a target="_blank" href={config.instagramURL}>
        <i className={`fa fa-instagram ${config.footerSocialClass}`} />
      </a>
    )}
    {!!config.pinterestURL && (
      <a target="_blank" href={config.pinterestURL}>
        <i className={`fa fa-pinterest ${config.footerSocialClass}`} />
      </a>
    )}
    {!!config.youTubeURL && (
      <a target="_blank" href={config.youTubeURL}>
        <i className={`fa fa-youtube ${config.footerSocialClass}`} />
      </a>
    )}
  </div>
);
