import {
  ADD_CARD_TO_CART,
  RESET_SELL,
  DELETE_CARD,
  SET_SELL_CART_ID,
  RESET,
  RESET_EXPIRE,
  SET_CARDS_UNDER_CONSIDERATION,
  COMPLETE_ORDER_SELL,
  LOGOUT,
  DELETE_ALL_CARDS,
} from "../constants/actionTypes";

const initCartSellState = {
  cardIds: [],
  cartId: null,
  customerId: null,

  cardsUnderConsideration: {},
};

export default (state = initCartSellState, action) => {
  switch (action.type) {
    case DELETE_CARD:
      let newIds = state.cardIds.filter((card) => {
        return card !== action.payload;
      });
      return {
        ...state,
        cardIds: newIds,
      };

    case DELETE_ALL_CARDS:
      return {
        ...state,
        cardIds: initCartSellState.cardIds,
      };

    case SET_SELL_CART_ID:
      return {
        ...state,
        cartId: action.payload,
      };

    case ADD_CARD_TO_CART:
      return {
        ...state,
        cardIds: action.payload,
      };

    case SET_CARDS_UNDER_CONSIDERATION:
      return {
        ...state,
        cardsUnderConsideration: action.payload,
      };

    case LOGOUT:
    case COMPLETE_ORDER_SELL:
    case RESET:
    case RESET_EXPIRE:
    case RESET_SELL:
      return initCartSellState;

    default:
      return state;
  }
};
