import React from "react";
import { connect } from "react-redux";

import { deleteCardInCart, updateStepOneFields } from "../../../thunks/cards";
import { sendCardToCart, createSellCart } from "../../../thunks/cart";

import EachCard from "../../../components/stepOne/eachCard";
import CardInCart from "../../../components/stepOne/cardInCart";
import OfferAndContinue from "../../../components/stepOne/offerAndContinue";

import { defaultAction } from "../../../actions/defaultAction";
import { currencyFormatter } from "../../../utils/display";
import totalPrice from "../../../utils/totalPrice";

import {
  POPUPS_SHOW_DONATION_INFO,
  CARD_PARTIAL_MERCHANT,
  CARD_PARTIAL_BALANCE,
  CARD_PARTIAL_CLEAR,
  OPEN_ERROR_BOX,
  DISPLAY_CARD_ERROR_PARTIAL,
  DISPLAY_MAX_ERROR_PARTIAL,
  DISPLAY_MIN_ERROR_PARTIAL,
  DISPLAY_NO_ERROR_PARTIAL,
  DISPLAY_MERCHANT_ERROR_PARTIAL,
  DISPLAY_CARD_BALANCE_FORMAT_ERROR,
  DISPLAY_MIN_BALANCE_ERROR,
  DISPLAY_MAX_BALANCE_ERROR,
  DISPLAY_NO_BALANCE_ERROR,
  HIDE_ADD_CARD_BOX,
  SHOW_ADD_CARD_BOX,
  KEEP_ADD_CARD_BOX_CLOSED,
} from "../../../constants/actionTypes";

class Main extends React.Component {
  moveToStepTwo = () => {
    const {
      cartSell,
      cardPartial,
      sendCard,
      cards,
      cardPartialClear,
      showErrorMsg,
      store,
    } = this.props;

    let total = totalPrice(cartSell, cards);

    if (
      cardPartial.selectedMerchant !== null &&
      cardPartial.balance.length > 0
    ) {
      let discount = Number(cardPartial.selectedMerchant.sellPercentage) / 100;
      let offerAmount = Number(cardPartial.balance) * discount;

      if (offerAmount > store.maxPayout) {
        showErrorMsg(
          "Sorry, please check your cart",
          `Your cart has a payout amount of ${currencyFormatter(
            offerAmount
          )}. The maximum payout we are allowed to provide is ${currencyFormatter(
            store.minPayout
          )}`
        );
      } else if (offerAmount < store.minPayout) {
        showErrorMsg(
          "Sorry, please check your cart",
          `Your cart has a payout amount of ${currencyFormatter(
            offerAmount
          )}. The minimum payout we are allowed to provide is ${currencyFormatter(
            store.minPayout
          )}`
        );
      } else if (
        offerAmount < cardPartial.selectedMerchant.limit.max &&
        offerAmount > cardPartial.selectedMerchant.limit.min
      ) {
        sendCard(
          cardPartial.balance,
          cardPartial.selectedMerchant,
          cartSell.cartId,
          this.props.cards
        );
        this.props.history.push("/steptwo");
        cardPartialClear();
      } else {
        this.props.history.push("/steptwo");
      }
    } else if (total < store.minPayout) {
      showErrorMsg(
        "Sorry, please check your cart",
        `Your cart has a payout amount of ${currencyFormatter(
          total
        )}. The minimum payout we are allowed to provide is ${currencyFormatter(
          store.minPayout
        )}`
      );
    } else if (total > store.maxPayout) {
      showErrorMsg(
        "Sorry, please check your cart",
        `Your cart has a payout amount of ${currencyFormatter(
          total
        )}. The maximum payout we are allowed to provide is ${currencyFormatter(
          store.minPayout
        )}`
      );
    } else {
      this.props.history.push("/steptwo");
    }
  };

  hideAddCardBox = () => {
    const { hideAddCardBox, keepAddCardBoxClosed } = this.props;
    hideAddCardBox();
    keepAddCardBoxClosed();
  };

  render() {
    const {
      merchantsSell,
      cards,
      cardPartial,
      dispatch,
      cartSell,
      deleteCardInCart,
      cardPartialMerchant,
      cardPartialBalance,
      sendCard,
      cardPartialClear,
      updateStepOneFields,
      showCardBalanceError,
      showMinCardError,
      showMaxCardError,
      showNoCardPartialError,
      showCardBalanceFormatError,
      showMerchantError,
      cardInCartMinError,
      cardInCartMaxError,
      cardInCartValidBalance,
      cardInCartNoBalanceError,
      createSellCart,
      config,
      showDonationInfo,
      userActions,
      showAddCardBox,
    } = this.props;

    const options = merchantsSell.sortedByName.map((merch) => ({
      value: merch.id,
      label: merch.name,
      ...merch,
    }));

    let allCardsInCart;
    if (cartSell.cardIds) {
      allCardsInCart = cartSell.cardIds.map((card, index) => {
        let merchantName = merchantsSell.hasOwnProperty(cards[card].id)
          ? merchantsSell[cards[card].id].name
          : "";
        return (
          <CardInCart
            key={index}
            index={index}
            merchantsSell={merchantsSell}
            merchantID={cards[card].id}
            options={options}
            dispatch={dispatch}
            cardVal={cards[card].cashValue}
            enterVal={cards[card].enterValue}
            cartSellID={cartSell.cartId}
            cardID={card}
            deleteCardInCart={deleteCardInCart}
            selected={{ value: cards[card].id, label: merchantName }}
            updateStepOneFields={updateStepOneFields}
            cards={cards}
            cardInCartMinError={cardInCartMinError}
            cardInCartMaxError={cardInCartMaxError}
            cardInCartValidBalance={cardInCartValidBalance}
            cardInCartNoBalanceError={cardInCartNoBalanceError}
            config={config}
          />
        );
      });
    }

    let preventGoToNextStep = false;
    for (var theCard in cards) {
      if (cards[theCard].balanceError !== "PASSED") {
        preventGoToNextStep = true;
      }
    }

    return (
      <div className="small-margins container">
        <div className="row">
          <div className="panel panel-default custBox animated fadeInUp">
            <div className="panel-body">
              {allCardsInCart}
              <EachCard
                merchID={
                  cardPartial.selectedMerchant
                    ? cardPartial.selectedMerchant.id
                    : null
                }
                cardPartialMerchant={cardPartialMerchant}
                cardPartialBalance={cardPartialBalance}
                options={options}
                dispatch={dispatch}
                sendCard={sendCard}
                cardID={cartSell.cartId}
                cardPartial={cardPartial}
                cardPartialClear={cardPartialClear}
                cards={cards}
                cartSell={cartSell}
                createSellCart={createSellCart}
                showCardBalanceError={showCardBalanceError}
                showMinCardError={showMinCardError}
                showMaxCardError={showMaxCardError}
                showNoCardPartialError={showNoCardPartialError}
                showMerchantError={showMerchantError}
                showCardBalanceFormatError={showCardBalanceFormatError}
                config={config}
                userActions={userActions}
                showAddCardBox={showAddCardBox}
                hideAddCardBox={this.hideAddCardBox}
              />
              <OfferAndContinue
                totalPrice={totalPrice(cartSell, cards)}
                moveToStepTwo={() => {
                  this.moveToStepTwo();
                }}
                preventGoToNextStep={preventGoToNextStep}
                cards={cards}
                config={config}
                showDonationInfo={showDonationInfo}
              />
              <div id="foot-notes">
                {config.balanceAsterisk && (
                  <p
                    style={{ fontSize: "smaller", color: "#777" }}
                    className="balance-asterisk"
                    dangerouslySetInnerHTML={{
                      __html: `* ${config.balanceAsterisk}`,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    merchantsSell: state.merchantsSell,
    cartSell: state.cartSell,
    cards: state.cards,
    cardPartial: state.cardPartial,
    popups: state.popups,
    config: state.config,
    userActions: state.userActions,
    store: state.store,
  }),
  (dispatch) => ({
    showDonationInfo: () => dispatch(defaultAction(POPUPS_SHOW_DONATION_INFO)),
    cardPartialMerchant: (merch) =>
      dispatch(defaultAction(CARD_PARTIAL_MERCHANT, merch)),
    cardPartialBalance: (e) =>
      dispatch(defaultAction(CARD_PARTIAL_BALANCE, e.target.value)),
    cardPartialClear: () => dispatch(defaultAction(CARD_PARTIAL_CLEAR)),
    showCardBalanceError: () =>
      dispatch(defaultAction(DISPLAY_CARD_ERROR_PARTIAL)),
    showMinCardError: () => dispatch(defaultAction(DISPLAY_MAX_ERROR_PARTIAL)),
    showMaxCardError: () => dispatch(defaultAction(DISPLAY_MIN_ERROR_PARTIAL)),
    showNoCardPartialError: () =>
      dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL)),
    showMerchantError: () =>
      dispatch(defaultAction(DISPLAY_MERCHANT_ERROR_PARTIAL)),
    showCardBalanceFormatError: () =>
      dispatch(defaultAction(DISPLAY_CARD_BALANCE_FORMAT_ERROR)),
    cardInCartMinError: (cardID) =>
      dispatch(defaultAction(DISPLAY_MIN_BALANCE_ERROR, cardID)),
    cardInCartMaxError: (cardID) =>
      dispatch(defaultAction(DISPLAY_MAX_BALANCE_ERROR, cardID)),
    cardInCartValidBalance: (cardID) =>
      dispatch(defaultAction(DISPLAY_MIN_BALANCE_ERROR, cardID)),
    cardInCartNoBalanceError: (cardID) =>
      dispatch(defaultAction(DISPLAY_NO_BALANCE_ERROR, cardID)),
    sendCard: (cardBalance, selectedMerchant, cartSell, cards) =>
      dispatch(sendCardToCart(cardBalance, selectedMerchant, cartSell, cards)),
    deleteCardInCart: (cartSellID, cardID, cards) =>
      dispatch(deleteCardInCart(cartSellID, cardID, cards)),
    updateStepOneFields: (cartSellID, cardID, merchantId, amount, cards) =>
      dispatch(
        updateStepOneFields(cartSellID, cardID, merchantId, amount, cards)
      ),
    createSellCart: (cardBalance, selectedMerchant, cards) =>
      dispatch(createSellCart(cardBalance, selectedMerchant, cards)),
    showErrorMsg: (title, msg) =>
      dispatch(defaultAction(OPEN_ERROR_BOX, { title: title, message: msg })),
    hideAddCardBox: () => dispatch(defaultAction(HIDE_ADD_CARD_BOX)),
    showAddCardBox: () => dispatch(defaultAction(SHOW_ADD_CARD_BOX)),
    keepAddCardBoxClosed: () =>
      dispatch(defaultAction(KEEP_ADD_CARD_BOX_CLOSED)),
  })
)(Main);
