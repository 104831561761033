import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import CardInCart from "../../../components/stepOne/cardInCartV2";
import StepTwoCart from "../../../components/stepTwo/stepTwoCartV2";
import Button from "../containers/button";
import {
  deleteCardInCart,
  sendCardAndPinToServer,
} from "../../../thunks/cards";

import { defaultAction } from "../../../actions/defaultAction";

import styled from "@emotion/styled";

import {
  cartIsEmptySelector,
  enteredCardsValidateSelector,
  cartSelector,
  cardsSelector,
  merchantSelector,
} from "../../../selectors";

import {
  CLEAR_CARD_AND_PIN_ENTRY,
  CLEAR_UPDATE_CARD_SERVER_ERROR,
  DISPLAY_INVALID_MATCH,
  DISPLAY_MATCH,
  UPDATE_CARD_NUMBER,
  UPDATE_CARD_PIN,
} from "../../../constants/actionTypes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputContainer = styled.div`
  width: 85%;
  margin-left: auto;
  margin-right: auto;
`;

const Actions = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95px;
`;

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    config,
    cartSell,
    cards,
    cartIsEmpty,
    enteredCardsValidate,
    merchantsSell,
  } = useSelector(
    (state) => ({
      config: state.config,
      cartSell: cartSelector(state),
      cards: cardsSelector(state),
      cartIsEmpty: cartIsEmptySelector(state),
      merchantsSell: merchantSelector(state),
      enteredCardsValidate: enteredCardsValidateSelector(state),
    }),
    shallowEqual
  );

  return (
    <Guard condition={cartIsEmpty} redirect={"/"}>
      <ActionContainer
        title={config.cardDetailsTitle}
        goBack={true}
        scrollable={true}
        minHeight={"11.5rem"}
      >
        <React.Fragment>
          {cartSell.cardIds.map(
            (card, index) =>
              card && (
                <Container key={index}>
                  <CardInCart
                    key={`cardDetailsDisplay_${index}`}
                    brand={
                      merchantsSell.hasOwnProperty(cards[card].id)
                        ? merchantsSell[cards[card].id].name
                        : ""
                    }
                    balance={+cards[card].enterValue}
                    offer={+cards[card].cashValue}
                    onRemove={() =>
                      dispatch(deleteCardInCart(cartSell.cartId, card, cards))
                    }
                    layoutVersion={config.layoutVersion}
                    image={
                      merchantsSell.hasOwnProperty(cards[card].id)
                        ? merchantsSell[cards[card].id].image
                        : ""
                    }
                    offerTxt={config.offerTxt}
                  />
                  <InputContainer>
                    <StepTwoCart
                      key={`cardDetailsInputs_${index}`}
                      merchantsSell={merchantsSell}
                      cashValue={cards[card].cashValue}
                      merchantId={cards[card].id}
                      enteredAmount={cards[card].enterValue}
                      cardIndex={index}
                      cartSellID={cartSell.cartId}
                      cardID={card}
                      updatePin={(cardID, pin) =>
                        dispatch(
                          defaultAction(UPDATE_CARD_PIN, { cardID, pin: pin })
                        )
                      }
                      updateCardNum={(cardID, num) =>
                        dispatch(
                          defaultAction(UPDATE_CARD_NUMBER, {
                            cardID,
                            number: num,
                          })
                        )
                      }
                      sendCardAndPinToServer={(
                        cartSellID,
                        cardID,
                        number,
                        pin,
                        cards
                      ) =>
                        dispatch(
                          sendCardAndPinToServer(
                            cartSellID,
                            cardID,
                            number,
                            pin,
                            cards
                          )
                        )
                      }
                      pin={cards[card].pin}
                      number={cards[card].number}
                      validation={
                        (merchantsSell[cards[card].id] &&
                          merchantsSell[cards[card].id].validation) ||
                        ""
                      }
                      displayInvalidMatch={(cardID) =>
                        dispatch(
                          defaultAction(DISPLAY_INVALID_MATCH, {
                            cardID: cardID,
                          })
                        )
                      }
                      displayMatch={(cardID) =>
                        dispatch(
                          defaultAction(DISPLAY_MATCH, { cardID: cardID })
                        )
                      }
                      cardNumEnter={cards[card].cardNumEnter}
                      pinNumEnter={cards[card].pinNumEnter}
                      cards={cards}
                      match={cards[card].match}
                      msg={cards[card].msg}
                      clearUpdateCardServerError={(cardID) =>
                        dispatch(
                          defaultAction(CLEAR_UPDATE_CARD_SERVER_ERROR, {
                            cardID,
                          })
                        )
                      }
                      clearCardAndPin={(cardID) =>
                        dispatch(
                          defaultAction(CLEAR_CARD_AND_PIN_ENTRY, { cardID })
                        )
                      }
                      config={config}
                    />
                  </InputContainer>
                </Container>
              )
          )}
        </React.Fragment>
        <React.Fragment>
          <Actions>
            <Button
              active={!enteredCardsValidate}
              onClick={() => {
                setIsSubmitting(true);
                history.push("/identity");
              }}
              disabled={isSubmitting || enteredCardsValidate}
            >
              {config.cardDetailsContinueButtonTxt}
            </Button>
          </Actions>
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
