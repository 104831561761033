import React from "react";
import FAQ, { FAQSteps, FAQHeader } from "./faq";
import FAQContent from "./faqContent";
import { connect } from "react-redux";

export class howItWorks extends React.Component {
  render() {
    switch (this.props.config.faqVersion) {
      case 1:
        return (
          <div className="faq-container">
            <div className="container">
              <div className="col-xs-12">
                <FAQ {...this.props} />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="faq-container">
            <div className="container">
              <div className="col-xs-12">
                <div className={"faq-container"}>
                  <FAQHeader
                    className={"faq-header"}
                    content={this.props.config.faq.title}
                  />
                  <FAQSteps
                    header={this.props.config.faq.stepsHeader}
                    steps={this.props.config.faq.steps}
                  />
                  <hr className={"faq-divider"} />
                  <FAQContent
                    {...this.props}
                    allFAQs={this.props.config.faq.questions}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <h1 className="no-faq">
            There are no frequently asked questions at this time.
          </h1>
        );
    }
  }
}

export default connect(
  state => ({
    config: state.config
  }),
  {}
)(howItWorks);
