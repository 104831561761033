import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import ActionContainer from "../containers/actionContainer";
import Guard from "../containers/guard";
import InputCard from "../../../components/stepOne/inputCardV3";
import Button from "../containers/button";

import {
  validBalance,
  addCard,
} from "../../../utils/validators/stepOneSell_v2";

import {
  cartIsEmptySelector,
  cartSelector,
  cardsSelector,
  cardPartialSelector,
} from "../../../selectors";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { config, cartSell, cards, cardPartial, cartIsEmpty } = useSelector(
    (state) => ({
      config: state.config,
      cartSell: cartSelector(state),
      cards: cardsSelector(state),
      cardPartial: cardPartialSelector(state),
      cartIsEmpty: cartIsEmptySelector(state),
    }),
    shallowEqual
  );

  const submitCard = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    addCard(cartSell, cardPartial, cards, dispatch, () =>
      validBalance(cardPartial, dispatch)
    );

    if (cartSell.cardIds.length) {
      history.push("/sounds-good");
    } else {
      setIsSubmitting(false);
    }
  };

  const submitBtnDisabled =
    isSubmitting ||
    (cardPartial.balance === "" && cardPartial.selectedMerchant === null) ||
    (cardPartial.balance === "" && cardPartial.selectedMerchant !== null);

  return (
    <Guard condition={!cartIsEmpty} redirect={"/sounds-good"}>
      <ActionContainer title={config.offerTitle} scrollable={false}>
        <form onSubmit={submitCard}>
          <InputCard
            offerMerchantDropDownPlaceHolder={
              config.offerMerchantDropDownPlaceHolder
            }
            offerBalanceInputPlaceHolder={config.offerBalanceInputPlaceHolder}
            cardPartial={cardPartial}
            primaryColor={config.theme.primary_color}
            variant={config.variant}
          />
        </form>
        <>
          <Button
            onClick={submitCard}
            disabled={submitBtnDisabled}
            active={!submitBtnDisabled}
          >
            {cartIsEmpty ? config.offerButtonTxt : config.offerButtonTxtMany}
          </Button>
        </>
      </ActionContainer>
    </Guard>
  );
};
