import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  cardNumValid,
  expiryValid,
  cvvValid,
} from "../../utils/validators/creditCardValidation";

import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";

const Card = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;

  ${mediaPhone} {
    flex-direction: column;
    height: 7rem;
  }
  ${mediaDesktop} {
    flex-direction: row;
  }
`;

const Num = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  ${mediaDesktop} {
    margin-right: 1rem;
    flex: 1;
  }
`;

const Expiry = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaDesktop} {
    margin-right: 1rem;
    flex: 0.5;
  }
  ${mediaPhone} {
    margin-top: 25px;
  }
`;

const CCV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.5;
  ${mediaPhone} {
    margin-top: 25px;
  }
`;

const Icon = styled.span`
  right: 1.2rem;
  position: relative;
  color: ${({ theme, active }) => (active ? theme.primary_color : "#1F2021")};

  ${mediaPhone} {
    bottom: 0.5rem;
  }
`;

const NumDesktopError = styled.div`
  margin-right: 1rem;
  margin-top: -10px;
  flex: 1;
  ${mediaPhone} {
    display: none;
  }
`;

const ExpiryDesktopError = styled.div`
  margin-top: -10px;
  flex: 1;
  margin-left: 90px;
  ${mediaPhone} {
    display: none;
  }
`;

const CVVDesktopError = styled.div`
  margin-top: -10px;
  flex: 0.9;
  margin-left: 9px;
  ${mediaPhone} {
    display: none;
  }
`;

const NumMobileError = styled.div`
  height: 5px;
  margin-top: -25px;
  ${mediaDesktop} {
    display: none;
  }
`;

const ExpiryMobileError = styled.div`
  height: 5px;
  margin-top: -25px;
  ${mediaDesktop} {
    display: none;
  }
`;

const CVVMobileError = styled.div`
  height: 5px;
  margin-top: -25px;
  ${mediaDesktop} {
    display: none;
  }
`;

const CreditCardForm = ({
  config,
  customerInfo,
  hideCreditCardNumError,
  setCreditCard,
  hideMonthError,
  selectMonth,
  hideYearError,
  selectYear,
  hideCCVError,
  setCCV,
  setCreditCardNumError,
  setMonthError,
  setYearError,
  setCCVError,
}) => {
  const [cardNumActive, setCardNumActive] = useState(false);
  const [expiry, setExpiry] = useState(
    `${
      customerInfo.get("month") && customerInfo.get("year")
        ? `${customerInfo.get("month")}/${customerInfo.get("year")}`
        : ""
    }`
  );

  const toggleCardNumActive = () => {
    setCardNumActive(!cardNumActive);
  };

  const validateExpiry = (expiryVal, showErrors = true) => {
    let validExpiry = expiryValid(expiryVal);
    if (validExpiry.status) {
      hideMonthError();
      hideYearError();
      selectMonth(validExpiry.month);
      selectYear(validExpiry.year);
    } else if (showErrors) {
      setMonthError();
      setYearError();
    }
  };

  return (
    <>
      <Card>
        <Num>
          <input
            type="text"
            defaultValue={customerInfo.get("creditCardNum")}
            onChange={(e) => {
              if (cardNumValid(e.target.value)) {
                hideCreditCardNumError();
                setCreditCard(e);
              } else {
                setCreditCardNumError();
              }
            }}
            onFocus={() => toggleCardNumActive()}
            onBlur={() => toggleCardNumActive()}
            className="form-control"
            maxLength={19}
            placeholder={config.creditCardTextPlaceHolder}
          />
          <Icon active={cardNumActive}>
            <svg
              width="16px"
              height="13px"
              viewBox="0 0 16 13"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Flow"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={`${cardNumActive ? "1" : "0.300000012"}`}
              >
                <g
                  id="icon"
                  transform="translate(-551.000000, -556.000000)"
                  fill="currentColor"
                  fillRule="nonzero"
                >
                  <g id="Form" transform="translate(232.000000, 156.000000)">
                    <g id="Group-6" transform="translate(0.000000, 282.000000)">
                      <g
                        id="Group-8"
                        transform="translate(132.000000, 113.000000)"
                      >
                        <g id="Group-5">
                          <g
                            id="credit-card"
                            transform="translate(187.000000, 5.000000)"
                          >
                            <path
                              d="M0,9.75 C0,11.5449254 1.4326888,13 3.2,13 L12.8,13 C14.5673112,13 16,11.5449254 16,9.75 L16,5.95833333 L0,5.95833333 L0,9.75 Z M12.096,7.58333333 C12.8376658,7.43037852 13.5968674,7.74736627 14.0181595,8.38588832 C14.4394517,9.02441036 14.4394517,9.85808964 14.0181595,10.4966117 C13.5968674,11.1351337 12.8376658,11.4521215 12.096,11.2991667 C12.9557434,10.2144452 12.9557434,8.66805475 12.096,7.58333333 L12.096,7.58333333 Z M9.808,7.58333333 C10.8389315,7.58333333 11.6746667,8.43212683 11.6746667,9.47916667 C11.6746667,10.5262065 10.8389315,11.375 9.808,11.375 C8.77706847,11.375 7.94133333,10.5262065 7.94133333,9.47916667 C7.94133333,8.43212683 8.77706847,7.58333333 9.808,7.58333333 Z M12.8,0 L3.2,0 C1.4326888,0 0,1.45507456 0,3.25 L16,3.25 C16,1.45507456 14.5673112,0 12.8,0 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Icon>
        </Num>
        <NumMobileError>
          <p
            className={
              customerInfo.get("_creditCardNumError") ? "errorMsg" : "hide"
            }
          >
            Not a valid credit number
          </p>
        </NumMobileError>
        <Expiry>
          <input
            type="text"
            onKeyDown={(e) => {
              if (e.keyCode === 8 && expiry.length === 1) {
                setExpiry("");
              }
            }}
            onChange={(e) => {
              if (e.target.value) {
                const expiryValue = e.target.value
                  .replace(/[^0-9]/g, "")
                  .replace(/^([2-9])$/g, "0$1/")
                  .replace(/^(1{1})([3-9])$/g, "0$1/$2")
                  .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
                setExpiry(expiryValue);
                validateExpiry(expiryValue, false);
              }
            }}
            onBlur={(e) => validateExpiry(expiry)}
            value={expiry}
            className="form-control"
            maxLength={5}
            placeholder={config.monthYearTextPlaceHolder}
          />
        </Expiry>
        <ExpiryMobileError>
          <p
            className={
              customerInfo.get("_monthError") && customerInfo.get("_yearError")
                ? "errorMsg"
                : "hide"
            }
          >
            Not a expiration date
          </p>
        </ExpiryMobileError>
        <CCV>
          <input
            type="text"
            defaultValue={customerInfo.get("ccv")}
            onChange={(e) => {
              if (e.target.value && cvvValid(e.target.value)) {
                hideCCVError();
                setCCV(e);
              } else {
                setCCVError();
              }
            }}
            className="form-control"
            maxLength={4}
            placeholder={config.cvvTextPlaceHolder}
          />
        </CCV>
        <CVVMobileError>
          <p className={customerInfo.get("_ccvError") ? "errorMsg" : "hide"}>
            Not a valid cvv
          </p>
        </CVVMobileError>
      </Card>

      <Card>
        <NumDesktopError>
          <p
            className={
              customerInfo.get("_creditCardNumError") ? "errorMsg" : "hide"
            }
          >
            Not a valid credit number
          </p>
        </NumDesktopError>
        <ExpiryDesktopError>
          <p
            className={
              customerInfo.get("_monthError") && customerInfo.get("_yearError")
                ? "errorMsg"
                : "hide"
            }
          >
            Not a expiration date
          </p>
        </ExpiryDesktopError>
        <CVVDesktopError>
          <p className={customerInfo.get("_ccvError") ? "errorMsg" : "hide"}>
            Not a valid cvv
          </p>
        </CVVDesktopError>
      </Card>
    </>
  );
};

CreditCardForm.propTypes = {
  config: PropTypes.object.isRequired,
  customerInfo: PropTypes.object.isRequired,
  hideCreditCardNumError: PropTypes.func.isRequired,
  setCreditCard: PropTypes.func.isRequired,
  hideMonthError: PropTypes.func.isRequired,
  selectMonth: PropTypes.func.isRequired,
  hideYearError: PropTypes.func.isRequired,
  selectYear: PropTypes.func.isRequired,
  hideCCVError: PropTypes.func.isRequired,
  setCCV: PropTypes.func.isRequired,
  setCreditCardNumError: PropTypes.func.isRequired,
  setMonthError: PropTypes.func.isRequired,
  setYearError: PropTypes.func.isRequired,
  setCCVError: PropTypes.func.isRequired,
};

export default CreditCardForm;
