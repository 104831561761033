import React from "react";
import PropTypes from "prop-types";

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  state = {
    error: false
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) return <p>Something went wrong. Please refresh.</p>;

    return this.props.children;
  }
}
