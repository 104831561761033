import React from "react";
import { currencyFormatter } from "../../utils/display";
import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";

const Wrapper = styled.div`
  transition: all 0.3s ease-out;
  display: flex;
  margin-top: 0.5rem;
  position: relative;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
`;
const Details = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-sizing: border-box;
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.1rem solid #c3c3c3;
`;
const Brand = styled.p`
  color: rgb(61, 61, 61);
  font-weight: bold;
`;
const Offer = styled.p`
  justify-content: flex-end;
  font-weight: bold;
  color: #42a54d;
`;
const RemoveWrapper = styled.div`
  justify-content: flex-end;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  top: 50%;
  right: -1rem;
  transform: translateY(-50%);
  text-align: center;
  position: absolute;
`;

const RemoveContainer = styled.div`
  cursor: pointer;
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.05rem;
  margin: 0.2rem;
`;

const RemoveBar = styled.div`
  background-color: darkgrey;
  top: 50%;
  width: 100%;
  height: 0.15rem;
  position: absolute;
  transform-origin: 50% 0%;
  transition: all 0.3s ease-out;
  border-radius: 0.05rem;

  &:first-of-type {
    transform: rotate(-45deg) translateY(-50%);
  }

  &:last-child {
    transform: rotate(45deg) translateY(-50%);
  }
`;

export default ({ brand, offer, onRemove }) => (
  <Wrapper>
    <Container>
      <Details>
        <Brand>{brand}</Brand>
        <Offer>{currencyFormatter(offer)}</Offer>
      </Details>
    </Container>
    <RemoveWrapper onClick={onRemove}>
      <RemoveContainer>
        <RemoveBar />
        <RemoveBar />
      </RemoveContainer>
    </RemoveWrapper>
  </Wrapper>
);
