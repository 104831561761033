import React from "react";
import Router from "../routes";

import { connect } from "react-redux";

import { startSession } from "../../../thunks/session";
import { defaultAction } from "../../../actions/defaultAction";
import { getPaypalInfo } from "../../../thunks/customerInfo.js";
import { HideIfPathContains } from "../../../components/helpers";
import loadAssets from "../../../utils/loadAssets";

import Header from "./header";
import Footer from "./footer";
import Description from "./description";
import StepsVersion1 from "./stepsVersion1";
import StepsVersion2 from "./stepsVersion2";
import SellOrderDesc from "../../../components/stepOne/sellOrderDesc";
import TermsConditions from "./footer/termsConditions";
import SessionExpireWarning from "./popups/sessionExpireWarning";
import SessionExpired from "./popups/sessionExpired";
import AccountLocked from "./popups/accountLocked";
import DonationInfoPopup from "./popups/donationInfo";

import SpecificError from "./popups/specificError";
import ContactUs from "./popups/contactUs";
import Loading from "./popups/loading";

import {
  TOGGLE_CONTACT_US,
  RESET_EXPIRE,
  HIDE_ACCOUNT_LOCKED,
  SET_PAYOUT,
  CHANGE_PAYMENT_METHOD,
  CHANGE_PAYPAL_STATUS,
  HIDE_SESSION_EXPIRE_WARNING,
  TOGGLE_DROP_DOWN,
  CLOSE_ERROR_BOX,
  SHOW_SESSION_EXPIRED,
  HIDE_SESSION_EXPIRED,
  UPDATE_PAYPAL_PROFILE_INFO,
  POPUPS_HIDE_DONATION_INFO,
  HIDE_CARDS_UNDER_CONSIDERATION_POPUP,
} from "../../../constants/actionTypes";
import utmFinder from "../../../utils/utmFinder";
import { doEvent } from "../../../thunks/events";

class layout extends React.Component {
  async componentDidMount() {
    const {
      startNewSession,
      config,
      setPartnerPayout,
      getPaypalInfo,
      router,
      doUTMEvent,
    } = this.props;
    const location = router.location;
    await startNewSession();
    setPartnerPayout(
      config.paymentType,
      config.paymentType === "donation" ? "DONATION" : "PARTNER"
    );

    const data = utmFinder(location.hash || location.search || "");

    if (data.length) doUTMEvent(data);

    //TODO: FIX DURING PAYPAL
    location.query &&
      location.query.code &&
      getPaypalInfo(location.query.code, this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.router?.location !== this.props.router.location) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "pageview",
        pageUrl: `${window?.location?.href}`,
      });
    }
  }

  findWhatRouteToGoTo = () => {
    if (Object.keys(this.props.cards).length < 1) {
      this.props.history.push("/");
    } else {
      for (let card in this.props.cards) {
        if (
          this.props.cards[card].pinError !== "PASSED" ||
          this.props.cards[card].cardError !== "PASSED" ||
          this.props.cards[card].match !== "PASSED"
        ) {
          this.props.history.push("/steptwo");
          break;
        }
      }
      this.props.history.push("/payment");
    }
  };

  render() {
    const {
      popups,
      closeError,
      toggleContactUs,
      config,
      startNewSession,
      showSessionExpiredBox,
      startNewSessionAndReset,
      hideAccountLockedBox,
      merchantsSell,
      customerInfo,
      hideDonationInfo,
      sellVerification,
      router,
    } = this.props;
    const location = router.location;

    let theClass = `cfg-${config.configurationName}`;
    if (
      sellVerification.get("paypalStatus") === "PAYPAL RECEIVED INFO" &&
      location.pathname === "/"
    ) {
      this.findWhatRouteToGoTo();
    }
    if (sellVerification.get("paypalStatus") === "PAYPAL REQUESTING INFO") {
      return <Loading showLoading={true} />;
    }
    const stepOneRoute = "/";
    const stepTwoRoute = "/steptwo";
    const stepThreeRoute = "/payment";
    const isNonStepPath =
      location.pathname === "/faq" || location.pathname === "/order-complete";
    if (
      location.pathname === stepOneRoute &&
      config.stepsVersion === 2 &&
      !isNonStepPath
    ) {
      theClass += ` steps-v2--step-one`;
    } else if (
      location.pathname === stepTwoRoute &&
      config.stepsVersion === 2 &&
      !isNonStepPath
    ) {
      theClass += ` steps-v2--step-two`;
    } else if (
      location.pathname === stepThreeRoute &&
      config.stepsVersion === 2 &&
      !isNonStepPath
    ) {
      theClass += ` steps-v2--step-three`;
    }

    return (
      <>
        <div className="flex-contain">
          {/* load Analytics & fraud tools */}
          {customerInfo.get("renderAssets") &&
            loadAssets(config.googleAnalytics)}

          {/* Headers */}
          <Header {...this.props} />
          {/* Content */}
          <HideIfPathContains
            options={["/faq", "/order-complete"].concat(config.stepsHideTopOn)}
            path={location.pathname}
          >
            <div className={theClass}>
              <Description
                detailStepOneText={config.detailStepOneText}
                detailStepTwoText={config.detailStepTwoText}
                detailStepThreeText={config.detailStepThreeText}
                path={location.pathname}
                stepsVersion={config.stepsVersion}
                steps={config.stepsV2}
              />
              <div className="container steps-container">
                {config.headline && (
                  <div className="tgc header">{config.headline}</div>
                )}
                <StepsVersion1
                  detailStepOneText={config.detailStepOneText}
                  detailStepTwoText={config.detailStepTwoText}
                  detailStepThreeText={config.detailStepThreeText}
                  headerTextPartOne={config.headerTextPartOne}
                  headerTextPartTwo={config.headerTextPartTwo}
                  secHeaderText={config.secHeaderText}
                  stepsVersion={config.stepsVersion}
                  paymentType={config.paymentType}
                  path={location.pathname}
                  stepsV1={config.stepsV1}
                />
              </div>
            </div>
          </HideIfPathContains>
          <div className="main-content">
            <Router history={history} />
          </div>
          {/* Footers */}
          <HideIfPathContains
            options={["/faq", "/order-complete"].concat(
              config.stepsHideBottomOn
            )}
            path={location.pathname}
          >
            <StepsVersion2 config={config} path={location.pathname} />
            <SellOrderDesc config={config} merchantsSell={merchantsSell} />
          </HideIfPathContains>
          <HideIfPathContains options={["faq"]} path={location.pathname}>
            <div
              className="goodbye-text"
              dangerouslySetInnerHTML={{ __html: config.goodbyeText }}
            />
          </HideIfPathContains>
          <TermsConditions config={config} toggleContactUs={toggleContactUs} />
          <Footer {...this.props} />
          {/* Popups */}
          <Loading
            showLoading={
              popups.get("showLoading") &&
              location.pathname !== stepOneRoute &&
              location.pathname !== stepTwoRoute
            }
          />
          <AccountLocked
            hideAccountLockedBox={hideAccountLockedBox}
            showAccountLockedBox={popups.get("showAccountLockedBox")}
          />
          <SessionExpired
            showSessionExpired={popups.get("showSessionExpired")}
            startNewSession={startNewSession}
            startNewSessionAndReset={startNewSessionAndReset}
          />
          <DonationInfoPopup
            hideDonationInfo={hideDonationInfo}
            popups={popups}
            config={config}
          />
          <SessionExpireWarning
            showSessionExpireWarning={popups.get("showSessionExpireWarning")}
            startNewSession={startNewSession}
            showSessionExpiredBox={showSessionExpiredBox}
          />
          <ContactUs {...this.props} />
          <SpecificError
            notification={popups.get("errorMsg")}
            errorBox={popups.get("errorBox")}
            closeError={closeError}
            title={popups.get("title")}
          />
        </div>
      </>
    );
  }
}
export default connect(
  (state) => ({
    popups: state.popups,
    config: state.config,
    cartSell: state.cartSell,
    cards: state.cards,
    merchantsSell: state.merchantsSell,
    customerInfo: state.customerInfo,
    sellVerification: state.sellVerification,
    router: state.router,
  }),
  (dispatch) => ({
    getPaypalInfo: (code, ctx) => dispatch(getPaypalInfo(code, ctx)),
    updateProfileInfo: (profileInfo) =>
      dispatch(defaultAction(UPDATE_PAYPAL_PROFILE_INFO, profileInfo)),
    hideDonationInfo: () => dispatch(defaultAction(POPUPS_HIDE_DONATION_INFO)),
    deleteSellCardInCart: (cartSellID, cardID, cards) =>
      dispatch(deleteSellCardInCart(cartSellID, cardID, cards)),
    startNewSession: async (utmData) => {
      await dispatch(startSession(utmData));
      dispatch(defaultAction(HIDE_SESSION_EXPIRE_WARNING));
      dispatch(defaultAction(HIDE_SESSION_EXPIRED));
    },
    showSessionExpiredBox: () => {
      dispatch(defaultAction(RESET_EXPIRE));
      dispatch(defaultAction(HIDE_SESSION_EXPIRE_WARNING));
      dispatch(defaultAction(SHOW_SESSION_EXPIRED));
    },
    changePaypalStatus: (status) =>
      dispatch(defaultAction(CHANGE_PAYPAL_STATUS, status)),
    hideCardsUnderConsiderationPopup: () =>
      dispatch(defaultAction(HIDE_CARDS_UNDER_CONSIDERATION_POPUP)),
    hideAccountLockedBox: () => {
      dispatch(defaultAction(HIDE_ACCOUNT_LOCKED));
    },
    closeError: () => dispatch(defaultAction(CLOSE_ERROR_BOX)),
    toggleContactUs: () => dispatch(defaultAction(TOGGLE_CONTACT_US)),
    toggleDropDown: () => dispatch(defaultAction(TOGGLE_DROP_DOWN)),
    getCarts: () => dispatch(getCartContents()),
    getSellMerch: () => dispatch(getSellMerch()),
    getPaymentOptions: () => dispatch(getPaymentOptions()),
    changePaymentMethod: (method) =>
      dispatch(defaultAction(CHANGE_PAYMENT_METHOD, method)),
    setPartnerPayout: (payoutType, payoutMethod) =>
      dispatch(
        defaultAction(SET_PAYOUT, {
          payoutType: payoutType,
          payoutMethod: payoutMethod,
        })
      ),
    doUTMEvent: (data) =>
      dispatch(doEvent("CAPTURE_UTM_DATA", { values: data })),
  })
)(layout);
