import React from "react";
import PropTypes from "prop-types";

export default class PayPalButton extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    shouldHide: PropTypes.bool
  };

  static defaultProps = {
    disabled: false,
    onClick: () => {},
    shouldHide: false
  };

  get hideContent() {
    return this.propsshouldHide && this.propsdisabled;
  }

  render() {
    return (
      <div
        className="iframe-container"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <div
          id={this.props.id}
          style={{ visibility: this.hideContent ? "hidden" : "visible" }}
        />
        {this.props.children}
        {!this.propsshouldHide && (
          <div
            onClick={this.props.onClick}
            className="veil"
            style={{
              cursor: "not-allowed",
              position: "absolute",
              display: this.propsdisabled ? "block" : "none",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }}
          />
        )}
      </div>
    );
  }
}
