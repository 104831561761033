import React from "react";
import { Link as RLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { defaultAction } from "../../../actions/defaultAction";
import { TOGGLE_DROP_DOWN } from "../../../constants/actionTypes";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import CCA from "./a";

const Nav = styled.nav`
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 30;
  ${mediaPhone} {
    position: fixed;
  }
  ${mediaDesktop} {
    position: relative;
  }
  width: 100vw;
  height: 3.5rem;
  min-height: 3.5rem;
  background-color: ${({ theme }) => `${theme.nav_backgroundColor}`};
`;

const NavContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 2.5rem;
  top: 0.5rem;
  ${mediaDesktop} {
    margin-left: 4vw;
    margin-right: 4vw;
  }
`;

const Logos = styled.span`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    max-height: ${"2rem"};
  }
`;

const BurgerWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.025rem;
  right: 0.5rem;

  ${mediaDesktop} {
    display: none;
  }
`;

const BurgerLines = styled.div`
  transition: opacity 0.5s ease, transform 0.5s ease, top 0.5s ease,
    transform-origin 0.5s ease, width 0.5s ease, left 0.5s ease,
    border-radius 0.5s ease;
  transform: translateY(-50%);
  border-radius: 0.025rem;
  width: 80%;
  left: 10%;
  height: 10%;
  position: absolute;
  transform-origin: 50% 0%;
  background-color: ${({ theme }) => `${theme.nav_linkColor}`};

  ${({ open }) =>
    open
      ? ` &:nth-of-type(1) {
        top: 20%;
        transform-origin: 9% 50%;
        transform: translateY(-50%) rotate(0deg);
      }

      &:nth-of-type(2) {
        opacity: 1;
        top: 50%;
      }

      &:nth-of-type(3) {
        top: 80%;
        transform-origin: 9% 50%;
        transform: translateY(-50%) rotate(0deg);
      }
    `
      : ` &:nth-of-type(1) {
        transform-origin: 50% 50%;
        top: 50%;
        transform: translate(0%, -50%) rotate(45deg);
      }

      &:nth-of-type(2) {
        top: 50%;
        opacity: 0;
      }

      &:nth-of-type(3) {
        top: 50%;

        transform-origin: 50% 50%;
        transform: translate(0%, -50%) rotate(-45deg);
      }`}
`;

const Links = styled.span`
  flex: 1;
  color: ${({ theme }) => `${theme.nav_linkColor}`};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transition: height 0.5s ease, background-color 0.5s ease;

  ${mediaPhone} {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    color: transparent;
    position: fixed;
    top: 3.4rem;
    height: 0;
    overflow-y: hidden;
    width: 100vw;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  ${mediaDesktop} {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
  }

  ${mediaPhone} {
    ${({ open, theme }) =>
      open
        ? ` 
            display: flex;
            background-color: ${theme.nav_backgroundColor};
            position: fixed;
            top: 3.4rem;
            height: 100vh;
            width: 100vw;
            left: 0;
            flex-direction: column;
            align-items: flex-start;
    `
        : ``}
  }
`;

const NavLink = styled(RLink)`
  color: ${({ theme }) => `${theme.nav_linkColor}`};
  text-decoration: none;
  margin-left: 1rem;
  font-weight: 300;
  ${mediaPhone} {
    margin: 1rem;
    font-size: 1.5rem;
  }
  &:visited {
    color: ${({ theme }) => `${theme.nav_linkColor}`};
  }
`;

const A = styled(CCA)`
  color: ${({ theme }) => `${theme.nav_linkColor}`};
  margin-left: 1rem;
  font-weight: 300;
  ${mediaPhone} {
    margin: 1rem;
    font-size: 1.5rem;
  }
  &:visited {
    color: ${({ theme }) => `${theme.nav_linkColor}`};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => `${theme.nav_linkColor}`};
  margin-left: 1rem;
  margin-right: 1rem;
`;

const HDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => `${theme.nav_linkColor}`};
  ${mediaDesktop} {
    display: none;
  }
`;

const Header = ({ config, popups }) => {
  if (!config.showHeader) return null; // if show header is false break out!

  const dispatch = useDispatch();
  const toggleDropDown = () => dispatch(defaultAction(TOGGLE_DROP_DOWN));
  const capitalizeName = (name) =>
    name[0].toUpperCase() + name.slice(1, name.length) || "";

  const logos = config.headerLogoOrder.map((name, i) => (
    <React.Fragment key={i}>
      <a to="/">
        <span key={i}>
          <img src={config[name + "Icon"]} />
        </span>
      </a>
      {i != config.headerLogoOrder.length - 1 && <Divider />}
    </React.Fragment>
  ));

  const menuItems = {
    backToItem: (itemConfig) => (
      <A href={config.partnerURL}>{itemConfig.text(config)}</A>
    ),
    helpItem: (itemConfig) => (
      <NavLink href={config.partnerURL} to={"/help"} onClick={toggleDropDown}>
        {itemConfig.text(config)}
      </NavLink>
    ),
    faqItem: (itemConfig) => (
      <NavLink
        to={(location) => ({
          pathname: "/faq",
          state: { referrer: `${location.pathname}` },
        })}
        onClick={toggleDropDown}
      >
        {itemConfig.text(config)}
      </NavLink>
    ),
  };

  const menuList = config.headerMenuItemOrder.map((name, i) => (
    <React.Fragment key={i}>
      {menuItems[name](config[`headerMenu${capitalizeName(name)}`])}
      <HDivider />
    </React.Fragment>
  ));

  return (
    <Nav>
      <NavContainer>
        {/* Logo */}
        <Logos>
          {logos}

          <BurgerWrapper id="BurgerWrapper" onClick={toggleDropDown}>
            <BurgerLines id="BurgerLines" open={!popups.get("showDropDown")} />
            <BurgerLines id="BurgerLines" open={!popups.get("showDropDown")} />
            <BurgerLines id="BurgerLines" open={!popups.get("showDropDown")} />
          </BurgerWrapper>
        </Logos>

        <Links open={popups.get("showDropDown")}>{menuList}</Links>
      </NavContainer>
    </Nav>
  );
};

Header.propTypes = {
  config: PropTypes.object.isRequired,
  popups: PropTypes.object.isRequired,
};

export default Header;
