import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import CCA from "../containers/a";
import { mediaDesktop } from "../../styles/Breakpoints";
import ActionContainer from "../containers/actionContainer";

const Wrapper = styled.div`
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: rgb(51, 51, 51) 0px 0px 0.1rem inset;
  border-radius: 0.2rem;
  overflow: hidden auto;
  ${mediaDesktop} {
    max-height: 18rem;
  }
`;

const Back = styled(CCA)`
  margin: 0.3rem;
  width: 100%;
  top: 1.2rem;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Title = styled.h4`
  margin-bottom: 0.2rem;
`;
const Desc = styled.p`
  p {
    margin-top: -0.8rem;
  }
`;

export default () => {
  const history = useHistory();
  const helpContent = useSelector((state) => state.config.helpContent);

  return (
    <ActionContainer>
      <React.Fragment>
        <Wrapper>
          <Title>{helpContent.title}</Title>
          <Desc
            dangerouslySetInnerHTML={{
              __html: helpContent.content, // needed to display the link
            }}
          ></Desc>
        </Wrapper>
      </React.Fragment>
      <React.Fragment>
        <Back onClick={() => history.goBack()}>Back</Back>
      </React.Fragment>
    </ActionContainer>
  );
};
