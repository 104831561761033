import React from "react";
import { remote } from "ccui-framework";
import OrderDetailsBox from "./orderDetailsBox";

import { currencyFormatter } from "../../utils/display";
import trimString from "../../utils/trimString";
import totalPrice from "../../utils/totalPrice";

import {
  CITYREGEX,
  PHONEREGEX,
  NAMEREGEX,
  ZIPREGEX,
  STREETREGEX,
  EMAILREGEX,
  NUMSREGEX,
} from "../../constants/regex";

let internalPaymentId = "";

export default class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.customerInfo.get("mainErrorMsg") ===
      "Your customer monthly order limit has been exceeded."
    ) {
      this.props.hideMainError();
    }

    let env =
      this.props.config.devMode === "production" ? "production" : "sandbox";
    if (
      typeof paypal !== "undefined" &&
      this.props.store.payoutMethod === "PAYPALEXPRESS"
    ) {
      paypal.Button.render(
        {
          env: env,
          style: {
            size: "medium",
            shape: "rect",
          },
          payment: async (resolve, reject) => {
            const { cartSell, displayMainError } = this.props;
            var number = this.validateOrder(0);
            if (number > 0) {
              reject();
              window.scrollTo(0, 0);
              displayMainError(
                "Please correct the errors below and try again."
              );
            }

            try {
              const results = await remote(
                "post",
                "/orders/paypal/checkout",
                {
                  cartId: cartSell.cartId,
                  paypalReturnUrl: "http://www.cardcash.com",
                  paypalCancelUrl: "http://www.cardcash.com",
                },
                this.props.dispatch
              );
              internalPaymentId = results.data.internalPaymentId + "";
              resolve(results.data.paymentId);
            } catch (e) {
              reject();
              displayMainError(
                "There was a problem checking out with PayPal. Please correct the errors below and try again."
              );
            }
          },
          onAuthorize: async (data) => {
            try {
              const {
                customerInfo,
                cartSell,
                showLoading,
                sellVerification,
              } = this.props;
              showLoading();

              let intPayId = data.internalPaymentId;
              let payId = data.paymentID;

              await remote(
                "put",
                "/orders/paypal/result",
                {
                  cartId: this.props.cartSell.cartId,
                  internalPaymentId: internalPaymentId,
                  paymentId: data.paymentID,
                  payerId: data.payerID,
                },
                this.props.dispatch
              );

              let orderBase = {
                autoSplit: false,
                billingDetails: {
                  firstname: trimString(sellVerification.get("firstName")),
                  lastname: trimString(sellVerification.get("lastName")),
                  street: trimString(sellVerification.get("street")),
                  street2: trimString(sellVerification.get("street2"))
                    ? trimString(sellVerification.get("street2"))
                    : "",
                  city: trimString(sellVerification.get("city")),
                  state: trimString(sellVerification.get("state")),
                  postcode: trimString(sellVerification.get("zip")),
                },
                creditCardDetails: {
                  save: false,
                  number: trimString(
                    customerInfo.get("creditCardNum").replace(/\s+/g, "")
                  ),
                  ccv: trimString(customerInfo.get("ccv")),
                  expMonth: trimString(customerInfo.get("month")),
                  expYear: trimString(customerInfo.get("year").slice(2)),
                },
                cartId: cartSell.cartId,
                internalPaymentId: parseInt(result.data.internalPaymentId),
                paymentId: payId,
                paymentMethod: "PAYPALEXPRESS",
              };

              this.props.placeOrder(orderBase, this);
            } catch (e) {}
          },
          onError: (err) => {
            // this.props.displayMainError("There was a problem checking out with PayPal. Please try another payment method or contact customer service.")
            // this.props.hideLoading()
          },
          onCancel: (data, actions) => {
            this.props.hideLoading();
          },
        },
        "#paypal-sell"
      );
    }
  }

  validateOrder = (count) => {
    const {
      customerInfo,
      cartSell,
      displayCustomerInfoErrors,
      sellVerification,
      displayShippingErrors,
      displayVerificationErrors,
      store,
    } = this.props;

    let customerInfoErrors = {
      _creditCardNumErr: false,
      _acceptTermsErr: false,
      _ccvError: false,
      _emailErr: false,
      _monthErr: false,
      _yearErr: false,
      _achNumErr: false,
      _routingNumErr: false,
      _acctTypeErr: false,
    };

    let verificationErrors = {
      firstName: false,
      lastName: false,
      street: false,
      street2: false,
      phone: false,
      zip: false,
      city: false,
      state: false,
      email: false,
    };

    let verificationErrorsCount = 0;
    let shippingErrorsCount = 0;

    if (!sellVerification.get("sameAsShipping")) {
      if (
        !sellVerification.get("firstName") ||
        !NAMEREGEX.test(trimString(sellVerification.get("firstName")))
      ) {
        verificationErrors.firstName = true;
        verificationErrorsCount++;
      }
      if (
        !sellVerification.get("lastName") ||
        !NAMEREGEX.test(trimString(sellVerification.get("lastName")))
      ) {
        verificationErrors.lastName = true;
        verificationErrorsCount++;
      }
      if (
        !sellVerification.get("street") ||
        !STREETREGEX.test(trimString(sellVerification.get("street")))
      ) {
        verificationErrors.street = true;
        verificationErrorsCount++;
      }

      if (
        sellVerification.get("street2") &&
        trimString(sellVerification.get("street2")).length
      ) {
        if (!STREETREGEX.test(sellVerification.get("street2"))) {
          verificationErrors.street2 = true;
          verificationErrorsCount++;
        }
      }

      if (
        !sellVerification.get("zip") ||
        !ZIPREGEX.test(trimString(sellVerification.get("zip")))
      ) {
        verificationErrors.zip = true;
        verificationErrorsCount++;
      }
      if (!sellVerification.get("state")) {
        verificationErrors.state = true;
        verificationErrorsCount++;
      }
      if (
        !sellVerification.get("city") ||
        !CITYREGEX.test(trimString(sellVerification.get("city")))
      ) {
        verificationErrors.city = true;
        verificationErrorsCount++;
      }
    }

    if (
      store.payoutMethod === "PARTNER" ||
      store.paymentMethod === "DONATION"
    ) {
      if (
        !sellVerification.get("phone") ||
        !PHONEREGEX.test(trimString(sellVerification.get("phone")))
      ) {
        verificationErrors.phone = true;
        verificationErrorsCount++;
      }
      if (
        !sellVerification.get("email") ||
        !EMAILREGEX.test(trimString(sellVerification.get("email")))
      ) {
        verificationErrors.email = true;
        verificationErrorsCount++;
      }
    }

    if (store.payoutMethod !== "DONATION") {
      if (
        !customerInfo.get("ccv") ||
        trimString(customerInfo.get("ccv")).length < 3
      ) {
        customerInfoErrors._ccvErr = true;
        count++;
      }
      if (!customerInfo.get("month")) {
        customerInfoErrors._monthErr = true;
        count++;
      }
      if (!customerInfo.get("year")) {
        customerInfoErrors._yearErr = true;
        count++;
      }
      if (
        !customerInfo.get("creditCardNum") ||
        trimString(customerInfo.get("creditCardNum")).length < 13
      ) {
        customerInfoErrors._creditCardNumErr = true;
        count++;
      }
    }

    if (!customerInfo.get("acceptTerms")) {
      customerInfoErrors._acceptTermsErr = true;
      count++;
    }

    if (count > 0) {
      displayCustomerInfoErrors(customerInfoErrors);
    }

    if (shippingErrorsCount > 0) {
      displayShippingErrors(shippingErrors);
    }

    if (verificationErrorsCount > 0) {
      displayVerificationErrors(verificationErrors);
    }

    return count + shippingErrorsCount + verificationErrorsCount;
  };

  checkOrder = (count) => {
    const { customerInfo, config } = this.props;

    let errors = [
      "_firstnameError",
      "_lastnameError",
      "_acceptTermsError",
      "_streetError",
      "_phoneError",
      "_zipError",
      "_stateError",
      "_cityError",
      "_emailError",
    ];

    if (config.paymentType !== "donation") {
      errors = errors.concat([
        "_ccvError",
        "_monthError",
        "_yearError",
        "_creditCardNumError",
      ]);
    }

    errors.forEach((err) => {
      if (customerInfo.get(err)) {
        count++;
      }
    });

    return count;
  };

  completeOrder() {
    const {
      customerInfo,
      sellVerification,
      cartSell,
      sellShipping,
      placeOrder,
      store,
    } = this.props;

    var number = this.validateOrder(0);
    if (number > 0) {
      window.scrollTo(0, 0);
      this.props.displayMainError(
        "Please correct the errors below and try again."
      );
      return;
    }

    let whereToGetInfoFrom =
      store.payoutMethod === "MAILCHECK" &&
      sellVerification.get("sameAsShipping")
        ? sellShipping
        : sellVerification;

    let orderBase = {
      autoSplit: false,
      cartId: cartSell.cartId,
    };
    let order = Object.assign(orderBase, {
      paymentMethod: store.payoutMethod,
      billingDetails: {
        firstname: trimString(whereToGetInfoFrom.get("firstName")),
        lastname: trimString(whereToGetInfoFrom.get("lastName")),
        street: trimString(whereToGetInfoFrom.get("street")),
        street2: trimString(whereToGetInfoFrom.get("street2"))
          ? trimString(whereToGetInfoFrom.get("street2"))
          : "",
        city: trimString(whereToGetInfoFrom.get("city")),
        state: trimString(whereToGetInfoFrom.get("state")),
        postcode: trimString(whereToGetInfoFrom.get("zip")),
      },
      customerDetails: {
        firstname: trimString(sellVerification.get("firstName")),
        lastname: trimString(sellVerification.get("lastName")),
        street: trimString(sellVerification.get("street")),
        street2: trimString(sellVerification.get("street2"))
          ? trimString(sellVerification.get("street2"))
          : "",
        city: trimString(sellVerification.get("city")),
        state: trimString(sellVerification.get("state")),
        postcode: trimString(sellVerification.get("zip")),
        email: trimString(sellVerification.get("email")),
        phone: trimString(sellVerification.get("phone")) + "",
      },
    });

    if (store.payoutMethod === "PARTNER") {
      order = Object.assign(order, {
        creditCardDetails: {
          save: false,
          number: trimString(
            customerInfo.get("creditCardNum").replace(/\s+/g, "")
          ),
          ccv: trimString(customerInfo.get("ccv")),
          expMonth: trimString(customerInfo.get("month")),
          expYear: trimString(customerInfo.get("year").slice(2)),
        },
      });
    }

    placeOrder(order, this);
  }

  isChecked = (e) => {
    if (e.target.checked) {
      this.props.hideAcceptTermsError();
      this.props.acceptTerms();
    } else {
      this.props.denyTerms();
    }
  };

  render() {
    const {
      config,
      toggleViewOrderDetails,
      customerInfo,
      stepOneRoute,
      cartSell,
      cards,
      payoutType,
      buttonText,
      hideMainError,
      displayMainError,
      history,
      merchantsSell,
      store,
    } = this.props;

    let numOfErrors = this.checkOrder(0);
    if (numOfErrors) {
      if (!customerInfo.get("mainPaymentError")) {
        window.scrollTo(0, 0);
        displayMainError("Please fix the errors and try again");
      }
    } else {
      hideMainError();
    }

    let totalCardsVal = totalPrice(cartSell, cards);
    if (store.tradeBonus) {
      let valueToAdd;
      totalCardsVal = Number(totalCardsVal);
      valueToAdd = (store.tradeBonus / 100) * totalCardsVal;
      totalCardsVal += valueToAdd;
      totalCardsVal = totalCardsVal + "";
    }

    return (
      <div
        className="panel panel-default col-md-6 order-details-container animated fadeInUp"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="detailCard">
          <h3 className={"order-details-title--v1"}>
            {config.orderDetails_title}
          </h3>
          <div>
            {config.paymentType === "DONATION" ? (
              ""
            ) : (
              <p className={"order-description--v1"}>
                After the verification process your {config.partnerBrand}{" "}
                {config.paymentType} receipt will be sent to the email provided.
              </p>
            )}
            {config.orderDetails_detailSubHeader && (
              <p
                className={"detail-sub-header--v1"}
                dangerouslySetInnerHTML={{
                  __html: config.orderDetails_detailSubHeader,
                }}
              />
            )}
            <p
              className={"view-order-details--v1"}
              style={{ paddingBottom: "5px" }}
            >
              <a
                onClick={toggleViewOrderDetails}
                className={"vod-display-status--v1 lnk"}
              >
                {customerInfo.get("showDetailsOrder") ? "Hide" : "View"} Order
                Details
              </a>
              <a
                style={{ float: "right" }}
                className={"vod-edit-link--v1 lnk"}
                onClick={() => history.push(stepOneRoute)}
              >
                Edit Cart
              </a>
            </p>
            <OrderDetailsBox
              cards={cards}
              merchantsSell={merchantsSell}
              showDetailsOrder={customerInfo.get("showDetailsOrder")}
            />
          </div>
        </div>

        <p className={"offer-amount"}>
          {config.orderDetails_offerAmount_prefix(config)}
          <strong>{currencyFormatter(totalCardsVal)}</strong>
          {config.orderDetails_offerAmount_postfix(config)}
        </p>

        <p className={"order-accept--v1"}>
          <input
            onChange={(e) => {
              this.isChecked(e);
            }}
            checked={customerInfo.get("acceptTerms")}
            type="checkbox"
            tabIndex="24"
            id="tos-checkbox"
          />{" "}
          I accept the{" "}
          <a
            href="https://www.cardcash.com/tos/"
            target="_blank"
            className="lnk"
          >
            Terms and Conditions
          </a>
          .
          {config.payment_partnerTermsAndConditions && (
            <small
              className={"partner-terms-and-conditions-asterisk--configuration"}
            >
              <sup>*</sup>
            </small>
          )}
          <span
            className={
              customerInfo.get("_acceptTermsError")
                ? "errorMsg text-center animated fadeIn"
                : "hide"
            }
          >
            You need to accept the terms
          </span>
        </p>
        {payoutType === "Paypal" ? (
          <div id="paypal-sell" className="center-flex paypal-sell-btn" />
        ) : (
          <div className="confirm-order">
            <input
              id="complete-order"
              type="submit"
              className="btn btn-primary"
              onClick={() => this.completeOrder()}
              value={buttonText}
            />
          </div>
        )}
        {config.payment_partnerTermsAndConditions && (
          <p
            className={
              "partner-terms-and-conditions-description--configuration"
            }
            dangerouslySetInnerHTML={{
              __html: config.payment_partnerTermsAndConditions,
            }}
          />
        )}
      </div>
    );
  }
}
