import React from "react";
import Modal from "react-modal";

export default props => (
  <Modal
    className="Modal__Bootstrap modal-dialog"
    closeTimeoutMS={150}
    isOpen={props.popups.get("contactus")}
    onRequestClose={props.toggleContactUs}
    contentLabel="Contact Us"
  >
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" onClick={props.toggleContactUs}>
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title partner-specific-color">Contact Us</h4>
      </div>
      <div className="modal-body">
        <p> Have any questions or concerns? We would love to hear from you.</p>
        <p>
          <strong>Email Address: </strong>
          <br className="hidden-sm-up" />
          <a
            href={`mailto:${props.config.partnerEmail ||
              `customersuccess@cardcash.com`}`}
          >
            {props.config.partnerEmail || `customersuccess@cardcash.com`}
          </a>
        </p>
        {props.config.showContactUs_phoneNumber && (
          <p>
            <strong>Phone Number:</strong>
            <br className="hidden-sm-up" />
            <a href={`tel:${props.config.contactUs_phoneNumber}`}>
              {props.config.contactUs_phoneNumber}
            </a>
          </p>
        )}
      </div>
    </div>
  </Modal>
);
