import React from "react";
import CCModal from "../modal";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../../styles/Breakpoints";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 30px;
`;

const Head = styled.div`
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  color: rgb(51, 67, 61);
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 30px 30px 0 0;
`;

const ErrorCrossContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 5px;
`;

const XIcon = styled.img`
  cursor: pointer;
  padding-top: 1rem;
`;

const Title = styled.h4``;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  box-shadow: rgba(61, 58, 53, 0.05) 0px 5px 20px 0px;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  padding: 4rem;

  p {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    margin: 0 auto;
    width: 90%;

    ${mediaPhone} {
      font-size: 12px;
    }
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #f7f7f7;
  border-radius: 30px;
  margin-bottom: 15px;

  p {
    padding: 5px;
    text-align: center;
  }

  a {
    margin: 1rem;
    color: ${({ theme }) => theme.hyperlink_color};
    font-weight: bold;
    font-size: 18px;
  }

  ${mediaPhone} {
    a {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

const ContactUs = ({
  isOpen,
  toggle,
  partnerEmail,
  showPhoneNum,
  phoneNum,
}) => (
  <CCModal isOpen={isOpen} onRequestClose={toggle}>
    <Container>
      <Head>
        <ErrorCrossContainer onClick={toggle}>
          <XIcon src="https://cdn.cardcash.com/website/partners/starbucks/close.png" />
        </ErrorCrossContainer>

        <Title>Contact CardCash</Title>
      </Head>
      <Body>
        <p> Have any questions or concerns? We would love to hear from you.</p>
        <Contact>
          <p>
            <a className="btn" href={`mailto:${partnerEmail}`}>
              {partnerEmail}
            </a>
          </p>
          {showPhoneNum && (
            <p>
              <a className="btn" href={`tel:${phoneNum}`}>
                {phoneNum}
              </a>
            </p>
          )}
        </Contact>
      </Body>
    </Container>
  </CCModal>
);

ContactUs.propTypes = {
  phoneNum: PropTypes.string,

  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  partnerEmail: PropTypes.string.isRequired,
  showPhoneNum: PropTypes.bool.isRequired,
};

export default ContactUs;
