import cartSell from "./cartSell";
import cards from "./cards";
import merchantsSell from "./merchantsSell";
import userNotifications from "./userNotifications";
import cardPartial from "./cardPartial";
import customerInfo from "./customerInfo";
import popups from "./popups";
import sellVerification from "./sellVerification";
import userInfo from "./userInfo";
import userActions from "./userActions";
import tlo from "./tlo";
import store from "./store";

export const combineReducers = {
  cartSell,
  cards,
  merchantsSell,
  customerInfo,
  cardPartial,
  userNotifications,
  popups,
  sellVerification,
  userInfo,
  userActions,
  tlo,
  store,
};
