import React, { useState } from "react";
import Modal from "react-modal";

export default ({
  isShowingTLOVerificationInit,
  showErrorModal,
  phoneNumbers,
  selectedPhoneNumberId,
  selectPhoneNumber,
  onSend,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const verifiableNumber = selectedPhoneNumberId
    ? phoneNumbers.find((phone) => phone.id === selectedPhoneNumberId)
    : null;

  return (
    <Modal
      className="Modal__Bootstrap modal-dialog modal-lg"
      isOpen={isShowingTLOVerificationInit}
      contentLabel="Additional verification required"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title text-center partner-specific-color">
            Additional verification required
          </h4>
        </div>
        <div className="modal-body" style={{ fontSize: "14px" }}>
          <div className="m_10_bottom">
            Please select from one of the following phone numbers where you can
            receive a text message or phone call.
          </div>
          <select
            className={"form-control"}
            value={selectedPhoneNumberId}
            onChange={(e) => selectPhoneNumber(e.target.value)}
          >
            <option value="">Phone Number</option>
            {phoneNumbers.map((phoneNumber, i) => (
              <option key={i} value={phoneNumber.id}>
                {phoneNumber.maskedPhone}
              </option>
            ))}
          </select>

          {selectedPhoneNumberId && verifiableNumber && (
            <div className={"notify-buttons--container"}>
              <button
                disabled={isSubmitting}
                className={"btn btn-primary"}
                onClick={() => {
                  setIsSubmitting(true);
                  onSend("sms");
                }}
              >
                TEXT <small> {verifiableNumber.maskedPhone}</small>
              </button>

              <button
                disabled={isSubmitting}
                className={"btn btn-primary"}
                onClick={() => {
                  setIsSubmitting(true);
                  onSend("call");
                }}
              >
                CALL <small> {verifiableNumber.maskedPhone}</small>
              </button>
            </div>
          )}

          <br />
          <div>
            <p>
              Phone number missing?
              <button className={"btn btn-primary"} onClick={showErrorModal}>
                Click here
              </button>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
