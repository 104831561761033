import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Text = styled.p`
  height: 300px;
  text-align: center;
`;
export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  state = {
    error: false
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    if (this.state.error)
      return <Text>Something went wrong. Please refresh.</Text>;

    return this.props.children;
  }
}
