import { Map } from "immutable";

import {
  SET_FIRST_PASS,
  SET_SECOND_PASS,
  SET_EMAIL_ADDRESS,
  VALID_TOKEN,
  INVALID_TOKEN,
  UPDATE_ERRORS,
  DISPLAY_ERROR_MSG,
  PASS_RESET,
  EMAIL_SENT,
  DISPLAY_LOADING,
  HIDE_LOADING,
  SET_PW,
  SET_SELL_PAYMENT_METHOD,
  SET_BUY_PAYMENT_METHOD,
  SHOW_LOADING,
  SET_RESET_PASSWORD_EMAIL,
  SET_SIGN_IN_ERRORS,
  HIDE_SIGNIN_EMAIL_ERROR,
  HIDE_SIGNIN_PASS_ERROR,
  DISPLAY_SIGNIN_PASS_ERROR,
  RESET,
  RESET_EXPIRE,
  PREV_PATH,
  SET_ACTIVE_CC_ROW,
  SET_ACTIVE_ACH_ROW,
  CLEAR_SET_PW,
  CLEAR_SET_EMAIL_ADDRESS,
  SET_TWILIO_ATTEMPTS_EXCEEDED,
  RESET_TWILIO_ATTEMPTS_EXCEEDED,
  SET_SLICK_DEALS_SDTID,
  SHOW_SPREADSHEET_UPLOAD_ERROR,
  HIDE_SPREADSHEET_UPLOAD_ERROR
} from "../constants/actionTypes";

const initialState = Map({
  firstPass: "",
  secondPass: "",
  pass: "",
  _passError: false,
  emailAddress: "",
  _emailError: false,
  validToken: null,
  token: null,
  passMatch: false,
  containsUpperCaseLetter: false,
  containsLowerCaseLetter: false,
  containsNumber: false,
  containsSpecialCharacter: false,
  validLength: false,
  emailSent: false,
  passReset: false,
  errorMsg: null,
  displayLoading: false,
  sellPaymentMethod: null,
  buyPaymentMethod: null,
  resetPasswordEmailAddress: null,
  prevPath: "/",
  activeRow: 0,
  activeAchRow: 0,
  changePassErrMsg: "",
  twilioAttemptsExceeded: false,
  slickDealsSdtId: null,
  spreadsheetUploadError: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_LOADING:
      return state.set("displayLoading", false);

    case DISPLAY_LOADING:
    case SHOW_LOADING:
      return state.set("displayLoading", true);

    case HIDE_SIGNIN_EMAIL_ERROR:
      return state.set("_emailError", false);

    case HIDE_SIGNIN_PASS_ERROR:
      return state.set("_passError", false);

    case SET_PW:
      return state.set("pass", action.payload);

    case CLEAR_SET_PW:
      return state.set("pass", "");

    case SET_EMAIL_ADDRESS:
      return state.set("emailAddress", action.payload);

    case CLEAR_SET_EMAIL_ADDRESS:
      return state.set("emailAddress", "");

    case SET_RESET_PASSWORD_EMAIL:
      return state.set("resetPasswordEmailAddress", action.payload);

    case SET_FIRST_PASS:
      return state.set("firstPass", action.payload);

    case SET_SECOND_PASS:
      return state.set("secondPass", action.payload);

    case VALID_TOKEN:
      return state.set("validToken", true).set("token", action.payload);

    case INVALID_TOKEN:
      return state.set("validToken", false);

    case PASS_RESET:
      return state.set("passReset", true);

    case SET_SIGN_IN_ERRORS:
      return state
        .set("_emailError", action.payload.email)
        .set("_passError", action.payload.pass);

    case EMAIL_SENT:
      return state.set("emailSent", true);

    case DISPLAY_ERROR_MSG:
      return state.set("errorMsg", "Invalid Email");

    case SET_SELL_PAYMENT_METHOD:
      return state.set("sellPaymentMethod", action.payload);

    case SET_BUY_PAYMENT_METHOD:
      return state.set("buyPaymentMethod", action.payload);

    case DISPLAY_SIGNIN_PASS_ERROR:
      return state.set("_passError", true);

    case UPDATE_ERRORS:
      return state
        .set("passMatch", action.payload.match)
        .set("containsUpperCaseLetter", action.payload.upper)
        .set("containsLowerCaseLetter", action.payload.lower)
        .set("validLength", action.payload.len)
        .set("containsSpecialCharacter", action.payload.special)
        .set("containsNumber", action.payload.num);

    case PREV_PATH:
      return state.set("prevPath", action.payload);

    case SET_ACTIVE_CC_ROW:
      return state.set("activeRow", action.payload);

    case SET_ACTIVE_ACH_ROW:
      return state.set("activeAchRow", action.payload);

    case SET_TWILIO_ATTEMPTS_EXCEEDED:
      return state.set("twilioAttemptsExceeded", true);

    case RESET_TWILIO_ATTEMPTS_EXCEEDED:
      return state.set("twilioAttemptsExceeded", false);

    case SET_SLICK_DEALS_SDTID:
      return state.set("slickDealsSdtId", action.payload);

    case SHOW_SPREADSHEET_UPLOAD_ERROR:
      return state.set("spreadsheetUploadError", true);

    case HIDE_SPREADSHEET_UPLOAD_ERROR:
      return state.set("spreadsheetUploadError", false);

    case RESET:
    case RESET_EXPIRE:
      return initialState;

    default:
      return state;
  }
};
