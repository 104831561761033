import React from "react";
import EachFAQ from "./eachFAQ";

const filterFAQ = faq => !faq.hide;

export default props => {
  const donateOrExchange = props.config.donationSite ? "donate" : "exchange";
  const donationOrExchange = props.config.donationSite
    ? "donation"
    : "exchange";
  const donateOrSell = props.config.donationSite ? "donate" : "sell";

  const allFAQs = [
    {
      question: "How much will I receive in return for my gift card?",
      answer: `Every brand is different. In order to find the exchange value for your card, please enter the brand name of the gift card and the balance to receive an offer. You are under no obligation to ${donateOrExchange} your card, and we will not retain your gift card information if you do not confirm your ${donationOrExchange}.`
    },
    {
      question: "Do you accept partially used cards or uneven balances?",
      answer:
        "Yes, we accept gift cards with any balance remaining on the gift card."
    },
    {
      question: "Do I need to mail in my gift card?",
      answer: "No, you do not need to mail in your card."
    },
    {
      question: "Can I exchange more than one gift card?",
      answer:
        'Yes, you can exchange more than one gift card. To trade in more than one, click the "Add Another Card" link.'
    },
    {
      question: `When will I receive my ${props.config.partnerBrand} ${props.config.paymentType}?`,
      answer: `As soon as you finish the process, your ${props.config.partnerBrand} eGift Card will be issued and emailed to you within 1 business day.`,
      hide: props.config.donationSite
    },
    {
      question: "How quickly will my donation be processed?",
      answer:
        "Your Charity On Top donation will be processed within 1 business day.",
      hide: !props.config.donationSite
    },
    {
      question:
        "Can I get better rates for my cards or is it the same for all gift cards?",
      answer: `Our rates are determined by market pricing, which is affected by several factors. In order to find the rate for your card, please enter your gift card information to receive an offer. You are under no obligation to ${donateOrSell} your card, and we will not retain your gift card information if you do not confirm your ${donationOrExchange}. Please note that the offer you see displayed for the same gift card of the same value may change from time to time.`
    },
    {
      question: `I received ${props.config.aOrAn} ${props.config.partnerBrand} eGift Card of lesser value than what you offered.`,
      answer: `If you believe this was in error, please contact by email at ${props.config.partnerEmail}`,
      hide: props.config.donationSite
    },
    {
      question: `Where can I find my ${props.config.partnerBrand} eGift Card?`,
      answer: "Your card will be emailed to you upon order approval.",
      hide: props.config.donationSite
    },
    {
      question: "What do you do with the gift cards?",
      answer:
        "CardCash.com is one of the biggest gift card exchanges in the world. We repurpose and then resell the gift cards at a discounted rate on our website."
    },
    {
      question: "I changed my mind. How do I get my card back?",
      answer: "We're sorry; once you've accepted the offer, the sale is final."
    },
    {
      question: "Do you accept merchandise credits?",
      answer: "Yes."
    },
    {
      question: "Do you accept cards with expiration dates?",
      answer:
        "No, unfortunately, we cannot accept gift cards that have an expiration date."
    },
    {
      question: "I’m still confused, how can I reach you?",
      answer: `Our customer service department can be reached during normal business hours or by email at ${props.config.partnerEmail}`
    }
  ];

  let allQs = [];
  if (props.allFAQs) {
    allQs = props.allFAQs
      .filter(filterFAQ)
      .map((faq, index) => (
        <EachFAQ question={faq.question} key={index} answer={faq.answer} />
      ));
  } else {
    allQs = allFAQs
      .filter(filterFAQ)
      .map((faq, index) => (
        <EachFAQ question={faq.question} key={index} answer={faq.answer} />
      ));
  }

  return <div className="pull-left faq-content">{allQs}</div>;
};
