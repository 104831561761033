import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import Guard from "../containers/guard";
import ActionContainer from "../containers/actionContainer";
import TloVerificationEnter from "../../../components/stepThree/tloVerificationEnter";
import styled from "@emotion/styled";
import { SET_TLO_ENTER_CODE } from "../../../constants/actionTypes";

import { defaultAction } from "../../../actions/defaultAction";
import { sendTLOVerification, doTLOVerify } from "../../../thunks/customerInfo";
import { isOrderIdEmptySelector, tloSelector } from "../../../selectors";

const A = styled.a`
  margin-top: 2rem;
  font-weight: bold;
`;

export default () => {
  const dispatch = useDispatch();
  const [resendCode, setResendCode] = useState(false);
  const { config, tlo, isOrderIdEmpty } = useSelector(
    (state) => ({
      config: state.config,
      tlo: tloSelector(state),
      isOrderIdEmpty: isOrderIdEmptySelector(state),
    }),
    shallowEqual
  );

  return (
    <Guard condition={isOrderIdEmpty} redirect={"/"}>
      <ActionContainer
        title={`${config.identityTloPassCodeTitle} ${
          tlo.get("validationType") === "sms" ? "we texted" : "you received"
        }  `}
      >
        <React.Fragment>
          <TloVerificationEnter
            config={config}
            enteredCode={tlo.get("enteredCode")}
            enteredCodeError={tlo.get("enteredCodeError")}
            setTLOEnteredCode={(code) =>
              dispatch(defaultAction(SET_TLO_ENTER_CODE, code))
            }
            doTLOVerify={() => dispatch(doTLOVerify())}
            showLinks={false}
          />
        </React.Fragment>
        <React.Fragment>
          <A
            onClick={() => {
              setResendCode(true);
              dispatch(sendTLOVerification());
            }}
          >
            {!resendCode ? config.tloResendCodeLinkTxt : "Code Sent"}
          </A>
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
